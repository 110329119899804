import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect } from 'react';
import {FormGroup, Modal, Alert, Button, Form, Input, Card, CardBody, CardTitle, CardHeader, CardFooter, Row, Col,} from "reactstrap";
import general_fetch from '../../utils/fetch';
import { useAlert } from 'react-alert'
import useRazorpay from "react-razorpay";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import Country_list from '../../utils/country';
import './index.css'

const stripePromise = loadStripe(global.config.stripe.key);

const studio_plan = ['Includes 2 User Licenses', 'Additional Licenses at ₹ 2,000/mo per user']
const studio_features = ['All Design Features including Architecture, interior & modular kitchen design', 
                            'Export Design as .gltf', 
                            'Production Drawings', 
                            'Includes 100 HD (or equivalent) renders /month per user',
                            'Custom Catalog',
                            'Price Quotation',
                            'Create your own design & presentation templates'
                            ]

const business_plan = ['Includes 5 User Licenses', 'Additional Licenses at ₹ 2,000/mo per user']
const business_features = ['All Studio Features Plus', 
                            'Custom Cabinet Construction mechanisms', 
                            'Cutting List & BOQ', 
                            'Custom Pricing Rules Framework', 
                            'Custom Hardware rules & design constraints',
                            'CNC Compatible output generation',
                            'Support over phone and video call']

const enterprise_plan = ['Includes 40 User Licenses', 'Additional Licenses at ₹ 2,000/mo per user']
const enterprise_features = ['All Business Features Plus',
                                'Custom output generation',
                                'API Integrations',
                                'Usage & Analytics reports', 
                                'Custom Branding',
                                'Dedicated Account Manager & Enterprise level support']

const no_of_add_ons = [1,1,1]

const add_ons = [{name: 'Manufacturing', price_IN: '10000', price_US: '250', features: ['Cutting List & BOQ', 'Custom Pricing Rules Framework', 'Custom Hardware rules & design constraints', 'CNC Compatible output generation']},
                 {name: 'API Integration', price_IN: '10000', price_US: '250', features: ['CRM / ERP Integration', 'Cabinet Creation API', 'Custom APIs to suit your workflow']},                                
                 {name: 'White Labeling', price_IN: '10000', price_US: '250', features: ['Custom URL', 'Self-Branded Landing Page', 'Self-Branded Emails']},]

const ShowPlanSummary = ({open, handleClose, plan_selected, payment_function, store_details, set_page_loader, start_pinging_status, set_show_payment_confirmation_modal, additional_users, selected_add_ons}) => {

    const stripe = useStripe();
    const elements = useElements();
    const alert = useAlert()
    const [first_order_created, set_first_order_created] = useState(false);
    const [order_details, set_order_details] = useState();
    const [line_1, set_line_1] = useState('');
    const [line_2, set_line_2] = useState('');
    const [city, set_city] = useState('');
    const [state, set_state] = useState('');
    const [pin_code, set_pin_code] = useState('');
    const [country, set_country] = useState('');
    const [currency, set_currency] = useState('\u20B9');

    const onclick_hanldeclose = () => {
        handleClose()
        set_first_order_created(false)
        set_order_details('')
    }

    const create_first_order = async() => {
        
        if(!line_1){
            alert.error('Please enter Address line 1')
            return
        }
        if(!pin_code){
            alert.error('Please enter Zipcode')
            return
        }
    
        if(!city){
            alert.error('Please enter City')
            return
        }
    
        if(!state){
            alert.error('Please enter State')
            return
        }

        try{
            set_page_loader({show:true, text: 'Please wait ...'})
            let resp = await general_fetch({url:'billing/order/request_first_order', body:{plan_type:'studio',billing_address:{line_1, line_2, city, state, country, pin_code}, country: store_details.country, additional_users, add_on_ids: selected_add_ons}})
            set_page_loader({show:false})
            set_order_details(resp)
            set_first_order_created(true)
        }catch(err){
            console.log(err)
            set_page_loader({show:false})
            alert.error('Something went wrong, please try again')
        }
    }


    const handlePayment = async() => {
        if(store_details.market === 'india'){
            payment_function(order_details?.order)
            // set_order_status('payment_button_clicked')
        }else{
        // Call your backend to retrieve the Payment Intent
        // const response = await fetch(`/payment-intents/${paymentIntentId}`);
        // const { client_secret } = await response.json();
    
        // Use Stripe.js to confirm the Payment Intent with the Payment Method collected by the CardElement
    
            // Retrieve the Card Element from Stripe Elements
            const cardElement = elements.getElement(CardElement);
    
            // Check if the card number is complete and valid
            if (cardElement && !cardElement._complete) {
            // Display an alert to the customer
            alert.error('Please enter a valid and complete card number.');
            return;
            }
    
            // If the card details are complete and valid, continue with the payment flow
            set_page_loader({show:true, text:'Please wait...'})
            const { error } = await stripe.confirmCardPayment(order_details && order_details.order ? order_details.order.payment_gateway_client_secret : '', {
            payment_method: {
                card: elements.getElement(CardElement),
            },
            });
            set_page_loader({show:false})
    
            start_pinging_status(order_details.order.id)
            if (error) {
            console.log(error);
            set_first_order_created(false)
            // set_order_status('payment_button_clicked')
            } else {
            console.log('Payment succeeded!');
            // set_order_status('payment_button_clicked')
            set_show_payment_confirmation_modal(true)
            }
        
        }
        onclick_hanldeclose()
    }

    useEffect(() => {
        if(store_details){
            set_line_1(store_details.location ? store_details.location.line_1: '');
            set_line_2(store_details.location ? store_details.location.line_2: '');
            set_city(store_details.location ? store_details.location.city: '');
            set_state(store_details.location ? store_details.location.state: '');
            set_pin_code(store_details.location ? store_details.location.pin_code: '');
            if(store_details.country){
                set_country(store_details.country)
                if(store_details.country === 'IN'){
                    set_currency('\u20B9')
                }else{
                    set_currency('$')
                }
            }else{
                if(store_details.market === 'india'){
                    set_country('IN')
                    // set_currency('\u20B9')
                }else{
                    set_country('US')
                    // set_currency('$')
                }
            }
        }
    }, [store_details]);

    return(
        <Modal isOpen={open} size='xs' className='modal-dialog-centered'>
            <CardHeader className='global_modal_header'>
                <div>{first_order_created ? 'Payment Summary (for 3 months)' : 'Billing Address'}</div>
                <i className='fa fa-times' style={{cursor:'pointer'}} onClick={onclick_hanldeclose}/>
            </CardHeader>
            <CardBody style={{minHeight:'240px', backgroundColor:'#F5F6F8'}}>
                <>
                <Row>
                    <Col xs={6}>
                        <div style={{ color: '#425A70', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                            <span style={{ backgroundColor:  !first_order_created ? '#1070CA' : 'rgba(0, 0, 0, 0.125)', color:  !first_order_created ? 'white' : 'black', justifyContent: "center", height: '25px', width: "25px", borderRadius: '50%', display: 'flex', alignItems: 'center', marginRight: '3px' }}>1</span>
                            <span>Billing Details</span>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div style={{ color: '#425A70', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                            <span style={{ backgroundColor:  first_order_created ? '#1070CA' : 'rgba(0, 0, 0, 0.125)', color:  first_order_created ? 'white' : 'black', justifyContent: "center", height: '25px', width: "25px", borderRadius: '50%', display: 'flex', alignItems: 'center', marginRight: '3px' }}>2</span>
                            <span>{'Payment Summary'}</span>
                        </div>
                    </Col>
                </Row>
            {
                first_order_created ?
                <>
                    <div style={{margin:'10px 10px 30px 10px', padding:'10px 20px', borderRadius:'6px', backgroundColor:'white', width:'calc(100% - 20px)', fontSize:'14px'}}>
                    <div className="pricingDetailsRows flex_property">
                        <div style={{ width: '50%' }}>Base Plan pricing:</div>
                        <div style={{ width: '50%', fontWeight: '700' }}>{order_details && order_details.prices ? currency + order_details.prices.base_plan_price + ' (3 months)' : 0}</div>
                      </div>
                      <div className="pricingDetailsRows flex_property">
                        <div style={{ width: '50%' }}>Number of users:</div>
                        <div style={{ width: '50%', fontWeight: '700' }}>{(order_details && order_details.prices ? order_details.prices.free_users : '0') + ' (free) + ' + (order_details && order_details.prices ? order_details.prices.additional_users : '0') + ' (paid)'}</div>
                      </div>
                      <div className="pricingDetailsRows flex_property">
                        <div style={{ width: '50%' }}>Additional user charges:</div>
                        <div style={{ width: '50%', fontWeight: '700' }}>{order_details && order_details.prices && order_details.prices.additional_users_price ? currency + order_details.prices.additional_users_price  : currency + '0'}</div>
                      </div>
                      {
                        selected_add_ons && selected_add_ons.length?
                        <div className="pricingDetailsRows flex_property">
                            <div style={{ width: '50%' }}>Add-ons:</div>
                            <div style={{fontWeight: '700'}}>
                            {
                                selected_add_ons.map((single_add_on, idx) => (
                                <div className="add_on flex_center">
                                    {add_ons[Number(single_add_on)-1].name}
                                </div>
                                ))
                            }
                            </div>
                        </div>:''
                      }
                      <div className="pricingDetailsRows flex_property">
                        <div style={{ width: '50%' }}>Add-on charges:</div>
                        <div style={{ width: '50%', fontWeight: '700' }}>{order_details && order_details.prices && order_details.prices.add_ons_price ? currency + order_details.prices.add_ons_price  : currency + '0'}</div>
                      </div>
                      <div className="flex_property" >
                        <div style={{ width: '50%' }}>Tax:</div>
                        <div style={{ width: '50%', fontWeight: '700' }}>{order_details && order_details.prices ? currency + order_details.prices.absolute_tax : 0}</div>
                      </div>
                    </div>
                    <div className='flex_property' style={{justifyContent:'space-between', margin:'20px', fontSize:'14px'}}>
                        <div>Total Amount payable (for 3 months) : </div>
                        <div style={{color:'#1A7945', fontWeight:'700', fontSize:'16px'}}>{currency} {order_details && order_details.order ? order_details.order.total : '0'}</div>
                    </div>
                    {
                        store_details.market === 'india'?'':
                        <div style={{padding:'0px 20px 20px 20px'}}>
                            <CardElement/>
                        </div>
                    }
                </>:
                <div style={{backgroundColor:'#F9FAFB', width:'100%', padding:'32px', borderRadius:'6px'}}>
                    <div className="flex_property">
                    <div style={{width:'40%', paddingRight:'8px'}}>Address line 1*</div>
                    <Input style={{width:'60%'}} type='text' value={line_1} onChange={(e) => set_line_1(e.target.value)} className="address_input"/>
                    </div>
                    <div className="flex_property">
                    <div style={{width:'40%', paddingRight:'8px'}}>Address line 2</div>
                    <Input style={{width:'60%'}} type='text' value={line_2} onChange={(e) => set_line_2(e.target.value)} className="address_input"/>
                    </div>
                    <div className="flex_property" style={{flexWrap:'wrap'}}>
                    <div className="flex_property col-12 col-lg-6" style={{paddingLeft:'0px'}}>
                        <div style={{ width:'40%', paddingRight:'8px'}}>City*</div>
                        <Input style={{ width:'60%', marginRight:'10px'}} value={city} onChange={(e) => set_city(e.target.value)} type='text' className="address_input"/>
                    </div>
                    <div className="flex_property col-12 col-lg-6">
                        <div style={{ width:'40%', paddingRight:'8px'}}>State*</div>
                        <Input type='text' style={{width:'60%'}} value={state} onChange={(e) => set_state(e.target.value)} className="address_input"/>
                    </div>
                    </div>
                    <div className="flex_property" style={{flexWrap:'wrap'}}>
                    <div className="flex_property col-12 col-lg-6" style={{paddingLeft:'0px'}}>
                        <div style={{ width:'40%', paddingRight:'8px'}}>Country*</div>
                        <Input style={{ width:'60%', marginRight:'10px'}} type='select' value={country == 'IN' ? 'india' : country} className="address_input">
                        {
                            Country_list.map((single_country) => (
                                <option value={single_country.code}>{single_country.name}</option>
                            ))
                        }
                        </Input>
                    </div>
                    <div className="flex_property col-12 col-lg-6">
                        <div style={{ width:'40%', paddingRight:'8px'}}>Zipcode*</div>
                        <Input type='text' className="address_input" value={pin_code} onChange={(e) => set_pin_code(e.target.value)} style={{width:'60%'}}/>
                    </div>
                    </div>
                </div>
            }
            </>
            </CardBody>
            <CardFooter className='global_modal_footer'>
                <Button className='blue_button' onClick={first_order_created ? handlePayment : create_first_order}>{first_order_created ? 'Proceed to Payment' : 'Confirm'}</Button>
            </CardFooter>
        </Modal>
    )
}

const ShowInfurniaPlans = ({payment_function, set_first_order, set_page_loader, plan_selected, set_plan_selected, store_details, start_pinging_status, set_show_payment_confirmation_modal}) => {
    
    const [show_plan_summary_modal, set_show_plan_summary_modal] = useState(false);
    const [view_more_info, set_view_more_info] = useState(true);
    const [additional_users, set_additional_users] = useState(0);
    const [selected_add_ons, set_selected_add_ons] = useState([]);
    const [tentative_cost, set_tentative_cost] = useState(0);
    const alert = useAlert()

    const update_selected_add_ons = (id, val) => {
        let a = [...selected_add_ons]
        if(val){
            a.push(String(id))
        }else{
            a = a.filter(x => x != String(id))
        }
        set_selected_add_ons(a)
        console.log('update_selected_add_ons', a)
    }

    const get_tentative_cost = async() => {
        try{
            if(store_details && store_details.country){
                let body = {
                    country:store_details.country,
                    plan_type: 'studio',
                    additional_users: additional_users,
                    add_on_ids: selected_add_ons
                }
            
                let resp = await general_fetch({url: 'prepaid_signup_flow/get_tentative_cost', body})
                if(resp)set_tentative_cost(resp.final_price)
            }
        }catch(err){
            alert.error('Something went wrong, please try again')
            console.log(err)
        }
    }

    useEffect(() => {
        if(store_details){
            get_tentative_cost()
        }
    }, [store_details, additional_users, selected_add_ons]);

    return(
        <div style={{height:'100%', overflow:'auto'}}>
            {/* <div style={{color:'#A7A8B2', margin:'10vh 0vh 2vh'}}>Thank you for taking the free trial</div>
            <div style={{fontSize:'24px', marginBottom:'3vh'}}>To continue with Infurnia, please upgrade your plan</div> */}
            {/* <div className='all_plans_container'> */}
                {/* <div className='col-lg-4 col-12'>
                    <div className='plan_container' style={{height: view_more_info ? '' : '380px'}}>
                        <div className='plan_name'>Studio</div>
                        <div className='plan_type'>SMEs & design studios</div>
                        <div style={{display:'flex', alignItems:'baseline', marginBottom:'5px'}}>
                            <div className='plan_price'>&#8377; 5,000</div>
                            <div className='per_month'>{'\xa0\xa0'}/mo Platform Fee</div>
                        </div>
                        {
                            studio_plan.map((single_line) => (
                                <div className='plan_points flex_center'>
                                    <div className='worksans' style={{fontSize:'16px'}}>+{'\xa0\xa0\xa0'}</div>
                                    <div className='worksans' style={{flex:'1'}}>{single_line}</div>
                                </div>
                            ))
                        }
                        <Button className='start_button_pricing' onClick = {() => {set_show_plan_summary_modal(true); set_plan_selected('studio'); set_first_order(true)}}>START NOW</Button>
                        {
                            view_more_info ? 
                            <>
                                <hr style={{margin:0, marginBottom:'30px'}}/>
                                {
                                    studio_features.map((single_line) => (
                                        <div className='plan_points flex_center'>
                                            <div className='worksans' style={{fontSize:'16px'}}><i className='fas fa-check pricing_tick' />{'\xa0\xa0\xa0'}</div>
                                            <div className='worksans' style={{flex:'1'}}>{single_line}</div>
                                        </div>
                                    ))
                                }
                            </>:''
                        }
                    </div>
                </div> */}
                {/* <div className='col-lg-4 col-12'>
                    <div className='plan_container' style={{height: view_more_info ? '' : '380px'}}>
                        <div className='plan_name'>Business</div>
                        <div className='plan_type'>Manufacturing Oriented Orgs</div>
                        <div style={{display:'flex', alignItems:'baseline', marginBottom:'5px'}}>
                            <div className='plan_price'>&#8377; 20,000</div>
                            <div className='per_month'>{'\xa0\xa0'}/mo Platform Fee</div>
                        </div>
                        {
                            business_plan.map((single_line) => (
                                <div className='plan_points flex_center'>
                                    <div className='worksans' style={{fontSize:'16px'}}>+{'\xa0\xa0\xa0'}</div>
                                    <div className='worksans' style={{flex:'1'}}>{single_line}</div>
                                </div>
                            ))
                        }
                        <Button className='start_button_pricing' onClick = {() => {create_first_order('business')}}>START NOW</Button>
                        {
                            view_more_info?
                            <>
                                <hr style={{margin:0, marginBottom:'30px'}}/>
                                {
                                    business_features.map((single_line, idx) => (
                                        <div className='plan_points flex_center'>
                                            <div className='worksans' style={{fontSize:'16px'}}><i className='fas fa-check pricing_tick' />{'\xa0\xa0\xa0'}</div>
                                            <div className='worksans' style={{flex:'1', fontWeight:idx===0?'700':''}}>{single_line}</div>
                                        </div>
                                    ))
                                }
                            </>:''
                        }
                    </div>
                </div>
                <div className='col-lg-4 col-12'>
                    <div className='plan_container' style={{height: view_more_info ? '' : '380px'}}>
                        <div className='plan_name'>Enterprise</div>
                        <div className='plan_type'>Large Organizations</div>
                        <div style={{display:'flex', alignItems:'baseline', marginBottom:'5px'}}>
                            <div className='plan_price'>&#8377; 1,00,000</div>
                            <div className='per_month'>{'\xa0\xa0'}/mo Platform Fee</div>
                        </div>
                        {
                            enterprise_plan.map((single_line) => (
                                <div className='plan_points flex_center'>
                                    <div className='worksans' style={{fontSize:'16px'}}>+{'\xa0\xa0\xa0'}</div>
                                    <div className='worksans' style={{flex:'1'}}>{single_line}</div>
                                </div>
                            ))
                        }
                        <Button className='start_button_pricing' onClick = {() => {create_first_order('enterprise')}}>START NOW</Button>
                        {
                            view_more_info?
                            <>
                                <hr style={{margin:0, marginBottom:'30px'}}/>
                                {
                                    enterprise_features.map((single_line, idx) => (
                                        <div className='plan_points flex_center'>
                                            <div className='worksans' style={{fontSize:'16px'}}><i className='fas fa-check pricing_tick' />{'\xa0\xa0\xa0'}</div>
                                            <div className='worksans' style={{flex:'1', fontWeight:idx===0?'700':''}}>{single_line}</div>
                                        </div>
                                    ))
                                }
                            </>:""
                        }
                    </div>
                </div> */}
            {/* </div> */}
            {/* <div style={{color:'#A7A8B2', margin: '10vh 0px 2vh'}}>Don’t worry, your existing data is preserved and you can continue with the same data</div>
            <div style={{color:'#A7A8B2', marginTop: '32px', position:'relative'}}>
                For feedbacks please write to us at support@infurnia.com
                {
                    !view_more_info ? <div style={{textDecoration:'underline', color:"#A7A8B2", cursor:'pointer', marginTop:'40px', position:'absolute', bottom:'15vh', left:'12vh'}} onClick={() => set_view_more_info(!view_more_info)}>View more information</div>:''
                }
            </div> */}
            <Elements stripe={stripePromise}>
                <ShowPlanSummary 
                    open = {show_plan_summary_modal}
                    handleClose={() => set_show_plan_summary_modal(false)} 
                    plan_selected={plan_selected} 
                    payment_function={payment_function} 
                    store_details={store_details} 
                    set_page_loader={set_page_loader} 
                    start_pinging_status={start_pinging_status}
                    set_show_payment_confirmation_modal = {set_show_payment_confirmation_modal}
                    additional_users={additional_users}
                    selected_add_ons={selected_add_ons}
                />
            </Elements>
            <div className='pricing_bottom_div'>
                <div>
                    <div className='flex_property'>
                        <div>Total Payable:{'\xa0\xa0\xa0\xa0'} </div>
                        <div style={{fontSize:'24px'}}>{store_details && store_details.country === 'IN' ? <span className='total_payable'>&#8377; {3 * tentative_cost} </span> : <span className='total_payable'>&#36; {3 * tentative_cost} </span> } for 3 months</div>
                        {/* <div style={{fontSize:'12px'}}>(will be charged for 3 months)</div> */}
                        {store_details.country === 'IN' ? <div style={{color:'#676878', textAlign:'left'}}>{'\xa0\xa0\xa0\xa0'} inclusive of 18% GST </div> : ''}
                    </div>
                    {/* <div style={{fontSize:'12px', color:'#676878', textAlign:'left'}}>** Payment will be required for a duration of 3 months</div> */}
                    {/* {store_details.country === 'IN' ? <div style={{fontSize:'12px', color:'#676878', textAlign:'left'}}>** All prices are exclusive of 18% GST (approx &#8377;{(18*3*tentative_cost)/100}) </div> : ''} */}
                </div>
                <div>
                    <div className='pay_button' onClick={() => {set_show_plan_summary_modal(true); set_first_order(true)}}>
                        {/* Pay {'\xa0'}{store_details && store_details.country === 'IN' ? <span>&#8377;5000 </span> : <span>&#36;125 </span> } */}
                        {/* Pay {store_details.country === 'IN' ? '\u20B9' : '$'}{(store_details.country === 'IN' ? (18*3*tentative_cost)/100 : 0) + (3 * tentative_cost)} */}
                        Pay Here
                    </div>
                    {/* <div className='mt-1' style={{fontSize:'12px', color:'#676878', textAlign:'left'}}>** will be charged for 3 months</div> */}
                </div>
            </div>
            <div style={{height: 'calc(100% - 100px', overflow:'auto'}}>
                <div className='pricing_base_plan'>
                    <div className='flex_property' style={{justifyContent:'space-between'}}>
                        <div style={{fontSize:'18px', textAlign:'left'}}>
                            Base Plan of Infurnia
                        </div>
                        <a href='https://www.infurnia.com/pricing' target='blank' style={{color:'#0078ff', fontWeight:"700", fontSize:'14px'}}>View all features</a>
                    </div>
                    <div className='flex_property' style={{marginTop:'20px', justifyContent:'space-between'}}>
                        <div className='flex_property'>
                            <div className='just_5000'>
                                <div style={{textAlign:'left', fontSize:'12px'}}>Just</div>
                                <div style={{fontSize:'20px', fontWeight:'700'}}>{store_details && store_details.country === 'IN' ? <span>&#8377; 5000 / mo</span> : <span>&#36; 125 / mo</span> }</div>
                            </div>
                            <div className='base_plan_features'>
                                <div style={{color:'#A7A8B2'}}>Includes</div>
                                <div>2 User Licenses</div>
                                <div>100 render credits per user / month</div>
                            </div>
                        </div>
                        <div className='additional_users'>
                            <div>Additional user licences at {store_details && store_details.country === 'IN' ? <span style={{color:'#0078ff'}}>&#8377; 2000/mo</span> : <span style={{color:'#0078ff'}}>&#36;50/mo</span> } per user</div>
                            <div className='flex_property mt-2'>
                                <input style={{height:'28px', width:'100px', padding:'0px 8px'}} type='number' min={0} value = {additional_users} onChange={(e) => set_additional_users(e.target.value)} />
                                <div className='price_indicator'>
                                {store_details && store_details.country === 'IN' ? <span>&#8377; {additional_users * 2000}</span> : <span>&#36; {additional_users * 50}</span> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{color:'#A7A8B2', fontWeight:'700', marginTop:'5vh', fontSize:'24px'}}>Add-ons</div>
                <div className='pricing_add_ons_container'>
                {
                    no_of_add_ons.map((single_add_on, idx) => (
                        <div className='pricing_add_on'>
                            <input type='checkbox' className='cp' style={{position:'absolute', top:'12px', left:'14px'}} onClick={() => {update_selected_add_ons(idx+1, !(selected_add_ons.includes(String(idx+1))))}}/>
                            <div className='add_on_name flex_center'>{add_ons[idx].name}</div>
                            <div className='add_on_price flex_center'>
                                {store_details && store_details.country === 'IN' ? <span>&#8377; {add_ons[idx].price_IN} / mo</span> : <span>&#36; {add_ons[idx].price_US} / mo</span> }
                            </div>
                            <div className='flex_center' style={{height:'calc(100% - 64px'}}>
                                <div className='add_on_features'>
                                    <ul style={{marginBottom:0, paddingLeft:'16px'}}>
                                    {
                                        add_ons[idx].features.map((single_feature) => (<li style={{textAlign:'left'}}>{single_feature}</li>))
                                    }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
        </div>
    )
}

export default ShowInfurniaPlans