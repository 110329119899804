import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import is_valid_add_key from '../../utils/is_valid_add_key';
import { useAlert } from 'react-alert'
import Multiselect from '../Multiselect';

// var prelam_array = [
//     {
//         id:'prelam_internal',
//         name:'Prelam Internal',
//     },{
//         id:'prelam_both',
//         name:'Prelam Both',
//     },{
//         id:'postlam',
//         name:'Postlam',
//     }
// ]
// var corner_unit_type_array = [
//     {
//         id:'l_corner',
//         name:'L Corner',
//     },{
//         id:'blind_corner',
//         name:'Blind Corner',
//     }
// ]

const KeyValuePair = ({item_json, set_item_json, mode, all_allowed_key, sku_category_type, disable_input, design_level, bulk_edit_modify, bulk_edit_delete, bulk_operation_checkboxes, update_bulk_operation_checkboxes}) => {

    // const [is_ap_open, set_is_ap_open] = useState(false);
    const alert = useAlert();
    const [all_key_options, set_all_key_options] = useState(all_allowed_key);
    const [all_key_options_array, set_all_key_options_array] = useState([])
    const [selected_keys_arrays, set_selected_keys_arrays] = useState([]);
    // const [applicable, set_applicable] = useState([]);
    // const [options_json_for_select, set_options_json_for_select] = useState([]);
    // const fetch_sku_sub_categories = async () => {
	// 	try {
	// 		var response = await general_fetch({ url: 'inventory/get_sub_categories' , body: { sku_category_type_id:'skirting_hardware' } });
	// 		// var depricated_filter_output =response && response.length ?response.filter(x => x.deprecated !=1):[]
	// 		// var resp = depricated_filter_output.length?depricated_filter_output.sort(custom_sort):[]
	// 		// resp && set_sku_sub_categories(resp);
	// 	} catch(err) {
	// 		alert.error('Something went wrong, Please refresh')
	// 		console.log(err);
	// 	}
	// }

    const add_new_item_json = () => {
        if(is_valid_add_key(item_json)){
            let a = [...item_json];
            a.push({key:'', value:''})
            set_item_json(a);
            // if(mode=='default')add_new_selected_array()
            console.log("Add", item_json)
        }else{
            alert.error("Empty or Duplicate key values not allowed.")
        }
	}

    const add_new_selected_array = () => {
        let a = [...selected_keys_arrays];
        a.push([])
        set_selected_keys_arrays(a);
    }

    // const add_new_editable_item_json_custom = (key,value) => {
    //     console.log('editable_item_json_custom',editable_item_json_custom);
    //     let a = [...editable_item_json_custom];
    //     a.push({key, value});
    //     console.log('editable_item_json_custom',a);
    //     set_editable_item_json_custom(a);
    // }

    useEffect(() => {
        if(all_allowed_key && mode=='default' && item_json){
            console.log("selected a;ll allwoed", all_allowed_key)
            let temp_array = Object.keys(all_allowed_key).map((key) => (all_allowed_key[key]))
            set_all_key_options_array(temp_array)
            // let selected_keys_arrays_temp = []
            console.log("selected before", item_json, item_json.length)
            let temp = null
            temp = item_json && item_json.length ? item_json.map((item,idx) => {
                console.log("selected item", item, all_allowed_key)
                let val = item.key!=''?[{checked:true, count:0, id:all_allowed_key[item_json[idx].key].id, name:all_allowed_key[item_json[idx].key].name, type:all_allowed_key[item_json[idx].key].type}]:''
                console.log("selected val", val)
                return item.key!=''?val:''
                // selected_keys_arrays_temp.push(val)
            }):''
            console.log("selected temp", temp, "items", item_json)
            set_selected_keys_arrays(temp)
        }
    },[all_allowed_key, item_json])

    useEffect(() =>{
		if(item_json && !item_json.length){
			add_new_item_json()
		}
		console.log('item_json=======>',item_json)
	},[item_json])

    useEffect(() => {
        {console.log("selected", selected_keys_arrays)}
    },[selected_keys_arrays])

    // useEffect(() => {
    //     if(editable_item_json_custom && !editable_item_json_custom){
    //         add_new_editable_item_json_custom();
    //     }
    // },[editable_item_json_custom])

	const delete_item_json = (index) => {
		let a = [...item_json];
		// console.log('item_json=======>',item_json,a)
		a.splice(index,1)
		// console.log('item_json=======>',item_json, a)
		set_item_json(a);
	}

    const update_item_json = (index, key, value) => {
        console.log("Add 3", index, key, value)
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
	}

    const delete_selected_arrays = (index) => {
        let a = [...selected_keys_arrays]
        a.splice(index,1)
        set_selected_keys_arrays(a)
    }

    const update_selected_arrays = (index, value) => {
        let a = [...selected_keys_arrays]
        a[index] = value
        set_selected_keys_arrays(a)
    }

    const onselect_item = (index, key, value) => {
        // if(key=='key'){
        //     if(all_allowed_key[value] && all_allowed_key[value].id=='lamination_type'){
        //         set_options_json_for_select(prelam_array)
        //     }
        //     if(all_allowed_key[value] && all_allowed_key[value].id=='corner_unit_type'){
        //         set_options_json_for_select(corner_unit_type_array)
        //     }
        // }
        if(key=='value' && all_allowed_key[item_json[index].key] && all_allowed_key[item_json[index].key].type==='number'){ 
            if(value>=0){
                update_item_json(index, key, value)
            }else{
                alert.error('Please enter a positive number')
            }   
        }else{
            update_item_json(index, key, value)
        }
    }

    // const check_applicability = (element) => {
    //     console.log('all_key_options[element.key]',all_key_options[element.key], element.key);
    //     if( all_key_options[element.key].applicability && all_key_options[element.key].applicability.length)
    //     {
    //         console.log("applicability",all_key_options[element.key].applicability, element.key, element.value);
    //         if(!(all_key_options[element.key].applicability.includes(sku_category_type))){
    //         add_new_editable_item_json_custom(element.key, element.value);
    //         return false;
    //         }
            
    //     }       
    //     return true;
    // }

    // useEffect(() => {
    //     console.log('uuuuu', editable_item_json_custom)
    //     if(mode == 'default'){
    //         // console.log('item_json', item_json)
    //         let temp =  item_json && item_json.length ? item_json.map((x,idx)=> (check_applicability(item_json[idx]))
    //         )
    //         :''
    //         set_applicable(temp);
    //     }
    // },[])

    const onclick_item = (index,value) => {
        update_selected_arrays(index,value)
        onselect_item(index,'key', value[0] ? value[0].id:'')
        console.log("selected keys", selected_keys_arrays[index])
    }

    return(
        <CardBody style={{padding:'10px 15px', fontSize:'14px', backgroundColor:'white', marginTop:'15px', overflowY:'visible'}}>
            <div>
                {
                    design_level?'':<div style={{marginBottom:'10px', fontWeight:600}}>{mode==='default'?'Default Properties':'Custom Properties'}</div>
                }
                
                {item_json && item_json.length ? item_json.map((x,idx) => { 
                    return (
                    <Row key={idx} style={{margin:0,marginTop:'10px'}}>
                        <Col xs={1} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            {(bulk_edit_delete || bulk_edit_modify) ? <input type='checkbox' checked={bulk_operation_checkboxes[idx]} onClick={() => update_bulk_operation_checkboxes(idx, !(bulk_operation_checkboxes[idx]))} style={{marginRight:'16px'}} />:''}
                            Key
                        </Col>
                        <Col xs={4} style={{paddingLeft:0, pointerEvents: (disable_input || bulk_edit_modify || bulk_edit_delete)?'none':'auto'}}>
                            {    
                                mode==='default'?
                                // <Input id={'box1'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="select" 
                                //     value={item_json[idx].key}
                                //     onChange={(e) =>onselect_item(idx,'key', e.target.value) }
                                // >
                                //     <option value='none'>Select a key</option>
                                //     {
                                //         all_key_options && Object.keys(all_key_options).length?Object.keys(all_key_options).map((item, idx) => 
                                //         // all_key_options[item].applicability && all_key_options[item].applicability.length
                                //         // ? 
                                //         // all_key_options[item].applicability.includes(sku_category_type)
                                //         // ?
                                //             <option value={item}>{all_key_options[item].name}</option>
                                //         // :''
                                //         // :''
                                //         ):''
                                //     }
                                // </Input>
                                <Multiselect 
                                    options={all_key_options_array}
                                    selectedValues = {selected_keys_arrays[idx]}
                                    onSelect={(selectedList) => onclick_item(idx,selectedList)}
                                    onRemove={(selectedList) => onclick_item(idx,selectedList)}
                                    displayValue = 'name'
                                    placeholder='Search Value'
                                    selectionLimit={1}
                                />
                                :
                                <Input id={'box1'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="text" 
                                    value={item_json[idx].key}
                                    onChange={(e) =>update_item_json(idx,'key', e.target.value) }
                                />
                            }

                        </Col>
                        <Col xs={1} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            Value
                        </Col>
                        <Col xs={4} style={{padding:0, pointerEvents:(disable_input || bulk_edit_delete || (bulk_edit_modify && !bulk_operation_checkboxes[idx]))?'none':'auto'}}>
                        {
                            mode==='default'?
                                <div>
                                    {
                                        item_json && item_json[idx] && item_json[idx].key && all_allowed_key[item_json[idx].key]?
                                        <div>
                                            {   
                                                all_allowed_key[item_json[idx].key].type==='text' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="text"
                                                    value={item_json[idx].value?item_json[idx].value:''}
                                                    onChange={(e) =>onselect_item(idx,'value', e.target.value) }
                                                    placeholder={bulk_edit_delete || bulk_edit_modify?'Mixed':''}
                                                />
                                                :''
                                            }
                                            {
                                                all_allowed_key[item_json[idx].key].type==='number' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="number"
                                                    value={item_json[idx].value?item_json[idx].value:''}
                                                    onChange={(e) =>onselect_item(idx,'value', e.target.value) }
                                                    placeholder={bulk_edit_delete || bulk_edit_modify?'Mixed':''}
                                                />
                                                :''
                                            }
                                            {/* {    
                                                all_allowed_key[item_json[idx].key].type==='select' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="select"
                                                    value={item_json[idx].value?item_json[idx].value:''}
                                                    onChange={(e) =>update_item_json(idx,'value', e.target.value) }
                                                >
                                                    <option value='none'>select a value</option>
                                                    {
                                                        options_json_for_select && options_json_for_select ? options_json_for_select.map((item, idx) => 
                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                        )
                                                        :''
                                                    }
                                                </Input>
                                                :''
                                            } */}
                                            {    
                                                all_allowed_key[item_json[idx].key].id==='lamination_type' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="select"
                                                    value={item_json[idx].value?item_json[idx].value:''}
                                                    onChange={(e) =>onselect_item(idx,'value', e.target.value) }
                                                    placeholder={bulk_edit_delete || bulk_edit_modify?'Mixed':''}
                                                >
                                                    <option value='none'>Select a value</option>
                                                    <option value='prelam_internal'>Prelam Internal</option>
                                                    <option value='prelam_both'>Prelam Both</option>
                                                    <option value='postlam'>Postlam</option>
                                                    
                                                </Input>
                                                :''
                                            }

                                            {    
                                                all_allowed_key[item_json[idx].key].id==='cabinet_type' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="select"
                                                    value={item_json[idx].value?item_json[idx].value:''}
                                                    onChange={(e) =>onselect_item(idx,'value', e.target.value) }
                                                    placeholder={bulk_edit_delete || bulk_edit_modify?'Mixed':''}
                                                >
                                                    <option value='none'>Select a value</option>
                                                    <option value='filler'>Filler</option>
                                                    <option value='wardrobe'>Wardrobe</option>
                                                    <option value='smart_cube'>Smart Cube</option>
                                                    
                                                </Input>
                                                :''
                                            }

                                            {    
                                                all_allowed_key[item_json[idx].key].id==='ply_finish_display' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="select"
                                                    value={item_json[idx].value?item_json[idx].value:''}
                                                    onChange={(e) =>onselect_item(idx,'value', e.target.value) }
                                                    placeholder={bulk_edit_delete || bulk_edit_modify?'Mixed':''}
                                                >
                                                    <option value='null'>Select a value</option>
                                                    <option value='none'>None</option>
                                                    <option value='both'>Both</option>
                                                    <option value='ply_alone'>Ply Alone</option>
                                                    <option value='finish_alone'>Finish Alone</option>
                                                    
                                                </Input>
                                                :''
                                            }

                                            {    
                                                all_allowed_key[item_json[idx].key].id==='corner_unit_type' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="select"
                                                    value={item_json[idx].value?item_json[idx].value:''}
                                                    onChange={(e) =>onselect_item(idx,'value', e.target.value) }
                                                    placeholder={bulk_edit_delete || bulk_edit_modify?'Mixed':''}
                                                >
                                                    
                                                    <option value='none'>Select a value</option>
                                                    <option value='l_corner'>L Corner</option>
                                                    <option value='blind_corner_left'>Blind Corner Left</option>
                                                    <option value='blind_corner_right'>Blind Corner Right</option>
                                                    <option value='pentagonal'>Pentagonal</option>
                                                </Input>
                                                :''
                                            }
                                            
                                            {    
                                                all_allowed_key[item_json[idx].key].id==='panel_type' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="select"
                                                    value={item_json[idx].value?item_json[idx].value:''}
                                                    onChange={(e) =>onselect_item(idx,'value', e.target.value) }
                                                    placeholder={bulk_edit_delete || bulk_edit_modify?'Mixed':''}
                                                >
                                                    
                                                    <option value='none'>Select a value</option>
                                                    <option value='rect'>Rect</option>
                                                    <option value='L_shaped'>L Shaped</option>
                                                    <option value='pentagonal'>Pentagonal</option>
                                                </Input>
                                                :''
                                            }

                                            {
                                                all_allowed_key[item_json[idx].key].id === 'handle_type' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="select"
                                                    value={item_json[idx].value?item_json[idx].value:''}
                                                    onChange={(e) =>onselect_item(idx,'value', e.target.value) }
                                                    placeholder={bulk_edit_delete || bulk_edit_modify?'Mixed':''}
                                                >
                                                    
                                                    <option value='none'>Select a value</option>
                                                    <option value='gola_horizontal_c'>Gola Horizontal C</option>
                                                    <option value='gola_horizontal_j'>Gola Horizontal J</option>
                                                    <option value='gola_horizontal_wall'>Gola Horizontal Wall</option>
                                                    <option value='gola_vertical_single'>Gola Vertical Single</option>
                                                    <option value='gola_vertical_double'>Gola Vertical Double</option>
                                                    <option value='profile'>Profile</option>
                                                    <option value='other'>Other</option>
                                                </Input>
                                                :''
                                            }

                                            {    
                                                (all_allowed_key[item_json[idx].key].id==='drawer_feature' || all_allowed_key[item_json[idx].key].id==='shelf_feature') ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="select"
                                                    value={item_json[idx].value?item_json[idx].value:''}
                                                    onChange={(e) =>onselect_item(idx,'value', e.target.value) }
                                                    placeholder={bulk_edit_delete || bulk_edit_modify?'Mixed':''}
                                                >
                                                    
                                                    <option value='none'>Select a value</option>
                                                    <option value='enabled'>Enabled</option>
                                                    <option value='disabled'>Disabled</option>
                                                </Input>
                                                :''
                                            }       

                                            {
                                                all_allowed_key[item_json[idx].key].id==='handle_placement' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="select"
                                                    value={item_json[idx].value?item_json[idx].value:''}
                                                    onChange={(e) =>onselect_item(idx,'value', e.target.value) }
                                                    placeholder={bulk_edit_delete || bulk_edit_modify?'Mixed':''}
                                                >
                                                    <option value='none'>Select a value</option>
                                                    <option value='top'>Top</option>
                                                    <option value='bottom'>Bottom</option>
                                                </Input>
                                                :''
                                            }

                                            {    
                                                all_allowed_key[item_json[idx].key].type==='bool' ?
                                                <Input id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="select"
                                                    value={item_json[idx].value?item_json[idx].value:'' }
                                                    onChange={(e) =>onselect_item(idx,'value', e.target.value) }
                                                    placeholder={bulk_edit_delete || bulk_edit_modify?'Mixed':''}
                                                >
                                                    
                                                    <option value='none'>Select a value</option>
                                                    <option value={true}>True</option>
                                                    <option value={false}>False</option>
                                                    
                                                </Input>
                                                :''
                                            }
                                            
                                        </div>
                                        :
                                        <Input disabled id={'box2'+idx} style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} type="text"/>
                                    }
                                </div>
                                :
                                <Input 
                                    id={'box2'+idx} type="text"
                                    style={{height:'100%',overflowY:'auto', fontSize:'14px', height:'32px'}} 
                                    value={item_json[idx].value}
                                    placeholder={bulk_edit_delete || bulk_edit_modify?'Mixed':''}
                                    onChange={(e) =>update_item_json(idx,'value', e.target.value) }
                                />
                            }
                        </Col>
                        <Col xs={2} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            {
                                disable_input || bulk_edit_modify || bulk_edit_delete?'':
                                <span style={{cursor:'pointer',display:'flex', alignItems:'center'}} onClick={() =>{delete_item_json(idx);if(mode=='default')delete_selected_arrays(idx)}}><i className='fa fa-trash'/></span>
                            }
                        </Col>
                    </Row>
                )}):''}
                <Row style={{margin:0}}>
                    <Col style={{textAlign:'left', padding:'10px', paddingLeft:0, marginTop:'5px', display:(bulk_edit_modify || bulk_edit_delete)?'none':'block'}}>
                        <span onClick={() => {add_new_item_json();if(mode=='default')add_new_selected_array()}}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600, opacity:disable_input?'0.5':'1'}}>Add +</span>
                    </Col>
                </Row>
            </div>
        </CardBody>
    )
}

export default KeyValuePair;
