import React , { useState , useEffect, useContext }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, Alert, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import { useAlert } from 'react-alert'
import DCKeyValuePair from '../../components/DCKeyValuePair';
import DCKeyValuePair_applicable from '../../components/DCKeyValuePair_applicable';
import EscapeWarning from '../../components/EscapeWarning';
import Multiselect from '../../components/Multiselect';
import { UserContext } from '../../components/Contexts/UserContext';
//import AlertBox from "../../components/AlertBox/";

const styles = {
	error_style: {
		color: "red",
		fontSize: 'small'
	},

};

const Wrong_Configuration_Warning = ({open, handleClose}) => {
	return (
        <div>
            <Modal style ={{marginTop:'19%', height:'200px', width:'400px'}} size="sm" isOpen={open}>
                <Card>
                    <div style = {{backgroundColor:'#F5F6F8', overFlow:'auto', padding:'5%'}}> 
                        <div style = {{paddingDown:'10%', textAlign:'center'}}><div style={{fontWeight:'900'}}>The configuration of this rule is incorrect</div>Please delete the rule and create a new one</div>
                        <div style={{textAlign:'center', paddingTop:'5%'}}>
                            <Button className='white_button' type="button" onClick={() => handleClose()}>Cancel</Button>
                            <Button className='blue_button' type="button" onClick={() => handleClose()}>OK</Button>
                        </div>
                    </div>
                </Card>
            </Modal>
        </div>
    )
} 

const DCRulesDialogBox = ({open , handleClose , active_rule , fetch_rules_details , mode, set_page_loader, tags, sales_channels, rule_type_json, properties_json,component_options_json, last_item_order, sku_linked_rule, sku_id, drawer_ids_from_component_file, all_division_tree_data }) => {

	const [active_rule_copy ,set_active_rule_copy ] =useState()
	const [rule_name , set_rule_name] = useState("");
	const [rule_description , set_rule_description] = useState("");
	const [rule_type , set_rule_type] = useState("");
	const [rule_logic , set_rule_logic] = useState("permits_only");
	const [rule_activation , set_rule_activation] = useState(false);
	const [initialized_rule_name , set_initialized_rule_name] = useState(false);
	const [initialized_rule_description , set_initialized_rule_description] = useState(false);
	const [initialized_rule_type , set_initialized_rule_type] = useState(false);
	const [initialized_rule_logic , set_initialized_rule_logic] = useState(false);
	const [initialized_rule_activation , set_initialized_rule_activation] = useState(false);
	const [applicability_combination_logic, set_applicability_combination_logic] = useState("and");
	const [source_condition_combination_logic , set_source_condition_combination_logic] = useState("and");
	const [dest_condition_combination_logic , set_dest_condition_combination_logic] = useState("and");
	const [src_component,set_src_component ] =useState('')
	const [dest_component,set_dest_component ] =useState('')
	const [src_subcomponent,set_src_subcomponent ] =useState('')
	const [dest_subcomponent,set_dest_subcomponent ] =useState('')

	const [ fix_destination_component_id, set_fix_destination_component_id ] = useState(true);
	
	const [item_json_applicable , set_item_json_applicable] = useState([]);
	const [item_json_source , set_item_json_source] = useState([]);
	const [item_json_dest , set_item_json_dest] = useState([]);
	const [initialized_item_json_applicable , set_initialized_item_json_applicable] = useState(false);
	const [initialized_item_json_source , set_initialized_item_json_source] = useState(false);
	const [initialized_item_json_dest , set_initialized_item_json_dest] = useState(false);
	const [selected_ruletype_1_array, set_selected_ruletype_1_array] = useState([]);
	const [component_options_json_filtered, set_component_options_json_filtered]  = useState([]);
	const [discard_changes, set_discard_changes] = useState(false)
	const [show_escape_warning, set_show_escape_warning] = useState(false)
	const [is_dirty, set_is_dirty] = useState(false)
	const [show_wrong_configuration_modal,set_show_wrong_configuration_modal] = useState(false)
	// const [rule_word , set_rule_word] = useState('');
	const alert = useAlert()
	const user = useContext(UserContext)

	useEffect(() => {
		console.log("rule activation", rule_activation);
	}, [rule_activation]);

	const transform_to_usable = (item_array, array_name) => {
		if(item_array && item_array.length){
			let x_temp = item_array.map(x_item =>{
				console.log('inside tf usable -->> ', x_item);
				return({
					id:x_item && x_item.id?x_item.id:null,
					value:(x_item.value && (Array.isArray(x_item.value) && x_item.value.length))? (x_item.value.length? JSON.stringify(x_item.value.map(x => x.id)):''): (x_item.value ? (x_item.property[0].type == 'singleselect' ? x_item.value : Number(x_item.value)):''),
					min_value: (x_item.min_value || x_item.min_value == 0)?Number(x_item.min_value):'',
					max_value: (x_item.max_value || x_item.max_value == 0)?Number(x_item.max_value):'',
					type: x_item.condition_type?x_item.condition_type:'absolute', 
					operator:x_item.operator?x_item.operator:'', 
					value_combination_logic:x_item.value_combination_logic?x_item.value_combination_logic:'and', 
					sku:x_item.property && x_item.property.length && x_item.property[0].id == 'sku' && x_item.sku && x_item.sku.length ?x_item.sku.map(x => x.id):null,
					tag:x_item.property && x_item.property.length && x_item.property[0].id == 'tag' && x_item.tag && x_item.tag.length? x_item.tag.map(x => x.id):null,
					sales_channel:x_item.sales_channel && x_item.sales_channel.length? x_item.sales_channel.map(x => x.id):null,
					source_filter:x_item.component? ({
						id:x_item.source_filter && x_item.source_filter.id?x_item.source_filter.id:null,
						type:'source',
						component:array_name==='applicable'?(x_item.component && x_item.component.length? x_item.component[0].id:[]) : x_item.component?x_item.component:'' ,
						sub_component:array_name==='applicable'?(x_item.subcomponent && x_item.subcomponent.length? x_item.subcomponent[0].id:[]) : x_item.subcomponent? x_item.subcomponent:'',
						property:x_item.property && x_item.property.length? x_item.property[0].id:'',
					}) : null,
					destination_filter: (x_item.relativecomponent && x_item.relativecomponent.length) ? ({
						type: 'destination',
						component:null,
						sub_component:null,
						property:null
					}) : null,
				})
			})

			for(let i = 0; i < x_temp.length; i++){
				if(x_temp[i].source_filter){
					if(x_temp[i].source_filter.property == 'tag'){
						console.log('transform_to_usablee tag')
						if(!(x_temp[i].tag && x_temp[i].tag.length && typeof x_temp[i].tag[0] === 'string')) return -1
					}else if(x_temp[i].source_filter.property == 'sku' && x_temp[i].type == 'absolute'){
						console.log('transform_to_usablee sku', x_temp[i].sku)
						if(x_temp[i].sku && x_temp[i].sku.length){
							for(let p=0; p<x_temp[i].sku.length;p++){
								if(!(x_temp[i].sku[p] && typeof x_temp[i].sku[p] === 'string')){
									return -1
								}
							}
						}
						// if(!(x_temp[i].sku && x_temp[i].sku.length && typeof x_temp[i].sku[0] === 'string')) return -1
					}else if(x_temp[i].source_filter.property == 'height' || x_temp[i].source_filter.property == 'width' || x_temp[i].source_filter.property == 'depth'){
						console.log('transform_to_usablee height')
						if(!(x_temp[i].value >=0 && typeof x_temp[i].value === 'number')) return -1
					}else if(x_temp[i].source_filter.property == 'sales_channel'){
						console.log('transform_to_usablee sales_channel')
						if(!(x_temp[i].sales_channel && x_temp[i].sales_channel.length && typeof x_temp[i].sales_channel[0] === 'string')) return -1
					}
				}
			} 

			console.log('transform_to_usable', item_array, x_temp)
			return x_temp;
		}else{
			return []
		}
	} 

	const onclick_add_new_rule = async() => {
		if((!sku_linked_rule && !(is_valid_add_key(item_json_applicable, 'applicable')) && is_valid_add_key(item_json_source,'source') && is_valid_add_key(item_json_dest,'dest'))){
			alert.error("Empty or Duplicate key values not allowed.")
		}else{
			if(rule_name && rule_description){
				try {

					// var x_selected_ids = selected_ruletype_1_array && selected_ruletype_1_array.length?selected_ruletype_1_array.map(x => x.id):[]
					// var x_options = JSON.parse(JSON.stringify(Object.values(rule_type_json)))
					// if(x_options && x_options.length){
					// 	var px_options = x_options.filter(x => {
					// 		return x_selected_ids.includes(x.id)
					// 	})
					// 	console.log('body px', x_selected_ids, px_options)
					// 	set_rule_type(px_options[0].id);
					// }
					let temp_order = last_item_order+1024;
					let applicability = []
					if(!sku_linked_rule) applicability = transform_to_usable(item_json_applicable)
					let conditions = transform_to_usable(item_json_source)
					let actions = transform_to_usable(item_json_dest)
					if(applicability == -1 || conditions == -1 || actions == -1) 
					{
						alert.error('Please fill all required details: name, description, applicable and action fields ')
						return
					}

					let body = {
						applicability_combination_logic,
						applicability:applicability,
						conditions:conditions, 
						actions: actions, 
						condition_combination_logic:source_condition_combination_logic, 
						name:rule_name, 
						description:rule_description, 
						active:rule_activation, 
						only_active:'', 
						type:'design_constraint',
						rule_type,
						rule_logic,
						action_combination_logic: dest_condition_combination_logic,
						order: last_item_order+1024,
						business_unit_id:user.current_business_unit_id
					}
					if(sku_linked_rule){
						body['sku_linked_rule'] = true
						body['sku_id'] = sku_id
					}
					var response = await general_fetch({ url: 'automation_rule/create', body});	
					onclick_handleclose()
					alert.success('Rule created Successfully !')
					fetch_rules_details()
				} catch(err) {
					console.log(err);
					alert.error("Api call failed.")
				}
			}else{
				alert.error('Please fill all required details: name, description, applicable and action fields ')
			}
		}	
	}

	const onclick_edit_rule = async() => {
		if(!(is_valid_add_key(item_json_applicable, 'applicable') && is_valid_add_key(item_json_source,'source') && is_valid_add_key(item_json_dest,'dest'))){
			alert.error("Empty or Duplicate key values not allowed.")
		}else{
			if(rule_name && rule_description){
				try {

					let applicability = transform_to_usable(item_json_applicable)
					let conditions = transform_to_usable(item_json_source)
					let actions = transform_to_usable(item_json_dest)
					if(applicability == -1 || conditions == -1 || actions == -1) 
					{
						alert.error('Please fill all required details: name, description, applicable and action fields ')
						return
					}

					let body = {
						id:active_rule.id,
						name: rule_name,
						description: rule_description,
						type: 'design_constraint',
						rule_type,
						rule_logic,
						active:rule_activation,
						only_active:'', 

						applicability:applicability,
						conditions:conditions, 
						actions: actions, 

						applicability_combination_logic: applicability_combination_logic,
						condition_combination_logic:source_condition_combination_logic, 
						action_combination_logic: dest_condition_combination_logic,

						order: active_rule.order
					}
					var response = await general_fetch({ url: 'automation_rule/update', body});	
					onclick_handleclose()
					alert.success('Rule edited Successfully !')
					fetch_rules_details()
				} catch(err) {
					console.log(err);
					alert.error("Api call failed.")
				}
			}else{
				alert.error('Please fill all required details: name, description, applicable and action fields ')
			}
		}
	}
	
	useEffect(() => {
		console.log("active rule", active_rule)
		console.log("rule type", rule_type);
		if (active_rule) {
			if (active_rule.applicability && active_rule.applicability.length) {
				var x_component = component_options_json?component_options_json.filter(x =>active_rule.applicability[0].source_filter && x.id===active_rule.applicability[0].source_filter.component):[]
				var x_sub_component = x_component && x_component.length && x_component[0].sub_component_options && x_component[0].sub_component_options.length? x_component[0].sub_component_options.filter(x => x.id===active_rule.applicability[0].source_filter.sub_component):[]
				var x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length? x_sub_component[0].properties.filter(x =>x.id===active_rule.applicability[0].source_filter.property):[]

				var x_applicable_list = active_rule.applicability&& active_rule.applicability.length?active_rule.applicability.map(x_item => {return{
					...x_item, 
					// component : x_component,
					// subcomponent : x_sub_component,
					// property : x_selected_property,
					// tag: item.tag && item.tag.length && tags && tags.length? tags.filter(x => item.tag.includes(x.id)):[]
					component: x_item.source_filter ? x_item.source_filter.component : [], 
					subcomponent:x_item.source_filter ?x_item.source_filter.sub_component:null, 
					property: x_item.source_filter ? x_item.source_filter.property : []
				}}):[]
			}
			let x_source_list = active_rule.conditions && active_rule.conditions.length ? active_rule.conditions.map(x_item => {
				return {
					...x_item, 
					component: x_item.source_filter ? x_item.source_filter.component : [], 
					subcomponent:x_item.source_filter ?x_item.source_filter.sub_component:null, 
					property: x_item.source_filter ? x_item.source_filter.property : []
				}
			}):[]
			var x_dest_list = active_rule.actions&& active_rule.actions.length?active_rule.actions.map(x_item => {
				return {
					...x_item, 
					component:x_item.source_filter ?x_item.source_filter.component:[], 
					subcomponent:x_item.source_filter ?x_item.source_filter.sub_component:null, 
					property:x_item.source_filter ?x_item.source_filter.property:[]
				}
			}):[]
			set_rule_type(active_rule.rule_type)
			set_selected_ruletype_1_array([{'id': active_rule.rule_type}])
			console.log("RUUUUUUUUUUU", rule_type_json.active_rule, 'll',active_rule, JSON.stringify(x_source_list), JSON.stringify(x_dest_list));
			set_rule_logic(active_rule.rule_logic)
			set_item_json_applicable(x_applicable_list)
			set_item_json_source(x_source_list)
			console.log("x_source_list", x_source_list);
			set_item_json_dest(x_dest_list)
			set_rule_name(active_rule.name)
			set_rule_description(active_rule.description)
			set_rule_activation(active_rule.active ? true : false)
			set_active_rule_copy(JSON.parse(JSON.stringify(active_rule)))
			set_applicability_combination_logic(active_rule.applicability_combination_logic)
			set_source_condition_combination_logic(active_rule.condition_combination_logic)
			set_dest_condition_combination_logic(active_rule.action_combination_logic)
		}
	},[active_rule])

	useEffect(() => {
		if(open && mode === 'add'){
			if(sku_linked_rule){
				set_rule_type('drawer_to_accessory')
			}else{
				set_rule_type('ply_to_finish')
			}
		}
	},[open])
	
	const onclick_rule_type = (value) => {
		set_rule_type(value)
		set_item_json_source([])
		set_item_json_dest([])
	}

	const is_valid_add_key = (item_json, type) => {
		if(item_json && item_json.length){
			for(var i=0;i<item_json.length;i++){
				if(!(item_json[i].property && item_json[i].property.length)){
					return false;
				}
			}
			return true;
		}else{
			return true;
		}
	}

	const add_new_item_json = (x_item_json,type) => {
		if(is_valid_add_key(x_item_json,type)){
            let a = [...x_item_json];
			// a.push({condition_type:'absolute',component:[], subcomponent:[], property:[] ,relativecomponent:[], relativesubcomponent:[], relativeproperty:[], operator:'equal', division:[], category:[], sub_cat:[], group:[], sku:[], range_max:'', range_min:'', value:[], value_combination_logic:'and'})
			a.push({condition_type:'absolute',component:[], subcomponent:[], property:[] ,relativecomponent:[], relativesubcomponent:[], relativeproperty:[], operator:'equal', division:[], category:[], sub_cat:[], group:[], sku:[], range_max:'', range_min:'', value:'', tag:[], value_combination_logic:'and'})
			if(type==='applicable'){
				set_item_json_applicable(a)
			}else if (type==='source'){
				set_item_json_source(a)
			}else{
				set_item_json_dest(a)
			}
        }else{
            alert.error("Empty or Duplicate key values not allowed.")
        }	
	}

	const filter_component_options_json = () => {
		let temp = component_options_json.filter(x => ( rule_type == '3d_model_to_finish'
														?
														x.id == '3d_model'
														:
														(
															rule_type == 'ply_to_finish' || rule_type == 'finish_to_ply' || rule_type == 'panel_to_accessory'
															?
															x.id == 'cabinet' || x.id == 'wardrobe' || x.id == 'custom_panel_standalone' || x.id == 'design'
															:
															(
																rule_type == 'cabinet_to_shutter_system_configuration' || rule_type == 'cabinet_to_shutter_design'
																?
																x.id == 'cabinet' || x.id == 'wardrobe' || x.id == 'design'
																:
																(
																	rule_type == 'wardrobe_to_shutter_system_configuration' || rule_type == 'wardrobe_to_shutter_design'
																	?
																	x.id == 'cabinet' || x.id == 'wardrobe' || x.id == 'design'
																	:
																	(
																		rule_type == 'shutter_design_to_cabinet'
																		?
																		x.id == 'cabinet' || x.id == 'wardrobe' || x.id == 'design'
																		:
																		x.id == 'cabinet' || x.id == 'wardrobe' || x.id == 'design'
																	)
																)
															)
														)
													  )
												)
		set_component_options_json_filtered(temp);
														// (rule_type && rule_type == '3d_model_to_finish') ? x.id == '3d_model' : x.id != '3d_model')
	}

	useEffect(() => {
		if(rule_type){
			var active_rule_type = rule_type_json[rule_type];
			set_src_component(active_rule_type ?active_rule_type.source:'')
			set_dest_component(active_rule_type ?active_rule_type.dest:'')
			set_src_subcomponent(active_rule_type ?active_rule_type.source:'')
			set_dest_subcomponent(active_rule_type ?active_rule_type.dest:'')
			filter_component_options_json();
		}
	},[rule_type])

    useEffect(() =>{
		if(item_json_source && !item_json_source.length){
			add_new_item_json(item_json_source, 'source')
		} 
		// else if (item_json_source.length && item_json_source[0] && item_json_source[0].component) {
		// 	console.log('item_json_source component changed ---> ', item_json_source[0].component);
		// 	if (rule_type && rule_type_json[rule_type]) {
		// 		console.log("rule_type json --> ", rule_type_json[rule_type]);

		// }
	},[item_json_source])

    useEffect(() =>{
		if(item_json_dest && !item_json_dest.length){
			add_new_item_json(item_json_dest, 'dest')
		}
	},[item_json_dest])

	useEffect(() =>{
		if(item_json_applicable && !item_json_applicable.length){
			add_new_item_json(item_json_applicable, 'applicable')
		}
		console.log("item_json_applicable --> ", item_json_applicable);
	},[item_json_applicable])

	useEffect(() => {
		console.log("active rule", active_rule, rule_type)
		if((active_rule.rule_type != '3d_model_to_finish' && rule_type == '3d_model_to_finish') || (active_rule.rule_type == '3d_model_to_finish' && rule_type != '3d_model_to_finish'))
		{
			console.log("active rule2", active_rule, rule_type)
			let temp = [...item_json_applicable]
			temp = temp.map(x => { return {id:x.id}})
			set_item_json_applicable(temp);
		}
			
	}, [rule_type])

	useEffect(() => {
		console.log("discard", discard_changes, "is_dirty", is_dirty)
		if(discard_changes == true){
			onclick_handleclose()
			set_discard_changes(false)
			// set_is_dirty(false)
		}
	},[discard_changes])

	// useEffect(() => {
	// 	if(mode == 'add' && rule_name.length){
	// 		set_is_dirty(true)
	// 		console.log("here name")
	// 	}else{
	// 		if(initialized_rule_name){
	// 			set_is_dirty(true)
	// 			console.log("here name")
	// 		}else if(rule_name.length){
	// 			set_initialized_rule_name(true)
	// 		}
	// 	}
	// },[rule_name])

	// useEffect(() => {
	// 	if(mode == 'add' && rule_description.length){
	// 		set_is_dirty(true)
	// 		console.log("here desc")
	// 	}else{
	// 		if(initialized_rule_description){
	// 			set_is_dirty(true)
	// 			console.log("here desc")
	// 		}else if(rule_description.length){
	// 			set_initialized_rule_description(true)
	// 		}
	// 	}
	// },[rule_description])

	// useEffect(() => {
	// 	if(initialized_rule_type){
	// 		set_is_dirty(true)
	// 		console.log("here type")
	// 	}else if(rule_type.length){
	// 		set_initialized_rule_name(true)
	// 	}
	// },[rule_type])

	// useEffect(() => {
	// 	if(mode == 'add' && rule_logic != 'permits_only'){
	// 		set_is_dirty(true)
	// 		console.log("here logic")
	// 	}else{
	// 		if(initialized_rule_logic){
	// 			set_is_dirty(true)
	// 		}else if(rule_logic != 'permits_only'){
	// 			set_initialized_rule_logic(true)
	// 		}
	// 	}
	// },[rule_logic])

	// useEffect(() => {
	// 	console.log("item_json_app---->", item_json_applicable)
	// 	if(initialized_item_json_applicable){
	// 		set_is_dirty(true)
	// 		console.log("here applicable", item_json_applicable)
	// 	}else if(item_json_applicable.length){
	// 		set_initialized_item_json_applicable(true)
	// 		console.log("here applicable init", item_json_applicable)
	// 	}
	// },[item_json_applicable])

	// useEffect(() => {
	// 	if(initialized_item_json_source){
	// 		set_is_dirty(true)
	// 		console.log("here source", item_json_source)
	// 	}else if(item_json_source.length ){
	// 		set_initialized_item_json_source(true)
	// 		console.log("here source init", item_json_source)
	// 	}
	// },[item_json_source])

	// useEffect(() => {
	// 	if(initialized_item_json_dest){
	// 		set_is_dirty(true)
	// 		console.log("here dest")
	// 	}else if(item_json_dest.length){
	// 		set_initialized_item_json_dest(true)
	// 	}
	// },[item_json_dest])

	const onclick_handleclose = () => {
		fetch_rules_details()
		console.log("handle close called")
		handleClose()
		set_rule_type('')
		set_applicability_combination_logic('and')
		set_source_condition_combination_logic('and')
		set_dest_condition_combination_logic('and')
		set_item_json_source([])
		set_item_json_dest([])
		set_item_json_applicable([])
		set_rule_description('')
		set_rule_name('')
		set_rule_logic('permits_only')
		set_selected_ruletype_1_array([])
		set_rule_activation(false)
		// set_is_dirty(false)
	}
	const show_escape_warning_dialog = () => {
		set_show_escape_warning(true)
	}

	const close_escape_warning_dialog = () => {
		set_show_escape_warning(false)
	}

	const values_text = (values_Array, logic) => {
		var x_text=''
		values_Array.map((x,idx1) => {
			if(x.value && Array.isArray(x.value)){
				x.value.map((x_id,idx) => {
					x_text = ('equals ' + x_text.concat(x.value.length-1!=idx?x_id.tag+' '+logic+' ':x_id.tag))
				})
			}else{
				x_text = x_text.concat(values_Array.length-1!=idx1?x.operator+' '+ x.value+ ' '+logic +" ":x.operator+' '+ x.value + " ");
			}
		})
		return x_text;
	}

	const onclick_item =  (value) => {
		console.log("value", value);
		set_selected_ruletype_1_array(value);

		set_rule_type(value[0]?value[0].id:'')
		set_item_json_source([])
		set_item_json_dest([])
		// set_rule_type(value[0]?value[0].name:'')
	}

  	return (
		show_wrong_configuration_modal?<Wrong_Configuration_Warning open={show_wrong_configuration_modal} handleClose={() => {set_show_wrong_configuration_modal(false);onclick_handleclose()}} />:
		<Modal className="Margin_64px_top" size="xl" isOpen={open} toggle={() => {mode=='view'?onclick_handleclose():show_escape_warning_dialog()}}>
			<EscapeWarning open = {show_escape_warning} set_discard_changes={set_discard_changes} handleClose={close_escape_warning_dialog}/>
			<Card>
				<CardHeader className='global_modal_header'>
						<div style={{width:'100%', display:'flex'}}>
							<div>{(mode=='add') ? "Create a Rule" :  "Design Constraint Rule -"+'\xa0'}</div>
							<div title={mode=='add' ? '' : active_rule ?active_rule.name:''} className='text_ellipsis' style={{width:'calc(100% - 200px)'}}>{mode=='add' ? '' : active_rule ?active_rule.name:''}</div>
						</div>
						<i onClick={() => {mode=='view'?onclick_handleclose():show_escape_warning_dialog()}} style={{cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body' style={{ height:'600px' }}>
					<Row style={{marginBottom:'20px'}}>
						<Col xs={1}></Col>
							<Col xs={10}>
								<Row style={{marginBottom:'20px'}}>
									<Col>
										<div>
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Name *</div>
											<Input id='dc_rule_name' disabled={mode=='view' ? true : false} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder="Rule Name" type="text" value={rule_name} onChange={e => set_rule_name(e.target.value)}/>
										</div>
									</Col>	
									<Col>
										<div>
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Description*</div>
											<Input id='dc_rule_description' disabled={mode=='view' ? true : false} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder="Rule Description" type="text" value={rule_description} onChange={e => set_rule_description(e.target.value)}/>
										</div>
									</Col>	
								</Row>
								<Row style={{marginBottom:'20px'}}>
									<Col>
										<div>
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Type *</div>
											<Input id='dc_rule_type' disabled={mode=='view' ? true : false} style={{height:'100%',overflowY:'auto',fontSize:'12px',}} type="select"
												value={rule_type}
												onChange={(e)=>onclick_rule_type(e.target.value)}
											>
												{
													rule_type_json && Object.keys(rule_type_json).length ? Object.keys(rule_type_json).map((item, idx)=>(
														<option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} key={idx} value={rule_type_json[item].id}>
															{rule_type_json[item]?rule_type_json[item].name:''}
														</option>
													)):""
												}
											</Input>
											{/* <Multiselect
												options={Object.values(rule_type_json)} 
												selectedValues={selected_ruletype_1_array ?selected_ruletype_1_array:[]}
												onSelect={(selectedList) => onclick_item(selectedList)}
												onRemove={(selectedList) => onclick_item(selectedList)}
												displayValue="name" // Property name 
												// id={'select_comp'+idx}
												placeholder='Search Value'
												selectionLimit={1}
												// disable={idx>0?true:false}
											/> */}
										</div>
									</Col>	
									<Col>
										<div>
											<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Activation*</div>
											<Input 
												style={{ height:'100%',overflowY:'auto',fontSize:'12px' }} 
												disabled={mode=='view' ? true : false}
												type="select"
												value={rule_activation}
												onChange={e => set_rule_activation(e.target.value == "true")}
											>
												<option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} value={true}>True</option>
												<option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} value={false}>False</option>
											</Input>
										</div>
									</Col>	
								</Row>
								<Row style={{marginBottom:'20px'}}>
								</Row>
								<Row style={{marginBottom:'20px'}}>
									<Col>
										<div>
											<div style={{borderBottom:'1px solid #DDDDDD', width:'100%'}}></div>
										</div>
									</Col>	
								</Row>
								{
									!sku_linked_rule?
									<Row style={{margin:'20px 0px 5px 0px'}}>
										<Col xs={4} style={{padding:0}}>
											<div style={{fontSize:'18px', color:'#212529'}}>Applicable To</div>
										</Col>
									</Row>:""
								}
								{/* {console.log("item json app", item_json_applicable)}
								{
									item_json_applicable && item_json_applicable.length ?item_json_applicable.map((single_item,idx) => 
										<DCKeyValuePair_applicable 
											open={open} 
											idx={idx} 
											key={idx} 
											item_json={item_json_applicable} position_type='applicable' 
											single_item_json={single_item} 
											set_item_json={set_item_json_applicable} 
											tags={tags} 
											component_options_json={component_options_json_filtered} 
											mode={mode}
											active_rule = {active_rule}
										/>
									):''
								} */}
								{console.log("item_json_applicable_y", item_json_applicable, component_options_json, component_options_json_filtered)}
									{
										!sku_linked_rule && item_json_applicable && item_json_applicable.length ?item_json_applicable.map((single_item,idx) => 
											<DCKeyValuePair 
												open={open} 
												key={single_item.id?single_item.id:idx} 
												active_component={src_component} 
												item_json={item_json_applicable} 
												position_type='applicable' 
												idx={idx} 
												rule_type_json={rule_type_json} 
												single_item_json={single_item} 
												set_item_json={set_item_json_applicable} 
												rule_type={rule_type} 
												properties_json={properties_json} 
												mode={mode} 
												component_options_json={component_options_json_filtered} 
												tags={tags} 
												sales_channels={sales_channels}

												set_show_wrong_configuration_modal = {set_show_wrong_configuration_modal}
												combination_logic= {applicability_combination_logic}
												set_combination_logic={set_applicability_combination_logic}
												// fixed_component_id={item_json_applicable && item_json_applicable.length && item_json_applicable[0].component && item_json_applicable[0].component.length ? item_json_applicable[0].component[0].id : null}
											/>
										
										):''
									}
								{/* </div> */}
								<Row style={{margin:0}}>
								{
									mode == 'view' || sku_linked_rule ?'':
									<Col style={{paddingBottom:'10px', paddingLeft:0}}>
										<span onClick={() => add_new_item_json(item_json_applicable,'applicable')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add Applicability +</span>
									</Col>
								}
								</Row>
								{/* <div style={{display:"flex", alignItems:'center'}}> */}
									{/* <div style={{display:'flex',alignItems:'center'}}>
										<div style={{display:'flex',alignItems:'center', marginRight:'10px'}}>
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px', marginRight:'5px'}}>Component *</div>
											<Input disabled={true} style={{width:'200px',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} type="text" value={src_component?src_component.component_name:''} />
										</div>
										<div style={{display:'flex',alignItems:'center', marginRight:'10px'}}>
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px', marginRight:'5px'}}>Component *</div>
											<Input disabled={true} style={{width:'200px',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} type="text" value={src_subcomponent?src_subcomponent.subcomponent_name:''} />
										</div>
										<div style={{display:'flex',alignItems:'center'}}>
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px', marginRight:'5px'}}>Condition Combinator logic *</div>
											<Input style={{width:'200px',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} type="select" value={source_condition_combination_logic?source_condition_combination_logic:''} onChange={(e)=>set_source_condition_combination_logic(e.target.value)}>
												<option value='and'>And</option>
												<option value='or'>Or</option>
											</Input>
										</div>
									</div> */}
									<Row style={{margin:'20px 0px 5px 0px'}}>
										<Col xs={4} style={{padding:0}}>
											<div style={{fontSize:'18px', color:'#212529'}}>Source Condition</div> 
										</Col>
									</Row>
									{
										item_json_source && item_json_source.length ?item_json_source.map((single_item,idx) => 
											<DCKeyValuePair 
												open={open} 
												key={single_item.id?single_item.id:idx} 
												active_component={src_component} 
												item_json={item_json_source} 
												position_type='source' 
												idx={idx} 
												rule_type_json={rule_type_json} 
												single_item_json={single_item} 
												set_item_json={set_item_json_source} 
												rule_type={rule_type} 
												properties_json={properties_json} 
												mode={mode} 
												tags={tags}
												set_show_wrong_configuration_modal = {set_show_wrong_configuration_modal}
												combination_logic={source_condition_combination_logic} 
												set_combination_logic={set_source_condition_combination_logic}
												drawer_ids_from_component_file = {drawer_ids_from_component_file}
												set_page_loader = {set_page_loader}
												all_division_tree_data = {all_division_tree_data}
												// fixed_component_id={item_json_applicable && item_json_applicable.length && item_json_applicable[0].component && item_json_applicable[0].component.length ? item_json_applicable[0].component[0].id : null}
											/>
										
										):''
									}
								{/* </div> */}
								<Row style={{margin:0}}>
								{
									mode=='view'?'':
									<Col style={{paddingBottom:'10px', paddingLeft:0}}>
										<span onClick={() => add_new_item_json(item_json_source,'source')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add Condition +</span>
									</Col>
								}
								</Row>

								<Row style={{marginTop:'28px', marginBottom:'28px'}}>
									<Col style={{display:'flex', justifyContent:'flex-start'}}>
										<div style={{fontSize:'12px', color:'#425A70', marginRight:'5px',display:"flex", alignItems:'center'}}>Rule logic *</div>
										<Input 
											style={{height:'100%',overflowY:'auto',fontSize:'12px',width:'300px'}} 
											type="select"
											disabled={mode=='view' ? true : false}
											value={rule_logic}
											onChange={(e)=>set_rule_logic(e.target.value)}
										>
											<option value='permits_only'>Permits Only</option>
											<option value='does_not_permit'>Does not permit</option>
											{sku_linked_rule ? '' : <option value='alone_permits'>Alone Permits</option>}	
										</Input>
									</Col>	
								</Row>

								{/* <div style={{display:"flex", alignItems:'center'}}> */}
									
									{/* <div style={{display:'flex',alignItems:'center'}}>
										<div style={{display:'flex',alignItems:'center'}}>
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px', marginRight:'5px'}}>Component *</div>
											<Input disabled={true} style={{width:'200px',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} type="text" value={dest_component?dest_component.component_name:''} />
										</div>
										<div style={{display:'flex',alignItems:'center'}}>
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px', marginRight:'5px'}}>Component *</div>
											<Input disabled={true} style={{width:'200px',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} type="text" value={dest_subcomponent?dest_subcomponent.subcomponent_name:''} />
										</div>
										<div style={{display:'flex',alignItems:'center'}}>
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px', margin:'0px 5px 0px 10px '}}>Condition Combinator logic *</div>
											<Input style={{width:'200px',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} type="select" value={dest_condition_combination_logic?dest_condition_combination_logic:''} onChange={(e)=>set_dest_condition_combination_logic(e.target.value)}>
												<option value='and'>And</option>
												<option value='or'>Or</option>
											</Input>
										</div>
									</div> */}
									<Row style={{margin:'20px 0px 5px 0px'}}>
										<Col xs={4} style={{padding:0}}>
											<div style={{fontSize:'18px', color:'#212529'}}>Destination Condition</div>
										</Col>
									</Row>
									{
										item_json_dest && item_json_dest.length ?item_json_dest.map((single_item,idx) => 
											<DCKeyValuePair 
												open={open} 
												key={single_item.id?single_item.id:idx} 
												active_component={dest_component} 
												item_json={item_json_dest} 
												position_type='dest' 
												idx={idx} 
												rule_type_json={rule_type_json} 
												single_item_json={single_item} 
												set_item_json={set_item_json_dest} 
												rule_type={rule_type} 
												properties_json={properties_json} 
												mode={mode} 
												tags={tags} 
												condition_combination_logic={dest_condition_combination_logic} 
												set_condition_combination_logic={set_dest_condition_combination_logic}
												fixed_component_id={item_json_source && item_json_source.length ? item_json_source[0].component : null}
												set_show_wrong_configuration_modal = {set_show_wrong_configuration_modal}
												all_division_tree_data = {all_division_tree_data}
												set_page_loader = {set_page_loader}
											/>
										):''
									}
								{/* </div> */}
								
								{

									<Row style={{margin:0}}>
									{
										mode =='view'?'':
										<Col style={{paddingBottom:'10px', paddingLeft:0}}>
											<span id='add_condition_dest' onClick={() => add_new_item_json(item_json_dest,'dest')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add Action +</span>
										</Col>
									}
									</Row>
								}
							</Col>
						<Col xs={1}></Col>
					</Row>	

							{/* {
								rule_word?
								<Row style={{margin:'0px'}}>
									<Col style={{textAlign:'left', paddingBottom:'10px', paddingLeft:0}}>
										<div style={{fontSize:'12px', textAlign:'left',fontWeight:600}}><pre>{rule_word}</pre></div>
									</Col>
								</Row>
								:''
							} */}
				</CardBody>

				<CardFooter className='global_modal_footer'>
				{
					mode=='view'?
					<Button className='white_button' style={{fontSize:'14px', marginRight:'5px'}} type="button" onClick={onclick_handleclose}> Close </Button>
					:
					<Button className='blue_button' style={{fontSize:'14px'}} type="button" onClick={mode === 'add' ? onclick_add_new_rule : mode === 'edit' ? onclick_edit_rule : onclick_handleclose}> Done </Button>
				}
				</CardFooter>
			</Card>  
		</Modal>
  	);
}

export default DCRulesDialogBox;