const ComponentStructure = {
    "cabinet": {
        "cabinet": [
            {
                "id": "cbu",
                "type": "bool",
                "name": "CBU",
                "description": "Mark Cabinet as CBU"
            },
            {
                "id": "width",
                "type": "number",
                "name": "Width",
                "description": "Width of cabinet"
            },
            {
                "id": "depth",
                "type": "number",
                "name": "Depth",
                "description": "Depth of cabinet"
            },
            {
                "id": "height",
                "type": "number",
                "name": "Height",
                "description": "Height of cabinet"
            },
            {
                "id": "back_panel_offset",
                "type": "number",
                "name": "Back Panel Offset",
                "description": "Offest of Back Panel from Back"
            },
            {
                "id": "back_panel_grooving",
                "type": "number",
                "name": "Back Panel Grooving",
                "description": "Grooving of Back panel on all sides"
            },
            {
                "id": "sizing_rules",
                "type": "parent",
                "name": "Sizing Rules",
                "children": [
                    {
                        "id": "end_panel_max_height",
                        "type": "number",
                        "name": "End Panel Max Height",
                        "description": "End Panel Max Height(Default 2400)"
                    },
                    {
                        "id": "min_depth",
                        "type": "number",
                        "name": "Min Depth",
                        "description": "Min Depth. -1 to Unset, -2 For same value as Depth."
                    },
                    {
                        "id": "max_depth",
                        "type": "number",
                        "name": "Max Depth",
                        "description": "Max Depth. -1 to Unset, -2 For same value as Depth."
                    },
                    {
                        "id": "min_width",
                        "type": "number",
                        "name": "Min Width",
                        "description": "Min Width. -1 to Unset, -2 For same value as Width."
                    },
                    {
                        "id": "max_width",
                        "type": "number",
                        "name": "Max Width",
                        "description": "Max Width. -1 to Unset, -2 For same value as Width."
                    },
                    {
                        "id": "min_height",
                        "type": "number",
                        "name": "Min Height",
                        "description": "Min Height. -1 to Unset, -2 For same value as Height."
                    },
                    {
                        "id": "max_height",
                        "type": "number",
                        "name": "Max Height",
                        "description": "Max Height. -1 to Unset, -2 For same value as Height."
                    },
                    {
                        "id": "min_partition_depth_shelf",
                        "type": "number",
                        "name": "Min Partition Depth (Shelf)",
                        "description": "Shelf Restriction. -1 to Unset."
                    },
                    {
                        "id": "max_partition_depth_shelf",
                        "type": "number",
                        "name": "Max Partition Depth (Shelf)",
                        "description": "Shelf Restriction. -1 to Unset."
                    },
                    {
                        "id": "min_partition_width_shelf",
                        "type": "number",
                        "name": "Min Partition Width (Shelf)",
                        "description": "Shelf Restriction. -1 to Unset."
                    },
                    {
                        "id": "max_partition_width_shelf",
                        "type": "number",
                        "name": "Max Partition Width (Shelf)",
                        "description": "Shelf Restriction. -1 to Unset."
                    },
                    {
                        "id": "min_partition_height_shelf",
                        "type": "number",
                        "name": "Min Partition Height (Shelf)",
                        "description": "Shelf Restriction. -1 to Unset."
                    },
                    {
                        "id": "max_partition_height_shelf",
                        "type": "number",
                        "name": "Max Partition Height (Shelf)",
                        "description": "Shelf Restriction. -1 to Unset."
                    },
                    {
                        "id": "min_partition_depth_drawer",
                        "type": "number",
                        "name": "Min Partition Depth (Drawer)",
                        "description": "Drawer Restriction. -1 to Unset."
                    },
                    {
                        "id": "max_partition_depth_drawer",
                        "type": "number",
                        "name": "Max Partition Depth (Drawer)",
                        "description": "Drawer Restriction. -1 to Unset."
                    },
                    {
                        "id": "min_partition_width_drawer",
                        "type": "number",
                        "name": "Min Partition Width (Drawer)",
                        "description": "Drawer Restriction. -1 to Unset."
                    },
                    {
                        "id": "max_partition_width_drawer",
                        "type": "number",
                        "name": "Max Partition Width (Drawer)",
                        "description": "Drawer Restriction. -1 to Unset."
                    },
                    {
                        "id": "min_partition_height_drawer",
                        "type": "number",
                        "name": "Min Partition Height (Drawer)",
                        "description": "Drawer Restriction. -1 to Unset."
                    },
                    {
                        "id": "max_partition_height_drawer",
                        "type": "number",
                        "name": "Max Partition Height (Drawer)",
                        "description": "Drawer Restriction. -1 to Unset."
                    }
                ]
            },
            {
                "id": "carcass_material",
                "type": "parent",
                "name": "Carcass Material",
                "children": [
                    {
                        "id": "core_panel_sku_id",
                        "type": "sku",
                        "name": "Core Panel SKU ID",
                        "description": "Core Panel SKU ID. If prelam respective finishes are auto applied",
                        "possible_category_types": [
                            "panel_core_hardware"
                        ]
                    },
                    {
                        "id": "top_finish_material_id",
                        "type": "sku",
                        "name": "Top Finish Material ID",
                        "description": "Top Finish Material ID. Linked edgeband will be auto applied. Not Applied if prelam both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "bottom_finish_material_id",
                        "type": "sku",
                        "name": "Bottom Finish Material ID",
                        "description": "Bottom Finish Material ID. Not Applied if Prelam Internal or Prelam Both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "visible_edge_band_material_id",
                        "type": "sku",
                        "name": "Visible Edge Band Material",
                        "description": "Visible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "invisible_edge_band_material_id",
                        "type": "sku",
                        "name": "Invisible Edge Band Material",
                        "description": "Invisible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "visible_edge_band",
                        "type": "bool",
                        "name": "Visible Edge Band",
                        "description": "To Turn off Visible Edge Band on all sides give false"
                    },
                    {
                        "id": "invisible_edge_band",
                        "type": "bool",
                        "name": "Invisible Edge Band",
                        "description": "To Turn off Invisible Edge Band on all sides give false"
                    }
                ]
            },
            {
                "id": "skirting_material",
                "type": "parent",
                "name": "Skirting Material",
                "children": [
                    {
                        "id": "core_panel_sku_id",
                        "type": "sku",
                        "name": "Core Panel SKU ID",
                        "description": "Core Panel SKU ID. For Non wooden - Skirting Hardware. For wooden - Ply Hardware, Material Source should be none, Prelam respective finishes are auto applied",
                        "possible_category_types": [
                            "skirting_hardware",
                            "panel_core_hardware"
                        ]
                    },
                    {
                        "id": "top_finish_material_id",
                        "type": "sku",
                        "name": "Top Finish Material ID",
                        "description": "Top Finish Material ID. Applied only for Wooden and Material Source is None. Linked edgeband will be auto applied. Not Applied if prelam both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "bottom_finish_material_id",
                        "type": "sku",
                        "name": "Bottom Finish Material ID",
                        "description": "Bottom Finish Material ID. Applied only for Wooden and Material Source is None. Not Applied if Prelam Internal or Prelam Both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "visible_edge_band_material_id",
                        "type": "sku",
                        "name": "Visible Edge Band Material",
                        "description": "Visible Edge Band Material ID. Applied only for Wooden and Material Source is None. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "invisible_edge_band_material_id",
                        "type": "sku",
                        "name": "Invisible Edge Band Material",
                        "description": "Invisible Edge Band Material ID. Applied only for Wooden and Material Source is None. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "visible_edge_band",
                        "type": "bool",
                        "name": "Visible Edge Band",
                        "description": "To Turn off Visible Edge Band on all sides give false. Applied only for Wooden and Material Source is None"
                    },
                    {
                        "id": "invisible_edge_band",
                        "type": "bool",
                        "name": "Invisible Edge Band",
                        "description": "To Turn off Invisible Edge Band on all sides give false. Applied only for Wooden and Material Source is None"
                    }
                ]
            },
            {
                "id": "shutter_material",
                "type": "parent",
                "name": "Shutter Material",
                "children": [
                    {
                        "id": "core_panel_sku_id",
                        "type": "sku",
                        "name": "Core Panel SKU ID",
                        "description": "Core Panel SKU ID. If prelam respective finishes are auto applied",
                        "possible_category_types": [
                            "panel_core_hardware"
                        ]
                    },
                    {
                        "id": "top_finish_material_id",
                        "type": "sku",
                        "name": "Top Finish Material ID",
                        "description": "Top Finish Material ID. Linked edgeband will be auto applied. Not Applied if prelam both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "bottom_finish_material_id",
                        "type": "sku",
                        "name": "Bottom Finish Material ID",
                        "description": "Bottom Finish Material ID. Not Applied if Prelam Internal or Prelam Both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "design_material_id",
                        "type": "sku",
                        "name": "Shutter Design Material ID",
                        "description": "Shutter Design Material ID. Not Applied if Prelam Internal or Prelam Both",
                        "possible_category_types": [
                            "shutter_hardware"
                        ]
                    },
                    {
                        "id": "visible_edge_band_material_id",
                        "type": "sku",
                        "name": "Visible Edge Band Material",
                        "description": "Visible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "invisible_edge_band_material_id",
                        "type": "sku",
                        "name": "Invisible Edge Band Material",
                        "description": "Invisible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "visible_edge_band",
                        "type": "bool",
                        "name": "Visible Edge Band",
                        "description": "To Turn off Visible Edge Band on all sides give false"
                    },
                    {
                        "id": "invisible_edge_band",
                        "type": "bool",
                        "name": "Invisible Edge Band",
                        "description": "To Turn off Invisible Edge Band on all sides give false"
                    }
                ]
            },
            {
                "id": "drawer_fascia_material",
                "type": "parent",
                "name": "Drawer Fascia Material",
                "children": [
                    {
                        "id": "core_panel_sku_id",
                        "type": "sku",
                        "name": "Core Panel SKU ID",
                        "description": "Core Panel SKU ID. If prelam respective finishes are auto applied",
                        "possible_category_types": [
                            "panel_core_hardware"
                        ]
                    },
                    {
                        "id": "top_finish_material_id",
                        "type": "sku",
                        "name": "Top Finish Material ID",
                        "description": "Top Finish Material ID. Linked edgeband will be auto applied. Not Applied if prelam both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "bottom_finish_material_id",
                        "type": "sku",
                        "name": "Bottom Finish Material ID",
                        "description": "Bottom Finish Material ID. Not Applied if Prelam Internal or Prelam Both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "design_material_id",
                        "type": "sku",
                        "name": "Shutter Design Material ID",
                        "description": "Shutter Design Material ID. Not Applied if Prelam Internal or Prelam Both",
                        "possible_category_types": [
                            "shutter_hardware"
                        ]
                    },
                    {
                        "id": "visible_edge_band_material_id",
                        "type": "sku",
                        "name": "Visible Edge Band Material",
                        "description": "Visible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "invisible_edge_band_material_id",
                        "type": "sku",
                        "name": "Invisible Edge Band Material",
                        "description": "Invisible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "visible_edge_band",
                        "type": "bool",
                        "name": "Visible Edge Band",
                        "description": "To Turn off Visible Edge Band on all sides give false"
                    },
                    {
                        "id": "invisible_edge_band",
                        "type": "bool",
                        "name": "Invisible Edge Band",
                        "description": "To Turn off Invisible Edge Band on all sides give false"
                    }
                ]
            },
            {
                "id": "skirting",
                "type": "parent",
                "name": "Skirting Options",
                "children": [
                    {
                        "id": "visibility",
                        "type": "bool",
                        "name": "Visibility",
                        "description": "Visible All Skirting Panels"
                    },
                    {
                        "id": "skirting_type",
                        "type": "select",
                        "name": "Skirting Type",
                        "description": "Wooden For Cabinet, Non Wooden for Wardobes by default",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "wooden",
                                "name": "Wooden"
                            },
                            {
                                "id": "non_wooden",
                                "name": "Non Wooden"
                            }
                        ]
                    },
                    {
                        "id": "skirting_alignment",
                        "type": "select",
                        "name": "Skirting Alignment",
                        "description": "Applicable only for wooden. Carcass By Default",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "carcass",
                                "name": "Carcass"
                            },
                            {
                                "id": "shutter",
                                "name": "Shutter"
                            }
                        ]
                    },
                    {
                        "id": "skirting_height",
                        "type": "number",
                        "name": "Skirting Height",
                        "description": "Applicable only for wooden. To be given when changing to wooden"
                    },
                    {
                        "id": "mid_skirting_height",
                        "type": "number",
                        "name": "Mid Skirting Height",
                        "description": "Applicable only for wooden and for wardrobe."
                    },
                    {
                        "id": "skirting_material_source",
                        "type": "select",
                        "name": "Skirting Material Source",
                        "description": "Applicable only for wooden",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "carcass",
                                "name": "Carcass"
                            },
                            {
                                "id": "shutter",
                                "name": "Shutter"
                            },
                            {
                                "id": "none",
                                "name": "None"
                            }
                        ]
                    },
                    {
                        "id": "skirting_depth_offset",
                        "type": "number",
                        "name": "Skirting Depth Offset",
                        "description": "Depth Offset of Skirting from front"
                    },
                    {
                        "id": "front_visible",
                        "type": "bool",
                        "name": "Front Skirting Plank Visibility",
                        "description": "Visibility of front skirting plank"
                    },
                    {
                        "id": "back_visible",
                        "type": "bool",
                        "name": "Back Skirting Plank Visibility",
                        "description": "Visibility of back skirting plank"
                    },
                    {
                        "id": "left_visible",
                        "type": "bool",
                        "name": "Left Skirting Plank Visibility",
                        "description": "Visibility of left skirting plank"
                    },
                    {
                        "id": "right_visible",
                        "type": "bool",
                        "name": "Right Skirting Plank Visibility",
                        "description": "Visibility of right skirting plank"
                    }
                ]
            },
            {
                "id": "rails",
                "type": "parent",
                "name": "Rails",
                "children": [
                    {
                        "id": "stability_rails",
                        "type": "number",
                        "name": "Stability Rails",
                        "description": "Additional Number of Stability rails to be added. Applicable only for wardrobe"
                    },
                    {
                        "id": "stability_rail_height",
                        "type": "number",
                        "name": "Stability Rail Height",
                        "description": "Height of stability rail. Applicable when atleast One stability Rail is present"
                    },
                    {
                        "id": "half_top",
                        "type": "bool",
                        "name": "Half Top",
                        "description": "Converting top panel to rails. Not applicable for Wardrobe"
                    },
                    {
                        "id": "front_half_top_depth",
                        "type": "number",
                        "name": "Front Half Top Depth",
                        "description": "Front Top rail depth. Not applicable if Top panel is not rails"
                    },
                    {
                        "id": "back_half_top_depth",
                        "type": "number",
                        "name": "Back Half Top Depth",
                        "description": "Back Top rail depth. Not applicable if Top panel is not rails"
                    },
                    {
                        "id": "top_rails_orientation",
                        "type": "select",
                        "name": "Top Rails Orientation",
                        "description": "Top Rail Orientation. Not applicable if Top panel is not rails",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left_right",
                                "name": "Left-Right"
                            },
                            {
                                "id": "top_bottom",
                                "name": "Top-Bottom"
                            }
                        ]
                    },
                    {
                        "id": "has_back_rails",
                        "type": "bool",
                        "name": "Has Back Rails",
                        "description": "Converting back panel to rails"
                    },
                    {
                        "id": "back_rail_depth",
                        "type": "number",
                        "name": "Back Rail Depth",
                        "description": "Rail Depth of Back Rail"
                    },
                    {
                        "id": "back_rails_orientation",
                        "type": "select",
                        "name": "Back Rails Orientation",
                        "description": "Back Rail Orientation. Not applicable if Back panel is not rails",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left_right",
                                "name": "Left-Right"
                            },
                            {
                                "id": "top_bottom",
                                "name": "Top-Bottom"
                            }
                        ]
                    },
                    {
                        "id": "has_bottom_rails",
                        "type": "bool",
                        "name": "Has Bottom Rails",
                        "description": "Converting bottom panel to rails"
                    },
                    {
                        "id": "bottom_rail_depth",
                        "type": "number",
                        "name": "Bottom Rail Depth",
                        "description": "Rail Depth of Bottom Rail"
                    },
                    {
                        "id": "bottom_rails_orientation",
                        "type": "select",
                        "name": "Bottom Rails Orientation",
                        "description": "Bottom Rail Orientation. Not applicable if Bottom panel is not rails",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left_right",
                                "name": "Left-Right"
                            },
                            {
                                "id": "top_bottom",
                                "name": "Top-Bottom"
                            }
                        ]
                    },
                    {
                        "id": "has_left_rails",
                        "type": "bool",
                        "name": "Has Left Rails",
                        "description": "Converting Left panel to rails"
                    },
                    {
                        "id": "left_rail_depth",
                        "type": "number",
                        "name": "Left Rail Depth",
                        "description": "Rail Depth of Left Rail"
                    },
                    {
                        "id": "left_rails_orientation",
                        "type": "select",
                        "name": "Left Rails Orientation",
                        "description": "Left Rail Orientation. Not applicable if Left panel is not rails",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left_right",
                                "name": "Left-Right"
                            },
                            {
                                "id": "top_bottom",
                                "name": "Top-Bottom"
                            }
                        ]
                    },
                    {
                        "id": "has_right_rails",
                        "type": "bool",
                        "name": "Has Right Rails",
                        "description": "Converting Right panel to rails"
                    },
                    {
                        "id": "right_rail_depth",
                        "type": "number",
                        "name": "Right Rail Depth",
                        "description": "Rail Depth of Right Rail"
                    },
                    {
                        "id": "right_rails_orientation",
                        "type": "select",
                        "name": "Left Rails Orientation",
                        "description": "Left Rail Orientation. Not applicable if Left panel is not rails",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left_right",
                                "name": "Left-Right"
                            },
                            {
                                "id": "top_bottom",
                                "name": "Top-Bottom"
                            }
                        ]
                    },
                    {
                        "id": "bottom_support_panels",
                        "type": "number",
                        "name": "Bottom Support Panels",
                        "description": "Total number of Bottom Support Panels"
                    },
                    {
                        "id": "bottom_support_panels_height",
                        "type": "number",
                        "name": "Bottom Support Panels Height",
                        "description": "Height of each bottom Support panel"
                    },
                    {
                        "id": "bottom_support_panels_left_gap",
                        "type": "number",
                        "name": "Bottom Support Panels Left Gap",
                        "description": "Bottom Support Panels Left Gap"
                    },
                    {
                        "id": "bottom_support_panels_right_gap",
                        "type": "number",
                        "name": "Bottom Support Panels Right Gap",
                        "description": "Bottom Support Panels Right Gap"
                    }
                ]
            },
            {
                "id": "construction",
                "type": "parent",
                "name": "Construction",
                "children": [
                    {
                        "id": "bottomOnLeftEdge",
                        "type": "bool",
                        "name": "Bottom on Left Edge",
                        "description": "Carcass Bottom and Left Panel Construction"
                    },
                    {
                        "id": "topOnLeftEdge",
                        "type": "bool",
                        "name": "Top on Left Edge",
                        "description": "Carcass Top and Left Panel Construction"
                    },
                    {
                        "id": "bottomOnRightEdge",
                        "type": "bool",
                        "name": "Bottom on Right Edge",
                        "description": "Carcass Bottom and Right Panel Construction"
                    },
                    {
                        "id": "topOnRightEdge",
                        "type": "bool",
                        "name": "Top on Right Edge",
                        "description": "Carcass Top and Right Panel Construction"
                    },
                    {
                        "id": "backOnLeftEdge",
                        "type": "bool",
                        "name": "Back on Left Edge",
                        "description": "Carcass Back and Left Panel Construction"
                    },
                    {
                        "id": "backOnRightEdge",
                        "type": "bool",
                        "name": "Back on Right Edge",
                        "description": "Carcass Back and Right Panel Construction"
                    },
                    {
                        "id": "backOnTopEdge",
                        "type": "bool",
                        "name": "Back on Top Edge",
                        "description": "Carcass Back and Top Panel Construction"
                    },
                    {
                        "id": "backOnBottomEdge",
                        "type": "bool",
                        "name": "Back on Bottom Edge",
                        "description": "Carcass Back and Bottom Panel Construction"
                    }
                ]
            },
            {
                "id": "countertop",
                "type": "parent",
                "name": "Countertop",
                "children": [
                    {
                        "id": "visibility",
                        "type": "bool",
                        "name": "Countertop Visibility",
                        "description": "Not Applicable to Wardrobe"
                    },
                    {
                        "id": "material_id",
                        "type": "sku",
                        "possible_category_types": [
                            "countertop_finish"
                        ],
                        "name": "Countertop Material",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible"
                    },
                    {
                        "id": "has_ply",
                        "type": "bool",
                        "name": "Ply Under Granite Visibility",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible"
                    },
                    {
                        "id": "left_extension",
                        "type": "number",
                        "name": "Left Extension",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible"
                    },
                    {
                        "id": "right_extension",
                        "type": "number",
                        "name": "Right Extension",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible"
                    },
                    {
                        "id": "back_extension",
                        "type": "number",
                        "name": "Back Extension",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible"
                    },
                    {
                        "id": "front_extension",
                        "type": "number",
                        "name": "Front Extension",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible"
                    },
                    {
                        "id": "sink_sku_id",
                        "type": "sku",
                        "possible_category_types": [
                            "sink_hardware"
                        ],
                        "name": "Sink",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible"
                    }
                ]
            },
            {
                "id": "left_end_panel",
                "type": "bool",
                "name": "Left End Panel",
                "description": "Left End Panel to be Added or Removed"
            },
            {
                "id": "right_end_panel",
                "type": "bool",
                "name": "Right End Panel",
                "description": "Right End Panel to be Added or Removed"
            },
            {
                "id": "split_top_and_bottom_panels",
                "type": "bool",
                "name": "Split Top and Bottom Panels",
                "description": "Spliting Toip nad bottom panels. Default Partition Should have Vertical Partitions"
            }
        ],
        "partitions": [
            {
                "id": "partition_id",
                "type": "text",
                "name": "Partition ID",
                "description": "The partition_id for Default Partition(Outer Most Partition) is partition_1. The order of partitions (according to their id is from bottom to top, and left to right for sub-partitions within the same partition). For example, partition with partition_id: “partition_1” is the outermost partition. “partition_1_1” denotes the lower/left most sub-partition of the outermost partition."
            },
            {
                "id": "shelf_reveal",
                "type": "number",
                "name": "Shelf Reveal",
                "description": "Shelf Reveal of the Partition"
            },
            {
                "id": "sizing_rules",
                "type": "parent",
                "name": "Sizing Rules",
                "children": [
                    {
                        "id": "min_depth",
                        "type": "number",
                        "name": "Min Depth",
                        "description": "Min Depth. -1 to Unset"
                    },
                    {
                        "id": "max_depth",
                        "type": "number",
                        "name": "Max Depth",
                        "description": "Max Depth. -1 to Unset"
                    },
                    {
                        "id": "min_width",
                        "type": "number",
                        "name": "Min Width",
                        "description": "Min Width. -1 to Unset"
                    },
                    {
                        "id": "max_width",
                        "type": "number",
                        "name": "Max Width",
                        "description": "Max Width. -1 to Unset"
                    },
                    {
                        "id": "min_height",
                        "type": "number",
                        "name": "Min Height",
                        "description": "Min Height. -1 to Unset"
                    },
                    {
                        "id": "max_height",
                        "type": "number",
                        "name": "Max Height",
                        "description": "Max Height. -1 to Unset"
                    }
                ]
            },
            {
                "id": "partition_dimension",
                "type": "number",
                "name": "Partition Dimension",
                "description": "Fixing the Dimension of Partition. Adjust Automatically for Horizontal and Vertical"
            },
            {
                "id": "no_of_shelves",
                "type": "number",
                "name": "Additional Number of Shelves",
                "description": "Will Add Additional Shelves for the partition. No Drawers or Vertical Partition Panels Should be Present"
            },
            {
                "id": "no_of_vertical_partitions",
                "type": "number",
                "name": "Additional Number of Vertical Partitions",
                "description": "Will Add Additional Vertical Partitions for the partition. No Shelves or Vertical Partition Panels Should be Present"
            },
            {
                "id": "no_of_drawers",
                "type": "number",
                "name": "Additional Number of Drawers",
                "description": "Will Add Additional Drawers for the partition. No Shleves or Vertical Partition Panels Should be present"
            },
            {
                "id": "back_panel_offset",
                "type": "number",
                "name": "Back Panel Offset",
                "description": "Back Panel Offset"
            },
            {
                "id": "shutter_system_type",
                "type": "select",
                "name": "Shutter System Type",
                "description": "For Cabinet - single,double,gas_lift,top_lift,flap_down. For Wardrobe - single, double, slide_2, slide_3, slide_4, slide_5, slide_6",
                "options": [
                    {
                        "id": "",
                        "name": "No Changes"
                    },
                    {
                        "id": "single",
                        "name": "Single"
                    },
                    {
                        "id": "double",
                        "name": "Double"
                    },
                    {
                        "id": "gas_lift",
                        "name": "Gas Lift Up"
                    },
                    {
                        "id": "top_lift",
                        "name": "Top Lift"
                    },
                    {
                        "id": "flap_down",
                        "name": "Flap Down"
                    },
                    {
                        "id": "slide_2",
                        "name": "Slide 2"
                    },
                    {
                        "id": "slide_3",
                        "name": "Slide 3"
                    },
                    {
                        "id": "slide_4",
                        "name": "Slide 4"
                    },
                    {
                        "id": "slide_5",
                        "name": "Slide 5"
                    },
                    {
                        "id": "slide_6",
                        "name": "Slide 6"
                    }
                ]
            },
            {
                "id": "shutter_system",
                "type": "parent",
                "name": "Shutter System",
                "description": "Not Applicable if Partition Doesn't have Shutter System",
                "children": [
                    {
                        "id": "divisional_shutter",
                        "type": "parent",
                        "name": "Divisional Shutter",
                        "description": "To Add Divisional Shutter",
                        "children": [
                            {
                                "id": "orientation",
                                "type": "select",
                                "name": "Orientation",
                                "description": "Orientation of divisional Shutter to be added. Quantity is Mandatory",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "horizontal",
                                        "name": "Horizontal"
                                    },
                                    {
                                        "id": "vertical",
                                        "name": "Vertical"
                                    }
                                ]
                            },
                            {
                                "id": "quantity",
                                "type": "number",
                                "name": "Quantity",
                                "description": "Additional divisional Shutter panels to be added. Orientation is Mandatory. One Panels will be Already Present"
                            }
                        ]
                    },
                    {
                        "id": "handle",
                        "type": "parent",
                        "name": "Handle",
                        "description": "To Change the Handle",
                        "children": [
                            {
                                "id": "placement",
                                "type": "select",
                                "name": "Placement",
                                "description": "For normal handles - top_left, top_center, top_right, center_left, center, center_right, bottom_left, bottom_center, bottom_right. For profile handles - top,bottom,left,right",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "top_left",
                                        "name": "Top Left"
                                    },
                                    {
                                        "id": "top_center",
                                        "name": "Top Center"
                                    },
                                    {
                                        "id": "top_right",
                                        "name": "Top Right"
                                    },
                                    {
                                        "id": "center_left",
                                        "name": "Center Left"
                                    },
                                    {
                                        "id": "center",
                                        "name": "Center"
                                    },
                                    {
                                        "id": "center_right",
                                        "name": "Center Right"
                                    },
                                    {
                                        "id": "bottom_left",
                                        "name": "Bottom Left"
                                    },
                                    {
                                        "id": "bottom_center",
                                        "name": "Bottom Center"
                                    },
                                    {
                                        "id": "bottom_right",
                                        "name": "Bottom Right"
                                    },
                                    {
                                        "id": "top",
                                        "name": "Top"
                                    },
                                    {
                                        "id": "bottom",
                                        "name": "Bottom"
                                    },
                                    {
                                        "id": "right",
                                        "name": "Right"
                                    },
                                    {
                                        "id": "left",
                                        "name": "Left"
                                    }
                                ]
                            },
                            {
                                "id": "sku_id",
                                "type": "sku",
                                "name": "Handle SKU",
                                "possible_category_types": [
                                    "handle_hardware",
                                    "profile_and_gola_handles_hardware"
                                ],
                                "description": "To Add Handle to the Shutter"
                            },
                            {
                                "id": "orientation",
                                "type": "select",
                                "name": "Orientation",
                                "description": "Applicable only for normal handles.",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "horizontal",
                                        "name": "Horizontal"
                                    },
                                    {
                                        "id": "vertical",
                                        "name": "Vertical"
                                    }
                                ]
                            },
                            {
                                "id": "non_handle_profile_sku_id",
                                "type": "sku",
                                "name": "Non Handle Profile SKU",
                                "description": "To Add Non Handle Profile to the Shutter",
                                "possible_category_types": [
                                    "profile_and_gola_handles_hardware"
                                ]
                            },
                            {
                                "id": "handle_profile_sku_id",
                                "type": "sku",
                                "name": "Handle Profile SKU",
                                "description": "To Add Handle Profile to the Shutter",
                                "possible_category_types": [
                                    "profile_and_gola_handles_hardware"
                                ]
                            },
                            {
                                "id": "divisional_handle_profile_sku_id",
                                "type": "sku",
                                "name": "Divisional Profile SKU",
                                "description": "To Add Divisional Profile to the Shutter",
                                "possible_category_types": [
                                    "profile_and_gola_handles_hardware"
                                ]
                            }
                        ]
                    },
                    {
                        "id": "basket_sku_id",
                        "type": "sku",
                        "name": "Basket SKU",
                        "description": "Adding Basket to the Shutter",
                        "possible_category_types": [
                            "basket_hardware"
                        ]
                    },
                    {
                        "id": "front_mirror_ply_sku_id",
                        "type": "sku",
                        "name": "Mirror SKU",
                        "description": "Adding Front Mirror to the Shutter",
                        "possible_category_types": [
                            "mirror_hardware"
                        ]
                    },
                    {
                        "id": "back_mirror_ply_sku_id",
                        "type": "sku",
                        "name": "Mirror SKU",
                        "description": "Adding Back Mirror to the Shutter",
                        "possible_category_types": [
                            "mirror_hardware"
                        ]
                    },
                    {
                        "id": "parameters",
                        "type": "parent",
                        "name": "Shutter Parameters",
                        "description": "Shutter Parameters",
                        "children": [
                            {
                                "id": "placement",
                                "type": "select",
                                "name": "Placement",
                                "description": "Placement of Shutter System",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "internal",
                                        "name": "Internal"
                                    },
                                    {
                                        "id": "external",
                                        "name": "External"
                                    }
                                ]
                            },
                            {
                                "id": "depth_reduction",
                                "type": "number",
                                "name": "Depth Reduction",
                                "description": "Depth Reduction of the Shutter. Shutter System Must be internal"
                            },
                            {
                                "id": "left_reduction",
                                "type": "number",
                                "name": "Left Reduction",
                                "description": "Left Reduction of the Shutter System."
                            },
                            {
                                "id": "right_reduction",
                                "type": "number",
                                "name": "Right Reduction",
                                "description": "Right Reduction of the Shutter System."
                            },
                            {
                                "id": "top_reduction",
                                "type": "number",
                                "name": "Top Reduction",
                                "description": "Top Reduction of the Shutter System."
                            },
                            {
                                "id": "bottom_reduction",
                                "type": "number",
                                "name": "Bottom Reduction",
                                "description": "Bottom Reduction of the Shutter System."
                            }
                        ]
                    },
                    {
                        "id": "sliding_construction_parameters",
                        "type": "parent",
                        "name": "Sliding Construction Parameters",
                        "description": "Sliding Construction Parameters",
                        "children": [
                            {
                                "id": "top_panel_front_reduction",
                                "type": "number",
                                "name": "Top Panel Front Reduction",
                                "description": "Reduction of Top Carcass Panel from Front. Only Applicable for Sliding Wardrobes"
                            },
                            {
                                "id": "top_panel_top_reduction",
                                "type": "number",
                                "name": "Top Panel Top Reduction",
                                "description": "Reduction of Top Carcass Panel from Top. Only Applicable for Sliding Wardrobes"
                            },
                            {
                                "id": "side_panel_extension",
                                "type": "number",
                                "name": "Side Panel Extension",
                                "description": "Carcass Side Panel Extension. Only Applicable for Sliding Wardrobes"
                            },
                            {
                                "id": "left_shutter_carcass_gap",
                                "type": "number",
                                "name": "Left Shutter Gap",
                                "description": "Gap from the Carcass Left Panel to the Left Shutter. Only Applicable for Sliding Wardrobes"
                            },
                            {
                                "id": "right_shutter_carcass_gap",
                                "type": "number",
                                "name": "Right Shutter Gap",
                                "description": "Gap from the Carcass Right Panel to the Right Shutter. Only Applicable for Sliding Wardrobes"
                            },
                            {
                                "id": "shutter_extension",
                                "type": "number",
                                "name": "Shutter Extension",
                                "description": "Shutter Extension for Overlapping. Only Applicable for Sliding Wardrobes"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "drawer_system",
                "type": "parent",
                "name": "Drawer System",
                "description": "Not Applicable if Partition Doesn't have Drawer System",
                "children": [
                    {
                        "id": "construction_type",
                        "type": "select",
                        "name": "Construction Type",
                        "description": "Construction Type of Drawer System",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "normal",
                                "name": "Normal"
                            },
                            {
                                "id": "drawer_box",
                                "name": "Box Type"
                            }
                        ]
                    },
                    {
                        "id": "offset_from_top",
                        "type": "number",
                        "name": "Offset From Top",
                        "description": "Offset From Top shelf/Carcass Top panel"
                    },
                    {
                        "id": "placement",
                        "type": "select",
                        "name": "Placement",
                        "description": "Placement of Drawer System",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "internal",
                                "name": "Internal"
                            },
                            {
                                "id": "external",
                                "name": "External"
                            }
                        ]
                    },
                    {
                        "id": "tandem_box_collection_sku_id",
                        "type": "sku",
                        "possible_category_types": [
                            "drawer_system_collection_hardware"
                        ],
                        "name": "Tandem Box Collection SKU",
                        "description": "Tandem Box Collection SKU. Not Applicable if Basket Collection is Presnet or If the Number of Linked SKUs is not matching with the number of Drawers"
                    },
                    {
                        "id": "basket_collection_sku_id",
                        "type": "sku",
                        "possible_category_types": [
                            "basket_collection_hardware"
                        ],
                        "name": "Basket Collection SKU",
                        "description": "Basket Collection SKU. Not Applicable if Tandem Box Collection is Presnet or If the Number of Linked SKUs is not matching with the number of Drawers"
                    },
                    {
                        "id": "internal_depth_reduction",
                        "type": "number",
                        "name": "Internal Depth Reduction",
                        "description": "Depth Reduction for Internal Drawers. Not Applicable if Drawer System is External"
                    },
                    {
                        "id": "internal_back_offset",
                        "type": "number",
                        "name": "Internal Back Offset",
                        "description": "Back Offset for Internal Drawers. Not Applicable if Drawer System is External"
                    },
                    {
                        "id": "internal_drawer_box_offset_from_front",
                        "type": "number",
                        "name": "Internal Drawer Box Offset From Front",
                        "description": "Drawer Box Offset From Front for Internal Drawers. Not Applicable if Drawer System is External or Construction type is not drawer box"
                    },
                    {
                        "id": "left_filler_present",
                        "type": "bool",
                        "name": "Left Filler",
                        "description": "To Add/Remove Left Filler"
                    },
                    {
                        "id": "left_filler_width",
                        "type": "number",
                        "name": "Left Filler Width",
                        "description": "Applicable only when Left filler is present"
                    },
                    {
                        "id": "left_filler_gap",
                        "type": "number",
                        "name": "Left Filler Gap",
                        "description": "Applicable only when Left filler is present"
                    },
                    {
                        "id": "right_filler_present",
                        "type": "bool",
                        "name": "Right Filler",
                        "description": "To Add/Remove Right Filler"
                    },
                    {
                        "id": "right_filler_width",
                        "type": "number",
                        "name": "Right Filler Width",
                        "description": "Applicable only when Right filler is present"
                    },
                    {
                        "id": "right_filler_gap",
                        "type": "number",
                        "name": "Right Filler Gap",
                        "description": "Applicable only when Right filler is present"
                    }
                ]
            },
            {
                "id": "hanging_rods",
                "type": "parent",
                "name": "Hanging Rods",
                "description": "Hanging Rods",
                "children": [
                    {
                        "id": "sku_id",
                        "type": "sku",
                        "name": "Hanger Rod SKU",
                        "description": "Quantity is Mandatory",
                        "possible_category_types": [
                            "hanger_rod_hardware"
                        ]
                    },
                    {
                        "id": "quantity",
                        "type": "number",
                        "name": "Quantity",
                        "description": "SKU ID is Mandatory"
                    },
                    {
                        "id": "hanging_rod_offset_from_top",
                        "type": "number",
                        "name": "Hanger Rod Offset from Top",
                        "description": "Not Applicable if Hanger rod is not present"
                    },
                    {
                        "id": "hanging_rod_offset_from_front",
                        "type": "number",
                        "name": "Hanger Rod Offset from Front",
                        "description": "Not Applicable if Hanger rod is not present"
                    },
                    {
                        "id": "hanging_rod_offset_from_back",
                        "type": "number",
                        "name": "Quantity",
                        "description": "Not Applicable if Hanger rod is not present"
                    }
                ]
            }
        ],
        "panels": [
            {
                "id": "ids",
                "type": "multiselect",
                "name": "Panel Name",
                "options": [
                    {
                        "id": "carcass_left_panel",
                        "name": "Carcass Left Panel"
                    },
                    {
                        "id": "carcass_left_rail",
                        "name": "Carcass Left Rail"
                    },
                    {
                        "id": "carcass_right_panel",
                        "name": "Carcass Right Panel"
                    },
                    {
                        "id": "carcass_right_rail",
                        "name": "Carcass Right Rail"
                    },
                    {
                        "id": "carcass_top_panel",
                        "name": "Carcass Top Panel"
                    },
                    {
                        "id": "carcass_top_rail",
                        "name": "Carcass Top Rail"
                    },
                    {
                        "id": "carcass_back_panel",
                        "name": "Carcass Back Panel"
                    },
                    {
                        "id": "carcass_back_rail",
                        "name": "Carcass Back Rail"
                    },
                    {
                        "id": "carcass_bottom_panel",
                        "name": "Carcass Bottom Panel"
                    },
                    {
                        "id": "carcass_bottom_rail",
                        "name": "Carcass Bottom Rail"
                    },
                    {
                        "id": "left_skirting_panel",
                        "name": "Left Skirting Panel"
                    },
                    {
                        "id": "right_skirting_panel",
                        "name": "Right Skirting Panel"
                    },
                    {
                        "id": "front_skirting_panel",
                        "name": "Front Skirting Panel"
                    },
                    {
                        "id": "back_skirting_panel",
                        "name": "Back Skirting Panel"
                    },
                    {
                        "id": "mid_skirting_panel",
                        "name": "Mid Skirting Panel"
                    },
                    {
                        "id": "left_end_panel",
                        "name": "Left End Panel"
                    },
                    {
                        "id": "right_end_panel",
                        "name": "Right End Panel"
                    },
                    {
                        "id": "ply_under_granite",
                        "name": "Ply Under Granite"
                    }
                ]
            },
            {
                "id": "exposed_panel",
                "type": "bool",
                "name": "Exposed Panel",
                "description": "Mark/Unmark Panel as Exposed"
            },
            {
                "id": "visible",
                "type": "bool",
                "name": "Visibility",
                "description": "Visible/Remove Panel"
            },
            {
                "id": "shelf",
                "type": "parent",
                "name": "Shelf Parameters",
                "children": [
                    {
                        "id": "shelf_type",
                        "type": "select",
                        "name": "Shelf Type",
                        "description": "Only Applicable for Shelves",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "fixed",
                                "name": "Fixed"
                            },
                            {
                                "id": "adjustable",
                                "name": "Adjustable"
                            }
                        ]
                    },
                    {
                        "id": "visibility_type",
                        "type": "select",
                        "name": "Visibility Type",
                        "description": "Only Applicable for Shelves",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "visible",
                                "name": "Visible"
                            },
                            {
                                "id": "invisible",
                                "name": "Invisible"
                            }
                        ]
                    },
                    {
                        "id": "protected_panel",
                        "type": "bool",
                        "name": "Protected Panel",
                        "description": "Only Applicable for Shelves"
                    }
                ]
            },
            {
                "id": "material",
                "type": "parent",
                "name": "Material",
                "description": "Applying Material to Panel",
                "children": [
                    {
                        "id": "core_panel_sku_id",
                        "type": "sku",
                        "name": "Core Panel SKU ID",
                        "description": "Core Panel SKU ID. If prelam respective finishes are auto applied",
                        "possible_category_types": [
                            "panel_core_hardware"
                        ]
                    },
                    {
                        "id": "top_finish_material_id",
                        "type": "sku",
                        "name": "Top Finish Material ID",
                        "description": "Top Finish Material ID. Linked edgeband will be auto applied. Not Applied if prelam both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "bottom_finish_material_id",
                        "type": "sku",
                        "name": "Bottom Finish Material ID",
                        "description": "Bottom Finish Material ID. Not Applied if Prelam Internal or Prelam Both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "has_left_edge_band",
                        "type": "bool",
                        "name": "Left Edge Band",
                        "description": "To Turn off Left Edge Band give false"
                    },
                    {
                        "id": "has_back_edge_band",
                        "type": "bool",
                        "name": "Back Edge Band",
                        "description": "To Turn off Back Edge Band give false"
                    },
                    {
                        "id": "has_right_edge_band",
                        "type": "bool",
                        "name": "Right Edge Band",
                        "description": "To Turn off Right Edge Band give false"
                    },
                    {
                        "id": "has_front_edge_band",
                        "type": "bool",
                        "name": "Front Edge Band",
                        "description": "To Turn off Front Edge Band give false"
                    },
                    {
                        "id": "left_edge_band_type",
                        "type": "select",
                        "name": "Left Edge Band Type",
                        "description": "Inhertance of Left Edge Band",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "visible",
                                "name": "Visible"
                            },
                            {
                                "id": "invisible",
                                "name": "Invisible"
                            },
                            {
                                "id": "custom",
                                "name": "Custom"
                            }
                        ]
                    },
                    {
                        "id": "right_edge_band_type",
                        "type": "select",
                        "name": "Right Edge Band Type",
                        "description": "Inhertance of Right Edge Band",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "visible",
                                "name": "Visible"
                            },
                            {
                                "id": "invisible",
                                "name": "Invisible"
                            },
                            {
                                "id": "custom",
                                "name": "Custom"
                            }
                        ]
                    },
                    {
                        "id": "back_edge_band_type",
                        "type": "select",
                        "name": "Back Edge Band Type",
                        "description": "Inhertance of Back Edge Band",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "visible",
                                "name": "Visible"
                            },
                            {
                                "id": "invisible",
                                "name": "Invisible"
                            },
                            {
                                "id": "custom",
                                "name": "Custom"
                            }
                        ]
                    },
                    {
                        "id": "front_edge_band_type",
                        "type": "select",
                        "name": "Front Edge Band Type",
                        "description": "Inhertance of Front Edge Band",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "visible",
                                "name": "Visible"
                            },
                            {
                                "id": "invisible",
                                "name": "Invisible"
                            },
                            {
                                "id": "custom",
                                "name": "Custom"
                            }
                        ]
                    },
                    {
                        "id": "left_edge_band_material_id",
                        "type": "sku",
                        "name": "Left Edge Band Material",
                        "description": "Left Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "right_edge_band_material_id",
                        "type": "sku",
                        "name": "Right Edge Band Material",
                        "description": "Right Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "front_edge_band_material_id",
                        "type": "sku",
                        "name": "Front Edge Band Material ID",
                        "description": "Front Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "back_edge_band_material_id",
                        "type": "sku",
                        "name": "Back Edge Band Material ID",
                        "description": "Back Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "visible_edge_band_material_id",
                        "type": "sku",
                        "name": "Visible Edge Band Material ID",
                        "description": "Visible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "invisible_edge_band_material_id",
                        "type": "sku",
                        "name": "Invisible Edge Band Material ID",
                        "description": "Invisible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    }
                ]
            },
            {
                "id": "dimension_parameters",
                "type": "parent",
                "name": "Dimension Parameters",
                "children": [
                    {
                        "id": "width_dimension_type",
                        "type": "select",
                        "name": "Width Dimension Type",
                        "description": "Behaviour of Width of panel",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "fixed",
                                "name": "Fixed"
                            },
                            {
                                "id": "extendable",
                                "name": "Extendable"
                            },
                            {
                                "id": "divisional_panel",
                                "name": "Divisional Panel"
                            },
                            {
                                "id": "auto_adjustable",
                                "name": "Auto Adjustable"
                            }
                        ]
                    },
                    {
                        "id": "divisional_panel_width",
                        "type": "number",
                        "name": "Divisional Panel Width",
                        "description": "Applicable if Width Dimension Type is Divisional Panel. Applicable only if the panel is Vertical divisional panel"
                    },
                    {
                        "id": "fixed_width",
                        "type": "number",
                        "name": "Fixed Width",
                        "description": "Applicable if Width Dimension Type is Fixed"
                    },
                    {
                        "id": "width_offset",
                        "type": "number",
                        "name": "Width Offset",
                        "description": "Applicable if Width Dimension Type is Fixed"
                    },
                    {
                        "id": "width_offset_source",
                        "type": "select",
                        "name": "Width Offset Source",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left",
                                "name": "Left"
                            },
                            {
                                "id": "right",
                                "name": "Right"
                            }
                        ],
                        "description": "Applicable if Width Dimension Type is Fixed"
                    },
                    {
                        "id": "left_extension",
                        "type": "number",
                        "name": "Left Extension",
                        "description": "Applicable if Width Dimension Type is Extendable"
                    },
                    {
                        "id": "right_extension",
                        "type": "number",
                        "name": "Right Extension",
                        "description": "Applicable if Width Dimension Type is Extendable"
                    },
                    {
                        "id": "auto_adjustable_width",
                        "type": "number",
                        "name": "Auto Adjustable Width",
                        "description": "Will Adjust The Width by setting extension parameters"
                    },
                    {
                        "id": "depth_dimension_type",
                        "type": "select",
                        "name": "Depth Dimension Type",
                        "description": "Behaviour of Depth of panel",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "fixed",
                                "name": "Fixed"
                            },
                            {
                                "id": "extendable",
                                "name": "Extendable"
                            },
                            {
                                "id": "divisional_panel",
                                "name": "Divisional Panel"
                            },
                            {
                                "id": "auto_adjustable",
                                "name": "Auto Adjustable"
                            }
                        ]
                    },
                    {
                        "id": "divisional_panel_depth",
                        "type": "number",
                        "name": "Divisional Panel Depth",
                        "description": "Applicable if Depth Dimension Type is Divisional Panel. Applicable only if the panel is Horizontal divisional panel"
                    },
                    {
                        "id": "fixed_depth",
                        "type": "number",
                        "name": "Fixed Depth",
                        "description": "Applicable if Depth Dimension Type is Fixed"
                    },
                    {
                        "id": "depth_offset",
                        "type": "number",
                        "name": "Depth Offset",
                        "description": "Applicable if Depth Dimension Type is Fixed"
                    },
                    {
                        "id": "depth_offset_source",
                        "type": "select",
                        "name": "Depth Offset Source",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left",
                                "name": "Left"
                            },
                            {
                                "id": "right",
                                "name": "Right"
                            }
                        ],
                        "description": "Applicable if Depth Dimension Type is Fixed"
                    },
                    {
                        "id": "top_extension",
                        "type": "number",
                        "name": "Top Extension",
                        "description": "Applicable if Depth Dimension Type is Extendable"
                    },
                    {
                        "id": "bottom_extension",
                        "type": "number",
                        "name": "Bottom Extension",
                        "description": "Applicable if Depth Dimension Type is Extendable"
                    },
                    {
                        "id": "auto_adjustable_depth",
                        "type": "number",
                        "name": "Auto Adjustable Depth",
                        "description": "Applicable if Depth Dimension Type is Auto Adjustable"
                    }
                ]
            },
            {
                "id": "strip_light",
                "type": "parent",
                "name": "Strip Light",
                "children": [
                    {
                        "id": "sku_id",
                        "type": "sku",
                        "name": "Strip Light SKu",
                        "description": "Strip Light",
                        "possible_category_types": [
                            "strip_light_accessory"
                        ]
                    },
                    {
                        "id": "panel_side",
                        "type": "select",
                        "name": "Panel Side",
                        "description": "Strip Light Has to be present",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "bottom_fascia",
                                "name": "Bottom Finish"
                            },
                            {
                                "id": "top_fascia",
                                "name": "Top Finish"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "corner_unit": {
        "cabinet": [
            {
                "id": "cbu",
                "type": "bool",
                "name": "CBU",
                "description": "Mark Cabinet as CBU"
            },
            {
                "id": "width",
                "type": "number",
                "name": "Width",
                "description": "Width of cabinet"
            },
            {
                "id": "depth",
                "type": "number",
                "name": "Depth",
                "description": "Depth of cabinet"
            },
            {
                "id": "height",
                "type": "number",
                "name": "Height",
                "description": "Height of cabinet"
            },
            {
                "id": "front_right",
                "type": "number",
                "name": "Front Right",
                "description": "Front Right of L corner or pentagonal. Not Applicable to Blind corner"
            },
            {
                "id": "side_left",
                "type": "number",
                "name": "Side Left",
                "description": "Side Left of L corner or pentagonal. Not Applicable to Blind corner"
            },
            {
                "id": "shutter_width",
                "type": "number",
                "name": "Shutter Width",
                "description": "Shutter Width in the Blind Corner. Not applicable for L corner or pentagonal"
            },
            {
                "id": "back_panel_offset",
                "type": "number",
                "name": "Back Panel Offset",
                "description": "Offest of Back Panel from Back"
            },
            {
                "id": "back_panel_grooving",
                "type": "number",
                "name": "Back Panel Grooving",
                "description": "Grooving of Back panel On all sides"
            },
            {
                "id": "sizing_rules",
                "type": "parent",
                "name": "Sizing Rules",
                "children": [
                    {
                        "id": "end_panel_max_height",
                        "type": "number",
                        "name": "End Panel Max Height",
                        "description": "End Panel Max Height(Default 2400)"
                    },
                    {
                        "id": "min_depth",
                        "type": "number",
                        "name": "Min Depth",
                        "description": "Min Depth. -1 to Unset, -2 For same value as Depth."
                    },
                    {
                        "id": "max_depth",
                        "type": "number",
                        "name": "Max Depth",
                        "description": "Max Depth. -1 to Unset, -2 For same value as Depth."
                    },
                    {
                        "id": "min_width",
                        "type": "number",
                        "name": "Min Width",
                        "description": "Min Width. -1 to Unset, -2 For same value as Width."
                    },
                    {
                        "id": "max_width",
                        "type": "number",
                        "name": "Max Width",
                        "description": "Max Width. -1 to Unset, -2 For same value as Width."
                    },
                    {
                        "id": "min_height",
                        "type": "number",
                        "name": "Min Height",
                        "description": "Min Height. -1 to Unset, -2 For same value as Height."
                    },
                    {
                        "id": "max_height",
                        "type": "number",
                        "name": "Max Height",
                        "description": "Max Height. -1 to Unset, -2 For same value as Height."
                    },
                    {
                        "id": "min_front_width",
                        "type": "number",
                        "name": "Min Front Width",
                        "description": "Min Front Width. -1 to Unset, -2 For same value as Front Width. Not Applicable for Blind Corners"
                    },
                    {
                        "id": "max_front_width",
                        "type": "number",
                        "name": "Max Front Width",
                        "description": "Max Front Width. -1 to Unset, -2 For same value as Front Width. Not Applicable for Blind Corners"
                    },
                    {
                        "id": "min_side_depth",
                        "type": "number",
                        "name": "Min Side Depth",
                        "description": "Min Side Depth. -1 to Unset, -2 For same value as Side Depth. Not Applicable for Blind Corners"
                    },
                    {
                        "id": "max_side_depth",
                        "type": "number",
                        "name": "Max Side Depth",
                        "description": "Max Side Depth. -1 to Unset, -2 For same value as Side Depth. Not Applicable for Blind Corners"
                    },
                    {
                        "id": "min_shutter_partition_width",
                        "type": "number",
                        "name": "Min Shutter Partition Width",
                        "description": "Min Shutter Partition Width. -1 to Unset, -2 For same value as Shutter Partition Width. Not Applicable for L or Pentagonal Corners"
                    },
                    {
                        "id": "max_shutter_partition_width",
                        "type": "number",
                        "name": "Max Shutter Partition Width",
                        "description": "Max Shutter Partition Width. -1 to Unset, -2 For same value as Shutter Partition Width. Not Applicable for L or Pentagonal Corners"
                    },
                    {
                        "id": "min_partition_depth_shelf",
                        "type": "number",
                        "name": "Min Partition Depth (Shelf)",
                        "description": "Shelf Restriction. -1 to Unset."
                    },
                    {
                        "id": "max_partition_depth_shelf",
                        "type": "number",
                        "name": "Max Partition Depth (Shelf)",
                        "description": "Shelf Restriction. -1 to Unset."
                    },
                    {
                        "id": "min_partition_width_shelf",
                        "type": "number",
                        "name": "Min Partition Width (Shelf)",
                        "description": "Shelf Restriction. -1 to Unset."
                    },
                    {
                        "id": "max_partition_width_shelf",
                        "type": "number",
                        "name": "Max Partition Width (Shelf)",
                        "description": "Shelf Restriction. -1 to Unset."
                    },
                    {
                        "id": "min_partition_height_shelf",
                        "type": "number",
                        "name": "Min Partition Height (Shelf)",
                        "description": "Shelf Restriction. -1 to Unset."
                    },
                    {
                        "id": "max_partition_height_shelf",
                        "type": "number",
                        "name": "Max Partition Height (Shelf)",
                        "description": "Shelf Restriction. -1 to Unset."
                    }
                ]
            },
            {
                "id": "carcass_material",
                "type": "parent",
                "name": "Carcass Material",
                "children": [
                    {
                        "id": "core_panel_sku_id",
                        "type": "sku",
                        "name": "Core Panel SKU ID",
                        "description": "Core Panel SKU ID. If prelam respective finishes are auto applied",
                        "possible_category_types": [
                            "panel_core_hardware"
                        ]
                    },
                    {
                        "id": "top_finish_material_id",
                        "type": "sku",
                        "name": "Top Finish Material ID",
                        "description": "Top Finish Material ID. Linked edgeband will be auto applied. Not Applied if prelam both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "bottom_finish_material_id",
                        "type": "sku",
                        "name": "Bottom Finish Material ID",
                        "description": "Bottom Finish Material ID. Not Applied if Prelam Internal or Prelam Both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "visible_edge_band_material_id",
                        "type": "sku",
                        "name": "Visible Edge Band Material",
                        "description": "Visible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "invisible_edge_band_material_id",
                        "type": "sku",
                        "name": "Invisible Edge Band Material",
                        "description": "Invisible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "visible_edge_band",
                        "type": "bool",
                        "name": "Visible Edge Band",
                        "description": "To Turn off Visible Edge Band on all sides give false"
                    },
                    {
                        "id": "invisible_edge_band",
                        "type": "bool",
                        "name": "Invisible Edge Band",
                        "description": "To Turn off Invisible Edge Band on all sides give false"
                    }
                ]
            },
            {
                "id": "skirting_material",
                "type": "parent",
                "name": "Skirting Material",
                "children": [
                    {
                        "id": "core_panel_sku_id",
                        "type": "sku",
                        "name": "Core Panel SKU ID",
                        "description": "Core Panel SKU ID. For Non wooden - Skirting Hardware. For wooden - Ply Hardware, Material Source should be none, Prelam respective finishes are auto applied",
                        "possible_category_types": [
                            "skirting_hardware",
                            "panel_core_hardware"
                        ]
                    },
                    {
                        "id": "top_finish_material_id",
                        "type": "sku",
                        "name": "Top Finish Material ID",
                        "description": "Top Finish Material ID. Applied only for Wooden and Material Source is None. Linked edgeband will be auto applied. Not Applied if prelam both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "bottom_finish_material_id",
                        "type": "sku",
                        "name": "Bottom Finish Material ID",
                        "description": "Bottom Finish Material ID. Applied only for Wooden and Material Source is None. Not Applied if Prelam Internal or Prelam Both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "visible_edge_band_material_id",
                        "type": "sku",
                        "name": "Visible Edge Band Material",
                        "description": "Visible Edge Band Material ID. Applied only for Wooden and Material Source is None. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "invisible_edge_band_material_id",
                        "type": "sku",
                        "name": "Invisible Edge Band Material",
                        "description": "Invisible Edge Band Material ID. Applied only for Wooden and Material Source is None. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "visible_edge_band",
                        "type": "bool",
                        "name": "Visible Edge Band",
                        "description": "To Turn off Visible Edge Band on all sides give false. Applied only for Wooden and Material Source is None"
                    },
                    {
                        "id": "invisible_edge_band",
                        "type": "bool",
                        "name": "Invisible Edge Band",
                        "description": "To Turn off Invisible Edge Band on all sides give false. Applied only for Wooden and Material Source is None"
                    }
                ]
            },
            {
                "id": "shutter_material",
                "type": "parent",
                "name": "Shutter Material",
                "children": [
                    {
                        "id": "core_panel_sku_id",
                        "type": "sku",
                        "name": "Core Panel SKU ID",
                        "description": "Core Panel SKU ID. If prelam respective finishes are auto applied",
                        "possible_category_types": [
                            "panel_core_hardware"
                        ]
                    },
                    {
                        "id": "top_finish_material_id",
                        "type": "sku",
                        "name": "Top Finish Material ID",
                        "description": "Top Finish Material ID. Linked edgeband will be auto applied. Not Applied if prelam both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "bottom_finish_material_id",
                        "type": "sku",
                        "name": "Bottom Finish Material ID",
                        "description": "Bottom Finish Material ID. Not Applied if Prelam Internal or Prelam Both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "design_material_id",
                        "type": "sku",
                        "name": "Shutter Design Material ID",
                        "description": "Shutter Design Material ID. Not Applied if Prelam Internal or Prelam Both",
                        "possible_category_types": [
                            "shutter_hardware"
                        ]
                    },
                    {
                        "id": "visible_edge_band_material_id",
                        "type": "sku",
                        "name": "Visible Edge Band Material ID",
                        "description": "Visible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "invisible_edge_band_material_id",
                        "type": "sku",
                        "name": "Invisible Edge Band Material ID",
                        "description": "Invisible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "visible_edge_band",
                        "type": "bool",
                        "name": "Visible Edge Band",
                        "description": "To Turn off Visible Edge Band on all sides give false"
                    },
                    {
                        "id": "invisible_edge_band",
                        "type": "bool",
                        "name": "Invisible Edge Band",
                        "description": "To Turn off Invisible Edge Band on all sides give false"
                    }
                ]
            },
            {
                "id": "skirting",
                "type": "parent",
                "name": "Skirting Options",
                "children": [
                    {
                        "id": "visibility",
                        "type": "bool",
                        "name": "Visibility",
                        "description": "Visible All Skirting Panels"
                    },
                    {
                        "id": "skirting_type",
                        "type": "select",
                        "name": "Skirting Type",
                        "description": "Wooden For Cabinet, Non Wooden for Wardobes by default",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "wooden",
                                "name": "Wooden"
                            },
                            {
                                "id": "non_wooden",
                                "name": "Non Wooden"
                            }
                        ]
                    },
                    {
                        "id": "skirting_alignment",
                        "type": "select",
                        "name": "Skirting Alignment",
                        "description": "Applicable only for wooden. Carcass By Default",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "carcass",
                                "name": "Carcass"
                            },
                            {
                                "id": "shutter",
                                "name": "Shutter"
                            }
                        ]
                    },
                    {
                        "id": "skirting_height",
                        "type": "number",
                        "name": "Skirting Height",
                        "description": "Applicable only for wooden. To be given when changing to wooden"
                    },
                    {
                        "id": "skirting_material_source",
                        "type": "select",
                        "name": "Skirting Material Source",
                        "description": "Applicable only for wooden",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "carcass",
                                "name": "Carcass"
                            },
                            {
                                "id": "shutter",
                                "name": "Shutter"
                            },
                            {
                                "id": "none",
                                "name": "None"
                            }
                        ]
                    },
                    {
                        "id": "skirting_depth_offset",
                        "type": "number",
                        "name": "Skirting Depth Offset",
                        "description": "Depth Offset of Skirting from front"
                    }
                ]
            },
            {
                "id": "rails",
                "type": "parent",
                "name": "Rails",
                "children": [
                    {
                        "id": "stability_rails",
                        "type": "number",
                        "name": "Stability Rails",
                        "description": "Additional Number of Stability rails to be added. Applicable only for wardrobe"
                    },
                    {
                        "id": "stability_rail_height",
                        "type": "number",
                        "name": "Stability Rail Height",
                        "description": "Height of stability rail. Applicable when atleast One stability Rail is present"
                    },
                    {
                        "id": "half_top",
                        "type": "bool",
                        "name": "Half Top",
                        "description": "Converting top panel to rails. Not applicable for Wardrobe"
                    },
                    {
                        "id": "front_half_top_depth",
                        "type": "number",
                        "name": "Front Half Top Depth",
                        "description": "Front Top rail depth. Not applicable if Top panel is not rails"
                    },
                    {
                        "id": "back_half_top_depth",
                        "type": "number",
                        "name": "Back Half Top Depth",
                        "description": "Back Top rail depth. Not applicable if Top panel is not rails"
                    },
                    {
                        "id": "top_rails_orientation",
                        "type": "select",
                        "name": "Top Rails Orientation",
                        "description": "Top Rail Orientation. Not applicable if Top panel is not rails",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left_right",
                                "name": "Left-Right"
                            },
                            {
                                "id": "top_bottom",
                                "name": "Top-Bottom"
                            }
                        ]
                    },
                    {
                        "id": "has_back_rails",
                        "type": "bool",
                        "name": "Has Back Rails",
                        "description": "Converting back panel to rails"
                    },
                    {
                        "id": "back_rail_depth",
                        "type": "number",
                        "name": "Back Rail Depth",
                        "description": "Rail Depth of Back Rail"
                    },
                    {
                        "id": "back_rails_orientation",
                        "type": "select",
                        "name": "Back Rails Orientation",
                        "description": "Back Rail Orientation. Not applicable if Back panel is not rails",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left_right",
                                "name": "Left-Right"
                            },
                            {
                                "id": "top_bottom",
                                "name": "Top-Bottom"
                            }
                        ]
                    },
                    {
                        "id": "has_bottom_rails",
                        "type": "bool",
                        "name": "Has Bottom Rails",
                        "description": "Converting bottom panel to rails"
                    },
                    {
                        "id": "bottom_rail_depth",
                        "type": "number",
                        "name": "Bottom Rail Depth",
                        "description": "Rail Depth of Bottom Rail"
                    },
                    {
                        "id": "bottom_rails_orientation",
                        "type": "select",
                        "name": "Bottom Rails Orientation",
                        "description": "Bottom Rail Orientation. Not applicable if Bottom panel is not rails",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left_right",
                                "name": "Left-Right"
                            },
                            {
                                "id": "top_bottom",
                                "name": "Top-Bottom"
                            }
                        ]
                    },
                    {
                        "id": "has_left_rails",
                        "type": "bool",
                        "name": "Has Left Rails",
                        "description": "Converting Left panel to rails"
                    },
                    {
                        "id": "left_rail_depth",
                        "type": "number",
                        "name": "Left Rail Depth",
                        "description": "Rail Depth of Left Rail"
                    },
                    {
                        "id": "left_rails_orientation",
                        "type": "select",
                        "name": "Left Rails Orientation",
                        "description": "Left Rail Orientation. Not applicable if Left panel is not rails",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left_right",
                                "name": "Left-Right"
                            },
                            {
                                "id": "top_bottom",
                                "name": "Top-Bottom"
                            }
                        ]
                    },
                    {
                        "id": "has_right_rails",
                        "type": "bool",
                        "name": "Has Right Rails",
                        "description": "Converting Right panel to rails"
                    },
                    {
                        "id": "right_rail_depth",
                        "type": "number",
                        "name": "Right Rail Depth",
                        "description": "Rail Depth of Right Rail"
                    },
                    {
                        "id": "right_rails_orientation",
                        "type": "select",
                        "name": "Left Rails Orientation",
                        "description": "Left Rail Orientation. Not applicable if Left panel is not rails",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left_right",
                                "name": "Left-Right"
                            },
                            {
                                "id": "top_bottom",
                                "name": "Top-Bottom"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "construction",
                "type": "parent",
                "name": "Construction",
                "children": [
                    {
                        "id": "backOnLeftEdge",
                        "type": "bool",
                        "name": "Back on Left Edge",
                        "description": "Carcass Back and Left Panel Construction"
                    },
                    {
                        "id": "dummy_panel_depth",
                        "type": "number",
                        "name": "Dummy Panel Depth",
                        "description": "Only Applicable for Blind Corner Units"
                    },
                    {
                        "id": "top_plank_construction",
                        "type": "select",
                        "name": "Top Plank Construction",
                        "description": "Only applicable only for L corner and pentagonal corner when the top panel construction is not rails",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "normal",
                                "name": "Normal"
                            },
                            {
                                "id": "front_major",
                                "name": "Front Major"
                            },
                            {
                                "id": "side_major",
                                "name": "Side Major"
                            }
                        ]
                    },
                    {
                        "id": "bottom_plank_construction",
                        "type": "select",
                        "name": "Bottom Plank Construction",
                        "description": "Only applicable only for L corner and pentagonal corner when the bottom panel construction is not rails",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "normal",
                                "name": "Normal"
                            },
                            {
                                "id": "front_major",
                                "name": "Front Major"
                            },
                            {
                                "id": "side_major",
                                "name": "Side Major"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "countertop",
                "type": "parent",
                "name": "Countertop",
                "children": [
                    {
                        "id": "visibility",
                        "type": "bool",
                        "name": "Countertop Visibility",
                        "description": "Not Applicable to Wardrobe"
                    },
                    {
                        "id": "material_id",
                        "type": "sku",
                        "possible_category_types": [
                            "countertop_finish"
                        ],
                        "name": "Countertop Material",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible"
                    },
                    {
                        "id": "left_extension",
                        "type": "number",
                        "name": "Left Extension",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible"
                    },
                    {
                        "id": "right_extension",
                        "type": "number",
                        "name": "Right Extension",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible"
                    },
                    {
                        "id": "back_extension",
                        "type": "number",
                        "name": "Back Extension",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible"
                    },
                    {
                        "id": "front_extension",
                        "type": "number",
                        "name": "Front Extension",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible"
                    },
                    {
                        "id": "middle_front",
                        "type": "number",
                        "name": "Middle Front",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible. Applicable only for L corner"
                    },
                    {
                        "id": "middle_side",
                        "type": "number",
                        "name": "Middle Side",
                        "description": "Not Applicable to Wardrobe, Counter top should be visible. Applicable only for L corner"
                    }
                ]
            },
            {
                "id": "left_end_panel",
                "type": "bool",
                "name": "Left End Panel",
                "description": "Left End Panel to be Added or Removed"
            },
            {
                "id": "right_end_panel",
                "type": "bool",
                "name": "Right End Panel",
                "description": "Right End Panel to be Added or Removed"
            },
            {
                "id": "shutter_system_type",
                "type": "select",
                "name": "Shutter System Type",
                "description": "For L corner - corner_unit_double_swing, left_bifold, right_bifold. For pentagonal, blind_corner_left, blind_corner_right - single, double,  top_lift, gas_lift, flap_down",
                "options": [
                    {
                        "id": "",
                        "name": "No Changes"
                    },
                    {
                        "id": "corner_unit_double_swing",
                        "name": "Corner Unit Double Swing"
                    },
                    {
                        "id": "left_bifold",
                        "name": "Left Bifold"
                    },
                    {
                        "id": "right_bifold",
                        "name": "Right Bifold"
                    },
                    {
                        "id": "single",
                        "name": "Single"
                    },
                    {
                        "id": "double",
                        "name": "Double"
                    },
                    {
                        "id": "gas_lift",
                        "name": "Gas Lift Up"
                    },
                    {
                        "id": "top_lift",
                        "name": "Top Lift"
                    },
                    {
                        "id": "flap_down",
                        "name": "Flap Down"
                    }
                ]
            },
            {
                "id": "left_door",
                "type": "parent",
                "name": "Left Door",
                "description": "Left Door of L corner Unit. Not applicable for pentagonal, Blind corners",
                "children": [
                    {
                        "id": "divisional_shutter",
                        "type": "parent",
                        "name": "Divisional Shutter",
                        "description": "To Add Divisional Shutter",
                        "children": [
                            {
                                "id": "orientation",
                                "type": "select",
                                "name": "Orientation",
                                "description": "Orientation of divisional Shutter to be added. Quantity is Mandatory",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "horizontal",
                                        "name": "Horizontal"
                                    },
                                    {
                                        "id": "vertical",
                                        "name": "Vertical"
                                    }
                                ]
                            },
                            {
                                "id": "quantity",
                                "type": "number",
                                "name": "Quantity",
                                "description": "Additional divisional Shutter panels to be added. Orientation is Mandatory. One Panels will be Already Present"
                            }
                        ]
                    },
                    {
                        "id": "handle",
                        "type": "parent",
                        "name": "Handle",
                        "description": "To Change the Handle",
                        "children": [
                            {
                                "id": "placement",
                                "type": "select",
                                "name": "Placement",
                                "description": "For normal handles - top_left, top_center, top_right, center_left, center, center_right, bottom_left, bottom_center, bottom_right. For profile handles - top,bottom,left,right",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "top_left",
                                        "name": "Top Left"
                                    },
                                    {
                                        "id": "top_center",
                                        "name": "Top Center"
                                    },
                                    {
                                        "id": "top_right",
                                        "name": "Top Right"
                                    },
                                    {
                                        "id": "center_left",
                                        "name": "Center Left"
                                    },
                                    {
                                        "id": "center",
                                        "name": "Center"
                                    },
                                    {
                                        "id": "center_right",
                                        "name": "Center Right"
                                    },
                                    {
                                        "id": "bottom_left",
                                        "name": "Bottom Left"
                                    },
                                    {
                                        "id": "bottom_center",
                                        "name": "Bottom Center"
                                    },
                                    {
                                        "id": "bottom_right",
                                        "name": "Bottom Right"
                                    },
                                    {
                                        "id": "top",
                                        "name": "Top"
                                    },
                                    {
                                        "id": "bottom",
                                        "name": "Bottom"
                                    },
                                    {
                                        "id": "right",
                                        "name": "Right"
                                    },
                                    {
                                        "id": "left",
                                        "name": "Left"
                                    }
                                ]
                            },
                            {
                                "id": "sku_id",
                                "type": "sku",
                                "name": "Handle SKU",
                                "possible_category_types": [
                                    "handle_hardware",
                                    "profile_and_gola_handles_hardware"
                                ],
                                "description": "To Add Handle to the Shutter"
                            },
                            {
                                "id": "orientation",
                                "type": "select",
                                "name": "Orientation",
                                "description": "Applicable only for normal handles.",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "horizontal",
                                        "name": "Horizontal"
                                    },
                                    {
                                        "id": "vertical",
                                        "name": "Vertical"
                                    }
                                ]
                            },
                            {
                                "id": "non_handle_profile_sku_id",
                                "type": "sku",
                                "name": "Non Handle Profile SKU",
                                "description": "To Add Non Handle Profile to the Shutter",
                                "possible_category_types": [
                                    "profile_and_gola_handles_hardware"
                                ]
                            },
                            {
                                "id": "handle_profile_sku_id",
                                "type": "sku",
                                "name": "Handle Profile SKU",
                                "description": "To Add Handle Profile to the Shutter",
                                "possible_category_types": [
                                    "profile_and_gola_handles_hardware"
                                ]
                            },
                            {
                                "id": "divisional_handle_profile_sku_id",
                                "type": "sku",
                                "name": "Divisional Profile SKU",
                                "description": "To Add Divisional Profile to the Shutter",
                                "possible_category_types": [
                                    "profile_and_gola_handles_hardware"
                                ]
                            }
                        ]
                    },
                    {
                        "id": "basket_sku_id",
                        "type": "sku",
                        "name": "Corner Unit System SKU",
                        "description": "Adding Corner Unit System to the Shutter",
                        "possible_category_types": [
                            "corner_unit_systems_hardware"
                        ]
                    },
                    {
                        "id": "front_mirror_ply_sku_id",
                        "type": "sku",
                        "name": "Mirror SKU",
                        "description": "Adding Front Mirror to the Shutter",
                        "possible_category_types": [
                            "mirror_hardware"
                        ]
                    },
                    {
                        "id": "back_mirror_ply_sku_id",
                        "type": "sku",
                        "name": "Mirror SKU",
                        "description": "Adding Back Mirror to the Shutter",
                        "possible_category_types": [
                            "mirror_hardware"
                        ]
                    },
                    {
                        "id": "parameters",
                        "type": "parent",
                        "name": "Shutter Parameters",
                        "description": "Shutter Parameters",
                        "children": [
                            {
                                "id": "placement",
                                "type": "select",
                                "name": "Placement",
                                "description": "Placement of Shutter System",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "internal",
                                        "name": "Internal"
                                    },
                                    {
                                        "id": "external",
                                        "name": "External"
                                    }
                                ]
                            },
                            {
                                "id": "depth_reduction",
                                "type": "number",
                                "name": "Depth Reduction",
                                "description": "Depth Reduction of the Shutter. Shutter System Must be internal"
                            },
                            {
                                "id": "left_reduction",
                                "type": "number",
                                "name": "Left Reduction",
                                "description": "Left Reduction of the Shutter System."
                            },
                            {
                                "id": "right_reduction",
                                "type": "number",
                                "name": "Right Reduction",
                                "description": "Right Reduction of the Shutter System."
                            },
                            {
                                "id": "top_reduction",
                                "type": "number",
                                "name": "Top Reduction",
                                "description": "Top Reduction of the Shutter System."
                            },
                            {
                                "id": "bottom_reduction",
                                "type": "number",
                                "name": "Bottom Reduction",
                                "description": "Bottom Reduction of the Shutter System."
                            }
                        ]
                    }
                ]
            },
            {
                "id": "right_door",
                "type": "parent",
                "name": "Right Door",
                "description": "Right Door of L corner Unit. Not applicable for pentagonal, Blind corners",
                "children": [
                    {
                        "id": "divisional_shutter",
                        "type": "parent",
                        "name": "Divisional Shutter",
                        "description": "To Add Divisional Shutter",
                        "children": [
                            {
                                "id": "orientation",
                                "type": "select",
                                "name": "Orientation",
                                "description": "Orientation of divisional Shutter to be added. Quantity is Mandatory",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "horizontal",
                                        "name": "Horizontal"
                                    },
                                    {
                                        "id": "vertical",
                                        "name": "Vertical"
                                    }
                                ]
                            },
                            {
                                "id": "quantity",
                                "type": "number",
                                "name": "Quantity",
                                "description": "Additional divisional Shutter panels to be added. Orientation is Mandatory. One Panels will be Already Present"
                            }
                        ]
                    },
                    {
                        "id": "handle",
                        "type": "parent",
                        "name": "Handle",
                        "description": "To Change the Handle",
                        "children": [
                            {
                                "id": "placement",
                                "type": "select",
                                "name": "Placement",
                                "description": "For normal handles - top_left, top_center, top_right, center_left, center, center_right, bottom_left, bottom_center, bottom_right. For profile handles - top,bottom,left,right",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "top_left",
                                        "name": "Top Left"
                                    },
                                    {
                                        "id": "top_center",
                                        "name": "Top Center"
                                    },
                                    {
                                        "id": "top_right",
                                        "name": "Top Right"
                                    },
                                    {
                                        "id": "center_left",
                                        "name": "Center Left"
                                    },
                                    {
                                        "id": "center",
                                        "name": "Center"
                                    },
                                    {
                                        "id": "center_right",
                                        "name": "Center Right"
                                    },
                                    {
                                        "id": "bottom_left",
                                        "name": "Bottom Left"
                                    },
                                    {
                                        "id": "bottom_center",
                                        "name": "Bottom Center"
                                    },
                                    {
                                        "id": "bottom_right",
                                        "name": "Bottom Right"
                                    },
                                    {
                                        "id": "top",
                                        "name": "Top"
                                    },
                                    {
                                        "id": "bottom",
                                        "name": "Bottom"
                                    },
                                    {
                                        "id": "right",
                                        "name": "Right"
                                    },
                                    {
                                        "id": "left",
                                        "name": "Left"
                                    }
                                ]
                            },
                            {
                                "id": "sku_id",
                                "type": "sku",
                                "name": "Handle SKU",
                                "possible_category_types": [
                                    "handle_hardware",
                                    "profile_and_gola_handles_hardware"
                                ],
                                "description": "To Add Handle to the Shutter"
                            },
                            {
                                "id": "orientation",
                                "type": "select",
                                "name": "Orientation",
                                "description": "Applicable only for normal handles.",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "horizontal",
                                        "name": "Horizontal"
                                    },
                                    {
                                        "id": "vertical",
                                        "name": "Vertical"
                                    }
                                ]
                            },
                            {
                                "id": "non_handle_profile_sku_id",
                                "type": "sku",
                                "name": "Non Handle Profile SKU",
                                "description": "To Add Non Handle Profile to the Shutter",
                                "possible_category_types": [
                                    "profile_and_gola_handles_hardware"
                                ]
                            },
                            {
                                "id": "handle_profile_sku_id",
                                "type": "sku",
                                "name": "Handle Profile SKU",
                                "description": "To Add Handle Profile to the Shutter",
                                "possible_category_types": [
                                    "profile_and_gola_handles_hardware"
                                ]
                            },
                            {
                                "id": "divisional_handle_profile_sku_id",
                                "type": "sku",
                                "name": "Divisional Profile SKU",
                                "description": "To Add Divisional Profile to the Shutter",
                                "possible_category_types": [
                                    "profile_and_gola_handles_hardware"
                                ]
                            }
                        ]
                    },
                    {
                        "id": "basket_sku_id",
                        "type": "sku",
                        "name": "Corner Unit System SKU",
                        "description": "Adding Corner Unit System to the Shutter",
                        "possible_category_types": [
                            "corner_unit_systems_hardware"
                        ]
                    },
                    {
                        "id": "front_mirror_ply_sku_id",
                        "type": "sku",
                        "name": "Mirror SKU",
                        "description": "Adding Front Mirror to the Shutter",
                        "possible_category_types": [
                            "mirror_hardware"
                        ]
                    },
                    {
                        "id": "back_mirror_ply_sku_id",
                        "type": "sku",
                        "name": "Mirror SKU",
                        "description": "Adding Back Mirror to the Shutter",
                        "possible_category_types": [
                            "mirror_hardware"
                        ]
                    },
                    {
                        "id": "parameters",
                        "type": "parent",
                        "name": "Shutter Parameters",
                        "description": "Shutter Parameters",
                        "children": [
                            {
                                "id": "placement",
                                "type": "select",
                                "name": "Placement",
                                "description": "Placement of Shutter System",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "internal",
                                        "name": "Internal"
                                    },
                                    {
                                        "id": "external",
                                        "name": "External"
                                    }
                                ]
                            },
                            {
                                "id": "depth_reduction",
                                "type": "number",
                                "name": "Depth Reduction",
                                "description": "Depth Reduction of the Shutter. Shutter System Must be internal"
                            },
                            {
                                "id": "left_reduction",
                                "type": "number",
                                "name": "Left Reduction",
                                "description": "Left Reduction of the Shutter System."
                            },
                            {
                                "id": "right_reduction",
                                "type": "number",
                                "name": "Right Reduction",
                                "description": "Right Reduction of the Shutter System."
                            },
                            {
                                "id": "top_reduction",
                                "type": "number",
                                "name": "Top Reduction",
                                "description": "Top Reduction of the Shutter System."
                            },
                            {
                                "id": "bottom_reduction",
                                "type": "number",
                                "name": "Bottom Reduction",
                                "description": "Bottom Reduction of the Shutter System."
                            }
                        ]
                    }
                ]
            },
            {
                "id": "door",
                "type": "parent",
                "name": "Door",
                "description": "Door of Pentagonal, Blind Corners. Not applicable for L corner",
                "children": [
                    {
                        "id": "divisional_shutter",
                        "type": "parent",
                        "name": "Divisional Shutter",
                        "description": "To Add Divisional Shutter",
                        "children": [
                            {
                                "id": "orientation",
                                "type": "select",
                                "name": "Orientation",
                                "description": "Orientation of divisional Shutter to be added. Quantity is Mandatory",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "horizontal",
                                        "name": "Horizontal"
                                    },
                                    {
                                        "id": "vertical",
                                        "name": "Vertical"
                                    }
                                ]
                            },
                            {
                                "id": "quantity",
                                "type": "number",
                                "name": "Quantity",
                                "description": "Additional divisional Shutter panels to be added. Orientation is Mandatory. One Panels will be Already Present"
                            }
                        ]
                    },
                    {
                        "id": "handle",
                        "type": "parent",
                        "name": "Handle",
                        "description": "To Change the Handle",
                        "children": [
                            {
                                "id": "placement",
                                "type": "select",
                                "name": "Placement",
                                "description": "For normal handles - top_left, top_center, top_right, center_left, center, center_right, bottom_left, bottom_center, bottom_right. For profile handles - top,bottom,left,right",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "top_left",
                                        "name": "Top Left"
                                    },
                                    {
                                        "id": "top_center",
                                        "name": "Top Center"
                                    },
                                    {
                                        "id": "top_right",
                                        "name": "Top Right"
                                    },
                                    {
                                        "id": "center_left",
                                        "name": "Center Left"
                                    },
                                    {
                                        "id": "center",
                                        "name": "Center"
                                    },
                                    {
                                        "id": "center_right",
                                        "name": "Center Right"
                                    },
                                    {
                                        "id": "bottom_left",
                                        "name": "Bottom Left"
                                    },
                                    {
                                        "id": "bottom_center",
                                        "name": "Bottom Center"
                                    },
                                    {
                                        "id": "bottom_right",
                                        "name": "Bottom Right"
                                    },
                                    {
                                        "id": "top",
                                        "name": "Top"
                                    },
                                    {
                                        "id": "bottom",
                                        "name": "Bottom"
                                    },
                                    {
                                        "id": "right",
                                        "name": "Right"
                                    },
                                    {
                                        "id": "left",
                                        "name": "Left"
                                    }
                                ]
                            },
                            {
                                "id": "sku_id",
                                "type": "sku",
                                "name": "Handle SKU",
                                "possible_category_types": [
                                    "handle_hardware",
                                    "profile_and_gola_handles_hardware"
                                ],
                                "description": "To Add Handle to the Shutter"
                            },
                            {
                                "id": "orientation",
                                "type": "select",
                                "name": "Orientation",
                                "description": "Applicable only for normal handles.",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "horizontal",
                                        "name": "Horizontal"
                                    },
                                    {
                                        "id": "vertical",
                                        "name": "Vertical"
                                    }
                                ]
                            },
                            {
                                "id": "non_handle_profile_sku_id",
                                "type": "sku",
                                "name": "Non Handle Profile SKU",
                                "description": "To Add Non Handle Profile to the Shutter",
                                "possible_category_types": [
                                    "profile_and_gola_handles_hardware"
                                ]
                            },
                            {
                                "id": "handle_profile_sku_id",
                                "type": "sku",
                                "name": "Handle Profile SKU",
                                "description": "To Add Handle Profile to the Shutter",
                                "possible_category_types": [
                                    "profile_and_gola_handles_hardware"
                                ]
                            },
                            {
                                "id": "divisional_handle_profile_sku_id",
                                "type": "sku",
                                "name": "Divisional Profile SKU",
                                "description": "To Add Divisional Profile to the Shutter",
                                "possible_category_types": [
                                    "profile_and_gola_handles_hardware"
                                ]
                            }
                        ]
                    },
                    {
                        "id": "basket_sku_id",
                        "type": "sku",
                        "name": "Corner Unit System SKU",
                        "description": "Adding Corner Unit System to the Shutter",
                        "possible_category_types": [
                            "corner_unit_systems_hardware"
                        ]
                    },
                    {
                        "id": "front_mirror_ply_sku_id",
                        "type": "sku",
                        "name": "Mirror SKU",
                        "description": "Adding Front Mirror to the Shutter",
                        "possible_category_types": [
                            "mirror_hardware"
                        ]
                    },
                    {
                        "id": "back_mirror_ply_sku_id",
                        "type": "sku",
                        "name": "Mirror SKU",
                        "description": "Adding Back Mirror to the Shutter",
                        "possible_category_types": [
                            "mirror_hardware"
                        ]
                    },
                    {
                        "id": "parameters",
                        "type": "parent",
                        "name": "Shutter Parameters",
                        "description": "Shutter Parameters",
                        "children": [
                            {
                                "id": "placement",
                                "type": "select",
                                "name": "Placement",
                                "description": "Placement of Shutter System",
                                "options": [
                                    {
                                        "id": "",
                                        "name": "No Changes"
                                    },
                                    {
                                        "id": "internal",
                                        "name": "Internal"
                                    },
                                    {
                                        "id": "external",
                                        "name": "External"
                                    }
                                ]
                            },
                            {
                                "id": "depth_reduction",
                                "type": "number",
                                "name": "Depth Reduction",
                                "description": "Depth Reduction of the Shutter. Shutter System Must be internal"
                            },
                            {
                                "id": "left_reduction",
                                "type": "number",
                                "name": "Left Reduction",
                                "description": "Left Reduction of the Shutter System."
                            },
                            {
                                "id": "right_reduction",
                                "type": "number",
                                "name": "Right Reduction",
                                "description": "Right Reduction of the Shutter System."
                            },
                            {
                                "id": "top_reduction",
                                "type": "number",
                                "name": "Top Reduction",
                                "description": "Top Reduction of the Shutter System."
                            },
                            {
                                "id": "bottom_reduction",
                                "type": "number",
                                "name": "Bottom Reduction",
                                "description": "Bottom Reduction of the Shutter System."
                            }
                        ]
                    }
                ]
            }
        ],
        "partitions": [
            {
                "id": "partition_id",
                "type": "text",
                "name": "Partition ID",
                "description": "The partition_id for Default Partition(Outer Most Partition) is partition_1. The order of partitions (according to their id is from bottom to top, and left to right for sub-partitions within the same partition). For example, partition with partition_id: “partition_1” is the outermost partition. “partition_1_1” denotes the lower/left most sub-partition of the outermost partition.The Partition containing the partition_construction_type key is called Parent Split Partition. It has only 2 sub partitions. The left side sub partition is called Left Split Partition and has ID 1 and front side sub partition is called Right Split Partition and has ID 2."
            },
            {
                "id": "partition_construction_type",
                "type": "select",
                "name": "Partition Construction Type",
                "description": "Only applicable to l_corner, pentagonal and only to the partition where you would like to split the sub partitions to either of the fashion and no other parent partition contain this key."
            },
            {
                "id": "shelf_reveal",
                "type": "number",
                "name": "Shelf Reveal",
                "description": "Shelf Reveal of the Partition"
            },
            {
                "id": "sizing_rules",
                "type": "parent",
                "name": "Sizing Rules",
                "children": [
                    {
                        "id": "min_depth",
                        "type": "number",
                        "name": "Min Depth",
                        "description": "Min Depth. -1 to Unset"
                    },
                    {
                        "id": "max_depth",
                        "type": "number",
                        "name": "Max Depth",
                        "description": "Max Depth. -1 to Unset"
                    },
                    {
                        "id": "min_width",
                        "type": "number",
                        "name": "Min Width",
                        "description": "Min Width. -1 to Unset"
                    },
                    {
                        "id": "max_width",
                        "type": "number",
                        "name": "Max Width",
                        "description": "Max Width. -1 to Unset"
                    },
                    {
                        "id": "min_height",
                        "type": "number",
                        "name": "Min Height",
                        "description": "Min Height. -1 to Unset"
                    },
                    {
                        "id": "max_height",
                        "type": "number",
                        "name": "Max Height",
                        "description": "Max Height. -1 to Unset"
                    }
                ]
            },
            {
                "id": "partition_dimension",
                "type": "number",
                "name": "Partition Dimension",
                "description": "Default dimension of partition is auto calculated. Will be fixing the partition dimension and is auto adjusted to width or height according to the orientation. Not Applicable to the Default Partition. Not Applicable for Front and Side Split Partitions in l_corner,pentagonal corner unit"
            },
            {
                "id": "no_of_shelves",
                "type": "number",
                "name": "Additional Number of Shelves",
                "description": "Will Add Additional Shelves for the partition. No Drawers or Vertical Partition Panels Should be Present"
            },
            {
                "id": "no_of_vertical_partitions",
                "type": "number",
                "name": "Additional Number of Vertical Partitions",
                "description": "Will Add Additional Vertical Partitions for the partition. No Shelves or Vertical Partition Panels Should be Present. Not Applicable if any of the parent partition is not split in l_corner,pentagonal"
            },
            {
                "id": "back_panel_offset",
                "type": "number",
                "name": "Back Panel Offset",
                "description": "Back Panel Offset"
            },
            {
                "id": "hanging_rods",
                "type": "parent",
                "name": "Hanging Rods",
                "description": "Hanging Rods",
                "children": [
                    {
                        "id": "sku_id",
                        "type": "sku",
                        "name": "Hanger Rod SKU",
                        "description": "Quantity is Mandatory",
                        "possible_category_types": [
                            "hanger_rod_hardware"
                        ]
                    },
                    {
                        "id": "quantity",
                        "type": "number",
                        "name": "Quantity",
                        "description": "SKU ID is Mandatory"
                    },
                    {
                        "id": "hanging_rod_offset_from_top",
                        "type": "number",
                        "name": "Hanger Rod Offset from Top",
                        "description": "Not Applicable if Hanger rod is not present"
                    },
                    {
                        "id": "hanging_rod_offset_from_front",
                        "type": "number",
                        "name": "Hanger Rod Offset from Front",
                        "description": "Not Applicable if Hanger rod is not present"
                    },
                    {
                        "id": "hanging_rod_offset_from_back",
                        "type": "number",
                        "name": "Quantity",
                        "description": "Not Applicable if Hanger rod is not present"
                    }
                ]
            }
        ],
        "panels": [
            {
                "id": "ids",
                "type": "multiselect",
                "name": "Panel Name",
                "options": [
                    {
                        "id": "carcass_left_panel",
                        "name": "Carcass Left Panel"
                    },
                    {
                        "id": "carcass_left_rail",
                        "name": "Carcass Left Rail"
                    },
                    {
                        "id": "carcass_right_panel",
                        "name": "Carcass Right Panel"
                    },
                    {
                        "id": "carcass_right_rail",
                        "name": "Carcass Right Rail"
                    },
                    {
                        "id": "carcass_top_panel",
                        "name": "Carcass Top Panel"
                    },
                    {
                        "id": "carcass_top_rail",
                        "name": "Carcass Top Rail"
                    },
                    {
                        "id": "carcass_back_panel",
                        "name": "Carcass Back Panel"
                    },
                    {
                        "id": "carcass_back_rail",
                        "name": "Carcass Back Rail"
                    },
                    {
                        "id": "carcass_bottom_panel",
                        "name": "Carcass Bottom Panel"
                    },
                    {
                        "id": "carcass_bottom_rail",
                        "name": "Carcass Bottom Rail"
                    },
                    {
                        "id": "carcass_dummy_panel",
                        "name": "Carcass Dummy Panel"
                    },
                    {
                        "id": "carcass_front_panel",
                        "name": "Carcass Front Panel"
                    },
                    {
                        "id": "left_skirting_panel",
                        "name": "Left Skirting Panel"
                    },
                    {
                        "id": "right_skirting_panel",
                        "name": "Right Skirting Panel"
                    },
                    {
                        "id": "front_skirting_panel",
                        "name": "Front Skirting Panel"
                    },
                    {
                        "id": "back_skirting_panel",
                        "name": "Back Skirting Panel"
                    },
                    {
                        "id": "blind_skirting_panel",
                        "name": "Blind Skirting Panel"
                    },
                    {
                        "id": "left_door_skirt",
                        "name": "Left Door Skirt"
                    },
                    {
                        "id": "right_door_skirt",
                        "name": "Right Door Skirt"
                    },
                    {
                        "id": "door_skirt",
                        "name": "Door Skirt"
                    },
                    {
                        "id": "left_end_panel",
                        "name": "Left End Panel"
                    },
                    {
                        "id": "right_end_panel",
                        "name": "Right End Panel"
                    }
                ]
            },
            {
                "id": "exposed_panel",
                "type": "bool",
                "name": "Exposed Panel",
                "description": "Mark/Unmark Panel as Exposed"
            },
            {
                "id": "visible",
                "type": "bool",
                "name": "Visibility",
                "description": "Visible/Remove Panel"
            },
            {
                "id": "shelf",
                "type": "parent",
                "name": "Shelf Parameters",
                "children": [
                    {
                        "id": "shelf_type",
                        "type": "select",
                        "name": "Shelf Type",
                        "description": "Only Applicable for Shelves",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "fixed",
                                "name": "Fixed"
                            },
                            {
                                "id": "adjustable",
                                "name": "Adjustable"
                            }
                        ]
                    },
                    {
                        "id": "visibility_type",
                        "type": "select",
                        "name": "Visibility Type",
                        "description": "Only Applicable for Shelves",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "visible",
                                "name": "Visible"
                            },
                            {
                                "id": "invisible",
                                "name": "Invisible"
                            }
                        ]
                    },
                    {
                        "id": "protected_panel",
                        "type": "bool",
                        "name": "Protected Panel",
                        "description": "Only Applicable for Shelves"
                    }
                ]
            },
            {
                "id": "material",
                "type": "parent",
                "name": "Material",
                "description": "Applying Material to Panel",
                "children": [
                    {
                        "id": "core_panel_sku_id",
                        "type": "sku",
                        "name": "Core Panel SKU ID",
                        "description": "Core Panel SKU ID. If prelam respective finishes are auto applied",
                        "possible_category_types": [
                            "panel_core_hardware"
                        ]
                    },
                    {
                        "id": "top_finish_material_id",
                        "type": "sku",
                        "name": "Top Finish Material ID",
                        "description": "Top Finish Material ID. Linked edgeband will be auto applied. Not Applied if prelam both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "bottom_finish_material_id",
                        "type": "sku",
                        "name": "Bottom Finish Material ID",
                        "description": "Bottom Finish Material ID. Not Applied if Prelam Internal or Prelam Both",
                        "possible_category_types": [
                            "cabinet_finish_finish"
                        ]
                    },
                    {
                        "id": "has_left_edge_band",
                        "type": "bool",
                        "name": "Left Edge Band",
                        "description": "To Turn off Left Edge Band give false"
                    },
                    {
                        "id": "has_back_edge_band",
                        "type": "bool",
                        "name": "Back Edge Band",
                        "description": "To Turn off Back Edge Band give false"
                    },
                    {
                        "id": "has_right_edge_band",
                        "type": "bool",
                        "name": "Right Edge Band",
                        "description": "To Turn off Right Edge Band give false"
                    },
                    {
                        "id": "has_front_edge_band",
                        "type": "bool",
                        "name": "Front Edge Band",
                        "description": "To Turn off Front Edge Band give false"
                    },
                    {
                        "id": "left_edge_band_type",
                        "type": "select",
                        "name": "Left Edge Band Type",
                        "description": "Inhertance of Left Edge Band",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "visible",
                                "name": "Visible"
                            },
                            {
                                "id": "invisible",
                                "name": "Invisible"
                            },
                            {
                                "id": "custom",
                                "name": "Custom"
                            }
                        ]
                    },
                    {
                        "id": "right_edge_band_type",
                        "type": "select",
                        "name": "Right Edge Band Type",
                        "description": "Inhertance of Right Edge Band",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "visible",
                                "name": "Visible"
                            },
                            {
                                "id": "invisible",
                                "name": "Invisible"
                            },
                            {
                                "id": "custom",
                                "name": "Custom"
                            }
                        ]
                    },
                    {
                        "id": "back_edge_band_type",
                        "type": "select",
                        "name": "Back Edge Band Type",
                        "description": "Inhertance of Back Edge Band",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "visible",
                                "name": "Visible"
                            },
                            {
                                "id": "invisible",
                                "name": "Invisible"
                            },
                            {
                                "id": "custom",
                                "name": "Custom"
                            }
                        ]
                    },
                    {
                        "id": "front_edge_band_type",
                        "type": "select",
                        "name": "Front Edge Band Type",
                        "description": "Inhertance of Front Edge Band",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "visible",
                                "name": "Visible"
                            },
                            {
                                "id": "invisible",
                                "name": "Invisible"
                            },
                            {
                                "id": "custom",
                                "name": "Custom"
                            }
                        ]
                    },
                    {
                        "id": "left_edge_band_material_id",
                        "type": "sku",
                        "name": "Left Edge Band Material ID",
                        "description": "Left Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "right_edge_band_material_id",
                        "type": "sku",
                        "name": "Right Edge Band Material ID",
                        "description": "Right Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "front_edge_band_material_id",
                        "type": "sku",
                        "name": "Front Edge Band Material ID",
                        "description": "Front Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "back_edge_band_material_id",
                        "type": "sku",
                        "name": "Back Edge Band Material ID",
                        "description": "Back Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "visible_edge_band_material_id",
                        "type": "sku",
                        "name": "Visible Edge Band Material ID",
                        "description": "Visible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    },
                    {
                        "id": "invisible_edge_band_material_id",
                        "type": "sku",
                        "name": "Invisible Edge Band Material ID",
                        "description": "Invisible Edge Band Material ID. Not Applied if Linked Edge Band is auto applied",
                        "possible_category_types": [
                            "edge_band_hardware"
                        ]
                    }
                ]
            },
            {
                "id": "dimension_parameters",
                "type": "parent",
                "name": "Dimension Parameters",
                "children": [
                    {
                        "id": "width_dimension_type",
                        "type": "select",
                        "name": "Width Dimension Type",
                        "description": "Behaviour of Width of panel",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "fixed",
                                "name": "Fixed"
                            },
                            {
                                "id": "extendable",
                                "name": "Extendable"
                            },
                            {
                                "id": "divisional_panel",
                                "name": "Divisional Panel"
                            },
                            {
                                "id": "auto_adjustable",
                                "name": "Auto Adjustable"
                            }
                        ]
                    },
                    {
                        "id": "divisional_panel_width",
                        "type": "number",
                        "name": "Divisional Panel Width",
                        "description": "Applicable if Width Dimension Type is Divisional Panel. Applicable only if the panel is Vertical divisional panel"
                    },
                    {
                        "id": "fixed_width",
                        "type": "number",
                        "name": "Fixed Width",
                        "description": "Applicable if Width Dimension Type is Fixed"
                    },
                    {
                        "id": "fixed_front_width",
                        "type": "number",
                        "name": "Fixed Front Width",
                        "description": "Applicable if Width Dimension Type is Fixed"
                    },
                    {
                        "id": "width_offset",
                        "type": "number",
                        "name": "Width Offset",
                        "description": "Applicable if Width Dimension Type is Fixed"
                    },
                    {
                        "id": "width_offset_source",
                        "type": "select",
                        "name": "Width Offset Source",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left",
                                "name": "Left"
                            },
                            {
                                "id": "right",
                                "name": "Right"
                            }
                        ],
                        "description": "Applicable if Width Dimension Type is Fixed"
                    },
                    {
                        "id": "left_extension",
                        "type": "number",
                        "name": "Left Extension",
                        "description": "Applicable if Width Dimension Type is Extendable"
                    },
                    {
                        "id": "right_extension",
                        "type": "number",
                        "name": "Right Extension",
                        "description": "Applicable if Width Dimension Type is Extendable"
                    },
                    {
                        "id": "front_width_extension",
                        "type": "number",
                        "name": "Front Width Extension",
                        "description": "Applicable if Width Dimension Type is Extendable"
                    },
                    {
                        "id": "auto_adjustable_width",
                        "type": "number",
                        "name": "Auto Adjustable Width",
                        "description": "Will Adjust The Width by setting extension parameters"
                    },
                    {
                        "id": "depth_dimension_type",
                        "type": "select",
                        "name": "Depth Dimension Type",
                        "description": "Behaviour of Depth of panel",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "fixed",
                                "name": "Fixed"
                            },
                            {
                                "id": "extendable",
                                "name": "Extendable"
                            },
                            {
                                "id": "divisional_panel",
                                "name": "Divisional Panel"
                            },
                            {
                                "id": "auto_adjustable",
                                "name": "Auto Adjustable"
                            }
                        ]
                    },
                    {
                        "id": "divisional_panel_depth",
                        "type": "number",
                        "name": "Divisional Panel Depth",
                        "description": "Applicable if Depth Dimension Type is Divisional Panel. Applicable only if the panel is Horizontal divisional panel"
                    },
                    {
                        "id": "fixed_depth",
                        "type": "number",
                        "name": "Fixed Depth",
                        "description": "Applicable if Depth Dimension Type is Fixed"
                    },
                    {
                        "id": "fixed_side_depth",
                        "type": "number",
                        "name": "Fixed Side Depth",
                        "description": "Applicable if Depth Dimension Type is Fixed"
                    },
                    {
                        "id": "depth_offset",
                        "type": "number",
                        "name": "Depth Offset",
                        "description": "Applicable if Depth Dimension Type is Fixed"
                    },
                    {
                        "id": "depth_offset_source",
                        "type": "select",
                        "name": "Depth Offset Source",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "left",
                                "name": "Left"
                            },
                            {
                                "id": "right",
                                "name": "Right"
                            }
                        ],
                        "description": "Applicable if Depth Dimension Type is Fixed"
                    },
                    {
                        "id": "top_extension",
                        "type": "number",
                        "name": "Top Extension",
                        "description": "Applicable if Depth Dimension Type is Extendable"
                    },
                    {
                        "id": "bottom_extension",
                        "type": "number",
                        "name": "Bottom Extension",
                        "description": "Applicable if Depth Dimension Type is Extendable"
                    },
                    {
                        "id": "side_depth_extension",
                        "type": "number",
                        "name": "Side Depth Extension",
                        "description": "Applicable if Depth Dimension Type is Extendable"
                    },
                    {
                        "id": "auto_adjustable_depth",
                        "type": "number",
                        "name": "Auto Adjustable Depth",
                        "description": "Applicable if Depth Dimension Type is Auto Adjustable"
                    }
                ]
            },
            {
                "id": "strip_light",
                "type": "parent",
                "name": "Strip Light",
                "children": [
                    {
                        "id": "sku_id",
                        "type": "sku",
                        "name": "Strip Light SKu",
                        "description": "Strip Light",
                        "possible_category_types": [
                            "strip_light_accessory"
                        ]
                    },
                    {
                        "id": "panel_side",
                        "type": "select",
                        "name": "Panel Side",
                        "description": "Strip Light Has to be present",
                        "options": [
                            {
                                "id": "",
                                "name": "No Changes"
                            },
                            {
                                "id": "bottom_fascia",
                                "name": "Bottom Finish"
                            },
                            {
                                "id": "top_fascia",
                                "name": "Top Finish"
                            }
                        ]
                    }
                ]
            }
        ]
    }
}
// [
//     // {
//     //     "id": "width",
//     //     "type": "number",
//     //     "name": "Width"
//     // },
//     // {
//     //     "id": "depth",
//     //     "type": "number",
//     //     "name": "Depth"
//     // },
//     // {
//     //     "id": "height",
//     //     "type": "number",
//     //     "name": "Height"
//     // },
//     {
//         "id": "back_panel_offset",
//         "type": "number",
//         "name": "Back Panel Offset",
//         // "value": 1
//     },
//     // {
//     //     "id": "back_panel_grooving",
//     //     "type": "number",
//     //     "name": "Back Panel Grooving"
//     // },
//     {
//         "id": "sizing_rules",
//         "type": "parent",
//         "name": "Sizing Rules",
//         "children": [
//             {
//                 "id": "end_panel_max_height",
//                 "type": "number",
//                 "name": "End Panel Max Height",
//                 // "value": 11
//             },
//             {
//                 "id": "min_depth",
//                 "type": "number",
//                 "name": "Min Depth"
//             },
//             {
//                 "id": "max_depth",
//                 "type": "number",
//                 "name": "Max Depth"
//             },
//             {
//                 "id": "min_width",
//                 "type": "number",
//                 "name": "Min Width"
//             },
//             {
//                 "id": "max_width",
//                 "type": "number",
//                 "name": "Max Width"
//             },
//             {
//                 "id": "min_height",
//                 "type": "number",
//                 "name": "Min Height"
//             },
//             {
//                 "id": "max_height",
//                 "type": "number",
//                 "name": "Max Height"
//             },
//             {
//                 "id": "min_partition_depth_shelf",
//                 "type": "number",
//                 "name": "Min Partition Depth (Shelf)"
//             },
//             {
//                 "id": "max_partition_depth_shelf",
//                 "type": "number",
//                 "name": "Max Partition Depth (Shelf)"
//             },
//             {
//                 "id": "min_partition_width_shelf",
//                 "type": "number",
//                 "name": "Min Partition Width (Shelf)"
//             },
//             {
//                 "id": "max_partition_width_shelf",
//                 "type": "number",
//                 "name": "Max Partition Width (Shelf)"
//             },
//             {
//                 "id": "min_partition_height_shelf",
//                 "type": "number",
//                 "name": "Min Partition Height (Shelf)"
//             },
//             {
//                 "id": "max_partition_height_shelf",
//                 "type": "number",
//                 "name": "Max Partition Height (Shelf)"
//             },
//             {
//                 "id": "min_partition_depth_drawer",
//                 "type": "number",
//                 "name": "Min Partition Depth (Drawer)"
//             },
//             {
//                 "id": "max_partition_depth_drawer",
//                 "type": "number",
//                 "name": "Max Partition Depth (Drawer)"
//             },
//             {
//                 "id": "min_partition_width_drawer",
//                 "type": "number",
//                 "name": "Min Partition Width (Drawer)"
//             },
//             {
//                 "id": "max_partition_width_drawer",
//                 "type": "number",
//                 "name": "Max Partition Width (Drawer)"
//             },
//             {
//                 "id": "min_partition_height_drawer",
//                 "type": "number",
//                 "name": "Min Partition Height (Drawer)"
//             },
//             {
//                 "id": "max_partition_height_drawer",
//                 "type": "number",
//                 "name": "Max Partition Height (Drawer)"
//             }
//         ]
//     },
//     {
//         "id": "carcass_material",
//         "type": "parent",
//         "name": "Carcass Material",
//         "children": [
//             {
//                 "id": "core_panel_sku_id",
//                 "type": "sku",
//                 "name": "Core Panel SKU ID",
//                 "possible_category_types": ["panel_core_hardware"]
//             },
//             {
//                 "id": "top_finish_material_id",
//                 "type": "sku",
//                 "name": "Top Finish Material ID",
//                 "possible_category_types": ["cabinet_finish_finish"]
//             },
//             {
//                 "id": "bottom_finish_material_id",
//                 "type": "sku",
//                 "name": "Bottom Finish Material ID",
//                 "possible_category_types": ["cabinet_finish_finish"]
//             },
//             {
//                 "id": "edge_band_material_id",
//                 "type": "sku",
//                 "name": "Edge Band Material ID",
//                 "possible_category_types": ["edge_band_hardware"]
//             },
//             {
//                 "id": "edge_band",
//                 "type": "bool",
//                 "name": "Edge Band"
//             }
//         ]
//     },
//     {
//         "id": "skirting_material",
//         "type": "parent",
//         "name": "Skirting Material",
//         "children": [
//             {
//                 "id": "core_panel_sku_id",
//                 "type": "sku",
//                 "name": "Core Panel SKU ID",
//                 "possible_category_types": ["panel_core_hardware"]
//             },
//             {
//                 "id": "top_finish_material_id",
//                 "type": "sku",
//                 "name": "Top Finish Material ID",
//                 "possible_category_types": ["cabinet_finish_finish"]
//             },
//             {
//                 "id": "bottom_finish_material_id",
//                 "type": "sku",
//                 "name": "Bottom Finish Material ID",
//                 "possible_category_types": ["cabinet_finish_finish"]
//             },
//             {
//                 "id": "edge_band_material_id",
//                 "type": "sku",
//                 "name": "Edge Band Material ID",
//                 "possible_category_types": ["edge_band_hardware"]
//             },
//             {
//                 "id": "edge_band",
//                 "type": "bool",
//                 "name": "Edge Band"
//             }
//         ]
//     },
//     {
//         "id": "shutter_material",
//         "type": "parent",
//         "name": "Shutter Material",
//         "children": [
//             {
//                 "id": "core_panel_sku_id",
//                 "type": "sku",
//                 "name": "Core Panel SKU ID",
//                 "possible_category_types": ["panel_core_hardware"]
//             },
//             {
//                 "id": "top_finish_material_id",
//                 "type": "sku",
//                 "name": "Top Finish Material ID",
//                 "possible_category_types": ["cabinet_finish_finish"]
//             },
//             {
//                 "id": "bottom_finish_material_id",
//                 "type": "sku",
//                 "name": "Bottom Finish Material ID",
//                 "possible_category_types": ["cabinet_finish_finish"]
//             },
//             {
//                 "id": "edge_band_material_id",
//                 "type": "sku",
//                 "name": "Edge Band Material ID",
//                 "possible_category_types": ["edge_band_hardware"]
//             },
//             {
//                 "id": "design_material_id",
//                 "type": "sku",
//                 "name": "Design Material ID",
//                 "possible_category_types": ["shutter_hardware"]
//             },
//             {
//                 "id": "edge_band",
//                 "type": "bool",
//                 "name": "Edge Band"
//             }
//         ]
//     },
//     // {
//     //     "id": "drawer_fascia_material",
//     //     "type": "parent",
//     //     "name": "Drawer Fascia Material",
//     //     "children": [
//     //         {
//     //             "id": "core_panel_sku_id",
//     //             "type": "text",
//     //             "name": "Core Panel SKU ID"
//     //         },
//     //         {
//     //             "id": "top_finish_material_id",
//     //             "type": "text",
//     //             "name": "Top Finish Material ID"
//     //         },
//     //         {
//     //             "id": "bottom_finish_material_id",
//     //             "type": "text",
//     //             "name": "Bottom Finish Material ID"
//     //         },
//     //         {
//     //             "id": "edge_band_material_id",
//     //             "type": "text",
//     //             "name": "Edge Band Material ID"
//     //         },
//     //         {
//     //             "id": "design_material_id",
//     //             "type": "text",
//     //             "name": "Design Material ID"
//     //         },
//     //         {
//     //             "id": "edge_band",
//     //             "type": "bool",
//     //             "name": "Edge Band"
//     //         }
//     //     ]

//     // },
//     {
//         "id": "skirting",
//         "type": "parent",
//         "name": "Skirting",
//         "children": [
//             {
//                 "id": "visibility",
//                 "type": "bool",
//                 "name": "Visibility"
//             },
//             {
//                 "id": "skirting_type",
//                 "type": "select",
//                 "name": "Skirting Type",
//                 "options": [
//                     {
//                         id: "",
//                         name: "No Changes"
//                     },
//                     {
//                         id: "wooden",
//                         name: "Wooden"
//                     },
//                     {
//                         id: "non_wooden",
//                         name: "Non Wooden"
//                     }
//                 ]
//             },
//             {
//                 "id": "skirting_alignment",
//                 "type": "select",
//                 "name": "Skirting Alignment",
//                 "options": [
//                     {
//                         id: "",
//                         name: "No Changes"
//                     },
//                     {
//                         id: "carcass",
//                         name: "Carcass"
//                     },
//                     {
//                         id: "shutter",
//                         name: "Shutter"
//                     }
//                 ]
//             },
//             {
//                 "id": "skirting_height",
//                 "type": "number",
//                 "name": "Skirting Height"
//             },
//             {
//                 "id": "mid_skirting_height",
//                 "type": "number",
//                 "name": "Mid Skirting Height"
//             },
//             {
//                 "id": "skirting_material_source",
//                 "type": "select",
//                 "name": "Skirting Material Source",
//                 "options": [
//                     {
//                         id: "",
//                         name: "No Changes"
//                     },
//                     {
//                         id: "carcass",
//                         name: "Carcass"
//                     },
//                     {
//                         id: "shutter",
//                         name: "Shutter"
//                     },
//                     {
//                         id: "none",
//                         name: "None"
//                     }
//                 ]
//             },
//             {
//                 "id": "skirting_depth_offset",
//                 "type": "number",
//                 "name": "Skirting Depth Offset"
//             }
//         ]

//     },
//     {
//         "id": "rails",
//         "type": "parent",
//         "name": "Rails",
//         "children": [
//             {
//                 "id": "stability_rails",
//                 "type": "number",
//                 "name": "Stability Rails"
//             },
//             {
//                 "id": "stability_rail_height",
//                 "type": "number",
//                 "name": "Stability Rail Height"
//             },
//             {
//                 "id": "half_top",
//                 "type": "bool",
//                 "name": "Half Top"
//             },
//             {
//                 "id": "front_half_top_depth",
//                 "type": "number",
//                 "name": "Front Half Top Depth"
//             },
//             {
//                 "id": "back_half_top_depth",
//                 "type": "number",
//                 "name": "Back Half Top Depth"
//             },
//             {
//                 "id": "has_back_rails",
//                 "type": "bool",
//                 "name": "Has Back Rails"
//             },
//             {
//                 "id": "back_rail_depth",
//                 "type": "number",
//                 "name": "Back Rail Depth"
//             },
//             {
//                 "id": "has_bottom_rails",
//                 "type": "bool",
//                 "name": "Has Bottom Rails"
//             },
//             {
//                 "id": "bottom_rail_depth",
//                 "type": "number",
//                 "name": "Bottom Rail Depth"
//             },
//             {
//                 "id": "has_left_rails",
//                 "type": "bool",
//                 "name": "Has Left Rails"
//             },
//             {
//                 "id": "left_rail_depth",
//                 "type": "number",
//                 "name": "Left Rail Depth"
//             },
//             {
//                 "id": "left_rails_orientation",
//                 "type": "text",
//                 "name": "Left Rails Orientation"
//             },
//             {
//                 "id": "has_right_rails",
//                 "type": "bool",
//                 "name": "Has Right Rails"
//             },
//             {
//                 "id": "right_rail_depth",
//                 "type": "number",
//                 "name": "Right Rail Depth"
//             },
//             {
//                 "id": "right_rails_orientation",
//                 "type": "text",
//                 "name": "Right Rails Orientation"
//             },
//             {
//                 "id": "bottom_support_panels",
//                 "type": "number",
//                 "name": "Bottom Support Panels"
//             },
//             {
//                 "id": "bottom_support_panels_height",
//                 "type": "number",
//                 "name": "Bottom Support Panels Height"
//             },
//             {
//                 "id": "bottom_support_panels_left_gap",
//                 "type": "number",
//                 "name": "Bottom Support Panels Left Gap"
//             },
//             {
//                 "id": "bottom_support_panels_right_gap",
//                 "type": "number",
//                 "name": "Bottom Support Panels Right Gap"
//             }
//         ]

//     },
//     {
//         "id": "construction",
//         "type": "parent",
//         "name": "Construction",
//         "children": [
//             {
//                 "id": "bottomOnLeftEdge",
//                 "type": "bool",
//                 "name": "Bottom on Left Edge"
//             },
//             {
//                 "id": "topOnLeftEdge",
//                 "type": "bool",
//                 "name": "Top on Left Edge"
//             },
//             {
//                 "id": "bottomOnRightEdge",
//                 "type": "bool",
//                 "name": "Bottom on Right Edge"
//             },
//             {
//                 "id": "topOnRightEdge",
//                 "type": "bool",
//                 "name": "Top on Right Edge"
//             },
//             {
//                 "id": "backOnLeftEdge",
//                 "type": "bool",
//                 "name": "Back on Left Edge"
//             },
//             {
//                 "id": "backOnRightEdge",
//                 "type": "bool",
//                 "name": "Back on Right Edge"
//             },
//             {
//                 "id": "backOnTopEdge",
//                 "type": "bool",
//                 "name": "Back on Top Edge"
//             },
//             {
//                 "id": "backOnBottomEdge",
//                 "type": "bool",
//                 "name": "Back on Bottom Edge"
//             }
//         ]
//     },
//     // {
//     //     "id": "countertop",
//     //     "type": "text",
//     //     "name": "Countertop"
//     // },
//     // {
//     //     "id": "hardwares",
//     //     "type": "array",
//     //     "name": "Hardwares"
//     // },
//     // {
//     //     "id": "accessories",
//     //     "type": "array",
//     //     "name": "Accessories"
//     // },
//     // {
//     //     "id": "left_end_panel",
//     //     "type": "sku",
//     //     "name": "Left End Panel"
//     // },
//     // {
//     //     "id": "right_end_panel",
//     //     "type": "sku",
//     //     "name": "Right End Panel"
//     // },
//     // {
//     //     "id": "split_top_and_bottom_panels",
//     //     "type": "bool",
//     //     "name": "Split Top and Bottom Panels"
//     // },
//     // {
//     //     "id": "custom_panels",
//     //     "type": "array",
//     //     "name": "Custom Panels"
//     // },
//     // {
//     //     "id": "partitions",
//     //     "type": "array",
//     //     "name": "Partitions"
//     // },
//     // {
//     //     "id": "panels",
//     //     "type": "array",
//     //     "name": "Panels"
//     // }
// ]

export default ComponentStructure;



