
import React , { useState , useEffect, useContext }from 'react';
import {Input, CardBody, Col, Row, CardFooter, Button } from "reactstrap";
import { useAlert } from 'react-alert'; 
import '../../views/Catalogue/SKU_Groups/sku_groups.css';
import Multiselect from '../Multiselect';
import custom_sort from '../../utils/custom_sort';
import general_fetch from '../../utils/fetch';
import { OrgBuContext } from '../Contexts/OrgBuContext';
import { UserContext } from '../Contexts/UserContext';

const all_finish_type = [
	{
		id:'internal_finish',
		name:'Internal Finish'
	},
	{
		id:'external_finish',
		name:'External Finish'
	},
	{
		id:'back_panel',
		name:'Back Panel Core Material'
	},
]

const all_type_shutter_hardware = [
	{
		id:'profile_with_handle',
		name:'Profile with handle'
	},
	{
		id:'profile',
		name:'Profile without handle '
	},
	{
		id:'glass',
		name:'Glass'
	},
]

const all_smart_cube_links = [
	{
		id:'3_sided_corner_joint',
		name:'3 sided corner joint',
		cat_type: 'fittings_hardware'
	},
	{
		id:'4_sided_corner_joint',
		name:'4 sided corner joint',
		cat_type: 'fittings_hardware',
	},
	{
		id:'profile_with_support_for_shelf',
		name:'Profile with support for shelf',
		cat_type: 'hanger_rod_hardware'
	},
	{
		id:'normal_profile',
		name:'Normal Profile',
		cat_type: 'hanger_rod_hardware'
	},
]

const smart_cube_category_type_validity = [ 'hanger_rod_hardware', 'fittings_hardware' ];

const new_types = [
	{
		id:'cam_subcomponent',
		name: 'Cam Subcomponent'
	},{
		id:'mounting_plate',
		name:'Mounting Plate'
	}
]

const Single_item = ({item, idx, all_division_tree_data, item_json, set_item_json, set_remove_loader, already_selected_sku_groups,active_division_id, active_category_name,use_cat_type }) => {

	// const [all_divisions ,set_all_divisions ] =useState([]);
	const alert = useAlert()
	const [all_division ,set_all_division ] =useState([]);
	const [all_category ,set_all_category ] =useState([]);
	const [all_sub_category ,set_all_sub_category ] =useState([]);
	const [all_groups ,set_all_groups ] =useState([]);
	const [all_skus ,set_all_skus ] =useState([]);

	const [selected_div_array ,set_selected_div_array ] =useState([]);
	const [selected_cat_array ,set_selected_cat_array ] =useState([]);
	const [selected_sub_cat_array ,set_selected_sub_cat_array ] =useState([]);
	const [selected_group_array ,set_selected_group_array ] =useState([]);
	const [selected_sku_array ,set_selected_sku_array ] =useState([]);
	const [selected_type_type_array ,set_selected_type_type_array ] =useState([]);	
	const [valid_types, set_valid_types] = useState(all_smart_cube_links);
	const [initialized ,set_initialized ] =useState(false);

	const [allow_edit ,set_allow_edit ] = useState(false);
	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)

	useEffect(() => {
        if(all_division_tree_data && all_division_tree_data.length && use_cat_type){
            console.log('all_division_tree_data======>',all_division_tree_data)
			if(use_cat_type==='panel_core_hardware'){
				set_all_division(all_division_tree_data.filter(x => ['finish','hardware'].includes(x.id)))
			}
			if(use_cat_type==='shutter_hardware' || use_cat_type == 'drawer_system_collection_hardware' || use_cat_type == 'basket_collection_hardware' || use_cat_type == 'smart_cube'){
				set_all_division(all_division_tree_data.filter(x => x.id==='hardware'))
			}
        }else if(all_division_tree_data){
			set_all_division(all_division_tree_data.filter(x => x.id==='hardware'))
		}
    },[all_division_tree_data, use_cat_type])

	const fetch_sku_groups = async (id) => {
		try {

			let body = { sku_sub_category_id:id }
            if(org_bu_view === 'bu'){
                body['business_unit_id'] = user.current_business_unit_id;
            }
			var response = await general_fetch({ url: 'inventory/get_groups' , body });
			var depricated_filter_output =response && response.length ?response.filter(x => x.depricated !=1):[]
			var resp = depricated_filter_output.length?depricated_filter_output.sort(custom_sort):[]
			set_all_groups(resp)
		} catch(err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if(item){
			set_selected_div_array(item.division)
			set_selected_cat_array(item.category)
			set_selected_sub_cat_array(item.sub_cat)
			set_selected_group_array(item.group)
			set_selected_sku_array(item.sku)
			set_allow_edit(item.sku && item.sku.length?false:true)
			if(use_cat_type==='panel_core_hardware'){
				var x_item_list = all_finish_type.filter(x => x.id===item.type)
				set_selected_type_type_array(x_item_list)
			}
			if(use_cat_type==='shutter_hardware'){
				var x_item_list = all_type_shutter_hardware.filter(x => x.id===item.type)
				set_selected_type_type_array(x_item_list)
			}
			if (use_cat_type === 'smart_cube') {
				var x_item_list = all_smart_cube_links.filter(x => x.id===item.type)
				set_selected_type_type_array(x_item_list)
			}
			if (!use_cat_type) {
				var x_item_list = new_types.filter(x => x.id===item.type)
				set_selected_type_type_array(x_item_list)
			}
		}        
    },[item])

	useEffect(() => {
		if(all_division && all_division.length && selected_div_array && selected_div_array.length ){
			console.log('Division execution started')
			update_item_json(idx, 'division', selected_div_array)
			var x_id = selected_div_array.map(x => x.id)[0]
			if(active_division_id==='finish'){
				let x_catlist = all_division && all_division.length?all_division.find(x => x.id===x_id).sku_category.filter(x => x.sku_category_type_id==="edge_band_hardware"):[]
				x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				set_all_category(x_catlist)
			}else if(use_cat_type==='shutter_hardware'){
				let x_catlist = all_division && all_division.length?all_division.find(x => x.id===x_id).sku_category.filter(x => ['handle_hardware', 'panel_core_hardware'].includes(x.sku_category_type_id)):[]
				x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				set_all_category(x_catlist)
			}else if(use_cat_type==='panel_core_hardware' && x_id==='hardware'){
				let x_catlist = all_division && all_division.length?all_division.find(x => x.id===x_id).sku_category.filter(x => ['panel_core_hardware'].includes(x.sku_category_type_id)):[]
				x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				set_all_category(x_catlist)
			}else if(use_cat_type==='drawer_system_collection_hardware' && x_id==='hardware'){
				let x_catlist = all_division && all_division.length?all_division[0].sku_category.filter(x => x.sku_category_type_id==="drawer_systems_hardware"):[]
				x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				console.log(all_category,all_division, x_catlist);
				set_all_category(x_catlist)
			}else if(use_cat_type==='basket_collection_hardware' && x_id==='hardware'){
				let x_catlist = all_division && all_division.length?all_division[0].sku_category.filter(x => x.sku_category_type_id==="basket_hardware"):[]
				x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				set_all_category(x_catlist)
			}else if(use_cat_type==='smart_cube' && x_id==='hardware'){
				let x_catlist = all_division && all_division.length?all_division[0].sku_category.filter(x => smart_cube_category_type_validity.includes(x.sku_category_type_id)):[]
				x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				set_all_category(x_catlist)
			}else{
				let x_catlist = all_division.find(x => x.id===x_id)?all_division.find(x => x.id===x_id).sku_category:[]
				x_catlist = x_catlist && x_catlist.length ? x_catlist.filter(x => x.deprecated != 1) : []
				set_all_category(x_catlist)
			}
			console.log('Division execution ended')
		}
    },[all_division,selected_div_array])

	useEffect(() => {
		if( all_category && all_category.length && selected_cat_array && selected_cat_array.length){
			console.log('Cat execution started')
			console.log('smart_cube', all_category, selected_cat_array, x_id)
			update_item_json(idx, 'category', selected_cat_array)
			var x_id = selected_cat_array.map(x => x.id)[0]

			let sub_cat_list = all_category.find(x => x.id===x_id) ?all_category.find(x => x.id===x_id).sku_sub_category:[]
			sub_cat_list = sub_cat_list && sub_cat_list.length ? sub_cat_list.filter(x => x.deprecated != 1) : []
			set_all_sub_category(sub_cat_list)
			if (use_cat_type == 'smart_cube' && all_category.find(x => x.id===x_id)) {
				set_valid_types(all_smart_cube_links.filter(x => x.cat_type ===  all_category.find(x => x.id===x_id).sku_category_type_id));
			}
		}      
    },[all_category,selected_cat_array])

	useEffect(() => {
		if (initialized) {
			if(all_sub_category && all_sub_category.length && selected_sub_cat_array && selected_sub_cat_array.length){
				update_item_json(idx, 'sub_cat', selected_sub_cat_array)
				var x_id = selected_sub_cat_array.map(x => x.id)[0]
				if(x_id){
					fetch_sku_groups(x_id)
				}else{
					set_all_groups([])
				}
			}        
		} else {
			if (already_selected_sku_groups && selected_sub_cat_array && selected_sub_cat_array.length) {
				// var x_groups_list = Object.keys(already_selected_sku_groups) && Object.keys(already_selected_sku_groups).length ? Object.keys(already_selected_sku_groups).filter(x => {
				// 	if(x===selected_sub_cat_array[0].id){
				// 		console.log("MAJOR CONDITION SAT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
				// 		return already_selected_sku_groups[x]
				// 	}
				// }) :[] 
				let x_groups_list = already_selected_sku_groups?.[selected_sub_cat_array[0].id]??[];
				console.log("MAJOR LOG ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~", x_groups_list);
				set_all_groups(x_groups_list);
			}
		}
    },[already_selected_sku_groups, all_sub_category, selected_sub_cat_array])

	useEffect(() => {
		if(all_groups && all_groups.length && selected_group_array && selected_group_array.length){
			update_item_json(idx, 'group', selected_group_array)
			var x_id = selected_group_array.map(x => x.id)[0]
			set_all_skus(all_groups.find(x => x.id===x_id)?all_groups.find(x => x.id===x_id).sku:[])
		}        
    },[all_groups, selected_group_array])

	useEffect(() => {
		if(all_skus && all_skus.length && selected_sku_array && selected_sku_array.length){
			update_item_json(idx, 'sku', selected_sku_array)
			console.log('skuexecuted')
		}
		if(idx===item_json.length-1){
			if(selected_sku_array && selected_sku_array.length){
				set_remove_loader(true)
			}
		}
    },[selected_sku_array])

	const onselect_multi_options = (property, selectedList_1) => {
		// update_item_json(idx,property, selectedList_1)
        if(property==='division'){
			set_selected_div_array(selectedList_1)
			update_item_json(idx, 'division', selectedList_1)
			reset_for_div('division')
		}else if(property==='category'){
			set_selected_cat_array(selectedList_1)
			update_item_json(idx, 'category', selectedList_1)
			reset_for_div('category')
		}else if(property==='sub_cat'){
			set_selected_sub_cat_array(selectedList_1)
			set_initialized(true);
			update_item_json(idx, 'sub_cat', selectedList_1)
			reset_for_div('sub_cat')
		}else if(property==='group'){
			set_selected_group_array(selectedList_1)
			update_item_json(idx, 'group', selectedList_1)
			reset_for_div('group')
		}else if(property==='sku'){
			set_selected_sku_array(selectedList_1)
			update_item_json(idx, 'sku', selectedList_1)
		}else if(property==='type'){
			set_selected_type_type_array(selectedList_1)
			update_item_json(idx, property, selectedList_1)
		}
	}

	const reset_for_div = (property) => {
		if(property==='division'){
			set_all_category([]);
			set_selected_cat_array([]);
			update_item_json(idx, 'category', [])
		}
		if(property==='category' || property==='division'){	
			set_all_sub_category([]);
			set_selected_sub_cat_array([]);
			set_initialized(true);
			update_item_json(idx, 'sub_cat', [])
		}
		if(property==='sub_cat' || property==='category' || property==='division'){	
			set_all_groups([]);
			set_selected_group_array([]);
			update_item_json(idx, 'group', [])
		}
		if(property==='group' || property==='sub_cat' || property==='category' || property==='division'){	
			set_all_skus([]);
			set_selected_sku_array([]);
			update_item_json(idx, 'sku', [])
		}
	}

	const delete_item_json = (index) => {
		let a = [...item_json];
		a.splice(index,1)
		set_item_json(a);
	}

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
    }

	// useEffect(() => {
	// 	console.log('outputs', item_json, item)       
    // },[item_json, item])

	return(
		<Row key={idx} style={{margin:0,marginTop:'10px', display:'flex'}}>
			{/* <Col xs={1} style={{display:'flex', alignItems:'center'}}>
				{idx+1}
			</Col> */}
			<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
				<Multiselect
					style={{backgroundColor:'white'}}
					options={all_division && all_division.length? JSON.parse(JSON.stringify(all_division)):[]} 
					selectedValues={selected_div_array}
					onSelect={(selectedList)=>onselect_multi_options('division', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('division', selectedList)} 
					displayValue="id" // Property name 
					selectionLimit={1}
					id={'select_div'+idx}
					placeholder='Select'
					disable={allow_edit?false:true}
				/> 
			</div>
			<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
				<Multiselect
					style={{backgroundColor:'white'}}
					options={all_category && all_category.length? JSON.parse(JSON.stringify(all_category)):[]} 
					selectedValues={selected_cat_array}
					onSelect={(selectedList)=>onselect_multi_options('category', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('category', selectedList)} 
					displayValue="name" // Property name 
					selectionLimit={1}
					id={'select_cat'+ idx}
					placeholder='Select'
					disable={allow_edit?false:true}
				/> 
			</div>
			<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
				<Multiselect
					style={{backgroundColor:'white'}}
					options={all_sub_category && all_sub_category.length? JSON.parse(JSON.stringify(all_sub_category)):[]} 
					selectedValues={selected_sub_cat_array}
					onSelect={(selectedList)=>onselect_multi_options('sub_cat', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('sub_cat', selectedList)} 
					displayValue="name" // Property name 
					selectionLimit={1}
					id={'select_sub_cat'+idx}
					placeholder='Select'
					disable={allow_edit?false:true}
				/> 
			</div>
			<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
				<Multiselect
					style={{backgroundColor:'white'}}
					options={all_groups && all_groups.length? JSON.parse(JSON.stringify(all_groups)):[]} 
					selectedValues={selected_group_array}
					onSelect={(selectedList)=>onselect_multi_options('group', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('group', selectedList)} 
					displayValue="name" // Property name 
					selectionLimit={1}
					id={'select_group'+ idx}
					placeholder='Select'
					disable={allow_edit?false:true}
				/> 
			</div>
			<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
				<Multiselect
					style={{backgroundColor:'white'}}
					options={all_skus && all_skus.length? JSON.parse(JSON.stringify(all_skus)):[]} 
					selectedValues={selected_sku_array}
					onSelect={(selectedList)=>onselect_multi_options('sku', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('sku', selectedList)} 
					displayValue="name" // Property name 
					selectionLimit={1}
					id={'select_sku'+ idx}
					placeholder='Select'
					disable={allow_edit?false:true}
				/> 
			</div>
			<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
				{
					use_cat_type==='panel_core_hardware'?
					<Multiselect
						style={{backgroundColor:'white'}}
						options={all_finish_type && all_finish_type.length? JSON.parse(JSON.stringify(all_finish_type)):[]} 
						selectedValues={selected_type_type_array}
						onSelect={(selectedList)=>onselect_multi_options('type', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('type', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'select_quan'+idx}
						placeholder='Select'
						disable={allow_edit?false:true}
					/>
					:''
				}
				{
					(use_cat_type === 'drawer_system_collection_hardware' || use_cat_type === 'basket_collection_hardware')?
					<Input
						style={{backgroundColor:'white',fontSize:'12px'}}
						className="opacity_div"
						type="text"
						id={'select_quan'+idx}
						value={idx==0?String(idx)+' (Top)':idx}
						placeholder='Quantity'
						disable={true}
					/>
					:''
				}
				{
					use_cat_type==='shutter_hardware'?
						<Multiselect
						style={{backgroundColor:'white'}}
						options={all_type_shutter_hardware && all_type_shutter_hardware.length? JSON.parse(JSON.stringify(all_type_shutter_hardware)):[]} 
						selectedValues={selected_type_type_array}
						onSelect={(selectedList)=>onselect_multi_options('type', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('type', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'select_quan'+idx}
						placeholder='Select'
						disable={allow_edit?false:true}
					/>
					:''
				}
				{
					use_cat_type==='smart_cube'?
						<Multiselect
						style={{backgroundColor:'white'}}
						options={valid_types && valid_types.length? JSON.parse(JSON.stringify(valid_types)):[]} 
						selectedValues={selected_type_type_array}
						onSelect={(selectedList)=>onselect_multi_options('type', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('type', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'select_quan'+idx}
						placeholder='Select'
						disable={allow_edit?false:true}
					/>
					:''
				}
				{
					!use_cat_type ? 
					<Multiselect
						style={{backgroundColor:'white'}}
						options={new_types && new_types.length? JSON.parse(JSON.stringify(new_types)):[]} 
						selectedValues={selected_type_type_array}
						onSelect={(selectedList)=>onselect_multi_options('type', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('type', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'select_quan'+idx}
						placeholder='Select'
						disable={allow_edit?false:true}
					/>
					:''
				}
			</div>
			{	
				!use_cat_type && active_division_id!='finish'?	
				<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
					<Input 
						style={{fontSize:'12px'}}
						className={allow_edit?'':'opacity_div'} type="number" placeolder='0' 
						value={item_json && item_json[idx]?item_json[idx].quantity:''} 
						onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'quantity',Number(e.target.value)):alert.error('Negative Number not allowed')}
					/>
				</div>:''	
			}
			<div style={{display:'flex', flex:'1', alignItems:'center', justifyContent:'flex-end'}}>
				<i style={{cursor:'pointer', marginRight:'10px'}} onClick={()=> set_allow_edit(!allow_edit)} className='fa fa-edit' />
				<span className={allow_edit?'':'opacity_div'}><i style={{cursor:'pointer'}} onClick={allow_edit?() =>delete_item_json(idx):null} className='fa fa-trash'/></span>
			</div>
		</Row>
	)
}

const Linked_sku_structure = ({item_json, set_item_json, selected_sku_properties, all_division_tree_data, active_division_id,sku_category_type}) => {

	const [skus_ansistry ,set_skus_ansistry ] =useState([]);
	const [remove_loader ,set_remove_loader ] =useState(false);
	const [sku_group_initial_options ,set_sku_group_initial_options ] = useState([]);
	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)
	const alert = useAlert()
	const [use_cat_type,set_use_cat_type ] =useState('');

	// useEffect(() => {
    //     if(selected_sku_properties && selected_sku_properties.linked_skus && selected_sku_properties.linked_skus.length){
    //         set_item_json([selected_sku_properties.linked_skus])
    //     }
    // },[selected_sku_properties])

	useEffect(() =>{
		if (sku_category_type==='panel_core_hardware') {
			set_use_cat_type('panel_core_hardware')
		} else if (sku_category_type==='shutter_hardware') {
			set_use_cat_type('shutter_hardware')
		} else if (sku_category_type === 'drawer_system_collection_hardware') {
			set_use_cat_type('drawer_system_collection_hardware')
		} else if (sku_category_type === 'basket_collection_hardware') {
			set_use_cat_type('basket_collection_hardware')
		} else if (selected_sku_properties.additional_properties && selected_sku_properties.additional_properties.length && selected_sku_properties.additional_properties.filter(x => x.key == 'cabinet_type' && x.value == 'smart_cube').length) {
			set_use_cat_type('smart_cube')
		}

	},[sku_category_type])

	const is_valid_add_key = (item_json) => {
		if(item_json && item_json.length){
			for(var i=0;i<item_json.length;i++){
				if(item_json[i].sku && item_json[i].sku.length<1 ){
					return false;
				}
			}
			return true;
		}else{
			return true;
		}
	}

    const add_new_item_json = () => {
		if(is_valid_add_key(item_json)){
            let a = [...item_json];
			a.push({division:[], category:[], sub_cat:[], group:[], sku:[], quantity:0, type:''})
			set_item_json(a);
        }else{
            alert.error("Empty or Duplicate key values not allowed.")
        }
	}

    useEffect(() =>{
		if(item_json && !item_json.length){
			add_new_item_json()
		}
	},[item_json])    

	const onclick_handleclose =() => {

	}

	const fetch_ansisters = async(sku_ids) => {
		// console.log('LL linked list=================================> ',sku_ids)
		var response =[]
		if(sku_ids && sku_ids.length && sku_ids[0]){
			response = await general_fetch({ url: 'inventory/get_sku_ancestry' , body: { sku_id:sku_ids } });
			let t = response && response.length ? response.map(single_item => {
				if(single_item.sku_data && !(typeof(single_item.sku_data.length) == 'number')){
					single_item.sku_data = [single_item.sku_data]
				}
				if(single_item.sku_category_data && !(typeof(single_item.sku_category_data.length) == 'number')){
					single_item.sku_category_data = [single_item.sku_category_data]
				}
				if(single_item.sku_sub_category_data && !(typeof(single_item.sku_sub_category_data.length) == 'number')){
					single_item.sku_sub_category_data = [single_item.sku_sub_category_data]
				}
				if(single_item.sku_group_data && !(typeof(single_item.sku_group_data.length) == 'number')){
					single_item.sku_group_data = [single_item.sku_group_data]
				}
			}) : ''
		}
		set_skus_ansistry(response && response.length?response:[]);
		return response;
	}

	const fetch_group_and_skus = async sku_sub_category_id => {
		try{
			let body = { sku_sub_category_id }
			if(org_bu_view === 'bu'){
                body['business_unit_id'] = user.current_business_unit_id;
            }
			var response = await general_fetch({ url: 'inventory/get_groups_for_multiple_sub_categories' , body});
			set_sku_group_initial_options(response)
		}catch(err){
			console.log(err)
		}
	}

	useEffect(() => {
		if(selected_sku_properties){
			console.log('selected_sku_properties', selected_sku_properties)
			var x_linked_sku_ids = selected_sku_properties.linked_skus &&  selected_sku_properties.linked_skus.length ?selected_sku_properties.linked_skus.map(x => x.id) :[];
			console.log('selected_sku_properties 11', x_linked_sku_ids)
			if(x_linked_sku_ids && x_linked_sku_ids.length){
				let ancestors = fetch_ansisters(x_linked_sku_ids);
			}
		}       
    },[selected_sku_properties])

	useEffect(() => {
		if(skus_ansistry && skus_ansistry.length && selected_sku_properties && selected_sku_properties.linked_skus && selected_sku_properties.linked_skus.length){
			let sku_sub_category_ids = skus_ansistry.map(x => x.sku_sub_category_data[0].id);
			fetch_group_and_skus(sku_sub_category_ids)
			var x_single_item = selected_sku_properties.linked_skus.map((x_lskus,idx) => {
				var x = skus_ansistry && skus_ansistry.length ?skus_ansistry.find(x => x.id===x_lskus.id):{}
				return({
					division:x?[{id:x.sku_division_id}]:[],
					category:x?x.sku_category_data:[],
					sub_cat:x?x.sku_sub_category_data:[],
					group:x?x.sku_group_data:[],
					sku:x?x.sku_data:[],
					quantity:selected_sku_properties.linked_skus[idx]?selected_sku_properties.linked_skus[idx].quantity:'',
					type:selected_sku_properties.linked_skus[idx]?selected_sku_properties.linked_skus[idx].type:'',
				})
			});
			set_item_json(x_single_item);
		}        
    },[skus_ansistry])

	return(
		<div>
			<div>
				<Row style={{margin:0,marginTop:'10px', fontSize:'14px', display:'flex'}}>
					{/* <Col xs={1} style={{display:'flex', alignItems:'center'}}>
						Sl no.
					</Col> */}
					<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
						Division 
					</div>
					<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
						Category 
					</div>
					<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
						Sub Category
					</div>
					<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
						Group 
					</div>
						<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
							SKU
						</div>
					<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>
						Type
					</div>
					{
						!use_cat_type && active_division_id!='finish'?
						<div style={{display:'flex', flex:'2', alignItems:"center", maxWidth:'13%', marginRight:'5px'}}>Quantity</div>:''
					}
					<div style={{display:'flex', flex:'1', alignItems:'center', justifyContent:'flex-end'}}>	
					</div>
				</Row>
			</div>
			{
				item_json && item_json.length?item_json.map((x,idx) => 
					<Single_item 
						item={x} 
						key={idx} 
						idx={idx} 
						set_remove_loader={set_remove_loader} 
						item_json={item_json} 
						set_item_json={set_item_json} 
						all_division_tree_data={all_division_tree_data}
						already_selected_sku_groups={sku_group_initial_options}
						active_division_id={active_division_id}
						sku_category_type={sku_category_type}
						use_cat_type={use_cat_type}
					/>
				):''
			}
			{/* {
				remove_loader?''
				:
				<div style={{position:'fixed',backgroundColor:'rgba(20,20,20,0.7',left:0,top:0,width:'100%',height:'100%',zIndex:10000}}>
					<div style={{position:'absolute',width:'350px',height:'40px',left:'calc(50% - 175px)',top:'calc(50% - 20px)',backgroundColor:'#314456',color:'#ffffff',display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}}>
						<i className = "fa fa-spinner fa-spin"></i> &nbsp; &nbsp; <span>Please Wat</span>
					</div>
				</div>
			} */}
			<Row style={{margin:0}}>
				<Col style={{textAlign:'left', paddingBottom:'10px', paddingLeft:0}}>
					<span onClick={add_new_item_json}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add +</span>
				</Col>
			</Row>
		</div>
	)
} 

export default Linked_sku_structure;