import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
// import Multiselect from 'multiselect-react-dropdown';
import { useAlert } from 'react-alert'
import './index.js'
import Multiselect from '../Multiselect';

const MFKeyValuePair_source = ({open,cabinet_subunit, item_json, idx, single_item_json, set_item_json, subunit_component_json ,mode, all_division_tree_data, tags, all_category, all_sub_category, all_sales_ch, set_source_condition_combination_logic, source_condition_combination_logic, is_back_button}) => {

    const [selected_component,set_selected_component] =useState([])
    const [selected_sub_component,set_selected_sub_component] =useState([])
    const [selected_property,set_selected_property] =useState([])
    const [selected_tag_1_array ,set_selected_tag_1_array ] =useState([])
    const [values_json ,set_values_json ] =useState([])
    const alert = useAlert()
	
    useEffect(() => {
        if(subunit_component_json && single_item_json){ 
            set_selected_component(single_item_json.component)
            set_selected_sub_component(single_item_json.subcomponent)
            set_selected_property(single_item_json.property)
            // if(!is_back_button){
            //     single_item_json.subcomponent=x_subcomponent;
            //     single_item_json.property=x_selected_property;
            // }
        }
    },[single_item_json])

    useEffect(() => {
        if(cabinet_subunit && subunit_component_json && subunit_component_json.length){
            var x_selected_comp = subunit_component_json.filter(x => x.id === cabinet_subunit);
            set_selected_component(x_selected_comp)
        }
    },[cabinet_subunit,subunit_component_json])

    useEffect(() =>{
		if(!open){
			handleClose_for_mf__source()
		}
	},[open])

    const handleClose_for_mf__source = () => {
        set_selected_sub_component([])
        set_selected_property('')
        set_selected_tag_1_array ([])
        set_values_json ([])
    }

	const delete_item_json = (index) => {
		let a = [...item_json];
		a.splice(index,1)
		set_item_json(a);
	}

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
	}

    const onclick_subunit_component = (value) => {
        update_item_json(idx,'subcomponent', value)
        update_item_json(idx,'property', '')
        update_item_json(idx,'operator','equal') 
        update_item_json(idx,'value', '') 
        set_selected_sub_component(value)
        set_selected_property([])
        value && value[0] && ['tag','sales_channel'].includes(value[0].id) && update_item_json(idx,'value_combination_logic','and') 
        console.log('item_json======>',item_json)
    }

    const onclick_properties = (value) => {
        set_selected_property(value)
        update_item_json(idx,'property', value)
        update_item_json(idx,'operator','equal') 
        update_item_json(idx,'value', '') 
        value && value[0] && ['tag','sales_channel'].includes(value[0].id) && update_item_json(idx,'value_combination_logic','and') 
        console.log('item_json======>',item_json)
    }

    const fn_check_null = (x) => {
        if(x===null){
            alert.error('value of '+selected_property?selected_property[0].name+' is no longer available.':'')
        }
        return x;
    }

    useEffect(() => {
        if(selected_property && selected_property.length){
            if(selected_property[0].name==='Tag'){
                set_values_json(tags)
                set_selected_tag_1_array(single_item_json.tag && single_item_json.tag.length?single_item_json.tag.filter(x=>fn_check_null(x)):[])
            }
            // }else if(selected_property[0].name==='Category'){
            //     set_values_json(all_category)
            //     set_selected_tag_1_array(single_item_json.property_value && single_item_json.property_value.length?single_item_json.property_value.filter(x=>fn_check_null(x)):[])
            // }else if(selected_property[0].name==='Sub Category'){
            //     set_values_json(all_sub_category)
            //     set_selected_tag_1_array(single_item_json.property_value && single_item_json.property_value.length?single_item_json.property_value.filter(x=>fn_check_null(x)):[])
            // }
            else if(selected_property[0].id==='sales_channel'){
                set_values_json(all_sales_ch)
                set_selected_tag_1_array(single_item_json.sales_channel && single_item_json.sales_channel.length?single_item_json.sales_channel.filter(x=>fn_check_null(x)):[])
            }else{
                set_values_json([])
            }
        }
    },[selected_property, tags])

    const onselect_multi_source_tags = (selectedList_1, operator) => {
		set_selected_tag_1_array(selectedList_1)
        update_item_json(idx,operator, selectedList_1)
    }

    return(
        <div style={{display:'flex', alignItems:'center', marginLeft:'-50px'}}>
        <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{width:'50px', height:'55px',borderBottom:idx!=0?'1px solid #DDDDDD':'' , borderLeft:idx!=0?'1px solid #DDDDDD':''}}>

            </div>
            <div style={{width:'50px', height:'55px', borderTop:item_json.length===idx+1?'':'1px solid #DDDDDD' , borderLeft:item_json.length===idx+1?"":'1px solid #DDDDDD'}}>
                
                    
            </div>
            {
                item_json.length===idx+1?"":
                <Input style={{fontSize:'12px', height:'30px', width:'70px', marginLeft:'-35px'}} type="select"
                    id = {'source_combination_logic_'+idx}
                    value={source_condition_combination_logic}
                    onChange={(e) =>set_source_condition_combination_logic(e.target.value)}
                >
                    <option value='and'>And</option>
                    <option value='or'>OR</option>
                </Input>
            }
        </div>
        <CardBody style={{padding:'15px 20px', overflow:'unset', backgroundColor:'white', marginBottom:item_json && item_json.length-1===idx?'0px':'20px', height:'120px'}}>
            <Row>
                <Col>
                    <div style={{fontSize:'16px', color:'black'}}>Condition {idx+1}</div>
                </Col>
            </Row>
            <Row style={{margin:0, fontSize:'12px'}}>
                <Col xs={3} style={{display:'flex', alignItems:"center", padding:'10px 10px 5px 0px'}}>
                    Sub-component
                </Col>
                <Col xs={3} style={{display:'flex', alignItems:"center" , padding:'10px 10px 5px'}}>
                    Property
                </Col>
                <Col xs={2} style={{display:'flex', alignItems:"center" , padding:'10px 10px 5px'}}>
                    Operator
                </Col>
                <Col xs={3} style={{display:'flex', alignItems:"center" , padding:'10px 10px 5px'}}>
                    Value
                </Col>
                <Col xs={1} style={{display:'flex', alignItems:'center', justifyContent:'flex-end' , padding:'10px 0px 5px 10px'}}>
                </Col>
            </Row>
            <Row key={idx} style={{ margin:0}}>
                <Col xs={3} style={{fontSize:'12px', color:'#425A70', display:'flex', alignItems:'center', padding:'0px 10px 0px 0px'}}>
                    {/* <div style={{paddingRight:'5px'}}>Choose SubComponent</div> */}
                    {
                        selected_component && selected_component[0] && selected_component[0].sub_component && selected_component[0].sub_component.length?
                            <Multiselect
                                options={selected_component[0].sub_component} 
                                selectedValues={selected_sub_component}
                                onSelect={onclick_subunit_component}
                                onRemove={onclick_subunit_component} 
                                displayValue="name" // Property name 
                                id={'source_sub_component_'+idx}
                                placeholder='Search Value'
                                selectionLimit={1}
                            />  
                        :
                        <Input id={'box3'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select">
                            <option>Choose a value</option>
                        </Input>
                    }
                    {/* <Input id={'box1'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto'}} type="select" 
                        value={item_json[idx]?item_json[idx].subcomponent:'none'}
                        onChange={(e) =>onclick_subunit_component(idx, e.target.value)}
                    >
                        <option value='none'>Choose a sub Component</option>
                        {
                            subunit_component_json && subunit_component_json.subunit_component && subunit_component_json.subunit_component.length ? subunit_component_json.subunit_component.map((item, idx)=>(
                                <option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} key={idx} value={item.id}>
                                    {item.type?item.type:''}
                                </option>
                            ))
                            :''
                            
                        }
                    </Input> */}
                </Col>		
                <Col xs={3} style={{fontSize:'12px', color:'#425A70', display:'flex', alignItems:'center', padding:'0px 10px'}}>
                    {/* <div style={{paddingRight:'5px'}}>Choose Property</div> */}
                        {
                            selected_sub_component && selected_sub_component[0] && selected_sub_component[0].properties &&  selected_sub_component[0].properties.length ?
                                <Multiselect
                                    options={selected_sub_component[0].properties} 
                                    selectedValues={selected_property}
                                    onSelect={onclick_properties}
                                    onRemove={onclick_properties} 
                                    displayValue="name" // Property name 
                                    id={'source_property_'+idx}
                                    placeholder='Search Value'
                                    selectionLimit={1}
                                />  
                            :
                            <Input id={'box3'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select">
                                <option>Choose a value</option>
                            </Input>
                        }
                        {/* <Input id={'box2'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto'}} type="select"
                            value={item_json[idx]?item_json[idx].property:'none'}
                            onChange={(e) =>onclick_properties(idx, e.target.value)}
                        >
                            <option value='none'>Choose a property</option>
                            {
                                allowed_properties && allowed_properties.properties &&  allowed_properties.properties.length ? allowed_properties.properties.map((item, idx)=>(
                                    <option  style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} key={idx} value={item.id}>
                                        {item.name?item.name:''}
                                    </option>
                                ))
                                :''
                            }
                        </Input> */}
                </Col>
                <Col xs={2} style={{fontSize:'12px', color:'#425A70', display:'flex', alignItems:'center', padding:'0px 10px'}}>
                    {/* <div style={{paddingRight:'5px'}}>Choose SubComponent</div> */}
                    <Input id={'source_operator_'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto'}} type="select" 
                        value={item_json[idx]?item_json[idx].operator:'none'}
                        onChange={(e) =>update_item_json(idx,'operator', e.target.value)}
                    >
                        {
                            selected_property && selected_property.length && selected_property[0].type==='number' ?
                            <React.Fragment>
                                <option value='equal'>Equal</option>
                                <option value='less'>Less Than</option>
                                <option value='greater'>Greater Than</option>
                                <option value='lessequal'>Less Than Equal</option>
                                <option value='greaterequal'>Greater Than Equal</option>
                            </React.Fragment>
                            :
                            (
                                selected_property && selected_property.length && selected_property[0].type==='panel_type'?
                                <React.Fragment>
                                    <option value='equal'>Equal</option>
                                    <option value='notequal'>Not Equal</option>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <option value='equal'>Equal</option>
                                </React.Fragment>
                            )
                        }
                    </Input>
                </Col>
                <Col xs={3} style={{fontSize:'12px', color:'#425A70', display:'flex', alignItems:'center' , padding:'0px 10px'}}>
                    {/* <div style={{paddingRight:'5px'}}>Value</div> */}
                    {
                        selected_property && selected_property.length?
                        <React.Fragment>
                            {
                                selected_property && selected_property.length && selected_property[0].type==='number'?
                                <Input id={'source_value_'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="number"
                                    placeholder='Enter a value'
                                    value={item_json[idx]?item_json[idx].value:''}
                                    onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'value',Number(e.target.value)):alert.error('Negative Number not allowed','value')}
                                />
                                :''
                            }
                            {/* {
                                allowed_properties && allowed_properties.length?
                                    <Multiselect
                                        options={allowed_properties} 
                                        selectedValues={selected_property}
                                        onSelect={onselect_source_property}
                                        onRemove={onselect_source_property} 
                                        displayValue="name" // Property name 
                                        id={'select_'+idx}
                                        placeholder='Search Value'
                                        selectionLimit={1}
                                    />  
                                :
                                <Input id={'box3'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select">
                                    <option>Choose a value</option>
                                </Input>
                            } */}
                            {/* {
                                selected_property && selected_property.length && selected_property[0].type==='shelf_type'?
                                <Input id={'box3'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select"
                                    value={item_json[idx]?item_json[idx].value:''}
                                    onChange={(e) =>onclick_selected_property_value(idx, e.target.value, )}
                                >
                                    <option value='none'>Select Option</option>
                                    <option value='fixed'>Fixed</option>
                                    <option value='adjustable'>Adjustable</option>
                                </Input>
                                :''
                            } */}
                            {
                                selected_property && selected_property.length && selected_property[0].type==='prelam_type'?
                                <Input id={'source_value_'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select"
                                    value={item_json[idx]?item_json[idx].value:''}
                                    onChange={(e) =>update_item_json(idx,'value', e.target.value)}
                                >
                                    <option value='none'>Select Option</option>
                                    <option value='prelam_internal'>Prelam Internal</option>
                                    <option value='prelam_both'>Prelam Both</option>
                                    <option value='postlam'>Postlam</option>
                                </Input>
                                :''
                            }
                            {
                                selected_property && selected_property.length && selected_property[0].type==='panel_type'?
                                <Input id={'source_value_'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select"
                                    value={item_json[idx]?item_json[idx].value:''}
                                    onChange={(e) =>update_item_json(idx,'value', e.target.value)}
                                >
                                    <option value='none'>Select Option</option>
                                    <option value='shelf'>Shelf</option>
                                    <option value='fixed_shelf'>Fixed Shelf</option>
                                    <option value='adjustable_shelf'>Adjustable Shelf</option>
                                    <option value='vertical_partition_panel'>Vertical Partition Panel</option>
                                    <option value='skirting_panel'>Skirting Panel</option>
                                    <option value='exposed_panel'>Exposed Panel</option>
                                    <option value='carcass_panel'>Carcass Panel</option>
                                    {/* <option value='shutter_panel'>Shutter Panel</option> */}
                                    <option value='end_panel'>End Panel</option>
                                    <option value='finger_groove_panel'>Finger Groove Panel</option>
                                </Input>
                                :''
                            }
                            {/* {
                                selected_property && selected_property.length && (selected_property[0].type=='select')?
                                    <Multiselect
                                        style={{backgroundColor:'white', width:'227px'}}
                                        options={values_json && values_json.length? JSON.parse(JSON.stringify(values_json)):[]} 
                                        selectedValues={selected_tag_1_array}
                                        onSelect={(selected_list) => onselect_multi_source_tags(selected_list,'sales_channel_id')} 
                                        onRemove={(selected_list) => onselect_multi_source_tags(selected_list,'sales_channel_id')} 
                                        displayValue="name" // Property name 
                                        id={'select_1'+idx}
                                        placeholder='Search Name'
                                        selectionLimit={1}
                                    />  
                                :''
                            } */}
                            {
                                selected_property && selected_property.length && ['tag','sales_channel'].includes(selected_property[0].id)?
                                    <React.Fragment>
                                        <Input id={'source_value_combination_logic_'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto', width:'80px'}} type="select" 
                                            value={item_json[idx]?item_json[idx].value_combination_logic:'none'}
                                            onChange={(e) =>update_item_json(idx,'value_combination_logic', e.target.value)}
                                            title='Select Logic between property values'
                                        >    
                                            <option value='and'>And</option>
                                            <option value='or'>Or</option>
                                        </Input>
                                        <Multiselect
                                            style={{backgroundColor:'white', width:'100%'}}
                                            options={values_json && values_json.length? JSON.parse(JSON.stringify(values_json)):[]} 
                                            selectedValues={selected_tag_1_array}
                                            onSelect={(selected_list) => onselect_multi_source_tags(selected_list,selected_property[0].id==="tag"?'tag':'sales_channel')}
                                            onRemove={(selected_list) => onselect_multi_source_tags(selected_list,selected_property[0].id==="tag"?'tag':'sales_channel')}
                                            displayValue={selected_property[0].id==="tag"?'tag':'name'} // Property name 
                                            id={'source_value_'+idx}
                                            placeholder= {selected_property[0].id==="tag"?'Select Tag':'Select Sales Channel'}
                                        /> 
                                    </React.Fragment>
                                :''
                            }
                        </React.Fragment>
                        :
                        <Input id={'box3'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select">
                            <option value='none'>Choose a value</option>
                        </Input>    
                    }
                </Col>
                <Col xs={1} style={{display:'flex', alignItems:'center', justifyContent:'flex-end', padding:'0px 0px 0px 10px'}}>
                    <span style={{cursor:'pointer',display:'flex', alignItems:'center',fontSize:'14px', color:'#F0656F'}} onClick={() =>delete_item_json(idx)}><i className='fa fa-trash'/></span>
                </Col>
            </Row>
        </CardBody>
        </div>
    )
}

export default MFKeyValuePair_source;

