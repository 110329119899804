import React , { useState , useEffect }from 'react';
import general_fetch from '../../../utils/fetch';
import {FormGroup, Button, Input, Modal, Card, CardBody, CardHeader, CardFooter, Col, TabPane , TabContent ,Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import Common_tag_component from '../../../components/Common_tag_component';
import { useAlert } from 'react-alert'

const Tab_Structure = ({set_page_loader, alert, user, orignal_tags, active_item, fetch_sku_groups, sku_category_id_for_group, handleClose}) => {

	const [	tabs , set_tabs] = useState(1)
	const [ item_order , set_item_order ] =useState('0')
	const [ item_name , set_item_name ] =useState("")
	const [	tags_assigned_inherited , set_tags_assigned_inherited] = useState('')
	const [	all_inherited_tags , set_all_inherited_tags] = useState('')
	const [	eligible_group_tags , set_eligible_group_tags] = useState([])

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	const fetch_group_tags = async () => {
		if(active_item){
			try {
				var resp = await general_fetch({ url: 'sku_group/get_tags', body:{sku_group_ids:[active_item.id]}});
				set_tags_assigned_inherited(resp)
			}catch(err) {
				console.log(err);
			}
		}
	}

	const get_group_eligible_tags = async(id) => {
        try { 
            let response = await general_fetch({ url: 'sku_group/get_eligible_tags', body: { sku_group_id: id}});
            set_eligible_group_tags(response && response.length ? response: []);
        }
        catch(err) {
            console.error(err);
        }
    }

	useEffect(()=>{
		if(active_item && tags_assigned_inherited && tags_assigned_inherited[active_item.id] ){
			var x_inherit = [];
			tags_assigned_inherited[active_item.id].sku_category_tags && tags_assigned_inherited[active_item.id].sku_category_tags.length && tags_assigned_inherited[active_item.id].sku_category_tags.map(x => x_inherit.push(x));
			tags_assigned_inherited[active_item.id].sku_sub_category_tags && tags_assigned_inherited[active_item.id].sku_sub_category_tags.length && tags_assigned_inherited[active_item.id].sku_sub_category_tags.map(x => x_inherit.push(x));
			set_all_inherited_tags(x_inherit)
		}else{
			set_all_inherited_tags([])
		}
	},[tags_assigned_inherited])

	const onclick_update_handleClose = () => {
		set_item_name("")
		set_item_order('0')
		set_tabs(1)
		handleClose()
		set_tags_assigned_inherited('')
		set_all_inherited_tags('')
	}

	useEffect(() => {
		if(active_item){
			set_item_order(active_item.order?active_item.order:'0')
			set_item_name(active_item.name?active_item.name:'')	
			set_tabs(active_item && active_item.store_id===user.store_id?1:2)
			fetch_group_tags()
			get_group_eligible_tags(active_item.id)
		}	
	},[active_item]) 

	const onClick_upload_handler = async () => {
		if(active_item && item_name ){
			try {
				let body = {
					sku_group_id:active_item.id,
					name:item_name, 
					order:item_order
				}
				set_page_loader({
					show:true,
					text:'Updating Group please wait ...'
				})
				var resp = await general_fetch({ url: 'sku_group/update', body});
				set_page_loader({
					show:false,
					text:''
				})
				alert.success('Group updated Successfully')
				fetch_sku_groups()
			}catch(err) {	
				set_page_loader({
					show:true,
					text:'Error in Updating Group. Please Refresh'
				})
				console.log(err);
			}
		}else{
			
		}
	}
	
	const update_sku_tag_assigned = async (selected_tag_array) => {
		if(active_item){
			try {
				var tag_array = selected_tag_array.map(x => x.id)
				var body = {sku_group_ids: [active_item.id] , tag_ids: tag_array}
				set_page_loader({
					show:true,
					text:'Adding tag please wait ...'
				})
				var response_for_add_tag = await general_fetch({ url: 'sku_group/attach_tags' , body});
				set_page_loader({
					show:false,
					text:''
				})
				fetch_group_tags()
				alert.success('Tags updated successfully')
			} catch(err) {
				alert.error('Error! in adding tags. Please Refresh')
				console.log(err);
			}
		}else{
			alert.error('No Tags Selected')
		}
	}

	const update_sku_tag_detached = async (selected_tag_array) => {
		try {
			var tag_array = selected_tag_array.map(x => x.id)
			var body = {sku_group_id: active_item.id , tag_id: tag_array}
			set_page_loader({
				show:true,
				text:'Removing tag please wait ...'
			})
			var response_for_del_sale = await general_fetch({ url: 'sku_group/unattach_tag' , body});
			set_page_loader({
				show:false,
				text:''
			})
			alert.success('Tags successfully removed')
			fetch_group_tags()
		} catch(err) {
			alert.error('Error! in removing tag. Please Refresh')
			console.log(err);
		}
	}

	return(
		<Card className='global_modal_height_for_mdlgxl'>
			<CardHeader className='global_modal_header'>
				<span>Manage Sub-Category {active_item?active_item.name:''}</span>	
				<i style={{cursor:'pointer'}} onClick={handleClose} className='fa fa-times'/>	
			</CardHeader>
			<div className="nav-wrapper" style={{padding:'10px 15px'}}>
				<Nav 
					style={{display:'flex', alignItems:'center'}}
					className="flex-md-row"
					id="tabs-icons-text" 
					pills
					role="tablist"
				>
					{active_item && active_item.store_id===user.store_id?
						<NavItem>
							<NavLink
								aria-selected={tabs === 1}
								className={classnames("mb-sm-3 mb-md-0", {
									active: tabs === 1
								})}
								onClick={e => toggleNavs(e, 1)}
								href="#pablo"
								role="tab"
							>
								<span>Group Details</span>
							</NavLink>
						</NavItem>
						:''
					}
					<NavItem>
						<NavLink
							aria-selected={tabs === 2}
							className={classnames("mb-sm-3 mb-md-0", {
									active: tabs === 2
							})}
							onClick={e => toggleNavs(e, 2)}
							href="#pablo"
							role="tab"
						>
							<span>Tags</span>
						</NavLink>
					</NavItem>
				</Nav>
			</div>
			<TabContent activeTab={"tabs" + tabs}>

				<TabPane tabId="tabs1" style={{fontSize:'12px'}}>
					<CardBody className='global_modal_body'>
						<Row style={{marginBottom:'8px'}}>
							<Col>
								<FormGroup>
									<label className="label_margin_5px">Group Name</label>
									<Input className='input_box' type="text" value = {item_name} onChange = {(e) => set_item_name(e.target.value)} />
								</FormGroup>
							</Col>
						</Row>
						<Row style={{marginTop:"5px"}}>
							<Col>
								<FormGroup >
									<label className="label_margin_5px" >Order No</label>
									<Input placeholder='Order no.' className='input_box' type="text" value = {item_order} onChange = {(e) => set_item_order(Number(e.target.value ? e.target.value : 0))} />
								</FormGroup>
							</Col>	
						</Row>
					</CardBody>									
					<CardFooter className='global_modal_footer'>
						<Button className='white_button' onClick={onclick_update_handleClose}> Cancel </Button>
						<Button className='blue_button'  onClick = {onClick_upload_handler}> Update </Button>
					</CardFooter>
				</TabPane>

				<TabPane tabId="tabs2">
						<Common_tag_component 
							type='sku_group' 
							all_tags={eligible_group_tags} 
							assigned_tags={active_item && tags_assigned_inherited && tags_assigned_inherited[active_item.id] && tags_assigned_inherited[active_item.id].sku_group_tags && tags_assigned_inherited[active_item.id].sku_group_tags.length?tags_assigned_inherited[active_item.id].sku_group_tags:[]} 
							seperated_inherited_tags={tags_assigned_inherited} 
							all_inherited_tags={all_inherited_tags} 
							active_item={active_item} 
							attach_tag_fn={update_sku_tag_assigned} 
							detatch_tag_fn={update_sku_tag_detached} 
							handleclose={()=>{}}
						/>
				</TabPane>
			</TabContent>	
		</Card>
	);
}
	

const EditGroupProperties = ({set_page_loader, Filler, open, handleClose, user, orignal_tags, active_item, fetch_sku_groups, sku_category_id_for_group }) => {
	   
	const alert = useAlert();	

	return (
		<div>
			<Modal className="Margin_64px_top" size="md" isOpen={open} toggle={handleClose}>	
				
				<Tab_Structure 
					handleClose={handleClose}
					alert={alert} 
					user={user}
					orignal_tags={orignal_tags}
					active_item={active_item}
					fetch_sku_groups={fetch_sku_groups}
					sku_category_id_for_group={sku_category_id_for_group}
					set_page_loader={set_page_loader}
				/>
				
			</Modal>
		</div>
	);
}

export default EditGroupProperties ;


// const fetch_finish_categories = async () => {
// 	try {
// 		console.log('Fetching finish categories !!')
// 		var identifiers = JSON.stringify({ store_id : user.store_id , sku_division_id : "finish" });
// 		var attributes = JSON.stringify({});
// 		var resp = await general_fetch({ url: 'sku_category/get_active' , body: { identifiers , attributes } });
// 		set_finish_categories(resp);
// 	} catch(err) {
// 		console.log(err);
// 	}
// }

// const update_finish_choice = async () => {
// 	if(choice_flag && selected_finish){
// 		try {
// 			console.log('Updating finish choice !!')
// 			var body = { sku_category_id: active_item.id , finish_id: selected_finish };
// 			var resp = await general_fetch({ url: 'sku_category/attach_preferred_finish_material' , body });
// 			set_choice_flag(false)
// 			fetch_categories()
// 		} catch(err) {
// 			console.log(err);
// 		}
// 	}
// }

// const on_change_selected = e => {
// 	set_choice_flag(true)
// 	set_selected_finish(e.target.value)
// }

// useEffect(() =>{
// 	if(choice_flag){
// 		update_finish_choice();
// 	}
// },[selected_finish])

// 						{/* <TabPane tabId="tabs3">
// 		                    <Row>
// 		                    	<Col>
// 		                          	<Card >
// 										<Row>
// 					                    	<Col className = "text-center">
// 					                          	<h3>Update Preferred Finish Category</h3>
// 					                    	</Col>	
// 					                    </Row>
// 					                    <Row>
// 					                    	<Col className = "text-center">
// 					                          	<Input
// 													type='select' 
// 													variant="outlined"
// 													fullWidth
// 													value = {selected_finish}
// 													onChange = {e => on_change_selected(e)}
// 												>
// 												{
// 													finish_categories && finish_categories.map((finish) => (
// 														<option value={finish.id}>{finish.name}</option>
// 												))}	
// 												</Input>
// 					                    	</Col>	
// 					                    </Row>
// 									</Card>
// 		                    	</Col>	
// 		                    </Row>
// 						</TabPane> */}

// 					</TabContent>
// 				</CardBody>
// 			</Card>
// 		</div>
// 	);



