import React , { useState , useEffect }from 'react';
import {Modal, Alert,FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import UploadFile from '../UploadFile';
import general_fetch from '../../utils/fetch';
import Choose_existing_texture from '../Choose_existing_material';
import { useAlert } from 'react-alert';
import SingleSelect from '../SingleSelect';
import UploadImage from '../UploadFile/UploadImage';

const Texture_structure = ({ text_name, property, default_val, properties, set_properties, set_page_loader, texture_json, disabled_input, input_size, set_base_texture_url }) => {
        
    const [add_texture_file, set_add_texture_file] =useState('')
    const [return_texture_details, set_return_texture_details] =useState('')
    const [texture_url, set_texture_url] = useState('')

    const uplaod_file = async () => {
        try{
            var fd = new FormData();	
            add_texture_file && fd.append('file', add_texture_file)
            console.log('add texture file', add_texture_file)
            fd.append('name', property.name)
            set_page_loader({
                show:true,
                text:'Please wait, uploading texture...'
            })
            var resp = await general_fetch({ url: 'texture/add', body:fd, is_form_data:true });
            resp && resp.id && set_return_texture_details(resp.id)
            
            if(resp && resp.image) set_texture_url(resp.image)
            set_page_loader({
                show:false,
                text:''
            })
        }catch(err){
            console.log(err)
        }
    }

    const get_texture_url = async(return_texture_details) => {
        var texture_get = await general_fetch({url:'texture/get', body:{identifiers:JSON.stringify({id:[return_texture_details]})}})
        if(texture_get){
            set_texture_url(texture_get[0] && texture_get[0].Image ? texture_get[0].Image.image :'')
            if(set_base_texture_url) set_base_texture_url(texture_get[0] && texture_get[0].Image ? texture_get[0].Image.image :'')
        }
    }

    useEffect(() => {
        if(add_texture_file){
            uplaod_file()
        }
    },[add_texture_file])

    useEffect(() => {
        console.log("set_return_texture_details", property)
        if(property)set_return_texture_details(property.final_value ? property.final_value:'')
        console.log("set_return_texture_details 1")
    },[default_val, property])

    useEffect(() => {
        if(return_texture_details){
            property.final_value=return_texture_details
            console.log("set_return_texture_details 3", return_texture_details)
            get_texture_url(return_texture_details)
            set_properties(properties)
        }
        
    },[return_texture_details])

    return(
        <div>
            <Row style={{display:"flex", alignItems:'center', justifyContent:'space-between', marginTop:"10px", pointerEvents:disabled_input?'none':'auto'}}>
                <Col xs={input_size=='small'?5:3}><label className="label_margin_5px" style={{textTransform:"capitalize", fontSize:'14px',marginTop:'10px'}}>{text_name?text_name:''}</label></Col>
                <Col xs={input_size=='small'?7:9} style={{textAlign:"left", fontSize:'14px',marginTop:'10px'}}>
                    <UploadImage selectedImage={add_texture_file} setSelectedImage={set_add_texture_file} inputId={'image_preview'} isVisible={false}/>
                    {/* <Input id={"image_preview"} type="file" onChange={(e) => {set_add_texture_file(e.target.files[0]);e.stopPropagation();}} style={{display:"none"}} accept='.jpg,.jpeg,.png'/> */}
                    {/* {return_texture_details ?  */}
                    <div className='flex_property'>
                        {
                            return_texture_details ? 
                                <img width='32px' height='32px' src={texture_url ? global.config.server_path.slice(0,-1)+texture_url :''} />
                            :''
                        }
                        {
                            disabled_input?'':
                            <>
                                <div onClick={() => {document.getElementById("image_preview").click()}} className='div_box' style={{cursor:'pointer',width:'100px', height:'32px', marginLeft:return_texture_details ? '5px' : '0px', alignItems:'center', borderRadius:'4px'}}>
                                    <i className='fa fa-upload' style={{ marginRight:'5px'}} /><span>Upload</span> 
                                </div>
                                {
                                    texture_url ?
                                    <i className='fa fa-trash ml-2 cp' style={{color:'red'}} onClick={() => {set_add_texture_file(null); set_texture_url(''); set_return_texture_details(''); if(set_base_texture_url)set_base_texture_url(''); property.final_value=''; set_properties(properties)}} />:""
                                }
                            </>
                        }
                    </div>
                    {/* : (
                            // <FormGroup>
                            //     <UploadFile id="new_texture_file" set_file={set_add_texture_file} accept='.jpeg, .jpg,.png'/>
                            // </FormGroup>
                            
                            <div className='flex_property'>
                                {
                                    disabled_input?'':
                                    <div onClick={() => {document.getElementById("image_preview").click()}} className='div_box' style={{cursor:'pointer',width:'100px', height:'32px', alignItems:'center', borderRadius:'4px'}}>
                                        <i className='fa fa-upload' style={{ marginRight:'5px'}} /><span>Upload</span> 
                                    </div>
                                }
                            </div>

                        )} */}
                </Col>
                {/* <Col xs={4}>
                    {return_texture_details ? (
                        <label style={{fontSize:'14px'}}> 
                            { return_texture_details ? (
                                <div style={{display:"flex", justifyContent:"center", alignItems:"center", fontSize:'14px'}}>
                                    <div style={{padding:'3px 5px 3px 0px', fontSize:'14px'}}>
                                        Attached Texture ID : {return_texture_details}
                                    </div>
                                    <div>
                                        <Button onClick={() =>set_return_texture_details('')} style={{minWidth:"auto"}} className='red_button'>
                                            <i style={{color:"white", fontSize:"10px"}} className='fa fa-times'/>
                                        </Button>
                                    </div>
                                </div>
                            ) : "" }
                        </label>
                    ):''}
                </Col> */}
            </Row>
        </div>
    )
}


const Select_Structure = ({default_val, text_name, property, type, properties, set_properties, options, disabled_input, input_size }) => {

    const [input_value , set_input_value] = useState(null);

    const validate_string = (value) =>{
        property.final_value=value;
        // if(property.useby==='blender'){
        set_input_value(value);
        console.log("properties =====> ", properties)
        set_properties(properties);
        // }else{
        //     set_portal_properties(portal_properties)
        // }
    }

    useEffect(() => {
        set_input_value(property.final_value)
    },[default_val])

    return(
        <Row style={{display:"flex", alignItems:'center', marginTop:"10px"}}>
            <Col xs={input_size=='small'?5:3} style={{textTransform:"capitalize", fontSize:'14px'}}>{ text_name ? property.value.purpose === "distance" ? text_name + " (in mm)" : text_name : ""}</Col>
            <Col xs={input_size=='small'?7:9}>
                <Input type="select" style={{height:'32px', fontSize:'14px', width:input_size=='small'?'100%':'75%',pointerEvents:disabled_input?'none':'auto'}} value={input_value} onChange={e => validate_string(e.target.value)}>
                    {options.map((option, index) => (
                        <option key={index} value={option.value} selected={option.value === input_value}>{option.label}</option>
                    ))}
                </Input> 
                {/* <SingleSelect
                    options={options}
                    selectedValue = {input_value}
                    onItemClick = {(selectedValue) => validate_string(selectedValue.value)}
                    placeholder = {"Select"}
                    displayValue = {'label'}
                /> */}
            </Col>
        </Row>
    )
}


const Input_structure = ({default_val, text_name, property, type, properties, set_properties, portal_properties, set_portal_properties, disabled_input, input_size}) => {

    const [input_value , set_input_value] = useState(null);
    const [wrong_value_flag , set_wrong_value_flag] = useState(false);
    const [ value_less_than_min , set_value_less_than_min ] = useState(false);
    const [ value_exceeds_max , set_value_exceeds_max ] = useState(false);
    const alert = useAlert()

    const validate_string = (value) =>{
        if (type==='number'){
            value = Number(value)
        }
        set_input_value(value)
        property.final_value=value;
        console.log("properties =====> ", properties)
        set_properties(properties)
    }

    useEffect(() => {
        if(type==='number'){
            if (input_value && property.value.min_value && (input_value < Number(property.value.min_value))){
                console.log('validation case 1 --> ', input_value, property.value.min_value)
                set_value_less_than_min(true)
            }else if (input_value && property.value.max_value && (input_value > Number(property.value.max_value))) {
                set_value_exceeds_max(true)
            }else if (property.value.max_value && property.value.min_value) {
                set_wrong_value_flag(true);
            }
        }else {
            set_wrong_value_flag(true)
        }
    },[input_value])

    useEffect(() => {
        if(type==='number' && default_val!=null){
            set_input_value(property.final_value)
            set_wrong_value_flag(false)
        }
        if(type==='color'){
            set_input_value(property.final_value)
            set_wrong_value_flag(false)
        }
    },[default_val])

    return(
        <Row style={{display:"flex", alignItems:'center', marginTop:"10px"}}>
            <Col xs={input_size=='small'?5:3} style={{textTransform:"capitalize", fontSize:'14px'}}>{ text_name ? property.value.purpose === "distance" ? text_name + " (in mm)" : text_name : ""}</Col>
            <Col xs={input_size=='small'?7:9}>
                {
                    type=='number'? 
                    <div className='flex_property' style={{width:input_size=='small'?'100%':'75%',}}>
                        {
                            property && property.value && (property.value.min_value || property.value.min_value==0) && property.value.max_value ?
                            <div className='flex_property' style={{flexDirection:'column', flex:'5',marginRight:'15px', marginTop:'15px'}}>
                                <div className='flex_property' style={{width:'100%'}}>
                                    <div style={{borderRight:'2px solid #C5C7CF', height:'6px', borderRadius:'4px'}}/>
                                    <input 
                                        className='slider' type='range'
                                        min={type=='number'?property.value.min_value:null} 
                                        max={type=='number'?property.value.max_value:null} 
                                        step = {(type=='number' && property.value.max_value==1) ? '0.1' : '1'}
                                        value={input_value!=null?input_value:''} 
                                        onChange={e => {console.log("value", e.target.value);validate_string(e.target.value)}}
                                        style={{pointerEvents:disabled_input?'none':'auto'}}
                                    />
                                    <div style={{borderRight:'2px solid #C5C7CF', height:'6px', borderRadius:'4px'}}/>
                                </div>
                                <div style={{ fontSize:'12px', display:'flex', alignItems:'end', justifyContent:'space-between', width:'100%', marginTop:'4px'}}>
                                    <span>{property.value.min_value}</span>
                                    <span>{property.value.max_value}</span>
                                </div>
                            </div>:''
                        }
                        <Input 
                            disabled={disabled_input?true:false}
                            min={type=='number'?property.value.min_value:null} 
                            max={type=='number'?property.value.max_value:null} 
                            step = {(type=='number' && property.value.max_value==1) ? '0.1' : '1'}
                            type={type?type:'text'} value={input_value!=null?input_value:''} 
                            style={{fontSize:"13px", backgroundColor:"white", borderRadius:"4px", height:'32px',flex:'2',pointerEvents:disabled_input?'none':'auto'}} 
                            onChange={e => {console.log("value", e.target.value, Number(e.target.value), property.value.max_value, Number(e.target.value)<= property.value.max_value);(property && property.value && (property.value.min_value || property.value.min_value==0) && property.value.max_value)?(Number(e.target.value)<= property.value.max_value && Number(e.target.value) >= property.value.min_value ? validate_string(e.target.value) : alert.error("Please enter a number in the range")):validate_string(e.target.value)}}
                        />
                    </div>:
                    <Input 
                        disabled={disabled_input?true:false}
                        type={type?type:'text'} value={input_value!=null?input_value:''} 
                        style={{fontSize:"13px", backgroundColor:"white", borderRadius:"4px", height:'32px',width:input_size=='small'?'100%':'75%',pointerEvents:disabled_input?'none':'auto'}} 
                        onChange={e => {console.log("value", e.target.value);validate_string(e.target.value)}}
                    />
                }
                {type==='number' && wrong_value_flag?<label style={{color:'red'}}>Please set value between  {property.value.min_value!=null?property.value.min_value:''} to  {property.value.max_value!=null?property.value.max_value:''} </label>:''}
                {type==='number' && value_less_than_min?<label style={{color:'red'}}>Value must exceed  {property.value.min_value!=null?property.value.min_value:''} </label>:''}
                {type==='number' && value_exceeds_max?<label style={{color:'red'}}>Value must be less than  {property.value.max_value!=null?property.value.max_value:''} </label>:''}
            </Col>
        </Row>
    )
}


const Property_Tab = ({ property, final_update_fn, properties, set_properties, portal_properties, set_portal_properties, set_page_loader, texture_json, disabled_input, input_size, set_base_texture_url}) => {
    return(
        <div>
        {
            property.value.purpose === 'number' || property.value.purpose === 'distance' ? (
                <Row>
                    <Col>
                        <Input_structure 
                            default_val={property.final_value!=null?property.final_value:""} 
                            text_name={property.value.label} 
                            property={property} 
                            type='number'
                            properties={properties} 
                            set_properties={set_properties}
                            portal_properties={portal_properties} 
                            set_portal_properties={set_portal_properties}
                            disabled_input = {disabled_input}
                            input_size = {input_size}
                        />
                    </Col>
                </Row>
            ) : ''
        }
        {
            property.value.purpose === 'image' && property.label === 'map' ? (
                <Row>
                    <Col>
                        <Texture_structure 
                            text_name={property.value.label} 
                            property={property} 
                            properties={properties} 
                            set_properties={set_properties}
                            portal_properties={portal_properties} 
                            set_portal_properties={set_portal_properties} 
                            set_page_loader={set_page_loader}
                            texture_json={texture_json}
                            final_update_fn={final_update_fn}
                            default_val={property.final_value!=null?property.final_value:""} 
                            disabled_input = {disabled_input}
                            input_size = {input_size}
                            set_base_texture_url = {set_base_texture_url}
                        />
                    </Col>
                </Row>
            ) : ''
        }
        {
            property.value.type === 'color' ? (
                <Row>
                    <Col>
                        <Input_structure 
                            default_val={property.value.value?property.value.value:""} 
                            text_name={property.value.label} 
                            type='color' 
                            property={property} 
                            properties={properties} 
                            set_properties={set_properties}
                            portal_properties={portal_properties} 
                            set_portal_properties={set_portal_properties}
                            disabled_input = {disabled_input}
                            input_size = {input_size}
                        />
                    </Col>
                </Row>
            ) : ''
        }
        {
            property.value.type === 'string_enum' ? (
                <Row>
                    <Col>
                        <Select_Structure 
                            default_val={property.value.value?property.value.value:""} 
                            text_name={property.value.label} 
                            property={property} 
                            properties={properties} 
                            set_properties={set_properties}
                            portal_properties={portal_properties} 
                            set_portal_properties={set_portal_properties}
                            options={property.value.string_enum}
                            disabled_input = {disabled_input}
                            input_size = {input_size}
                        />
                    </Col>
                </Row>
            ) : ''
        }
        </div>
    )
}

export default Property_Tab