import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import AMRulesDialogBox from'./AMRulesDialogBox'
import {Card, Col, Progress, Table, Row, } from "reactstrap";
import Pegasus_Paginator from '../../components/Pegasus_Paginator';
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import custom_sort from '../../utils/custom_sort';
import './index.css'
import DragDropComponent from '../../components/DragDropCompoment';
import Filter from '../../components/RuleFilterModal';
import { COLORS } from '../../utils/colors';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { UserContext } from '../../components/Contexts/UserContext';
import RuleNameCloneModal from '../../components/RuleNameCloneModal';


const component_options_json = [
	{
		name:'Carcass Excluding Back Panel',
		id:'carcass_excluding_back_panel',
		sub_component:[
			{
				name:'Ply',
				cat_type:'panel_core_hardware',
				division:'hardware',
				id:'ply',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					},{
						name:'Thickness',
						id:'thickness',
						type:'number'
					}
				],

			},{
				name:'Internal Finish',
				cat_type:'cabinet_finish_finish',
				division:'finish',
				id:'internal_finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					}
				],

			},{
				name:'External Finish',
				cat_type:'cabinet_finish_finish',
				division:'finish',
				id:'external_finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					}
				],

			}
		]
	},
	// {
	// 	name: 'Panel',
	// 	id: 'panel',
	// 	sub_component: [
	// 		{
	// 			name: 'Panel',
	// 			id: 'panel',
	// 			properties:[
	// 				{
	// 					name: 'Panel Type',
	// 					id: 'panel_type',
	// 					type: 'panel_type'
	// 				}
	// 			]
	// 		},{
	// 			name:'Ply',
	// 			id:'ply',
	// 			properties:[
	// 				{
	// 					name:'Sku',
	// 					id:'sku',
	// 					type:'select'
	// 				},
	// 				{
	// 					name:'Sales Channel',
	// 					id:'sales_channel',
	// 					type:'multiselect',
	// 				},
	// 				{
	// 					name:'Thickness',
	// 					id:'thickness',
	// 					type:'number'
	// 				},{
	// 					name:'Tag',
	// 					id:'tag',
	// 					type:'multiselect'
	// 				},{
	// 					name:'PreLam Type',
	// 					id:'prelam_type',
	// 					type:'prelam_type',
	// 				}
	// 			],

	// 		},{
	// 			name:'Internal Finish',
	// 			// cat_type:'cabinet_finish_finish',
	// 			division:'finish',
	// 			id:'internal_finish',
	// 			properties:[
	// 				{
	// 					name:'Sku',
	// 					id:'sku',
	// 					type:'select'
	// 				},{
	// 					name:'Tag',
	// 					id:'tag',
	// 					type:'multiselect'
	// 				},{
	// 					name:'Thickness',
	// 					id:'thickness',
	// 					type:'number'
	// 				}
	// 			],

	// 		},{
	// 			name:'External Finish',
	// 			// cat_type:'cabinet_finish_finish',
	// 			division:'finish',
	// 			id:'external_finish',
	// 			properties:[
	// 				{
	// 					name:'Sku',
	// 					id:'sku',
	// 					type:'select'
	// 				},{
	// 					name:'Tag',
	// 					id:'tag',
	// 					type:'multiselect'
	// 				},{
	// 					name:'Thickness',
	// 					id:'thickness',
	// 					type:'number'
	// 				}
	// 			],

	// 		}
	// 	]
	// },
	{
		name: 'Panel',
		id: 'panel',
		sub_component: [
			{
				name: 'Panel',
				id: 'panel',
				properties:[
					{
						name: 'Panel Type',
						id: 'panel_type',
						type: 'panel_type'
					}
				]
			},{
				name:'Ply',
				id:'ply',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},
					{
						name:'Sales Channel',
						id:'sales_channel',
						type:'multiselect',
					},
					{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					},{
						name:'PreLam Type',
						id:'prelam_type',
						type:'prelam_type',
					}
				],

			},{
				name:'Internal Finish',
				// cat_type:'cabinet_finish_finish',
				division:'finish',
				id:'internal_finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					},{
						name:'Thickness',
						id:'thickness',
						type:'number'
					}
				],

			},{
				name:'External Finish',
				// cat_type:'cabinet_finish_finish',
				division:'finish',
				id:'external_finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					},{
						name:'Thickness',
						id:'thickness',
						type:'number'
					}
				],

			}
		]
	},
	{
		name:'Back Panel',
		id:'back_panel',
		sub_component:[
			{
				name:'Ply',
				cat_type:'panel_core_hardware',
				division:'hardware',
				id:'ply',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					},{
						name:'Thickness',
						id:'thickness',
						type:'number'
					}
				],

			},{
				name:'Internal Finish',
				id:'internal_finish',
				cat_type:'cabinet_finish_finish',
				division:'finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					}
				],

			},{
				name:'External Finish',
				id:'external_finish',
				cat_type:'cabinet_finish_finish',
				division:'finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					}
				],

			}
		]
	},
	{
		name:'Shelf',
		id:'shelf',
		sub_component:[
			{
				name:'Ply',
				cat_type:'panel_core_hardware',
				division:'hardware',
				id:'ply',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					},{
						name:'Thickness',
						id:'thickness',
						type:'number'
					}
				],

			},{
				name:'Internal Finish',
				id:'internal_finish',
				cat_type:'cabinet_finish_finish',
				division:'finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					}
				],

			},{
				name:'External Finish',
				id:'external_finish',
				cat_type:'cabinet_finish_finish',
				division:'finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					}
				],

			},{
				name:'Shelf',
				id:'shelf',
				cat_type:'',
				division:'',
				properties:[
					{
						name:'Shelf Type',
						id:'shelf_type',
						type:'select'
					},{
						name:'Width',
						id:'width',
						type:'number'
					},{
						name:'Height',
						id:'height',
						type:'number'
					},{
						name:'Depth',
						id:'depth',
						type:'number'
					}
				],

			}
		]
	},
	{
		name:'Shutter',
		id:'shutter',
		sub_component:[
			{
				name:'Ply',
				cat_type:'panel_core_hardware',
				division:'hardware',
				id:'ply',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					},{
						name:'Thickness',
						id:'thickness',
						type:'number'
					}
				],

			},{
				name:'Internal Finish',
				id:'internal_finish',
				cat_type:'cabinet_finish_finish',
				division:'finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					}
				],

			},{
				name:'External Finish',
				id:'external_finish',
				cat_type:'cabinet_finish_finish',
				division:'finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					}
				],
			},{
				name:'Shutter Design',
				id:'shutter_design',
				cat_type:'shutter_hardware',
				division:'hardware',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					}
				],
			}
		]
	},{
		name:'Drawer Fascia',
		id:'drawer_fascia',
		sub_component:[
			{
				name:'Ply',
				cat_type:'panel_core_hardware',
				division:'hardware',
				id:'ply',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					},{
						name:'Thickness',
						id:'thickness',
						type:'number'
					}
				],

			},{
				name:'Internal Finish',
				id:'internal_finish',
				cat_type:'cabinet_finish_finish',
				division:'finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					}
				],

			},{
				name:'External Finish',
				id:'external_finish',
				cat_type:'cabinet_finish_finish',
				division:'finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					}
				],
			},{
				name:'Shutter Design',
				id:'shutter_design',
				cat_type:'shutter_hardware',
				division:'hardware',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					}
				],
			}
		]
	},{
		name:'Cabinet',
		id:'cabinet',
		sub_component:[
			{
				name:'Countertop',
				cat_type:'',
				division:'',
				id:'countertop',
				properties:[
					{
						name:'Visibility',
						id:'visibility',
						type:'select'
					},{
						name:'Thickness',
						id:'thickness',
						type:'number'
					}
				],

			},{
				name:'Ply Under Granite',
				cat_type:'',
				division:'',
				id:'ply_under_granite',
				properties:[
					{
						name:'Ply Sku',
						id:'ply_sku',
						type:'select'
					},{
						name:'Finish Sku',
						id:'finish_sku',
						type:'select'
					},{
						name:'Edgeband Sku',
						id:'edgeband_sku',
						type:'select'
					}
				],

			}
		]
	},{
		name:'Wardrobe',
		id:'wardrobe',
		sub_component:[
			{
				name:'Countertop',
				cat_type:'',
				division:'',
				id:'countertop',
				properties:[
					{
						name:'Visibility',
						id:'visibility',
						type:'select'
					},{
						name:'Thickness',
						id:'thickness',
						type:'number'
					}
				],

			},{
				name:'Ply Under Granite',
				cat_type:'',
				division:'',
				id:'ply_under_granite',
				properties:[
					{
						name:'Ply Sku',
						id:'ply_sku',
						type:'select'
					},{
						name:'Finish Sku',
						id:'finish_sku',
						type:'select'
					},{
						name:'Edgeband Sku',
						id:'edgeband_sku',
						type:'select'
					}
				],

			}
		]
	},{
		name:'Custom Panel (standalone)',
		id:'custom_panel_standalone',
		sub_component:[
			{
				name:'Internal Finish',
				cat_type:'cabinet_finish_finish',
				division:'finish',
				id:'internal_finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					}
				],

			},{
				name:'External Finish',
				cat_type:'cabinet_finish_finish',
				division:'finish',
				id:'external_finish',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					}
				],

			}
		]
	},{
        name:'Drawer System',
        id:'drawer_system',
        sub_component:[
            {
                name:'Tandem Box',
                cat_type:'',
                division:'',
                id:'tandem_box',
                properties:[
                    {
                        name:'Brand',
                        id:'brand',
                        type:'select'
                    },{
                        name:'Tag',
                        id:'tag',
                        type:'select'
                    }
                ],
            },{
                name:'Back Panel',
                cat_type:'',
                division:'',
                id:'back_panel',
                properties:[
                    {
                        name:'Ply Sku',
                        id:'ply_sku',
                        type:'select'
                    },{
                        name:'Finish Sku',
                        id:'finish_sku',
                        type:'select'
                    },{
						name:'Edgeband Sku',
						id:'edgeband_sku',
						type:'select'
					}
                ],
            },{
                name:'Bottom Panel',
                cat_type:'',
                division:'',
                id:'bottom_panel',
                properties:[
                    {
                        name:'Ply Sku',
                        id:'ply_sku',
                        type:'select'
                    },{
                        name:'Finish Sku',
                        id:'finish_sku',
                        type:'select'
                    },{
						name:'Edgeband Sku',
						id:'edgeband_sku',
						type:'select'
					}
                ],
            }
        ]
    },{
		name:'Drawer Set',
        id:'drawer_set',
		sub_component:[
			{
				name:'Drawer Set',
        		id:'drawer_set',
				properties:[
					{
						name:'Drawer Construction type',
						id:'drawer_construction_type',
						type:'select'
					}
				]
			},
			{
                name:'Bottom Panel',
                cat_type:'',
                division:'',
                id:'bottom_panel',
                properties:[
                    {
                        name:'Ply Sku',
                        id:'ply_sku',
                        type:'select'
                    },{
                        name:'Finish Sku',
                        id:'finish_sku',
                        type:'select'
                    },{
						name:'Edgeband Sku',
						id:'edgeband_sku',
						type:'select'
					}
                ],
            }
		]
	}
]

const component_options_json_applicable = [
	{
		name:'Cabinet',
		id:'cabinet',
		sub_component:[
			{
				name:'Cabinet',
				id:'cabinet',
				cat_type:'cabinet_furniture',
				division:'furniture',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					},{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Width',
						id:'width',
						type:'number'
					},{
						name:'Height',
						id:'height',
						type:'number'
					},{
						name:'Depth',
						id:'depth',
						type:'number'
					},{
						name:'No Condition',
						id:'no_condition',
						type:'no_condition'
					}
				],

			}
		]
	},{
		name:'Wardrobe',
		id:'wardrobe',
		sub_component:[
			{
				name:'Wardrobe',
				id:'wardrobe',
				cat_type:'cabinet_furniture',
				division:'furniture',
				properties:[
					{
						name:'Sku',
						id:'sku',
						type:'select'
					},{
						name:'Tag',
						id:'tag',
						type:'multiselect'
					},{
						name:'Thickness',
						id:'thickness',
						type:'number'
					},{
						name:'Width',
						id:'width',
						type:'number'
					},{
						name:'Height',
						id:'height',
						type:'number'
					},{
						name:'Depth',
						id:'depth',
						type:'number'
					},{
						name:'No Condition',
						id:'no_condition',
						type:'no_condition'
					}
				],

			}
		]
	}, {
		name:'Custom Panel (standalone)',
		id:'custom_panel_standalone',
		sub_component:[
			{
				name:'Custom Panel (standalone)',
				cat_type:'',
				division:'',
				id:'custom_panel_standalone',
				properties:[
					{
                        name:'Tag',
                        id:'tag',
                        type:'select'
                    }, {
						name:'No Condition',
						id:'no_condition',
						type:'no_condition'
					}
				],

			}
		]
	}, {
		name:'Design',
		id:'design',
		sub_component:[
			{
				name:'Design',
				cat_type:'',
				division:'',
				id:'design',
				properties:[
					{
                        name:'Sales Channel',
                        id:'sales_channel',
                        type:'select'
                    }, {
						name:'No Condition',
						id:'no_condition',
						type:'no_condition'
					}
				],

			}
		]
	}
]

const shelf_type_values = [{name:'Fixed Shelf',id:'fixed'},{name:'Adjustable Shelf', id:'adjustable'}]


const AM_rules = ({submit_for_delete_confirm, set_active_tab, send_analytics_event, check_route_fn, set_page_loader, store_details, get_store_details, set_active_division_id_sidebar}) => {
	const styles = {
		add_brand_style:{
			backgroundColor: '#007EF4',
			boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
			borderRadius: '3px',
			height:'32px',
			padding:'0px 24px',
			border:'0px',
			color:'white',
		},
		table_header: {
			fontWeight: "bold",
			fontSize: 'small'
		},
    };
	//console.log('BRANDPAGE====>CALLED')
	const [mf_rules, set_mf_rules ] = useState([]);
	const [show_rule_Dialog_Box, set_show_rule_Dialog_Box] = useState(false);
	const [active_rule, set_active_rule] = useState('');
	const [search_string , set_search_string] = useState('');
	const [display_mf_rules , set_display_mf_rules] = useState([]);
	const [key , set_key] = useState('');

	const [filtered_display_rules, set_filtered_display_rules] = useState([]);
	const [sort_value, set_sort_value] = useState(0);

	const [page_size , set_page_size] = useState(30)
	const [fetch_progress , set_fetch_progress] = useState(40)
	const [current_page , set_current_page] = useState(1)
	const [initialized , set_initialized] = useState(false) 
 	const [all_pages , set_all_pages] = useState([])
	const alert = useAlert()
	const [ start_index , set_start_index ] = useState('');
	const [ end_index , set_end_index ] = useState('');

	const [all_division_tree_data, set_all_division_tree_data] = useState([]);
	const [all_category ,set_all_category ] =useState([])
    const [all_sub_category ,set_all_sub_category ] =useState([])
	const [all_edge_band_sku ,set_all_edge_band_sku ] =useState([])
	const [all_edge_band_glue_sku ,set_all_edge_band_glue_sku ] =useState([])
	const [all_panel_glue_sku ,set_all_panel_glue_sku ] =useState([])
	const [all_sales_ch ,set_all_sales_ch ] =useState([])
	const [last_item_order ,set_last_item_order ] =useState(0)
	const [all_brands ,set_all_brands ] =useState([])
	const [tags ,set_tags ] =useState([]);

	const [is_filter_open, set_is_filter_open] = useState(false);

	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)


	const fetch_tags_details = async () => {
        try {
        var tags_details = await general_fetch({ url: 'tag/get'});
		console.log("tag AmRules")
        set_tags(tags_details);
        } catch(err) {
        console.log(err);
        }
	}
	
	const fetch_mf_rules_details = async () => {
        try {
            var resp = await general_fetch({ url: 'automation_rule/get_by_type', body:{type:'design_automation', business_unit_id: user.current_business_unit_id}});
            set_initialized(true);
			var mf_rules_details= resp && resp.length ? resp.sort(custom_sort):[]
            set_mf_rules(mf_rules_details);
            set_display_mf_rules(mf_rules_details);
			set_last_item_order(mf_rules_details.length?mf_rules_details[mf_rules_details.length-1].order:0)
			//console.log('brand======>',mf_rules);
        } catch(err) {
            console.log(err);
			// alert.error('Error! Please contact admin')
        }
    }

	// const onclick_suffle_rule = async(active_rule, new_order) => {
		// try {
		// 	var body = {data:[{id:active_rule.id,order:new_order}]};
		// 	var response = await general_fetch({ url: 'manufacturing_rule/update_order', body});	
		// 	alert.success('Rule Updated sucessfully')
		// 	fetch_mf_rules_details()
		// } catch(err) {
		// 	console.log(err);
		// }
	// }

	const fetch_sales_channel_details = async () => {
		try {
			let resp, sales_channel_details = []
			let body = {
				"include_sales_channel_profile_info": true, 
				"include_bu_mapping": true, 
				"include_bu_info": false, 
				"include_price_type_mapping": true, 
				"include_price_type_info": true, 
				"include_user_mapping": true, 
				"include_user_info": false
			}

			if(org_bu_view === 'bu'){
				if(user && user.current_business_unit_id){
					body['business_unit_ids'] = [user.current_business_unit_id]
					resp = await general_fetch({ url: 'sales_channel/get_by_business_unit' , body})
					let owned_sc = resp && resp.sales_channels_by_business_unit && resp.sales_channels_by_business_unit[user.current_business_unit_id] ? resp.sales_channels_by_business_unit[user.current_business_unit_id].owned : []
					let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x)) sales_channel_details.push(resp.sales_channels[x])}) : []
				}
			}else{
				resp = await general_fetch({ url: 'sales_channel/get_of_store' , body})
				sales_channel_details = resp && resp.sales_channels && resp.sales_channels.owned ? resp.sales_channels.owned.filter(x => !x.business_unit_id) : []
			}
			sales_channel_details = sales_channel_details.map(single_sales_channel => {
                let price_types = single_sales_channel.price_type_ids && single_sales_channel.price_type_ids.length ? single_sales_channel.price_type_ids.map(x => resp.price_types[x]) : []
                return {...single_sales_channel, price_types}
            })
			set_all_sales_ch(sales_channel_details);
		} catch(err) {
			console.log(err);
		}
	}

	const get_all_brands = async() => {
        try {
            var response = await general_fetch({ url: 'brand/get'});	
			set_all_brands(response)
        } catch(err) {
            console.log(err);
        }
    }

	const onclick_get_cat = async() => {
        try {
			let body = {}
			if(org_bu_view == 'bu'){
				body['business_unit_id'] = user.current_business_unit_id
			}
            var response = await general_fetch({ url: 'inventory/get_all_sub_categories', body});	
			set_all_division_tree_data(response)
        } catch(err) {
            console.log(err);
            // alert.success('Please contact admin')
        }
    }

	const get_edgeband_all_sku = async (cat_type_id) => {
		try {
			let body = { sku_category_type_id:cat_type_id }
			if(org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
			var response = await general_fetch({ url: 'inventory/get_sub_categories' , body });
			var depricated_filter_output =response && response.length ?response.filter(x => x.deprecated !=1):[]
			var resp = depricated_filter_output.length?depricated_filter_output.sort(custom_sort):[]
			var x_all_sku=[]
			var x_edge_band_sku = resp.reduce((prev, curr)=>{curr.sku_group.reduce((prev,curr)=>{curr.sku.reduce((prev,curr)=>x_all_sku.push(curr),[])},[])},[]);
			cat_type_id==='edge_band_hardware' && set_all_edge_band_sku(x_all_sku);
			cat_type_id==='edge_band_glue_hardware' && set_all_edge_band_glue_sku(x_all_sku);
			cat_type_id==='panel_glue_hardware' && set_all_panel_glue_sku(x_all_sku);
			console.log('x_edge_band_sku =>>>>',x_all_sku)
		} catch(err) {
			// alert.error('Something went wrong, Please refresh')
			console.log(err);
		}
	}

	const reducer_fn = (previousValue, currentValue) => {
        var x_cat = [...previousValue]
        currentValue.sku_category.map(x=>x_cat.push(x))
        return x_cat
    }

    const sub_cat_reducer_fn = (previousValue, currentValue) => {
        var x_sub_cat = [...previousValue]
        currentValue.sku_sub_category.map(x=>x_sub_cat.push(x))
        return x_sub_cat;
    }
 
    useEffect(() => {
        if(all_division_tree_data && all_division_tree_data.length){
            var x_cat = all_division_tree_data.reduce(reducer_fn,[]);
            set_all_category(x_cat && x_cat.length?x_cat:[])
            var x_sub_cat = x_cat.reduce(sub_cat_reducer_fn,[]);
            set_all_sub_category(x_sub_cat && x_sub_cat.length?x_sub_cat:[])
        }
		else{
			set_all_category([])
			set_all_sub_category([])
		}
    },[all_division_tree_data])

	// useEffect(() => {
	// 	console.log('brand======>',mf_rules);
	// }
	// , [mf_rules]);

	useEffect(() => {
		if(mf_rules && !mf_rules.length){
			fetch_mf_rules_details();
		}
		if(tags && !tags.length){
			fetch_tags_details();
		}
		if(all_category && all_sub_category && (!all_category.length||all_sub_category)){
			onclick_get_cat();
		}
		if(all_edge_band_sku && !all_edge_band_sku.length){
			get_edgeband_all_sku('edge_band_hardware');
		}if(all_edge_band_glue_sku && !all_edge_band_glue_sku.length){
			get_edgeband_all_sku('edge_band_glue_hardware');
		}
		if(all_panel_glue_sku && !all_panel_glue_sku.length){
			get_edgeband_all_sku('panel_glue_hardware');
		}
		if(all_sales_ch && !all_sales_ch.length){
			fetch_sales_channel_details()
		}
		if(all_brands && !all_brands.length){
			get_all_brands();
		}
		
	}, []);


	const onclick_delete_rule = async(id) => {
		if(id){
			try {
				var response = await general_fetch({ url: 'automation_rule/remove', body:{'id':id}});
				alert.success('Rule deleted Successfully !')
				fetch_mf_rules_details()
			} catch(err) {
				console.log(err);
				// alert.error('Error in deletion')
			}
		}else{
			alert.error('Error in deletion')
		}	
	}

	const open_rule_update_dialog = ({item, mode}) => {
		set_show_rule_Dialog_Box(true);
		set_active_rule(item);
		set_key(mode);
	}

	const close_rule_Dialog_Box = () => {
		set_show_rule_Dialog_Box(false);
		set_active_rule('');
		set_key('');
	}

	const open_rule_add_dialog = () => {
		set_show_rule_Dialog_Box(true);
		set_key('add');
	}
	
	const filter_rule = () => 
	{
		var filtered_brands = [...mf_rules];
		var lower_search_string = search_string ? search_string.toLowerCase():'';
		if(lower_search_string){
			filtered_brands = filtered_brands.filter( brand => (brand.name.toLowerCase().includes(lower_search_string) || (brand.description ? brand.description.toLowerCase().includes(lower_search_string):false)))
		}
		set_display_mf_rules(filtered_brands)
	}

	const onclick_sorting_arrows = () => {
		if(sort_value == 1){
			set_filtered_display_rules(display_mf_rules.filter(x => x.active))
		}
		else if(sort_value == 2){
			set_filtered_display_rules(display_mf_rules.filter(x => !x.active))
		}else{
			set_filtered_display_rules(display_mf_rules)
		}
	}


	useEffect(() => {
		onclick_sorting_arrows()
	}, [sort_value, display_mf_rules]);

	useEffect(() => {
			filter_rule();
	}, [search_string, mf_rules]);

	useEffect(() => {
		set_active_tab('DesignIntelligence')
		set_active_division_id_sidebar('AM_rules')
		// check_route_fn('AM_rules')
		send_analytics_event('auto', 'AM_rules', 'AM_rules', 1, window.location.href)
	}, []);

	useEffect(() => {
		if (initialized) {
			var pages = Number(Math.ceil(display_mf_rules.length / page_size));
			//console.log('all pages --> ', pages, 'projects length --<>', display_mf_rules.length)
			var tmp = Array.apply('', {length: pages + 1}).map(Number.call, Number)
			tmp.splice(0, 1);
			//console.log('tmp --<>', tmp)
			set_all_pages(tmp);
			//console.log('tmp spliced--<>', tmp)
			set_current_page(1);
		}
	}, [ page_size, display_mf_rules ])

	const onclick_suffle_rule = async(active_rule, new_order) => {
		try {
			var body = {
				rule_type: 'design_automation',
				data:[{id:active_rule.id,order:new_order}]
			};
			var response = await general_fetch({ url: 'automation_rule/update_order', body});	
			alert.success('Rule Updated Successfully !')
			fetch_mf_rules_details()
		} catch(err) {
			console.log(err);
		}
	}


	const MF_Rules_Filler = () => (
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
				<tr>
					{ initialized ? (
						<th scope="col"><span style={styles.table_header}><b>No rules to list  </b></span><br/> Try adjusting filters...<br /> &nbsp;</th>
					):(
						<th scope="col"><span style={styles.table_header}><b>Fetching all rules </b></span><br/> <br /> Please wait...<br /> <br />
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;</th>
					)}
				</tr>
			</thead>
		</Table>
	);

	const Single_table_row = ({item, index, start_index, end_index, open_rule_update_dialog, onclick_delete_rule, submit_for_delete_confirm, fetch_rules}) => {

		const [show_name_clone_modal, set_show_name_clone_modal] = useState(false);
		const [active_rule, set_active_rule] = useState('');
		const alert = useAlert();
		const user = useContext(UserContext)

		const clone_rule = async(rule_json, new_name) => {
			try{
				let body = {
					name: new_name,
					description: rule_json.description,
					order: rule_json.order+1,
					type: rule_json.type,
					active: false,
					applicability_combination_logic: rule_json.applicability_combination_logic,
					condition_combination_logic: rule_json.condition_combination_logic,
					action_combination_logic: rule_json.action_combination_logic,
					applicability:rule_json.applicability,
					conditions: rule_json.conditions,
					actions: rule_json.actions,
					business_unit_id:user.current_business_unit_id
				}

				let resp = await general_fetch({url:'automation_rule/create', body})
				fetch_rules()
				alert.success('Rule cloned Successfully !')
			}catch(err){
				alert.error("Rule clone failed !")
				console.log(err)
			}
		}

		const open_name_clone_modal = (rule_json) => {
			set_active_rule(rule_json)
			set_show_name_clone_modal(true)		
		}

		const close_name_clone_modal = () => {
			set_active_rule('')
			set_show_name_clone_modal(false)
		}

		return (
			// {
			// 	display_mf_rules && display_mf_rules.length && display_mf_rules.map((item, index) => (
					<tr key={index} style={{display:(index+1>=start_index && index<end_index)?'flex':'none'}} className='tr_style display_onhover'>
						<RuleNameCloneModal open={show_name_clone_modal} handleClose={close_name_clone_modal} rule_json={active_rule} clone_rule={clone_rule} />
						<td className='td_style flex_property' style={{flex:1}}>
							<div className='drag_option' style={{display:'flex', alignItems:'center', color:'#808080'}}>
								<i style={{marginRight:'1px'}} className='fa fa-ellipsis-v'/>
								<i className='fa fa-ellipsis-v'/>
								<span style={{marginLeft:'10px'}}>{index+1} .</span>
							</div>
						</td>
						<td className='td_style flex_property' style={{flex:5}}><span className='table_text_overflow'>{item.name}</span></td>
						<td className='td_style flex_property' style={{flex:5}}><span className='table_text_overflow'>{item.description?item.description:'-'}</span></td>
						{/* <td className='td_style flex_property' style={{flex:5}}>-</td> */}
						<td className='td_style flex_property' style={{flex:5}}>{item.active==1?<span><i className='fas fa-circle' style={{color: '#23C16B', marginRight:'8px'}}></i> <span>Active</span></span>  : <span><i className='fas fa-circle' style={{color: '#D9D9D9', marginRight:'8px'}}></i> <span>Inactive</span></span>}</td>
						{/* <td className='td_style flex_property' style={{flex:5}}><span className='table_text_overflow'>{item.created_at}</span></td> */}
						
						<td className='td_style flex_property x1' style={{ justifyContent:'flex-end', flex:2, paddingRight:'20px'}}>
							{
								user.store_id ==item.store_id ?(
									<span className='hide'>
										<i style={{marginRight:'15px'}} onClick ={() => open_rule_update_dialog({ item, mode: 'view' })} className='fa fa-eye'/>
										<i style={{marginRight:'15px'}} onClick ={() => open_rule_update_dialog({ item, mode: 'edit' })} className='fa fa-edit'/>
										<img src={'/assets/img/icons/clone_icon.svg'} onClick={() => {open_name_clone_modal(item)}} width='14px' height='14px' style={{marginRight:'15px'}}/>
										<i 
											onClick={()=>submit_for_delete_confirm(()=>onclick_delete_rule(item.id), ()=>{}, 'Are you sure you want to delete this Rule? Rule Name - '+ item.name)}
											className='fa fa-trash'
										/>
									</span>
								):''
							}
						</td>
						{/* <td className='td_style flex_property x1' style={{ justifyContent:'flex-end', flex:'1', paddingRight:"20px"}}>
							{
								user.store_id ==rule.store_id ?(
									<span className='hide'>
										
									</span>
								):''
							}
						</td> */}
					</tr>
				// ))}
		)
	}

	

	const MF_Rules_Table = () => (
		<table className="text-left flex_property table_style">
			<thead style={{width:'100%'}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',width:'100%'}}>
				<th className='th_style flex_property' style={{flex:1}}><span>Rule Order</span></th>
				<th className='th_style flex_property' style={{flex:5}}><span>Rule Name</span></th>
				<th className='th_style flex_property' style={{flex:5}}><span>Description</span></th>
				{/* <th className='th_style flex_property' style={{flex:5}}><span>Source Elements</span></th> */}
				<th className='th_style flex_property' style={{flex:5}}>
					<span style={{paddingRight:'8px'}}>Active</span>
					<span style={{display:'flex', flexDirection:'column', fontSize:'14px'}}>
						<i title='Filter' style={{color:sort_value?COLORS.blue6:''}} onClick={() => set_is_filter_open(!is_filter_open)} className='fa fa-filter arrow_onhover_1'/>
						{/* <i title='Descending' onClick={() => set_sort_value(1)} className='fa fa-caret-down arrow_onhover_2'/> */}
					</span>
				</th>
				{/* <th className='th_style flex_property' style={{border:0, flex:5}}><span>Created at</span></th> */}
				<th className='th_style' style={{border:0, flex:2, paddingRight:"20px"}}><span></span></th>
			</tr>
			</thead>
			<tbody style={{display:"block",width:'100%'}}>
				<DragDropComponent items_from_backened={filtered_display_rules} onclick_suffle_rule={onclick_suffle_rule} set_items_from_backened={set_display_mf_rules} Single_component={Single_table_row} start_index={start_index} end_index={end_index} user={user} open_rule_update_dialog={open_rule_update_dialog} submit_for_delete_confirm={submit_for_delete_confirm} onclick_delete_rule={onclick_delete_rule} fetch_rules={fetch_mf_rules_details}/>
			</tbody>
		</table>
	);

	return (
		<Main_Grid active_tab="mf_rules" user={user} store_details={store_details} get_store_details={get_store_details}>
			<Filter is_filter_open={is_filter_open} set_is_filter_open={set_is_filter_open} set_sort_value={set_sort_value}></Filter>
			<Row style={{padding:'10px 0px', color:'#22272e'}}>
	        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
	        		Viewing {display_mf_rules && display_mf_rules.length ?display_mf_rules.length:'0'} Design Automation Rules
	        	</Col>
	        	<Col xs={6} style={{textAlign:'right'}}>
	        		<button onClick={open_rule_add_dialog} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Design Automation Rule</span></button>
	        	</Col>
        	</Row>
	        <Row style={{height:'calc(100% - 90px)'}}>
		        <Col style={{height:'100%'}}>
		        	<Card style={{borderRadius: 0, height:'100%'}}>
		        		<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Rule Name, Description' /></div>
						<div id='design-paginator' style={{height:'calc(100% - 40px)'}}>
							{display_mf_rules && display_mf_rules.length ? (<MF_Rules_Table />) : (<MF_Rules_Filler />)}
						</div>
					</Card>
		        </Col>
	        </Row>
	        <Row style={{height:'3px'}}>
		        <Col>
		        </Col>
	        </Row>
	        <Row>
		        <Col>	
					<Pegasus_Paginator 
						id='design-paginator'
						end_index={end_index}
						set_end_index={set_end_index}
						start_index={start_index}
						set_start_index={set_start_index}
						all_projects={display_mf_rules && display_mf_rules.length ? display_mf_rules.length : '0'}
					/>
		        </Col>
	        </Row>
			<AMRulesDialogBox all_brands={all_brands} component_options_json = {component_options_json} component_options_json_applicable={component_options_json_applicable} all_panel_glue_sku={all_panel_glue_sku} all_division_tree_data={all_division_tree_data} all_category={all_category} all_sub_category={all_sub_category} all_edge_band_sku={all_edge_band_sku} all_edge_band_glue_sku={all_edge_band_glue_sku} open = {show_rule_Dialog_Box} handleClose = {close_rule_Dialog_Box} active_rule={active_rule} user= {user} fetch_mf_rules_details = {fetch_mf_rules_details} mode = {key} set_page_loader={set_page_loader} all_sales_ch={all_sales_ch} last_item_order={last_item_order} tags={tags} shelf_type_values={shelf_type_values} />
		</Main_Grid>
	);
}	

export default AM_rules;

