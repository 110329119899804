import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useAlert } from 'react-alert'
import './index.css';
import debounce from '../../utils/debounce';
// const item_json=[
//     {
//         key:'124',
//         value:10,
//     },
//     {
//         field:'128',
//         value:10,
//     },
//     {
//         key:'124',
//         value:10,
//     },
//     {
//         field:'128',
//         value:10,
//     },
// ];

const Single_badge = ({item, onclick_onRemove, options_dummy, displayValue}) => {

    const onclick_remove = (e) => {
        // set_is_checked(false)
        item.checked = false;
        var dummy_x_removed = options_dummy.map(x => {
            if(item && x.id===item.id){
                x.checked=false
            }
            return x;
        })
        onclick_onRemove()
        e.stopPropagation()
    }

    return(
        <div className='single_badge' title={item?item[displayValue]:''} style={{}}>
            <span className='badge_text' title={item?item[displayValue]:''} style={{}}>{item?item[displayValue]:''}</span>
            <i style={{cursor:'pointer'}} onClick={(e) => onclick_remove(e)} className='fa fa-times'/>
        </div>
    )
}

const Single_option = ({item, onclick_onSelect, options_dummy, selectionLimit, displayValue}) => {

   const [is_checked, set_is_checked] = useState(false)

   useEffect(() => {
       if(item){
           set_is_checked(item.checked)
       }
   },[item])

    const onclick_checkbox = (value) => {
        set_is_checked(value)
        item.checked = value;
        var dummy_x_checked = options_dummy.map(x => {
            if(x.id===item.id){
                x.checked=value
            }
            return x;
        })
        onclick_onSelect()
    }

    return(
        <div 
            onClick={(options_dummy.filter(x => x.checked).length>=selectionLimit && !item.checked)?null:() => onclick_checkbox(!is_checked)} 
            className='single_option'
        >
            <input style={{marginRight:'5px', cursor:'pointer'}} checked={item.checked} type='checkbox'/>
            <span title={item?item[displayValue]:''} className='option_text' style={{opacity:(options_dummy.filter(x => x.checked).length>=selectionLimit && !item.checked)?.5:1}}>{item?item[displayValue]:''}</span>
        </div>
    )
}


const Multiselect = ({style, options, selectedValues, onSelect, onRemove, displayValue,selectionLimit, id, placeholder, disable, key_to_select}) => {

    const [options_dummy, set_options_dummy] = useState([]);
    const [options_dummy_display, set_options_dummy_display] = useState([]);
    const [open_dropdown, set_open_dropdown] = useState(false); 
    const [search_string, set_search_string] = useState('');
    const [id_width, set_id_width] = useState(''); 
    const alert = useAlert();
    
    const toggle_dropdown = () => {
        set_open_dropdown(!open_dropdown)
        handle_close()
    }

    useEffect(() => {
        if(id){
            // console.log('Elememt unscroll for id',id)
            var x_id_width = document.getElementById('badge_container')?document.getElementById('badge_container').offsetWidth:200;
            set_id_width(x_id_width<200?200:x_id_width)
        }
    },[id])

    // const getOffset = () => {
    //     var x_id_width = document.getElementById(id)
    //     const rect_offset = x_id_width.getBoundingClientRect();
    //     return {
    //       left: rect_offset.left + window.scrollX,
    //       top: rect_offset.top + window.scrollY
    //     };
    // }

    // useEffect(() => {
    //     document.addEventListener('wheel', debounce(adjust_dropdown, 5));
    // },[])
    
    // const adjust_dropdown = () => {
    //     var options_search_container_element= document.getElementById('options_search_container'+id)
    //     console.log('Elememt','options_search_container'+id)
    //     if(options_search_container_element){
    //         var options_search_container_element_offsettop = getOffset().top
    //         options_search_container_element.style.top=(Number(options_search_container_element_offsettop)+32)+'px'
    //         console.log('top',getOffset().top)
    //     }else{
    //         console.log('Elememt unscroll',)
    //     }
    // }
  
    useEffect(() =>{
        var filtered_list = JSON.parse(JSON.stringify(options_dummy))
        var lower_search_string = search_string ? search_string.toLowerCase():'';
        if(lower_search_string){
            filtered_list = filtered_list.filter( item => item[displayValue] && item[displayValue].toLowerCase().includes(lower_search_string))
        }
        set_options_dummy_display(filtered_list)
	},[search_string])

    useEffect(() =>{
		if(options){
            //  console.log("options", displayValue, options);
            // console.log('multiselid', id)
            // console.log('multiselid_options', options)
            if (!Array.isArray(selectedValues)) {
                console.error('non array selection list', selectedValues);
            }
            var x_selected_ids = selectedValues && selectedValues.length && Array.isArray(selectedValues) ? selectedValues.map(x => x.id):[]
            // console.log("ccccc", options ,"id", id, 'selected', x_selected_ids, "ss", selectedValues);
            var x_options = JSON.parse(JSON.stringify(options))
            // console.log("ccc px", x_options, "ss",x_options.length)
            if(x_options && x_options.length){
                // console.log("ccc p", displayValue);
                var px_options = x_options.map(x => {
                    // console.log("ccc p", x.id);
                    var x_value = x_selected_ids.includes(x.id)?true:false;
                    // console.log("ccc p", x_value);
                    return{...x, checked:x_value, count:0}
                })
            // console.log("ccc pxx", px_options)
                // console.log('px_options========>',px_options)
                set_options_dummy(px_options)
                set_options_dummy_display(px_options)
            }else{
                // console.log('x_options========>',px_options)
                set_options_dummy([])
                set_options_dummy_display([])
            }
		}
	},[ options, selectedValues])

    const handle_close = () => {
        set_search_string('')
    }

    const onclick_onRemove = () => {
        var x_remove = options_dummy.filter(x => x.checked)
        if (key_to_select) {
            x_remove = x_remove.map(x => x[key_to_select]);
        }
        onRemove(x_remove)
        set_search_string('')
    }

    const onclick_onSelect = () => {
        var x_sel = options_dummy.filter(x => x.checked)
        if (key_to_select) {
            x_sel = x_sel.map(x => x[key_to_select]);
        }
        onSelect(x_sel)
        console.log(x_sel);
        set_search_string('')
    }
    try {
        return(
            <div style={{width:'100%', position:'relative', ...style}} id={id}>
                <div onClick={toggle_dropdown} style={{display:open_dropdown?'block':'none',}} className='inf_model_outer_div'/>
                <div id='badge_container' className='badge_container' style={{opacity:disable?.5:1}} onClick={disable?null:toggle_dropdown}>    
                    {
                        options_dummy && options_dummy.length && options_dummy.filter(x => x.checked).length? options_dummy.filter(x => x.checked).map((item, idx) => (
                            <Single_badge key={idx} item={item} onclick_onRemove={disable?()=>{}:onclick_onRemove} options_dummy={options_dummy} displayValue={displayValue}/>
                        ))
                        :<div style={{color:'rgb(67, 90, 111)', padding:'5px 10px'}}>{placeholder?placeholder:'Select Item'}</div>
                    }
                </div>
                <div id={'options_search_container'+id} className='options_search_container' style={{display:open_dropdown?'block':'none', width:id_width}}>
                    <input id={'options_search_input'+id} className='search_input_box' value={search_string} onChange={(e)=>set_search_string(e.target.value)} placeholder='Search here..' type='select'/>    
                    <div className='options_container' id={'options_container'+id}>
                        {
                            options_dummy_display && options_dummy_display.length?options_dummy_display.map((item, idx) => (
                                <Single_option key={idx} item={item} onclick_onSelect={onclick_onSelect} options_dummy={options_dummy} selectionLimit={selectionLimit} displayValue={displayValue}/>
                            ))
                            :
                            <div style={{padding:'5px', textAlign:'center'}}>No Items</div>
                        }
                    </div>
                </div>
            </div>
        )
    } catch(err) {
        console.error(err);
        return 'Error Inside MultiSelect';
    }
}

export default Multiselect;

