import React , {useEffect, useState}  from "react";
import {test_fn} from "./components/Unauthorised_toast";


var config_settings = {
	"stage":{
		// server_path: 'https://stagebackend.infurnia.com/',
		server_path: 'https://stage3backend.infurnia.com/',
		static_path: 'https://stagestaticassets.infurnia.com',
		// server_path: 'http://10.15.97.160:4444/
		auth_redirect_path: 'https://stage3login.infurnia.com/?target=admin',
		website_url: 'https://stage3design.infurnia.com/',
		admin_url:'https://stage3admin.infurnia.com/',
		local:'http://localhost:8000/',
		image_not_found: 'https://blog.rahulbhutani.com/wp-content/uploads/2020/05/Screenshot-2018-12-16-at-21.06.29.png',
		sku_image_not_found: 'https://stagestaticassets.infurnia.com/static_files/no_display_image.jpg',
		no_skus_found_in_group: 'https://stagestaticassets.infurnia.com/static_files/no_sku_found_in_group.jpg',

		segment_id: 'nbdTDBiyVIHkc1thGFH2k8rwHwyrkYJV',
		segment_url: 'stagesegmentcdn.infurnia.com/analytics.js/v1/',

		stripe:{
			key:"pk_test_9xEMeuI5xHt7m6pSRvqQJMHG" //'sk_test_6LQstvGtT0hh6Cr9XFE1Jd70'
		},
		razorpay:{
			key:'rzp_test_JJRu24LJ2cVcqb'
		},
		master_preferences_allowed_users:['15', '16', '31'], 
		global_roomtypes_allowed_users:['15', '16', '31'], 
		cat_type_allowed_store:['16'], // user_id:lovepreet
		mf_rules_allowed_store:['ed8b867fb42fde50','1504672400687','1520505489106','9ccf5e757a3a86a1'], // livspace,  global store, devs_admin, harsh
		dc_rules_allowed_store:['ed8b867fb42fde50','1504672400687','1520505489106','9ccf5e757a3a86a1'], //livspace,  global store, devs_admin, harsh
		hw_rules_allowed_store:['ed8b867fb42fde50','1504672400687','1520505489106','9ccf5e757a3a86a1'], // livspace,  global store, devs_admin, harsh
		am_rules_allowed_store:['ed8b867fb42fde50','1504672400687','1520505489106','9ccf5e757a3a86a1'], // livspace,  global store, devs_admin, harsh
		cc_rules_allowed_store:['1504672400687','1520505489106','9ccf5e757a3a86a1'], // global store, devs_admin, harsh
		pp_rules_allowed_store:['1504672400687','1520505489106','9ccf5e757a3a86a1'], // global store, devs_admin, harsh

		menu_items_ket_list:['category_type','panel_pricing_rule'],
		sentry_environment: 'stage3',
	},
	"preprod":{
		// server_path: 'https://stagebackend.infurnia.com/',
		server_path: 'https://preprodbackend.infurnia.com/',
		static_path: 'https://preprodstaticassets.infurnia.com',
		// server_path: 'http://10.15.97.160:4444/
		auth_redirect_path: 'https://preprodlogin.infurnia.com/?target=admin',
		website_url: 'https://preproddesign.infurnia.com/',
		admin_url:'https://preprodadmin.infurnia.com/',
		local:'http://localhost:8000/',
		image_not_found: 'https://blog.rahulbhutani.com/wp-content/uploads/2020/05/Screenshot-2018-12-16-at-21.06.29.png',
		sku_image_not_found: 'https://stagestaticassets.infurnia.com/static_files/no_display_image.jpg',
		no_skus_found_in_group: 'https://stagestaticassets.infurnia.com/static_files/no_sku_found_in_group.jpg',
		segment_id: 'nbdTDBiyVIHkc1thGFH2k8rwHwyrkYJV',
		segment_url: 'cdn.segment.com/analytics.js/v1/',
		
		stripe:{
			key:"pk_test_9xEMeuI5xHt7m6pSRvqQJMHG" //'sk_test_6LQstvGtT0hh6Cr9XFE1Jd70'
		},
		razorpay:{
			key:'rzp_test_JJRu24LJ2cVcqb'
		},
		master_preferences_allowed_users:['15', '16', '31'], 
		global_roomtypes_allowed_users:['15', '16', '31'], 
		cat_type_allowed_store:['16'], // user_id:lovepreet
		mf_rules_allowed_store:['ed8b867fb42fde50','1504672400687','1520505489106','9ccf5e757a3a86a1'], // livspace,  global store, devs_admin, harsh
		dc_rules_allowed_store:['ed8b867fb42fde50','1504672400687','1520505489106','9ccf5e757a3a86a1'], //livspace,  global store, devs_admin, harsh
		hw_rules_allowed_store:['ed8b867fb42fde50','1504672400687','1520505489106','9ccf5e757a3a86a1'], // livspace,  global store, devs_admin, harsh
		am_rules_allowed_store:['ed8b867fb42fde50','1504672400687','1520505489106','9ccf5e757a3a86a1'], // livspace,  global store, devs_admin, harsh
		cc_rules_allowed_store:['1504672400687','1520505489106','9ccf5e757a3a86a1'], // global store, devs_admin, harsh
		pp_rules_allowed_store:['1504672400687','1520505489106','9ccf5e757a3a86a1'], // global store, devs_admin, harsh

		menu_items_ket_list:['category_type','panel_pricing_rule'],
		sentry_environment: 'preprod',
	},
	"dev":{

		server_path: 'https://devbackendrunning.infurnia.com/',
		static_path: 'https://stagestaticassets.infurnia.com',
		// server_path: 'http://10.15.97.16,0:4444/
		// 'https://staging.infurnia.com/login.html?target=admin',
		auth_redirect_path: 'https://devbackendrunning.infurnia.com/login',
		// auth_redirect_path: 'https://devbackend3.infurnia.com/login/admin',
		website_url: 'https://stagedesign.infurnia.com/',
		admin_url:'https://devadmin2.infurnia.com/',
		local:'http://localhost:8000/',
		image_not_found: 'https://blog.rahulbhutani.com/wp-content/uploads/2020/05/Screenshot-2018-12-16-at-21.06.29.png',
		sku_image_not_found: 'https://stagestaticassets.infurnia.com/static_files/no_display_image.jpg',
		no_skus_found_in_group: 'https://stagestaticassets.infurnia.com/static_files/no_sku_found_in_group.jpg',
		stripe:{
			key:"pk_test_9xEMeuI5xHt7m6pSRvqQJMHG" //'sk_test_6LQstvGtT0hh6Cr9XFE1Jd70'
		},
		razorpay:{
			key:'rzp_test_JJRu24LJ2cVcqb'
		},
		master_preferences_allowed_users:['15', '16', '31'], 
		global_roomtypes_allowed_users:['15', '16', '31'], 
		cat_type_allowed_store:['16'], // user_id:lovepreet
		mf_rules_allowed_store:['ed8b867fb42fde50','1504672400687','1520505489106','9ccf5e757a3a86a1'], // livspace,  global store, devs_admin, harsh
		dc_rules_allowed_store:['ed8b867fb42fde50','1504672400687','1520505489106','9ccf5e757a3a86a1'], //livspace,  global store, devs_admin, harsh
		hw_rules_allowed_store:['ed8b867fb42fde50','1504672400687','1520505489106','9ccf5e757a3a86a1'], // livspace,  global store, devs_admin, harsh
		am_rules_allowed_store:['ed8b867fb42fde50','1504672400687','1520505489106','9ccf5e757a3a86a1'], // livspace,  global store, devs_admin, harsh
		cc_rules_allowed_store:['1504672400687','1520505489106','9ccf5e757a3a86a1'], // global store, devs_admin, harsh
		pp_rules_allowed_store:['1504672400687','1520505489106','9ccf5e757a3a86a1'], // global store, devs_admin, harsh

		segment_id :'PtVjPkkb0OIoOcZWwO2C3nbg2T8X07D2',
		segment_url: 'cdn.segment.com/analytics.js/v1/',

		menu_items_ket_list:['category_type','panel_pricing_rule','Rules','MF_rules','DC_rules','HW_rules','AM_rules'],
		sentry_environment: 'development',
	},
	"prod":{
		server_path: 'https://productionbackend.infurnia.com/',
		static_path: 'https://staticassets.infurnia.com',
		auth_redirect_path: 'https://login.infurnia.com/?target=admin',
		// auth_redirect_path: 'https://design.infurnia.com/login/admin',
		website_url: 'https://design.infurnia.com/',
		admin_url:'https://admin.infurnia.com/',
		image_not_found: 'https://blog.rahulbhutani.com/wp-content/uploads/2020/05/Screenshot-2018-12-16-at-21.06.29.png',
		sku_image_not_found: 'https://stagestaticassets.infurnia.com/static_files/no_display_image.jpg',
		no_skus_found_in_group: 'https://stagestaticassets.infurnia.com/static_files/no_sku_found_in_group.jpg',
		
		segment_id :'3PFhMzGaauvUcbfehS1tmLxiB9P77yMW',
		segment_url: 'cdn.segment.com/analytics.js/v1/',

		stripe:{
			key:"pk_live_uIov8S3wsuce8hwoEimgBMaf" //'sk_test_6LQstvGtT0hh6Cr9XFE1Jd70'
		},
		razorpay:{
			key:'rzp_test_JJRu24LJ2cVcqb'
		},
		master_preferences_allowed_users:['15', '16', '31'], 

		global_roomtypes_allowed_users:['15', '16', '31'], 
		cat_type_allowed_store:['16'], // user_id:lovepreet
		mf_rules_allowed_store:['8ed49b18faf2b9dd','ed8b867fb42fde50','1504672400687','1520505489106','8ec363164bbe08e2'], // xxxx, livspace,  global store, devs_admin, harsh
		dc_rules_allowed_store:['8ed49b18faf2b9dd','ed8b867fb42fde50','1504672400687','1520505489106','8ec363164bbe08e2'], // xxxx, livspace,  global store, devs_admin, harsh
		hw_rules_allowed_store:['8ed49b18faf2b9dd','ed8b867fb42fde50','1504672400687','1520505489106','8ec363164bbe08e2'], // xxxx, livspace,  global store, devs_admin, harsh
		am_rules_allowed_store:['8ed49b18faf2b9dd','ed8b867fb42fde50','1504672400687','1520505489106','8ec363164bbe08e2'], // xxxx, livspace,  global store, devs_admin, harsh
		cc_rules_allowed_store:['1504672400687','1520505489106','8ec363164bbe08e2'], // global store, devs_admin, harsh
		pp_rules_allowed_store:['1504672400687','1520505489106','8ec363164bbe08e2'], // global store, devs_admin, harsh


		menu_items_ket_list:['category_type','panel_pricing_rule','Rules','MF_rules','DC_rules','HW_rules','AM_rules'],
		sentry_environment: 'production',

	}
}

const check_401 = (allow_visible) => {
	test_fn(allow_visible) 
}

var config = config_settings[process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'stage'];

global.config = config
global.config.check_401 = check_401

window.segment_id=global.config.segment_id;

