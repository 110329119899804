import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect, useContext } from 'react';
import general_fetch from '../../utils/fetch';
import { Card, Col, Progress, Table, Row , CardBody, Modal, Button,CardFooter, CardHeader, Input, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import SalesChannelDialogBox from'./SalesChannelDialogBox'
import OptionsDialogBox from "./OptionsDialogBox"
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import classnames from "classnames";
import desc_date_sort from '../../utils/desc_date_sort';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { AllBuContext } from '../../components/Contexts/AllBuContext';

const Sc_pref_add_edit = ({open , handleClose, active_pref, onclick_update, onclick_add , mode}) => {

	const[active_sc_pref_name , set_active_sc_pref_name] = useState('');
	const[active_sc_pref_desc , set_active_sc_pref_desc] = useState('');

	useEffect (() => {
		if(active_pref){
			set_active_sc_pref_name(active_pref.name)
			set_active_sc_pref_desc(active_pref.description)
		}
	},[active_pref]);

	const onclick_handleclose = () => {
		handleClose()
		set_active_sc_pref_name('')
		set_active_sc_pref_desc('')
	}

	const onclick_add_update = () => {
		if(mode==='add'){
			onclick_add(active_sc_pref_name, active_sc_pref_desc)
		}else{
			onclick_update(active_sc_pref_name, active_sc_pref_desc)
		}	
		onclick_handleclose()
	}

  	return (
		<Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={handleClose}>
			<Card className='global_modal_height_for_xs'>
				<CardHeader className='global_modal_header'>
						<span>
							<span>{mode==='add'?'Add Price Field':''}</span>
							<span>{mode==='edit' && active_pref?'Edit Price Field - '+ active_pref.name:''}</span>
						</span>
						<i onClick={handleClose} style={{cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body'>
					<Row>
						<Col>	
							<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Name</div>
							<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Name" type="text" value={active_sc_pref_name} onChange={e => set_active_sc_pref_name(e.target.value)}/>	
						</Col>	
					</Row>
					<Row>
						<Col>	
							<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Description</div>
							<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Description" type="text" value={active_sc_pref_desc} onChange={e => set_active_sc_pref_desc(e.target.value)}/>	
						</Col>	
					</Row>
				</CardBody>

				<CardFooter className='global_modal_footer'>
						<Button className='white_button' type="button" onClick={handleClose}> Cancel </Button>
						<Button className='blue_button' type="button" onClick={onclick_add_update}>{mode==='add'?'Add':'Update'}</Button>
				</CardFooter>
			</Card> 
		</Modal>
  	);
}

const SalesChannelPreferencesDialogBox = ({open , handleClose, all_sc_preferences, fetch_all_pref }) => {

	const[sales_channel_id , set_sales_channel_id] = useState('');
	const[show_active_pref_modal , set_show_active_pref_modal] = useState(false);
	const[active_pref , set_active_pref] = useState('');
	const[mode , set_mode] = useState('add');
	const alert = useAlert();

	const onclick_add = async (name, desc) => {
		if(name){
			try {
				var body = {name : name, description:desc}
				var response = await general_fetch({ url: 'price_type/create', body});
				console.log(response)
				fetch_all_pref();
			} catch(err) {
				console.log(err);
			}
		}else{
			alert.error('Please enter name')
		}
	}

	const onclick_update = async (name, desc) => {
		if(name){
			try {
				var body = {id: active_pref.id,name : name, description:desc}
				console.log(body)
				var response = await general_fetch({ url: 'price_type/update', body});
				console.log(response)
				fetch_all_pref();
			} catch(err) { 
				console.log(err);
			}
		}else{
			alert.error('Please enter name')
		}
	}

	// const onclick_delete = async (x_item) => {
	// 	try {
	// 		var body = {ids: [x_item.id]}
	// 		var response = await general_fetch({ url: 'price_type/delete', body});
	// 		fetch_all_pref();
	// 	} catch(err) { 
	// 		console.log(err);
	// 	}
	// }

	const onclick_pref = (x_item) => {
		console.log(x_item)
	}

	const open_active_pref_modal = (mode, x_item) => {
		set_show_active_pref_modal(true)
		set_active_pref(x_item?x_item:'')
		set_mode(mode)	
	}
	const close_active_pref_modal = () => {
		set_show_active_pref_modal(false)	
	}

	const onclick_handle_close = () => {
		handleClose()
		set_sales_channel_id('')
		set_show_active_pref_modal(false)
		set_active_pref('')
		set_mode('add')
	}

  	return (
		<Modal className="Margin_64px_top" size="lg" isOpen={open} toggle={onclick_handle_close}>
			<Card className='global_modal_height_for_mdlgxl'>
				<CardHeader className='global_modal_header'>
						<span>
							<span>Sales Channel Preferences</span>
						</span>
						<span>
							<button className='blue_button' style={{marginRight:'20px'}} onClick ={() => open_active_pref_modal('add')}>Add Price Field</button>
							<i onClick={onclick_handle_close} style={{cursor: 'pointer'}} className='fa fa-times'/>
						</span>
						
				</CardHeader>

				<CardBody className='global_modal_body'>
					<Row>
						<Col style={{fontWeight:600}}>
							Price Fields
						</Col>	
					</Row>
					<div style={{padding:'20px'}}>
						{
							all_sc_preferences && all_sc_preferences.length? all_sc_preferences.map((x_item, idx) => (
								<div key={idx} style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'10px 0px'}}>
									<div>
										{idx+1+'. '}{x_item.name}
									</div>
									<div>	
										<i onClick={()=> open_active_pref_modal('edit', x_item)} style={{marginRight:'15px', cursor:'pointer'}} className='fa fa-edit'/>
										{/* <i onClick={()=> onclick_delete(x_item)} style={{cursor:'pointer'}} className='fa fa-trash'/>	 */}
									</div>
								</div>
							))
							:
							<div>No Items to list.</div>
						}
					</div>
				</CardBody>

				<CardFooter className='global_modal_footer'>
						<Button className='white_button' type="button" onClick={handleClose}> Cancel </Button>
						{/* <Button className='blue_button' type="button" onClick={update_sales_name}>Update</Button>} */}
				</CardFooter>
			</Card>  
			<Sc_pref_add_edit open={show_active_pref_modal} handleClose={close_active_pref_modal} active_pref={active_pref} onclick_update={onclick_update} onclick_add={onclick_add} mode={mode}/>
		</Modal>
  	);
}


const SalesChannel = ({user, submit_for_delete_confirm, set_active_tab, send_analytics_event, check_route_fn, store_details, get_store_details, set_page_loader}) => {
	
	const styles = {
		add_brand_style:{
			backgroundColor: '#007EF4',
			boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
			borderRadius: '3px',
			height:'32px',
			padding:'0px 24px',
			border:'0px',
			color:'white',
		},
		table_header: {
			fontWeight: "bold",
			fontSize: 'small'
		},
    };

	const [sales_channel , set_sales_channel] = useState([]);
	const [all_public_sales_channels, set_all_public_sales_channels] = useState([]);
	const [subscribed_sales_channels, set_subscribed_sales_channels] = useState([]);
	// const [users , set_users] = useState([]);
	const [open_sales_channel_Dialog, set_open_sales_channel_Dialog] = useState(false);
	const [open_options_dialog, set_open_options_dialog] = useState(false);
	const [active_sales_channel , set_active_sales_channel] = useState({});
	const [active_sc_for_delete_in_options , set_active_sc_for_delete_in_options] = useState({});
	const [search_string , set_search_string] = useState('');
	const [display_sales_channel , set_display_sales_channel] = useState([]);
	const [mode , set_mode] = useState(null);
	
	const [page_size , set_page_size] = useState(30)
	const [fetch_progress , set_fetch_progress] = useState(40)
	const [current_page , set_current_page] = useState(1)
	const [initialized , set_initialized] = useState(false) 
 	const [all_pages , set_all_pages] = useState([])
	const [show_sales_channel_preferences_dialog, set_show_sales_channel_preferences_dialog] = useState(false);
	const [all_sc_preferences , set_all_sc_preferences] = useState([]);
	const [tabs, set_tabs] = useState(1);
	const [subscription_requests_data, set_subscription_requests_data] = useState({});
	const org_bu_view = useContext(OrgBuContext)
	const all_bu_in_store = useContext(AllBuContext)

	const [	all_users_accounts , set_all_users_accounts ] = useState([])
	// const [all_ffff, set_all_ffff] = useState('string1');
	const roles_options = [{name: 'Designer', id: 'designer'},{name: 'Project Manager', id: 'project_manager'},{name: 'Account Manager', id: 'accounts_manager'},{name: 'Catalogue Manager', id: 'catalog_manager'}]		


	const alert = useAlert()

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	const fetch_sales_channel_details = async () => {
		try {
			// var brand_timer = setInterval(() => {
			// 	var x = Number(fetch_progress);
			// 	console.log('xxx ---> ', x,x + Math.floor((100 - x)/2))
			// 	set_fetch_progress(String(x + Math.floor((100 - x)/2)))  
			// }, 200);

			let resp, sales_channel_details = [], subscribed_sales_channel_details = []
			let body = {
				"include_sales_channel_profile_info": true, 
				"include_bu_mapping": true, 
				"include_bu_info": false, 
				"include_price_type_mapping": true, 
				"include_price_type_info": true, 
				"include_user_mapping": true, 
				"include_user_info": false
			}

			if(org_bu_view === 'bu'){
				if(user && user.current_business_unit_id){
					body['business_unit_ids'] = [user.current_business_unit_id]
					resp = await general_fetch({ url: 'sales_channel/get_by_business_unit' , body})
					let owned_sc = resp && resp.sales_channels_by_business_unit && resp.sales_channels_by_business_unit[user.current_business_unit_id] ? resp.sales_channels_by_business_unit[user.current_business_unit_id].owned : []
					let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x)) sales_channel_details.push(resp.sales_channels[x])}) : []

					let subscribed_sc = resp && resp.sales_channels_by_business_unit && resp.sales_channels_by_business_unit[user.current_business_unit_id] ? resp.sales_channels_by_business_unit[user.current_business_unit_id].subscribed : []
					t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(subscribed_sc.includes(x)) subscribed_sales_channel_details.push(resp.sales_channels[x])}) : []
					set_subscribed_sales_channels(subscribed_sales_channel_details)
				}
			}else{
				resp = await general_fetch({ url: 'sales_channel/get_of_store' , body})
				sales_channel_details = resp && resp.sales_channels && resp.sales_channels.owned ? resp.sales_channels.owned.filter(x => !x.business_unit_id) : []
			}
			sales_channel_details = sales_channel_details.map(single_sales_channel => {
                let price_types = single_sales_channel.price_type_ids && single_sales_channel.price_type_ids.length ? single_sales_channel.price_type_ids.map(x => resp.price_types[x]) : []
                return {...single_sales_channel, price_types}
            })
			set_initialized(true)
			set_sales_channel(sales_channel_details);
			// if(tabs == 1){
			// 	filter_sales_channel()
			// 	// set_display_sales_channel(sales_channel_details)
			// }

			resp = await general_fetch({url:'sales_channel/get_public', body: {include_org_info: true, include_subscription_requests_info: false}})
			let public_sales_channel_details = []
			let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(store_details && resp.sales_channels[x].store_id != store_details.id) {resp.sales_channels[x].store_details = resp.orgs[resp.sales_channels[x].store_id] ; public_sales_channel_details.push(resp.sales_channels[x])}}) : []
			set_all_public_sales_channels(public_sales_channel_details)
			// if(tabs == 2){
			// 	filter_sales_channel()
			// 	// set_display_sales_channel(public_sales_channel_details)
			// }

			resp = await general_fetch({url:'sales_channel/subscription_request/get', body: {filter_by_org: true}})
			resp = resp.sales_channel_subscription_requests
			t = Object.keys(resp) && Object.keys(resp).length ? Object.keys(resp).map((x) => {
						resp[x].sort((a,b) => desc_date_sort(b,a,'created_at'))
					}):''
			console.log('set_subscription_requests_data', resp)
			set_subscription_requests_data(resp)

			
		} catch(err) {
			console.log(err);
		}
	}

	const delete_sales_channel = async (id) => {
		try {
			var identifiers = JSON.stringify({id : id})
			var body = {identifiers}
			var sales_channel_details = await general_fetch({ url: 'sales_channel/remove' , body});
			alert.success('Successfull')
			fetch_sales_channel_details();
		} catch(err) {
			alert.error('Request Failed')
			console.log(err);
		}
	}

	const fetch_all_pref = async () => {
		try {
			var resp = await general_fetch({ url: 'price_type/get_all_of_store'});
			set_all_sc_preferences(resp && resp.length?resp:[])
		} catch(err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if(tabs==1)filter_sales_channel()
		if(active_sc_for_delete_in_options.id){
			var x = sales_channel.filter((sc) => sc.id==active_sc_for_delete_in_options.id)[0]
			set_active_sc_for_delete_in_options(x)

		}
	}, [sales_channel]);

	useEffect(() => {
		if(tabs==2)filter_sales_channel()
	}, [all_public_sales_channels]);

	useEffect(() => {
		if(org_bu_view && user){
			fetch_sales_channel_details();
		}
	}, [user, org_bu_view]);

	useEffect(() => {
		if(user)fetch_all_pref()
	}, [user]);

	useEffect(() => {
		set_active_tab('Sales')
		// check_route_fn('Sales')
		fetch_account_details()
		send_analytics_event('auto', 'Sales Channel', 'Sales Channel', 1, window.location.href)	
	}, []);

	const show_open_options_dialog = (sales_channel) => {
		set_open_options_dialog(true);
		set_active_sc_for_delete_in_options(sales_channel);
	}

	const close_open_options_dialog = () => {
		set_open_options_dialog(false);
		set_active_sc_for_delete_in_options({});
	}
	
	const show_sales_channel_update_dialog = (sales_channel) => {
		set_open_sales_channel_Dialog(true);
		set_active_sales_channel(sales_channel);
		set_mode('update');
	}


	const show_sales_channel_add_dialog = () => {
		set_open_sales_channel_Dialog(true);
		set_mode('add');
	}

	const show_sales_channel_share_dialog = (sales_channel) => {
		set_open_sales_channel_Dialog(true);
		set_active_sales_channel(sales_channel);
		set_mode('share');
	}

	const close_sales_channel_Dialog = () => {
		set_open_sales_channel_Dialog(false);
		set_active_sales_channel({});
		set_mode(null);
	}

	const open_sales_channel_preferences_dialog = () => {
		set_show_sales_channel_preferences_dialog(true)	
	}
	const close_sales_channel_preferences_dialog = () => {
		set_show_sales_channel_preferences_dialog(false)	
	}

	const fetch_account_details = async () => {
		try {
			var account_details = await general_fetch({ url: 'user/get_all'});
			if(account_details && account_details.length){
				var account_details = account_details.filter( account => !account.deprecated)
			}
			if(account_details && account_details.length){
				var account_details = [...account_details];
				var account_details = account_details.filter( account => 
					account.role = account.role && account.role.length ? roles_options.filter((x) => account.role.includes(x.id)):[]
				)
			}
			console.log('all users account_details=======>',account_details)
			set_all_users_accounts(account_details);
			// set_display_all_users_accounts(account_details?account_details:'');
			// set_initialized(true)
		} catch(err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if (initialized) {
			var pages = Number(Math.ceil(display_sales_channel.length / page_size));
			console.log('all pages --> ', pages, 'projects length --<>', display_sales_channel.length)
			var tmp = Array.apply(null, {length: pages + 1}).map(Number.call, Number)
			tmp.splice(0, 1);
			console.log('tmp --<>', tmp)
			set_all_pages(tmp);
			console.log('tmp spliced--<>', tmp)
			set_current_page(1);
		}
	}, [ page_size, display_sales_channel ])

	useEffect(() => {
		if(tabs == 1){
			set_display_sales_channel(sales_channel)
		}else{
			set_display_sales_channel(all_public_sales_channels)
		}
	}, [tabs]);

	const Sales_Channel_Filler = () => (
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
				<tr>
					{ initialized ? (
						<th scope="col"><span style={styles.table_header}><b>No channels to list  </b></span><br/> Try adjusting filters...<br /> &nbsp;</th>
					):(
						<th scope="col"><span style={styles.table_header}><b>Fetching all channels  </b></span><br/> <br /> Please wait...<br /> <br />
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;</th>
					)}
				</tr>
			</thead>
		</Table>
	);

	const filter_sales_channel = () => 
	{
		var filtered_sales_channels = tabs == 1 ? [...sales_channel] : [...all_public_sales_channels];
		var lower_search_string = search_string ? search_string.toLowerCase():'';
		if(lower_search_string){
			filtered_sales_channels = filtered_sales_channels.filter( sales_channel => sales_channel.name.toLowerCase().includes(lower_search_string))
		}
		set_display_sales_channel(filtered_sales_channels)
	}

	useEffect(() => {
		filter_sales_channel();
	}, [search_string]);


	const subscription_action = async(sales_channel_id, sales_channel_subscription_request_id, action) => {
		try{
			if(action === 'subscribe'){
				let resp = await general_fetch({url:'sales_channel/subscription_request/raise', body: {sales_channel_id}})
				if(resp.status === 'approved'){
					alert.success('Successfully subscribed to the sales channel')
				}else{
					alert.success('Successfully raised subscription request')
				}
			}else if(action === 'unsubscribe'){
				let resp = await general_fetch({url:'sales_channel/unsubscribe', body: {sales_channel_id}})
				alert.success('Successfully unsubscribed')
			}else if(action === 'withdraw'){
				let resp = await general_fetch({url:'sales_channel/subscription_request/withdraw', body: {sales_channel_subscription_request_id}})
				alert.success('Successfully withdrawn subscription request')
			}
			fetch_sales_channel_details()
		}catch(err){	
			console.log(err)
		}
	}

	const Sales_Channel_Table = () => (
		<table style={{height:'100%'}} className="text-left flex_property table_style">
			<thead style={{width:"100%"}}>
				<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6', height:'40px'}}>
					<th className='th_style flex_property' style={{flex:'16'}}><span>Channel Name</span></th>
					{/* <th className='th_style flex_property' style={{border:0, flex:'3'}}><span>No. of SKUs</span></th>
					<th className='th_style flex_property' style={{border:0, flex:'3'}}><span>Designer Subscriptions</span></th>
					<th className='th_style flex_property' style={{border:0, flex:'3'}}><span>Store Subscriptions</span></th> */}
					{/* <th className='th_style flex_property' style={{border:0, flex:'2'}}><span>Eligiblity</span></th> */}
					<th className='th_style flex_property' style={{border:0, flex:'1', paddingRight:"20px", justifyContent:'center'}}><span>Actions</span></th>
				</tr>
			</thead>
			<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
			{display_sales_channel && display_sales_channel.length && display_sales_channel.map((sales_channel, idx) => (
					<tr key={idx} style={{display:'flex'}} className='tr_style display_onhover'>
					
						<td className='td_style flex_property' style={{flex:'16'}}>
							<div className='table_text_overflow flex_property' style={{height:'100%'}}>
								{sales_channel.name}
								{sales_channel.type=='default' ? <div className='template_box' style={{marginLeft:'8px'}}><div className='template'>Default</div></div>:''}
								{sales_channel.sharing=='public' ? <div className='template_box' style={{marginLeft:'8px', width:'52px'}}><div className='template' style={{width:'52px'}}>Public</div></div>:''}
							</div>
						</td>
						{/* <td className='td_style flex_property' style={{ flex:'3'}}>0</td>
						<td className='td_style flex_property' style={{ flex:'3'}}>0</td>
						<td className='td_style flex_property' style={{ flex:'3'}}>0</td> */}
						{/* <td className='td_style flex_property x1' style={{ justifyContent:'flex-center', flex:'2'}}>
							{
								!(sales_channel.name == "ISN Feeder") ?
								<div className='flex_property'>
									<span style={{marginRight:'15px'}} className='hide'><i onClick ={() => show_sales_channel_share_dialog(sales_channel)} className='fa fa-share'/></span>
									<span style={{marginLeft:'12px'}} className='hide'><i onClick ={() => show_open_options_dialog(sales_channel)} className='fa fa-cog'/></span>
								</div>
								:""
							}
						</td> */}
						<td className='td_style flex_property x1' style={{ justifyContent:'center', flex:'1', paddingRight:"20px"}}>
							{
								!(sales_channel.type == "isn_feeder") ?
								<div className='flex_property'>
									<span style={{marginLeft:'12px'}} className='hide'><i onClick ={() => show_sales_channel_update_dialog(sales_channel)} className='fa fa-edit'/></span>
									{/* <span className='hide'><i onClick ={() => submit_for_delete_confirm(()=>delete_sales_channel(sales_channel.id), ()=>{}, 'Sales Channel will be deleted permanently.')} className='fa fa-trash'/></span> */}
								</div>
								:""
							}
						</td>
					</tr>
			))}
			</tbody>
		</table>
	);

	const Public_Sales_Channel_Table = () => (
		<table style={{height:'100%'}} className="text-left flex_property table_style">
			<thead style={{width:"100%"}}>
				<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6', height:'40px'}}>
					<th className='th_style flex_property' style={{flex:'10'}}><span>Channel Name</span></th>
					<th className='th_style flex_property' style={{flex:'10'}}><span>Parent Organization</span></th>
					<th className='th_style flex_property' style={{border:0, flex:'2'}}><span>Actions</span></th>
				</tr>
			</thead>
			<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
			{display_sales_channel && display_sales_channel.length && display_sales_channel.map((single_sales_channel, idx) => (
				!(sales_channel.find(o => o.id === single_sales_channel.id)) ?
					<tr key={idx} style={{display:'flex'}} className='tr_style display_onhover'>
						<td className='td_style flex_property' style={{flex:'10'}}>
							<span className='table_text_overflow flex_property'>
								{single_sales_channel.name}
							</span>
						</td>
						<td className='td_style flex_property' style={{flex:'10'}}>
							<span className='table_text_overflow flex_property'>
								{single_sales_channel.store_details ? single_sales_channel.store_details.name : ''}
							</span>
						</td>
						<td className='td_style flex_property x1' style={{flex:'2'}}>
							<span style={{cursor:'pointer'}}>
							{
								subscription_requests_data && subscription_requests_data[single_sales_channel.id] && subscription_requests_data[single_sales_channel.id].length && subscription_requests_data[single_sales_channel.id][0].status == 'pending_approval' ? 
									<span onClick={() => subscription_action(single_sales_channel.id, subscription_requests_data[single_sales_channel.id][0].id, 'withdraw')} style={{color:'#FF5247'}}>{'Withdraw'}</span> :(
										subscribed_sales_channels.find(o => o.id === single_sales_channel.id) ? 
										<span onClick={() => single_sales_channel.name === 'Infurnia Supply Network' ? "" : subscription_action(single_sales_channel.id, null, 'unsubscribe')} style={{color:'#FF5247', opacity: single_sales_channel.name === 'Infurnia Supply Network' ? '0.5' : '1'}}>{'Unsubscribe'}</span>:
										<span onClick={() => subscription_action(single_sales_channel.id, null, 'subscribe')} style={{color:'#0078ff'}}>{'Subscribe'}</span>
									)
							}
							</span>
						</td>
					</tr>:''
			))}
			</tbody>
		</table>
	)

	return (
		<Main_Grid active_tab="Sales" user={user} store_details={store_details} get_store_details={get_store_details}>
			<Row style={{padding:'10px 0px', color:'#22272e'}}>
	        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
	        		Viewing {display_sales_channel && display_sales_channel.length ?display_sales_channel.length:'0'} Sales Channels
	        	</Col>
	        	<Col xs={6} style={{justifyContent:'flex-end', display:'flex', }}>
					<button onClick ={() => open_sales_channel_preferences_dialog()} style={{...styles.add_brand_style, marginRight:'15px'}}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Sales Channel Preferences</span></button>
	        		<button onClick ={() => show_sales_channel_add_dialog()} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Sales Channel</span></button>
	        	</Col>
        	</Row>
	        <Row style={{height:'calc(100% - 90px)'}}>
				<Col style={{height:'100%'}}>
					<Card style={{borderRadius: 0, height:'100%', overflowY:'scroll'}}>
						
						<Nav 
							style={{display:'flex', alignItems:'center'}}
							className="flex-md-row"
							id="tabs-icons-text" 
							pills
							role="tablist"
						>
							<NavItem>
								<NavLink
									aria-selected={tabs === 1}
									style = {{marginLeft:'10px'}}
									className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 1
									})}
									onClick={e => toggleNavs(e, 1)}
									href="#pablo"
									role="tab"
								>
									<span style={{fontSize:'14px'}}>Owned Sales Channels</span>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									aria-selected={tabs === 2}
									className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 2
									})}
									onClick={e => toggleNavs(e, 2)}
									href="#pablo"
									role="tab"
								>
									<span style={{fontSize:'14px'}}>Sales Channel Subscriptions</span>
								</NavLink>
							</NavItem>
						</Nav>
						<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Sales Channel Name' /></div>
						<TabContent activeTab={"tabs" + tabs} style={{display : tabs==1 ? 'block' : 'none'}}>
							<TabPane tabId={'tabs1'} style={{fontSize:'12px'}}>
								<div id='design-paginator'>{display_sales_channel && display_sales_channel.length ? (<Sales_Channel_Table />) : (<Sales_Channel_Filler />)}</div>
							</TabPane>
						</TabContent>

						<TabContent activeTab={"tabs" + tabs} style={{height:'calc(100% - 80px)', display : tabs==2 ? 'block' : 'none'}}>
							<TabPane tabId={'tabs2'} style={{fontSize:'12px'}}>
								<div id='design-paginator'>{display_sales_channel && display_sales_channel.length ? (<Public_Sales_Channel_Table />) : (<Sales_Channel_Filler />)}</div>
							</TabPane>
						</TabContent>
					</Card>
				</Col>
	        </Row>
			<SalesChannelDialogBox open = {open_sales_channel_Dialog} handleClose = {close_sales_channel_Dialog} sales_channel={active_sales_channel} user= {user} fetch_sales_channel_details = {fetch_sales_channel_details} mode = {mode} all_sc_preferences={all_sc_preferences} store_details = {store_details} set_page_loader={set_page_loader} all_users_accounts={all_users_accounts.filter(x => x.role.filter(y => y.id == 'designer').length)} org_bu_view={org_bu_view}/>
			<SalesChannelPreferencesDialogBox open = {show_sales_channel_preferences_dialog} handleClose = {close_sales_channel_preferences_dialog} sales_channel={active_sales_channel} user= {user} fetch_sales_channel_details = {fetch_sales_channel_details} mode = {mode} all_sc_preferences={all_sc_preferences} fetch_all_pref={fetch_all_pref}/>
			<OptionsDialogBox open = {open_options_dialog} handleClose = {close_open_options_dialog} sales_channel={active_sc_for_delete_in_options} user= {user} fetch_sales_channel_details = {fetch_sales_channel_details} />
		</Main_Grid>
	);
}

export default SalesChannel;