import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect } from 'react';
import general_fetch from '../../utils/fetch';
import BrandDialogBox from'./BrandDialogBox'
import {Card, Col, Progress, Table, Row,  TabPane , TabContent, Nav, NavItem, NavLink} from "reactstrap";
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import classnames from "classnames";
import Moment from 'react-moment';

const Brand = ({user, submit_for_delete_confirm, set_active_tab, send_analytics_event, store_details, get_store_details}) => {
	const styles = {
		add_brand_style:{
			backgroundColor: '#007EF4',
			boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
			borderRadius: '3px',
			height:'32px',
			padding:'0px 24px',
			border:'0px',
			color:'white',
		},
		table_header: {
			fontWeight: "bold",
			fontSize: 'small'
		},
    };
	//console.log('BRANDPAGE====>CALLED')
	const [brands, set_brands ] = useState([]);
	const [show_Brand_Dialog_Box, set_show_Brand_Dialog_Box] = useState(false);
	const [active_brand, set_active_brand] = useState({});
	const [search_string , set_search_string] = useState('');
	const [display_brands , set_display_brands] = useState([]);
	const [store_owned_brands, set_store_owned_brands] = useState([]);
	const [global_owned_brands, set_global_owned_brands] = useState([]);
	const [key , set_key] = useState('');

	const [page_size , set_page_size] = useState(30)
	const [fetch_progress , set_fetch_progress] = useState(40)
	const [current_page , set_current_page] = useState(1)
	const [initialized , set_initialized] = useState(false) 
 	const [all_pages , set_all_pages] = useState([])
	const alert = useAlert()
	const [ start_index , set_start_index ] = useState('');
	const [ end_index , set_end_index ] = useState('');
	const [tabs , set_tabs ] = useState(1)
	// const [active_tab, set_active_tab] = useState(0);

	const [deleted_brands, set_deleted_brands] = useState([]);
	const [display_deleted_brands, set_display_deleted_brands] = useState([]);
	
	const fetch_brand_details = async () => {
        try {
            var resp = await general_fetch({ url: 'brand/get'});
			var brand_details= resp && resp.length ? resp.sort(name_sort):[]
            set_brands(brand_details);
            set_display_brands(brand_details);

			var resp_del = await general_fetch({ url: 'brand/get_deleted'});
			var brand_details_del= resp_del && resp_del.length ? resp_del.sort(name_sort):[]
            set_deleted_brands(brand_details_del);
            set_display_deleted_brands(brand_details_del);
            
			
			set_initialized(true);
			console.log('brand del======>',brand_details_del);
        } catch(err) {
            console.log(err);
        }
    }

	// useEffect(() => {
	// 	console.log('brand======>',brands);
	// }
	// , [brands]);

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	useEffect(() => {
		if(!brands.length){
			fetch_brand_details();
		}
	}
	, [user]);


	const remove_brand = async (id) => {
		try {
			var identifiers = JSON.stringify({id:id})
			var body ={identifiers} 
			var response = await general_fetch({ url: 'brand/remove', body});
			alert.success('Brand Deleted Successfully')
			fetch_brand_details();
		} catch(err) { 
			console.log(err);
		}
	}

	const open_brand_update_dialog = (brand) => {
		set_show_Brand_Dialog_Box(true);
		set_active_brand(brand);
		set_key('update');
	}

	const close_Brand_Dialog_Box = () => {
		set_show_Brand_Dialog_Box(false);
		set_active_brand({});
		set_key('');
	}

	const open_brand_add_dialog = () => {
		set_show_Brand_Dialog_Box(true);
		set_key('add');
	}
	
	const filter_brand = () => 
	{
		var filtered_brands = [...brands];
		var filtered_deleted_brands = [...deleted_brands];
		var lower_search_string = search_string ? search_string.toLowerCase():'';
		if(lower_search_string){
			filtered_brands = filtered_brands.filter( brand => brand.name.toLowerCase().includes(lower_search_string))
			filtered_deleted_brands = filtered_deleted_brands.filter( brand => brand.name.toLowerCase().includes(lower_search_string))
		}
		set_display_brands(filtered_brands)
		set_display_deleted_brands(filtered_deleted_brands)
	}

	useEffect(() => {
			filter_brand();
	}, [search_string]);

	useEffect(() => {
		if(display_brands && user){
			set_global_owned_brands(display_brands.filter(x => x.store_id != user.store_id))
			set_store_owned_brands(display_brands.filter(x => x.store_id == user.store_id))
		}
	}, [display_brands]);

	useEffect(() => {
		set_active_tab('Brands')
		send_analytics_event('auto', 'Brand', 'Brand', 1, window.location.href)
	}, []);

	useEffect(() => {
		if (initialized) {
			var pages = Number(Math.ceil(display_brands.length / page_size));
			//console.log('all pages --> ', pages, 'projects length --<>', display_brands.length)
			var tmp = Array.apply('', {length: pages + 1}).map(Number.call, Number)
			tmp.splice(0, 1);
			//console.log('tmp --<>', tmp)
			set_all_pages(tmp);
			//console.log('tmp spliced--<>', tmp)
			set_current_page(1);
		}
	}, [ page_size, display_brands ])

	const Brand_Filler = () => (
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
				<tr>
					{ initialized ? (
						<th scope="col"><span style={styles.table_header}><b>No brands to list  </b></span><br/> Try adjusting filters...<br /> &nbsp;</th>
					):(
						<th scope="col"><span style={styles.table_header}><b>Fetching all brands </b></span><br/> <br /> Please wait...<br /> <br />
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;</th>
					)}
				</tr>
			</thead>
		</Table>
	);

	const Brand_Table = ({display_brands_selected, is_deleted}) => (
		<table className="text-left flex_property table_style">
			<thead style={{width:'100%'}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',width:'100%'}}>
				<th className='th_style flex_property' style={{flex:'10'}}><span>Brand Name</span></th>
				{/* <th className='th_style flex_property' style={{border:0, flex:'3'}}><span>No. of SKUs</span></th> */}
				<th className='th_style' style={{ flex:'20'}}><span></span></th>
				{is_deleted ? '' : <th className='th_style flex_property' style={{border:0, flex:'2', justifyContent:'center', paddingRight:'24px'}}><span>{"Actions"}</span></th>}
				{is_deleted ? <th className='th_style flex_property' style={{border:0, flex:'8'}}><span>{'Deleted At'}</span></th> : ''}
				{/* <th className='th_style' style={{border:0, flex:'1', paddingRight:"20px"}}><span></span></th> */}
			</tr>
			</thead>
			<tbody style={{display:"block",width:'100%'}}>
			{
				display_brands_selected && display_brands_selected.length && display_brands_selected.map((brand, index) => (
				<tr key={index} style={{display:(index+1>=start_index && index<end_index)?'flex':'none'}} className='tr_style display_onhover'>
					
					<td className='td_style flex_property' style={{flex:'10'}}><span className='table_text_overflow'>{brand.name}</span></td>
					{/* <td className='td_style flex_property' style={{justifyContent:'flex-end', flex:'3'}}>0</td> */}
					<td className='td_style' style={{flex:'20'}}></td>
					{
						is_deleted ?
							<td className='td_style' style={{flex:'8'}}><Moment format="DD/MM/YYYY">{brand.deleted_at}</Moment></td>
							: ''
					}
					{
						!is_deleted ?
							<td className='td_style flex_property x1' style={{ justifyContent:'center', flex:'2'}}>
								{
									user.store_id ==brand.store_id && !brand.deleted_at ?(
										<span style={{marginRight:'28px'}} className='hide'><i onClick ={() => open_brand_update_dialog(brand)} className='fa fa-edit'/></span>
									):''
								}
							{/* </td> */}
							{/* <td className='td_style flex_property x1' style={{ justifyContent:'flex-end', flex:'1', marginRight:"36px"}}> */}
								{
									user.store_id ==brand.store_id && !brand.deleted_at ?(
										<span style={{marginRight:'28px'}} className='hide'>
											<i 
												onClick={()=>submit_for_delete_confirm(()=>remove_brand(brand.id), ()=>{}, 'Brand will be deleted permanently.')}
												className='fa fa-trash'
											/>
										</span>
									):''
								}
							</td>
						:''
					}
				</tr>
			))}
			</tbody>
		</table>
	);

	return (
		<Main_Grid active_tab="Brands" user={user} store_details={store_details} get_store_details={get_store_details}>
			<Row style={{padding:'10px 0px', color:'#22272e'}}>
	        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
	        		Viewing {display_brands && display_brands.length ?display_brands.length:'0'} Brands
	        	</Col>
	        	<Col xs={6} style={{textAlign:'right'}}>
	        		<button onClick={open_brand_add_dialog} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Brand</span></button>
	        	</Col>
        	</Row>
	        <Row style={{height:'calc(100% - 90px)'}}>
		        <Col style={{height:'100%'}}>
		        	<Card style={{borderRadius: 0, height:'100%'}}>
						<Nav 
							style={{display:'flex', alignItems:'center'}}
							className="flex-md-row"
							id="tabs-icons-text" 
							pills
							role="tablist"
						>
							<NavItem>
								<NavLink
									aria-selected={tabs === 1}
									style = {{marginLeft:'10px'}}
									className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 1
									})}
									onClick={e => {toggleNavs(e, 1)/*; set_active_tab(0)*/}}
									href="#pablo"
									role="tab"
								>
									<span style={{fontSize:'14px'}}>Org Brands</span>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									aria-selected={tabs === 2}
									className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 2
									})}
									onClick={e => {toggleNavs(e, 2)/*; set_active_tab(1)*/}}
									href="#pablo"
									role="tab"
								>
									<span style={{fontSize:'14px'}}>Global Brands</span>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									aria-selected={tabs === 3}
									className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 3
									})}
									onClick={e => {toggleNavs(e, 3)/*; set_active_tab(1)*/}}
									href="#pablo"
									role="tab"
								>
									<span style={{fontSize:'14px'}}>Deleted Brands</span>
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={"tabs" + tabs} id='design-paginator' style={{height:'calc(100% - 40px)', display: tabs==1 ? 'block' : 'none'}}>
							<TabPane tabId={'tabs1'} style={{fontSize:'12px'}}>
								<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string} onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Brand Name' /></div>
								<div >{store_owned_brands && store_owned_brands.length ? (<Brand_Table display_brands_selected={store_owned_brands}/>) : (<Brand_Filler />)}</div>
							</TabPane>
						</TabContent>
						<TabContent activeTab={"tabs" + tabs} id='design-paginator' style={{height:'calc(100% - 40px)', display: tabs==2 ? 'block' : 'none'}}>
							<TabPane tabId={'tabs2'} style={{fontSize:'12px'}}>
								<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string} onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Brand Name' /></div>
								<div >{global_owned_brands && global_owned_brands.length ? (<Brand_Table display_brands_selected={global_owned_brands}/>) : (<Brand_Filler />)}</div>
							</TabPane>
						</TabContent>
						<TabContent activeTab={"tabs" + tabs} id='design-paginator' style={{height:'calc(100% - 40px)', display: tabs==3 ? 'block' : 'none'}}>
							<TabPane tabId={'tabs3'} style={{fontSize:'12px'}}>
								<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string} onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Brand Name' /></div>
								<div >{display_deleted_brands && display_deleted_brands.length ? (<Brand_Table display_brands_selected={display_deleted_brands} is_deleted/>) : (<Brand_Filler />)}</div>
							</TabPane>
						</TabContent>
					</Card>
		        </Col>
	        </Row>
	        <Row style={{height:'3px'}}>
		        <Col>
		        </Col>
	        </Row>
	        <Row>
		        <Col>	
					<Pegasus_Paginator 
						id='design-paginator'
						end_index={end_index}
						set_end_index={set_end_index}
						start_index={start_index}
						set_start_index={set_start_index}
						all_projects={tabs == 1 ? store_owned_brands && store_owned_brands.length ? store_owned_brands.length : 0 : global_owned_brands && global_owned_brands.length ? global_owned_brands.length : 0 }
					/>
		        </Col>
	        </Row>
			<BrandDialogBox open = {show_Brand_Dialog_Box} handleClose = {close_Brand_Dialog_Box} brand={active_brand} user= {user} fetch_brand_details = {fetch_brand_details} mode = {key}/>
		</Main_Grid>
	);
}	


export default Brand;

