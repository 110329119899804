export const exclusion_list_options = [{
	name: 'shutter and drawer fascia',
	id: 'shutter_and_drawer_fascia'
},{
    id: 'handle',
    name: 'Handle',
},{
    id: 'appliance_hardware',
    name: 'Appliance',
},{
    id: 'basket_hardware',
    name: 'Basket',
},{
    id: 'basket_collection_hardware',
    name: 'Basket Collection',
},{
    id: 'cabinet_accessories_accessory',
    name: 'Cabinet Accessories',
},{
    id: 'cabinet_finish_finish',
    name: 'Cabinet Finish',
},{
    id: 'chimney_accessory',
    name: 'Chimney',
},{
    id: 'corner_unit_systems_hardware',
    name: 'Corner Unit Systems',
},{
    id: 'drawer_inserts_hardware',
    name: 'Drawer inserts',
},{
    id: 'drawer_systems_hardware',
    name: 'Drawer System',
},{
    id: 'drawer_system_collection_hardware',
    name: 'Drawer System Collection',
},{
    id: 'edge_band_glue_hardware',
    name: 'Edge Band Glue',
},{
    id: 'edge_band_hardware',
    name: 'Edge Band',
},{
    id: 'fittings_hardware',
    name: 'Fittings',
},{
    id: 'hanger_rod_hardware',
    name: 'Hanger Rod',
},{
    id: 'hinge_hardware',
    name: 'Hinge',
},{
    id: 'hob_accessory',
    name: 'Hob',
},{
    id: 'light_accessory',
    name: 'Light',
},{
    id: 'mirror_hardware',
    name: 'Mirror',
},{
    id: 'other_accessory_accessory',
    name: 'Other Accessory',
},{
    id: 'other_appliance_accessory',
    name: 'Other Appliance',
},{
    id: 'other_finish_finish',
    name: 'Other Finish',
},{
    id: 'other_hardware_hardware',
    name: 'Other Hardware',
},{
    id: 'panel_core_hardware',
    name: 'Panel Core',
},{
    id: 'panel_glue_hardware',
    name: 'Panel Glue Panel',
},{
    id: 'panel_hardware',
    name: 'Panel',
},{
    id: 'profile_and_gola_handles_hardware',
    name: 'Profile and Gola Handles',
},{
    id: 'shutter_hardware',
    name: 'Shutter',
},{
    id: 'sink_hardware',
    name: 'Sink',
},{
    id: 'tap_hardware',
    name: 'Tap',
}, {
	id: 'strip_light_accessory',
	name: 'Strip Light'
}, {
	id: 'drawer_set_furniture',
	name: 'Drawer Set'
}, {
	id: 'drawer_channel_hardware',
	name: 'Drawer Channel'
}];