import React , { useState , useEffect, useContext }from 'react';
import general_fetch from '../../utils/fetch';
import custom_sort from '../../utils/custom_sort';
import split_cat_type from '../../utils/split_cat_type';
import {Modal, CardImg, Alert, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import { useAlert } from 'react-alert'
import Multiselect from 'multiselect-react-dropdown';
import AMKeyValuePair_source from '../../components/AMKeyValuePair_source';
import AMLinked_sku_structure from '../../components/AMLinked_sku_structure';
import EscapeWarning from '../../components/EscapeWarning';
import { UserContext } from '../../components/Contexts/UserContext';
//import AlertBox from "../../components/AlertBox/";

const styles = {
	error_style: {
		color: "red",
		fontSize: 'small'
	},

};

const AMRulesDialogBox = ({open , handleClose , active_rule, fetch_mf_rules_details , mode, set_page_loader, all_brands, all_division_tree_data, all_category, all_sub_category, all_edge_band_sku, all_edge_band_glue_sku, all_panel_glue_sku,all_sales_ch, last_item_order, tags, component_options_json, component_options_json_applicable, shelf_type_values }) => {

	const [rule_name , set_rule_name] = useState("");
	const [rule_description , set_rule_description] = useState("");
	const [rule_activation , set_rule_activation] = useState(false);

	const [source_condition_combination_logic ,set_source_condition_combination_logic ] =useState('and')
	const [dest_condition_combination_logic ,set_dest_condition_combination_logic ] =useState('and')
	const [applicability_combination_logic ,set_applicability_combination_logic ] =useState('and');

	const [item_json_condition , set_item_json_condition] = useState([]);
	const [item_json_action , set_item_json_action] = useState([]);
	const [item_json_applicable , set_item_json_applicable] = useState([]);
	
	const [rule_word ,set_rule_word ] =useState('')
	const [source_rule_word ,set_source_rule_word ] =useState('')
	const [dest_rule_word ,set_dest_rule_word ] =useState('')
	const [active_rule_copy ,set_active_rule_copy ] =useState()
	

	const [mf_rule_step, set_mf_rule_step] = useState('1');
	const [is_back_button, set_is_back_button] = useState(false);

	const [discard_changes, set_discard_changes] = useState(false)
	const [show_escape_warning, set_show_escape_warning] = useState(false)

	const [is_modified, set_is_modified] = useState(false);

	const alert = useAlert()
	const user = useContext(UserContext)

	useEffect(() => {
		console.log("rule activation", rule_activation);
	}, [rule_activation]);


	const transform_to_usable = (item_array) => {
		if(item_array && item_array.length){
			var x_temp = item_array.map(x_item =>{
			return({
				id:x_item && x_item.id?x_item.id:null,
				value:(x_item.value && (Array.isArray(x_item.value) && x_item.value.length))? (x_item.property && x_item.property[0] && ['brand'].includes(x_item.property[0].id)?JSON.stringify(x_item.value.map(x => x.id)) :x_item.value[0].id): (x_item.property[0].type==='number'?Number(x_item.value):x_item.value),
				min_value: (x_item.min_value || x_item.min_value == 0)?Number(x_item.min_value):'',
				max_value: (x_item.max_value || x_item.max_value == 0)?Number(x_item.max_value):'',
				type: x_item.type, 
				operator:x_item.operator, 
				value_combination_logic:x_item.value_combination_logic, 
				sku:x_item.property && x_item.property.length && ['sku','ply_sku','edgeband_sku','finish_sku'].includes(x_item.property[0].id) && x_item.type=='absolute' && x_item.sku && x_item.sku.length? x_item.sku.map(x => x.id):null,
				tag:x_item.property && x_item.property.length && x_item.property[0].id == 'tag' && x_item.tag && x_item.tag.length? x_item.tag.map(x => x.id):null,
				sales_channel: x_item.sales_channel && x_item.sales_channel.length? x_item.sales_channel.map(x => x.id):null,
				source_filter:(x_item.component && x_item.component.length) ? ({
					id:x_item.source_filter && x_item.source_filter.id?x_item.source_filter.id:null,
					type:'source',
					component:x_item.component && x_item.component.length? x_item.component[0].id:[],
					sub_component:x_item.subcomponent && x_item.subcomponent.length? x_item.subcomponent[0].id:[],
					property:x_item.property && x_item.property.length? x_item.property[0].id:[],
				}) : null,
				destination_filter: (x_item.relativecomponent && x_item.relativecomponent.length) ? ({
					id:x_item.destination_filter && x_item.destination_filter.id?x_item.destination_filter.id:null,
					type: 'destination',
					component:x_item.relativecomponent && x_item.relativecomponent.length? x_item.relativecomponent[0].id:[],
					sub_component:x_item.relativesubcomponent && x_item.relativesubcomponent.length? x_item.relativesubcomponent[0].id:[],
					property:x_item.relativeproperty && x_item.relativeproperty.length? x_item.relativeproperty[0].id:[],
				}) : null,
			})})

			for(let i = 0; i < x_temp.length; i++){
				if(x_temp[i].source_filter){
					if(x_temp[i].source_filter.property == 'tag'){
						console.log('transform_to_usablee tag', x_temp, item_array)
						if(!(x_temp[i].tag && x_temp[i].tag.length && typeof x_temp[i].tag[0] === 'string')) return -1
					}else if(['sku','ply_sku','edgeband_sku','finish_sku'].includes(x_temp[i].source_filter.property) && x_temp[i].type == 'absolute'){
						console.log('transform_to_usablee sku', x_temp, item_array)
						if(!(x_temp[i].sku && x_temp[i].sku.length && typeof x_temp[i].sku[0] === 'string')) return -1
					}else if(x_temp[i].source_filter.property == 'height' || x_temp[i].source_filter.property == 'width' || x_temp[i].source_filter.property == 'depth'){
						console.log('transform_to_usablee height')
						if(!(x_temp[i].value >=0 && typeof x_temp[i].value === 'number')) return -1
					}else if(x_temp[i].source_filter.property == 'sales_channel'){
						console.log('transform_to_usablee sales_channel')
						if(!(x_temp[i].sales_channel && x_temp[i].sales_channel.length && typeof x_temp[i].sales_channel[0] === 'string')) return -1
					}
				}
			} 

			console.log('transform_to_usable', x_temp)
			return x_temp;
			
		}else{
			return []
		}
	} 

	const onclick_add_new_rule = async() => {
		// if(!is_valid_add_key(item_json_condition) && !is_valid_add_key(item_json_action)){
		// 	alert.error("Empty or Duplicate key values not allowed.")
		// }else{
			if(rule_name && rule_description && item_json_action && item_json_action.length && item_json_action[0].property && item_json_action[0].property.length && item_json_applicable && item_json_applicable.length && item_json_applicable[0].property && item_json_applicable[0].property.length){
				try {
					var temp_order = last_item_order+1024

					let applicability = transform_to_usable(item_json_applicable)
					let conditions = transform_to_usable(item_json_condition)
					let actions = transform_to_usable(item_json_action)
					if(applicability == -1 || conditions == -1 || actions == -1) 
					{
						alert.error('Please fill all required details: name, description, applicable and action fields ')
						return
					}

					var body = {
						applicability_combination_logic,
						applicability:applicability,
						conditions:conditions, 
						actions:actions , 
						condition_combination_logic:source_condition_combination_logic, 
						name:rule_name, 
						description:rule_description, 
						active:rule_activation, 
						type:'design_automation',
						action_combination_logic: dest_condition_combination_logic,
						order: temp_order,
						business_unit_id:user.current_business_unit_id
					};
					var response = await general_fetch({ url: 'automation_rule/create', body});	
					onclick_handleclose()
					alert.success('Rule created Successfully !')
					fetch_mf_rules_details()
				} catch(err) {
					console.log(err);
					alert.error("Please check input values or API failed.")
				}
			}else{
				alert.error('Please fill all required details: name, applicable and action fields ')
			}
		// }	
	}

	const onclick_edit_rule = async() => {
		if(!is_valid_add_key(item_json_condition) && !is_valid_add_key(item_json_action)){
			alert.error("Empty or Duplicate key values not allowed.")
		}else{
			if(active_rule && rule_name && rule_description && item_json_action && item_json_action.length && item_json_action[0].property && item_json_action[0].property.length && item_json_applicable && item_json_applicable.length && item_json_applicable[0].property && item_json_applicable[0].property.length){
				try {

					let applicability = transform_to_usable(item_json_applicable)
					let conditions = transform_to_usable(item_json_condition)
					let actions = transform_to_usable(item_json_action)
					if(applicability == -1 || conditions == -1 || actions == -1) 
					{
						alert.error('Please fill all required details: name, description, applicable and action fields ')
						return
					}

					var body = {
						id:active_rule.id?active_rule.id:'',
						applicability_combination_logic,
						applicability:applicability,
						conditions:conditions, 
						actions: actions, 
						condition_combination_logic:source_condition_combination_logic, 
						name:rule_name, 
						description:rule_description, 
						active:rule_activation, 
						type:'design_automation',
						action_combination_logic: dest_condition_combination_logic
					};
					var response = await general_fetch({ url: 'automation_rule/update', body});	
					onclick_handleclose()
					alert.success('Rule Updated Successfully !')
					fetch_mf_rules_details()
				} catch(err) {
					console.log(err);
					alert.error("Please check input values or APi failed.")
				}
			}else{
				alert.error('Please fill all required details: name, description, applicable and action fields ')
			}	
		}
	}
	
	const is_valid_add_key = (item_json) => {
		if(item_json && item_json.length){
			for(var i=0;i<item_json.length;i++){
				if(item_json[i].property===[]){
					return false;
				}
			}
			return true;
		}else{
			return true;
		}
	}

	useEffect(() => {
		if(active_rule){
			var x_applicable_list, x_condition_list, x_actions_list = [],
			linked_sku_actions = active_rule.actions && active_rule.actions.length?active_rule.actions.map((item) => {
				return{
					sku_id:item.sku[0],
					value:Number(item.value)
				}
			}):[]
			
			x_applicable_list = active_rule.applicability&& active_rule.applicability.length?active_rule.applicability.map(x_item => {console.log("transformedd", x_item); return{
				...x_item, 
				component:x_item.source_filter ?x_item.source_filter.component:[], 
				subcomponent:x_item.source_filter ?x_item.source_filter.sub_component:[], 
				property:x_item.source_filter ?x_item.source_filter.property:[]
			}}):[]
			x_condition_list = active_rule.conditions&& active_rule.conditions.length?active_rule.conditions.map(x_item => {return{
				...x_item, 
				component:x_item.source_filter ?x_item.source_filter.component:[], 
				subcomponent:x_item.source_filter ?x_item.source_filter.sub_component:[], 
				property:x_item.source_filter ?x_item.source_filter.property:[],

				relativecomponent:x_item.destination_filter ?x_item.destination_filter.component:[], 
				relativesubcomponent:x_item.destination_filter ?x_item.destination_filter.sub_component:[], 
				relativeproperty:x_item.destination_filter ?x_item.destination_filter.property:[]
			}}):[]
			x_actions_list = active_rule.actions&& active_rule.actions.length?active_rule.actions.map(x_item => {return{
				...x_item, 
				component:x_item.source_filter ?x_item.source_filter.component:[], 
				subcomponent:x_item.source_filter ?x_item.source_filter.sub_component:[], 
				property:x_item.source_filter ?x_item.source_filter.property:[],

				relativecomponent:x_item.destination_filter ?x_item.destination_filter.component:[], 
				relativesubcomponent:x_item.destination_filter ?x_item.destination_filter.sub_component:[], 
				relativeproperty:x_item.destination_filter ?x_item.destination_filter.property:[]
			}}):[]

			set_item_json_condition(x_condition_list)
			set_item_json_action(x_actions_list)
			set_item_json_applicable(x_applicable_list)
			// console.log("transformed", x_applicable_list, active_rule.applicability, x_condition_list, x_actions_list)

			set_rule_name(active_rule.name)
			set_rule_description(active_rule.description)
			set_rule_activation(active_rule.active ? true : false)
			// set_condition_combination_logic(active_rule.condition_combination_logic)
			set_source_condition_combination_logic(active_rule.condition_combination_logic)
			set_dest_condition_combination_logic(active_rule.action_combination_logic)
			set_applicability_combination_logic(active_rule.applicability_combination_logic)
			set_active_rule_copy(JSON.parse(JSON.stringify(active_rule)))
		}
	},[active_rule])
	
	const add_new_item_json = (x_item_json,type) => {
		if(is_valid_add_key(x_item_json)){
            let a = [...x_item_json];
			a.push({type:'absolute',component:[], subcomponent:[], property:[] ,relativecomponent:[], relativesubcomponent:[], relativeproperty:[], operator:'equal', division:[], category:[], sub_cat:[], group:[], sku:[], range_max:'', range_min:'', value:'', tag:[], value_combination_logic:'and'})
			if(type==='condition'){
				set_item_json_condition(a)
			}else if(type==='action'){
				set_item_json_action(a)
			}else{
				set_item_json_applicable(a)
			}
        }else{
            alert.error("Empty or Duplicate key values not allowed.")
        }	
	}

    useEffect(() =>{
		if(item_json_condition && !item_json_condition.length){
			add_new_item_json(item_json_condition,'condition')
		}
	},[item_json_condition])

    useEffect(() =>{
		if(item_json_action && !item_json_action.length){
			add_new_item_json(item_json_action,'action')
		}
	},[item_json_action])

	useEffect(() =>{
		if(item_json_applicable && !item_json_applicable.length){
			add_new_item_json(item_json_applicable,'applicable')			
		}
	},[item_json_applicable])

	useEffect(() => {
		console.log("discard", discard_changes)
		if(discard_changes == true){
			onclick_handleclose()
			set_discard_changes(false)
		}
	},[discard_changes])

	const onclick_handleclose = () => {
		fetch_mf_rules_details()
		handleClose()
		set_item_json_condition([])
		set_item_json_action([])
		set_item_json_applicable([])
		set_rule_description('')
		set_rule_name()
		set_mf_rule_step('1')
		set_is_back_button(false)
		set_rule_activation(false)
		set_is_modified(false)
	}

	const show_escape_warning_dialog = () => {
		set_show_escape_warning(true)
	}

	const close_escape_warning_dialog = () => {
		set_show_escape_warning(false)
	}

	const on_change_handler = () => {
		set_is_modified(true)
	}

	const values_text = (values_Array, logic, type) => {
		var x_text=''
		values_Array && Array.isArray(values_Array) && values_Array.length ? 
			values_Array.map((x_id,idx) => {	
				x_text = type.id==='tag'?
					( x_text.concat(values_Array.length-1!=idx?x_id.tag.toLowerCase()+' '+logic+' '+' ':x_id.tag.toLowerCase()))
					:
					( x_text.concat(values_Array.length-1!=idx?x_id.name.toLowerCase()+' '+logic+' '+' ':x_id.name.toLowerCase()))
			})
		:
			x_text = (logic+ ' ' + values_Array);
		return x_text;
	}

	const get_all_data_fom_id = (all_val_array, item) => {
		var prop_val_ids = item.property_value && item.property_value.length ?item.property_value.map(x => x.id):[]
		var o_list = all_val_array.filter(x=>prop_val_ids.includes(x.id))
		return o_list;
	}

	const return_word = (value) => {
		if(value==='lessequal'){
			return 'less than equal to'
		}else if(value==='greaterequal') {
			return 'greater than equal to'
		}else if(value==='less') {
			return 'less than'
		}else if(value==='greater') {
			return 'greater than' 
		}else{
			return 'equal to'
		}
	}

  	return (
		<Modal className="Margin_64px_top" size="xl" isOpen={open} toggle={() => {mode=='view'?onclick_handleclose():show_escape_warning_dialog()}}>
			<EscapeWarning open = {show_escape_warning} set_discard_changes={set_discard_changes} handleClose={close_escape_warning_dialog}/>
			<Card>
				<CardHeader className='global_modal_header'>
						<div style={{width:'100%', display:'flex'}}>
							<div>{(mode=='add') ? "Create a Rule" : "Design Automation Rule -"+'\xa0'}</div>
							<div title={mode=='add' ? '' : active_rule ?active_rule.name:''} className='text_ellipsis' style={{width:'calc(100% - 200px)'}}>{mode=='add' ? '' : active_rule ?active_rule.name:''}</div>
						</div>
						<i onClick={() => {mode=='view'?onclick_handleclose():show_escape_warning_dialog()}} style={{cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>
				{/* <Row style={{margin:0, backgroundColor:'white', width:'100%'}}>
					<Col style={{padding:0}}>
						<div style={{height:'40px', borderBottom:'1px solid rgba(0, 0, 0, 0.125)', display:"flex",alignItems:'center', justifyContent:'center'}}>
							<Row style={{width:'100%'}}>
								<Col xs={6}>
									<div style={{ color:'#425A70', display:"flex",alignItems:'center', justifyContent:'flex-end'}}>
										<span className={mf_rule_step==='1'?'tab_selected_step':'tab_completed_step'} style={{justifyContent:"center", alignItems:'center',height:'25px', width:"25px", borderRadius:'50%', display:'flex', marginRight:'3px'}}>1</span>
										<span>Creating Manufacturing Rules</span>
									</div>
								</Col>
								<Col xs={6}>
									<div style={{ color:'#425A70', display:"flex",alignItems:'center', justifyContent:'flex-start'}}>
										<span className={mf_rule_step==='1'?'tab_disabled_step':'tab_selected_step'} style={{justifyContent:"center",height:'25px', width:"25px", borderRadius:'50%', display:'flex', alignItems:'center', marginRight:'3px'}}>2</span>
										<span>Preview Rules</span>
									</div>
								</Col>		
							</Row>
						</div>
					</Col>
				</Row> */}
				{
					mf_rule_step==='1'?
					<CardBody className='global_modal_body'>
						<Row style={{ marginBottom:'20px', ...(mode=='view' ? { pointerEvents: 'none' } : {}) }}>
							<Col xs={1}></Col>
							<Col xs={10}>
								<Row style={{marginBottom:'20px'}}>
									<Col xs={5}>
										<div>
											<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Name *</div>
											<Input id='am_rule_name' style={{width:'100%',height:'32px', fontSize:'12px', backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder="Rule Name" type="text" value={rule_name} onChange={e => {set_rule_name(e.target.value); on_change_handler()}}/>
										</div>
									</Col>	
									<Col xs={5}>
										<div>
											<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Description*</div>
											<Input id='am_rule_description' style={{width:'100%',height:'32px', fontSize:'12px', backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder="Rule Description" type="text" value={rule_description} onChange={e => {set_rule_description(e.target.value); on_change_handler()}}/>
										</div>
									</Col>
									<Col xs={2}>
										<div>
											<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Activation*</div>
											<Input style={{height:'100%',overflowY:'auto',fontSize:'12px',}} type="select"
												value={rule_activation}
												onChange={(e) => {set_rule_activation(e.target.value == 'true'); on_change_handler()}}
											>
												<option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} value='true'>True</option>
												<option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} value='false'>False</option>
											</Input>
										</div>
									</Col>	
								</Row>
								
								<Row style={{marginBottom:'20px'}}>
									<Col>
										<div>
											<div style={{borderBottom:'1px solid #DDDDDD', width:'100%'}}></div>
										</div>
									</Col>	
								</Row>
								{/* 1st layer */}
								<Row style={{marginBottom:'15px'}}>
									<Col>
										<div style={{fontSize:'18px', color:'black'}}>Applicable To*</div>
									</Col>
								</Row>
								{
									item_json_applicable ?item_json_applicable.map((single_item,idx) => 
										<AMKeyValuePair_source 
											all_brands={all_brands} 
											component_options_json={component_options_json_applicable} 
											open={open} 
											key={idx} 
											position_type='applicable' 
											item_json={item_json_applicable} 
											idx={idx} 
											single_item_json={single_item} 
											set_item_json={set_item_json_applicable} 
											mode={mode} 
											all_division_tree_data={all_division_tree_data} 
											tags={tags} 
											all_category={all_category && all_category.length?all_category:[]} 
											all_sub_category={all_sub_category && all_sub_category.length?all_sub_category:[]} 
											all_sales_ch={all_sales_ch} 
											set_combination_logic={set_applicability_combination_logic} 
											combination_logic={applicability_combination_logic} 
											is_back_button={is_back_button}
											on_change_handler={on_change_handler}
										/>
									
									):''
								}
								<Row style={{margin:0}}>
									<Col style={{textAlign:'left', paddingBottom:'15px', paddingLeft:0}}>
										<span onClick={() => add_new_item_json(item_json_applicable,'applicable')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>+ Add Applicability</span>
									</Col>
								</Row>
								{/* 2nd layer */}
								<Row style={{margin:'15px 0px'}}>
									<Col style={{padding:0}}>
										<div style={{fontSize:'18px', color:'black'}}>If the following criteria is satisfied:</div>
									</Col>
								</Row>
								{
									item_json_condition ?item_json_condition.map((single_item,idx) => 
										<AMKeyValuePair_source
											all_brands={all_brands}
											component_options_json={component_options_json}
											open={open}
											key={single_item.id ? single_item.id : idx} position_type='condition'
											item_json={item_json_condition}
											idx={idx}
											single_item_json={single_item}
											set_item_json={set_item_json_condition}
											mode={mode}
											all_division_tree_data={all_division_tree_data}
											tags={tags}
											all_category={all_category && all_category.length ? all_category : []} all_sub_category={all_sub_category && all_sub_category.length ? all_sub_category : []}
											all_sales_ch={all_sales_ch}
											set_combination_logic={set_source_condition_combination_logic}
											combination_logic={source_condition_combination_logic}
											is_back_button={is_back_button}
											shelf_type_values={shelf_type_values}
											on_change_handler={on_change_handler}
										/>
									
									):''
								}
								<Row style={{margin:0}}>
								{
									mode=='view'?'':
									<Col style={{textAlign:'left', paddingBottom:'15px', paddingLeft:0}}>
										<span onClick={() => add_new_item_json(item_json_condition,'condition')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>+ Add Condition</span>
									</Col>
								}
								</Row>
								{/* 3rd layer */}
								<Row style={{marginBottom:'15px'}}>
									<Col>
										<div style={{fontSize:'18px', color:'#212529'}}>Then do the following action*:</div>
									</Col>
								</Row>
								{
									item_json_action ?item_json_action.map((single_item,idx) => 
										<AMKeyValuePair_source
										all_brands={all_brands}
										component_options_json={component_options_json}
										open={open}
										key={single_item.id?single_item.id:idx}
										position_type='action'
										item_json={item_json_action}
										idx={idx}
										single_item_json={single_item}
										set_item_json={set_item_json_action}
										mode={mode}
										all_division_tree_data={all_division_tree_data}
										tags={tags}
										all_category={all_category && all_category.length?all_category:[]}
										all_sub_category={all_sub_category && all_sub_category.length?all_sub_category:[]}
										all_sales_ch={all_sales_ch}
										set_condition_combination_logic={set_source_condition_combination_logic}
										condition_combination_logic={source_condition_combination_logic}
										is_back_button={is_back_button}
										shelf_type_values={shelf_type_values}
										on_change_handler={on_change_handler}
									/>
									
									):''
								}
								<Row style={{margin:0}}>
								{
									mode=='view'?'':
									<Col style={{textAlign:'left', paddingBottom:'10px', paddingLeft:0}}>
										<span id='add_condition_dest' onClick={() => add_new_item_json(item_json_action,'action')}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>+ Add Action</span>
									</Col>
								}
								</Row>
							</Col>

							<Col xs={1}></Col>
						</Row>
					</CardBody>
					:
					<CardBody className='global_modal_body'>
						<Row style={{ marginBottom:'20px', ...(mode=='view' ? { pointerEvents: 'none' } : {}) }}>
							<Col xs={1}></Col>
							<Col xs={10}>
								<Row style={{marginBottom:'20px'}}>
									<Col xs={5}>
										<div>
											<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Name</div>
											<div style={{fontSize:'14px', letterSpacing: '-0.04px', lineHeight: '22px'}}>{rule_name}</div>
										</div>
									</Col>	
									<Col xs={5}>
										<div>
											<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Description</div>
											<div style={{fontSize:'14px', letterSpacing: '-0.04px', lineHeight: '22px'}}>{rule_description}</div>
										</div>
									</Col>	
									<Col xs={2}>
										<div>
											<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Activation *</div>
											<div style={{fontSize:'14px', letterSpacing: '-0.04px', lineHeight: '22px'}}>{rule_activation}</div>
										</div>
									</Col>		
								</Row>
								<Row style={{marginBottom:'20px'}}>
									<Col>
										<div>
											<div style={{borderBottom:'1px solid #DDDDDD', width:'100%'}}></div>
										</div>
									</Col>	
								</Row>
								<Row style={{marginBottom:'15px'}}>
									<Col>
										<div style={{fontSize:'18px', color:'black'}}>If the following criteria is satisfied:</div>
									</Col>
								</Row>
								
								<Row style={{margin:'0px', backgroundColor:'white'}}>
									<Col style={{padding:'10px 30px', fontSize:'16px', backgroundColor:'white'}}>
										{
											item_json_condition && item_json_condition.length && item_json_condition[0].property_value?item_json_condition.map((item, idx) =>(
												<div key={idx} style={{padding:'10px 0px', display:'flex'}}>
													<div style={{flex:1}}>
														<span style={{marginRight:'20px'}}>{idx===0?'Condition '+(idx+1):'\nCondition '+(idx+1)}</span> 
													</div>
													<div style={{flex:4}}>
														{idx===0?'':<span style={{marginRight:'5px'}}>{source_condition_combination_logic}</span>}
														<span style={{marginRight:'5px'}}>{idx===0?'For':'for'}</span>
														<b>{item.subcomponent && item.subcomponent.length ?item.subcomponent[0].type:''}</b>
														<span style={{marginRight:'5px'}}>,</span>
														<b style={{marginRight:'5px'}}>{item.property_name && item.property_name.length?item.property_name[0].name:''}</b> 
														<span style={{marginRight:'5px'}}>{['tag','sales_channel'].includes(item.property_name[0].id)?'equal to':return_word(item.property_comparator)}</span>
														<span>
															{
																item.property_value && Array.isArray(item.property_value) && item.property_value.length ? item.property_value.map((x_id,idx) => (	
																	item.property_name[0].id==='tag'?
																		<span key={idx}>{idx===0?'':<span style={{marginRight:'5px'}}>{item.property_conjunction}</span>}<b style={{marginRight:'5px'}}>{x_id.tag}</b></span>
																		:<span key={idx}>{idx===0?'':<span style={{marginRight:'5px'}}>{item.property_conjunction}</span>}<b style={{marginRight:'5px'}}>{x_id.name}</b></span>
																	))
																:
																item ?<b>{item.property_value}</b>:''
															}
														</span>
													</div>
													{/* {values_text(item.property_value,item.property_comparator?item.property_comparator:'and')} */}
												</div>
	  										)):''	
										}
									</Col>
								</Row>
									
								<Row style={{marginBottom:'15px'}}>
									<Col>
										<div style={{fontSize:'18px', color:'#212529'}}>Then do the following action:</div>
									</Col>
								</Row>
								
								<Row style={{margin:'0px', backgroundColor:'white'}}>
									<Col style={{padding:'10px 30px', fontSize:'16px'}}>
										{
											item_json_action && item_json_action.length && item_json_action[0].property_value?item_json_action.map((item, idx) =>(
												<div key={idx} style={{padding:'10px 0px', display:'flex'}}>
													<div style={{flex:1}}>
														<span style={{marginRight:'20px'}}>{idx===0?'Condition '+(idx+1):'\nCondition '+(idx+1)}</span> 
													</div>
													<div style={{flex:4}}>
														{idx===0?'':<span style={{marginRight:'5px'}}>{source_condition_combination_logic}</span>}
														<b style={{marginRight:'5px'}}>{item.property_name && item.property_name.length ?item.property_name[0].name:''}</b>
														<span style={{marginRight:'5px'}}>{item.property_name[0] && ['tag', 'sales_channel'].includes(item.property_name[0].id)?'equal to':return_word(item.property_comparator)}</span>
														<span>
															{
																item.property_value && Array.isArray(item.property_value) && item.property_value.length ? item.property_value.map((x_id,idx) => (	
																	item.property_name[0] && item.property_name[0].id==='tag'?
																		<span key={idx} style={{marginRight:'5px'}}><span style={{marginRight:'5px'}}>{idx===0?'':item.property_conjunction}</span><b style={{marginRight:'5px'}}>{x_id.tag}</b></span>
																		:
																		<b>{x_id.name}</b>
																	))
																:
																item ?<b>{item.property_value}</b>:''
															}
														</span>
													</div>
													{/* {values_text(item.property_value,item.property_comparator?item.property_comparator:'and')} */}
												</div>
	  										)):''	
										}										
									</Col>
								</Row>
									
							</Col>
							<Col xs={1}></Col>
						</Row>
					</CardBody>
				}
				<CardFooter className='global_modal_footer'>
					{/* {mf_rule_step==='2'?
						<Button className='white_button' style={{fontSize:'14px'}} type="button" onClick={() => {set_mf_rule_step('1') ;set_is_back_button(true)}}>Back</Button>
						:''
					} */}
					<Button className='white_button' style={{fontSize:'14px', marginRight:mode=='view'?'5px':'12px'}} type="button" onClick={() => {mode=='view'?onclick_handleclose():show_escape_warning_dialog()}}>{mode=='view'?'Close':'Cancel'}</Button>
					{
						mode=='view'?'':<Button disabled={mode === 'edit' && !is_modified} id = 'add_edit_am_rule_button' className='blue_button' style={{fontSize:'14px'}} type="button" onClick={mode==='add'?onclick_add_new_rule:mode==='edit'?onclick_edit_rule:onclick_handleclose}> {mode==='add'?'Add': mode==='edit' ? 'Update': "Done"}</Button>
					}
					{/* <Button className='blue_button' style={{fontSize:'14px'}} type="button" onClick={mf_rule_step==='1'?() => set_mf_rule_step('2'):mode==='add'?onclick_add_new_rule:onclick_edit_rule}> {mf_rule_step==='1'?'Preview':mode==='add'?'Add':'Edit'}</Button> */}
					{/* onClick={mode==='add'?onclick_add_new_rule:onclick_edit_rule} */}
				</CardFooter>
			</Card>  
		</Modal>
  	);
}

export default AMRulesDialogBox ;

//rule_name && item_json_condition && item_json_action && cabinet_subunit

//${x_item_json_action_dummy.length-1===idx?"":source_condition_combination_logic}
													// has ${dest_condition_combinator_logic}

// ( x_text.concat(values_Array.length-1!=idx?x_id.tag.toLowerCase()+' '+logic+' '+' ':x_id.tag.toLowerCase()))
																		// :
																		// ( x_text.concat(values_Array.length-1!=idx?x_id.name.toLowerCase()+' '+logic+' '+' ':x_id.name.toLowerCase()))