import React , { useState , useEffect, useContext }from 'react';
import general_fetch from '../../utils/fetch';
import custom_sort from '../../utils/custom_sort';
import split_cat_type from '../../utils/split_cat_type';
import {Modal, CardImg, Alert, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import { useAlert } from 'react-alert'
import Multiselect from "../../components/Multiselect/";
import HWKeyValuePair_range from '../../components/HWKeyValuePair_range';
import HWLinked_sku_structure from '../../components/HWLinked_sku_structure copy';
import EscapeWarning from '../../components/EscapeWarning';
import { UserContext } from '../../components/Contexts/UserContext';

//import AlertBox from "../../components/AlertBox/";


//generate a 20 character id alphanumerically lesser than the one provided
const generate_id_greater_than = (id) => {
	console.log('id generated in comparison to  ==>> ', id);
	const id_length = 13;
	const char_list = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
	let text = "";
	let randomise = false;
	for (let i = 0; i < id_length; i++) {
		console.log('Generated ID char list ', char_list, randomise)
		let valid_char_list = char_list;
		if (randomise) {
			text += valid_char_list.charAt(Math.floor(Math.random() * valid_char_list.length));
			continue;
		}
		if (id) {
			valid_char_list = char_list.split('').filter(x => x > id[i] || x == id[i]).reduce((a, b) => a + b, '');
			console.log('Generated ID VALID CHAR LIST', valid_char_list);
		} else {
			valid_char_list = '0123456789';
		}
		if (valid_char_list.length > 0) {
			text += valid_char_list[1];
		} else {
			text += valid_char_list[0];
		}
		if (id < text) {
			randomise = true;
		}
	}
	console.log("GENERATED ID", text);
	return text;
}


//generate a random 20 charecter id
const generate_id = () => {
	const id_length = 20;
	const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let text = "";
	for (let i = 0; i < id_length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
}

const styles = {
	error_style: {
		color: "red",
		fontSize: 'small'
	},

};
const shelf_type_values = [{name:'Fixed Shelf',id:'fixed'},{name:'Adjustable Shelf', id:'adjustable'}]
const shutter_mechanism_values = [
	{name:'Single Swing',id:'single_swing', option_type:'cabinet'},
    {name:'Double Swing', id:'double_swing', option_type:'cabinet'},
    {name:'Single Lift Up',id:'single_lift_up', option_type:'cabinet'},
    {name:'Double Lift Up', id:'double_lift_up', option_type:'cabinet'},
    {name:'Bi-fold', id:'bi_fold', option_type:'cabinet'},
    {name:'Left Bi-fold', id:'left_bifold', option_type:'cabinet'},
    {name:'Right Bi-fold', id:'right_bifold', option_type:'cabinet'},
    {name:'Flap Down', id:'flap_down', option_type:'cabinet'},
    {name:'Bi-corner Unit Double Swing', id:'corner_unit_double_swing', option_type:'cabinet'},
    {name:'2 door Sliding Shutter', id:'2_door_sliding_shutter', option_type:'wardrobe'}
]
 
const HWRulesDialogBox = ({open , handleClose , active_rule , designer_inputs, all_brands, fetch_hw_rules_details , mode, set_page_loader, all_division_tree_data, component_options_json, component_tags_json, tags, all_category_type, last_item_order, sales_channels }) => {

	const [rule_name , set_rule_name] = useState("");
	const [rule_description , set_rule_description] = useState("");
	const [rule_activation , set_rule_activation] = useState(false);
	const [condition_combination_logic , set_condition_combination_logic] = useState("and");
	const [applicability_combination_logic, set_applicability_combination_logic] = useState("and");
	const [linked_item_json , set_linked_item_json] = useState([]);
	const [item_json_source , set_item_json_source] = useState([]);
	const [item_json_dest , set_item_json_dest] = useState([]);
	const [active_rule_copy ,set_active_rule_copy ] =useState('');
	const [item_json_dest_component , set_item_json_dest_component] = useState([]);
	const [item_json_dest_subcomponent ,set_item_json_dest_subcomponent ] =useState([])
	const [ all_eligible_cat_type, set_all_eligible_cat_type] = useState([]);
	const [ all_eligible_designer_inputs, set_all_eligible_designer_inputs] = useState([]);
	const [ valid_inputs, set_valid_inputs] = useState([]);
	const [ rule_input_values, set_rule_input_values] = useState([]);
	const [ selected_category_type, set_selected_category_type] = useState([]);
	const [discard_changes, set_discard_changes] = useState(false)
	const [show_escape_warning, set_show_escape_warning] = useState(false)
	const [initialized, set_initialized] = useState(0);
	const alert = useAlert()
	const user = useContext(UserContext)

	useEffect(() => {
		console.log('rule input values array', rule_input_values)
	}, [rule_input_values]);
	
	useEffect(() => {
		console.log("initialized status", initialized)
	}, [initialized]);

	useEffect(() => {
		console.log("valid designer inputs ---> ",valid_inputs);
		if (valid_inputs.length) {
			update_all_input_values();
		} else {
			// set_rule_input_values(rule_input_values.map(x => ({...x, value: []})));
			set_rule_input_values([])
		}
		if(valid_inputs && valid_inputs.length){
			console.log('setting category type --> ', valid_inputs, designer_inputs)
			set_selected_category_type(valid_inputs[0].sku_category_type_id)
			console.log('selected_category_type ====> ',valid_inputs[0].sku_category_type_id)
			var x_list = designer_inputs.filter(x => valid_inputs[0].sku_category_type_id === x.sku_category_type_id)
			set_all_eligible_designer_inputs(x_list)
		}
		console.log('linkjed_item_json $$$$$113')
		if(active_rule && initialized === 0){
			set_initialized(1)
		}else if(active_rule && initialized){
			set_linked_item_json([]);
		}

		if(!active_rule){
			set_linked_item_json([]);
		}
		
	},[ valid_inputs ])

	const add_new_input_value = () => {
		if(valid_inputs && valid_inputs.length){
			// let rule_input_value_id = generate_id(rule_input_values.length?rule_input_values[rule_input_values.length-1].id:"");
			let rule_input_value_id = generate_id_greater_than(rule_input_values.length?rule_input_values[rule_input_values.length-1].id:"");
			console.log("xsxsx input value addition called ---> ",rule_input_value_id);
				console.log("1111111111111111111111")
			set_rule_input_values([
				...rule_input_values,
				{
					id: rule_input_value_id,
					value: valid_inputs.map(valid_input => ({
						rule_input_id: valid_input.id,
						value: valid_input.valid_values[0],
					}))
				}
			]);
			return rule_input_value_id;
		}

		return
	}

	const update_input_value = (generated_id, rule_input_id, value) => {
			console.log("2222222222222222222")
		set_rule_input_values(rule_input_values.map(rule_input_value => {
			if (rule_input_value.id === generated_id) {
				rule_input_value.value = rule_input_value.value.map(rule_input_value_item => {
					if (rule_input_value_item.rule_input_id === rule_input_id) {
						rule_input_value_item.value = value;
					}
					return rule_input_value_item;
				});
			}
			return rule_input_value;
		}));
	}

	const update_all_input_values = () => {
		console.log('updating all input values ---> ',rule_input_values, valid_inputs);
		if (rule_input_values.length) {
			console.log("333333333333333333333")
			set_rule_input_values(rule_input_values.map(rule_input_value => {
				let new_rule_input_value = {
					id: rule_input_value.id,
					value: valid_inputs.map(valid_input => {	
						let existing_entry = rule_input_value.value.filter(x => x.rule_input_id == valid_input.id)[0];
						console.log('existing_entry',existing_entry);
						if (existing_entry) {
							return {
								rule_input_id: valid_input.id,
								value: existing_entry.value
							}
						} else {
							return {
								rule_input_id: valid_input.id,
								value: valid_input.valid_values[0],
							}
						}
					})
				}
				console.log("in setting new rule_inoput", new_rule_input_value)
				return new_rule_input_value;
			}));
		} else {
			add_new_input_value();
		}
	}

	// const onclick_temp_output = async() => {
	// 	var out_temp = {
	// 		rule_name:rule_name,
	// 		rule_description:rule_description,
	// 		tags_array:item_json_source && item_json_source[0] && item_json_source[0].component?item_json_source:[],
	// 		range_array:item_json_dest && item_json_dest[0] && item_json_dest[0].component?item_json_dest:[],
	// 		sku_array:linked_item_json && linked_item_json[0] && linked_item_json[0].sku && linked_item_json[0].sku.length?linked_item_json:[],
	// 	}
	// 	console.log(out_temp)
	// }

	// const onclick_temp_output = () => {

	// 	var tags_and_range_array = [] 
	// 	if(item_json_dest && item_json_source && (item_json_dest[0].component || item_json_source[0].component)){
	// 		item_json_source.map(x => tags_and_range_array.push(x))
	// 		item_json_dest.map(x => tags_and_range_array.push(x))
	// 	}

	// 	var linked_skus = linked_item_json && linked_item_json[0] && linked_item_json[0].sku && linked_item_json[0].sku.length?linked_item_json.map(x => {
	// 		return {
	// 			sku_id:x.sku[0].id,
	// 			quantity:x.quantity
	// 		}
	// 	}):[]
			
	// 	var out_temp = {
	// 		name:rule_name,
	// 		description:rule_description,
	// 		conditions_combination_logic:condition_combination_logic,
	// 		conditions:tags_and_range_array,
	// 		hardware_to_be_added:linked_skus
	// 	}
	// 	console.log(out_temp)
	// 	return out_temp
	// }

	const transform_to_usable = (item_array, key) => {
		if(item_array && item_array.length){
			// var linked_skus = linked_item_json && linked_item_json[0] && linked_item_json[0].sku && linked_item_json[0].sku.length?linked_item_json.map(x => {
			// 	return {
			// 		sku_id:x.sku[0].id,
			// 		quantity:x.quantity
			// 	}
			// }):[]
			var x_temp = item_array.map(x_item =>{
			return({
				id:x_item && x_item.id?x_item.id:null,
				value:(x_item.value && (Array.isArray(x_item.value) && x_item.value.length))? (x_item.value.length? x_item.value.map(x => x.id)[0]:[]): (isNaN(x_item.value)?x_item.value:Number(x_item.value)),
				min_value: (x_item.min_value || x_item.min_value == 0)?Number(x_item.min_value):'',
				max_value: (x_item.max_value || x_item.max_value == 0)?Number(x_item.max_value):'',
				type: x_item.condition_type, 
				index_value: x_item.index_value, 
				operator:x_item.operator, 
				value_combination_logic:x_item.value_combination_logic, 
				sku:((key!='actions' && x_item.property && x_item.property.length && x_item.property[0].id == 'sku') || (key == 'actions')) &&  x_item.sku && x_item.sku.length ?x_item.sku.map(x => x.id):[],
				tag:x_item.property && x_item.property.length && x_item.property[0].id == 'tag' && x_item.tag && x_item.tag.length? x_item.tag.map(x => x.id):null,
				sales_channel:x_item.sales_channel && x_item.sales_channel.length? x_item.sales_channel.map(x => x.id):null,
				source_filter:(x_item.component && x_item.component.length) ? ({
					id:x_item.source_filter && x_item.source_filter.id?x_item.source_filter.id:null,
					type:'source',
					component:x_item.component && x_item.component.length? x_item.component[0].id:[],
					sub_component:x_item.subcomponent && x_item.subcomponent.length? x_item.subcomponent[0].id:[],
					property:x_item.property && x_item.property.length? x_item.property[0].id:[],
				}) : null,
				destination_filter: (x_item.relativecomponent && x_item.relativecomponent.length) ? ({
					type: 'destination',
					component:x_item.relativecomponent && x_item.relativecomponent.length? x_item.relativecomponent[0].id:[],
					sub_component:x_item.relativesubcomponent && x_item.relativesubcomponent.length? x_item.relativesubcomponent[0].id:[],
					property:x_item.relativeproperty && x_item.relativeproperty.length? x_item.relativeproperty[0].id:[],
				}) : null,
				additional_sku_metadata: x_item.additional_sku_metadata,
				...(x_item.rule_input_value_id ? { rule_input_value_id: x_item.rule_input_value_id} : {}),
				...(x_item.default_action ? { default_action: x_item.default_action} : {}),
			})})

			for(let i = 0; i < x_temp.length; i++){
				if(x_temp[i].source_filter){
					if(x_temp[i].source_filter.property == 'tag'){
						console.log('transform_to_usablee tag')
						if(!(x_temp[i].tag && x_temp[i].tag.length == 1 && typeof x_temp[i].tag[0] === 'string')) return -1
					}else if(x_temp[i].source_filter.property == 'sku' && x_temp[i].type == 'absolute'){
						console.log('transform_to_usablee sku', x_temp)
						if(!(x_temp[i].sku && x_temp[i].sku.length == 1 && typeof x_temp[i].sku[0] === 'string')) return -1
					}else if(x_temp[i].source_filter.property == 'height' || x_temp[i].source_filter.property == 'width' || x_temp[i].source_filter.property == 'depth'){
						console.log('transform_to_usablee height')
						if(!(x_temp[i].value >=0 && typeof x_temp[i].value === 'number')) return -1
					}else if(x_temp[i].source_filter.property == 'sales_channel'){
						console.log('transform_to_usablee sales_channel')
						if(!(x_temp[i].sales_channel && x_temp[i].sales_channel.length == 1 && typeof x_temp[i].sales_channel[0] === 'string')) return -1
					}
				}
			} 

			console.log('transform_to_usable', x_temp)
			return x_temp;
		}else{
			return []
		}
	} 

	const check_fn_for_floor = () => {
		if(item_json_dest && item_json_dest.length && item_json_source && item_json_source.length){
			if(item_json_source[0].component[0].id==='floor' && item_json_dest[0].component[0].id!='floor'){
				alert.error("If Applicable component is floor then Condition component must be floor")
				return true
			}	
			if(item_json_source[0].component[0].id==='cabinet' && item_json_dest[0].component[0].id==='floor'){
				alert.error("If Applicable component is cabinet then Condition component cannot be floor")
				return true
			}
			return false
		}
	}

	const check_sku_metadata = (actions) => {
		for(let i=0;i<actions.length;i++){
			if(actions[i].additional_sku_metadata){
				let sku_id = actions[i].sku[0]
				let metadata_array = actions[i].additional_sku_metadata[sku_id]
				let not_set_exists = 0, value_exists = 0
				for(let j=0;j<metadata_array.length;j++){
					if(!metadata_array[j].distance){
						not_set_exists = 1
					}else{
						value_exists = 1	
					}

					if(metadata_array[j].from === 'not_set'){
						not_set_exists = 1
					}else{
						value_exists = 1
					}

					metadata_array[j].distance = Number(metadata_array[j].distance)
				}

				if(not_set_exists && value_exists){
					alert.error('Enter all "distance" and "from" fields for a given SKU or set all to not_set')
					return 0
				}

				if(not_set_exists && !value_exists){
					actions[i].additional_sku_metadata = []
				}
			}
		}
		return 1
	}

	const onclick_add_new_rule = async() => {
		if(!(is_valid_add_key(item_json_source, 'applicable') && is_valid_add_key(item_json_dest,'condition') && is_valid_add_key(linked_item_json,'link_sku'))){
			alert.error("Empty or Duplicate key values not allowed.")
		}else if(check_fn_for_floor()){
			
		}else{
			if(rule_name && rule_description){
				try {
					var temp_order = last_item_order+1024;

					let applicability = transform_to_usable(item_json_source)
					let conditions = transform_to_usable(item_json_dest)
					let actions = transform_to_usable(linked_item_json, 'actions')
					if(applicability == -1 || conditions == -1 || actions == -1) 
					{
						alert.error('Please fill all required details: name, description, applicable and action fields ')
						return
					}

					if(!check_sku_metadata(actions)) return

					var body = {
						applicability_combination_logic,
						applicability:applicability,
						conditions:conditions, 
						actions: actions, 
						condition_combination_logic:condition_combination_logic,
						name:rule_name, 
						description:rule_description, 
						active:rule_activation, 
						type:'hardware_rule',
						action_combination_logic: 'and',
						valid_rule_inputs: valid_inputs.map(x => x.id),
						rule_input_values: rule_input_values.filter(x => x.value && x.value.length),
						order: last_item_order+1024,
						business_unit_id:user.current_business_unit_id
					};
					console.log("firing api", body);
					var response = await general_fetch({ url: 'automation_rule/create', body});	
					onclick_handleclose()
					alert.success('Rule created Successfully !')
					fetch_hw_rules_details()
				} catch(err) {
					console.log(err);
					alert.error("Please check input values or API call failed")
				}
			}else{
				alert.error('Please fill all required details: name, description, applicable and action fields ')
			}
		}	
	}

	const onclick_edit_rule = async() => {
		if(!(is_valid_add_key(item_json_source, 'applicable') && is_valid_add_key(item_json_dest,'condition') && is_valid_add_key(linked_item_json,'link_sku'))){
			alert.error("Empty or Duplicate key values not allowed.")
		}else if(check_fn_for_floor()){
			
		}else{
			if(rule_name && active_rule && rule_description){
				try {

					let applicability = transform_to_usable(item_json_source)
					let conditions = transform_to_usable(item_json_dest)
					let actions = transform_to_usable(linked_item_json, 'actions')
					if(applicability == -1 || conditions == -1 || actions == -1) 
					{
						alert.error('Please fill all required details: name, description, applicable and action fields ')
						return
					}
					if(!check_sku_metadata(actions)) return
					var body = {
						id:active_rule.id?active_rule.id:null,
						applicability_combination_logic,
						applicability:applicability,
						conditions:conditions, 
						actions: actions, 
						condition_combination_logic:condition_combination_logic, 
						name:rule_name, 
						description:rule_description, 
						active:rule_activation, 
						type:'hardware_rule',
						action_combination_logic: 'and',
						valid_rule_inputs: valid_inputs.map(x => x.id),
						rule_input_values: rule_input_values.filter(x => x.value && x.value.length)
					};
					console.log("firing api", body);
					var response = await general_fetch({ url: 'automation_rule/update', body});	
					onclick_handleclose()
					alert.success('Rule Updated Successfully !')
					fetch_hw_rules_details()
				} catch(err) {
					console.log(err);
					alert.error("Please check input values or API call failed")
				}
			}else{
				alert.error('Please fill all required details: name, description, applicable and action fields ')
			}
		}
	}
	
	const is_valid_add_key = (item_json, type) => {
		if(item_json && item_json.length){
			for(var i=0;i<item_json.length;i++){
				if((type==='condition'||type==='applicable') && !(item_json[i].property && item_json[i].property.length)){
					return false;
				}else if(type==='link_sku' && !(linked_item_json[i].sku && linked_item_json[i].sku.length)){
					return false;
				}
			}
			return true;
		}else{
			return true;
		}
	}
	
	useEffect(() => {
		if(active_rule){
			console.log('inside init run active rule ', active_rule);
			let temp = 0;
			if (active_rule.valid_rule_inputs && active_rule.valid_rule_inputs.length) {
				console.log('in setting valid inputs', active_rule, designer_inputs.filter(x => active_rule.valid_rule_inputs.includes(x.id)))
				let designer_inputs_ids = designer_inputs.map(x => x.id)
				active_rule.valid_rule_inputs.map(x => {
					if(!(designer_inputs_ids.includes(x))){
						temp = 1;
					}
				})
				if(temp){
					set_initialized(1)
					set_valid_inputs([])
				}else{
					set_valid_inputs(designer_inputs.filter(x => active_rule.valid_rule_inputs.includes(x.id)));
				}
			}else{
				set_valid_inputs([])
			}
			if (active_rule.rule_input_values && active_rule.rule_input_values.length) {
				console.log("4444444444444444444444", active_rule.rule_input_values)
				set_rule_input_values(active_rule.rule_input_values);
			}
			var x_dest_list, x_source_list, linked_sku_actions = [];
			let rule_input_value_map = rule_input_values.reduce((final, elem) => ({
				...final,
				[elem.id]: false
			}), {});
			linked_sku_actions = active_rule.actions && active_rule.actions.length?active_rule.actions.sort((a, b) => (a.rule_input_value_id > b.rule_input_value_id || a.rule_input_value_id == b.rule_input_value_id || !b.rule_input_value_id) ? 1 : -1).map((item) => {
				// linked_sku_actions = active_rule.actions && active_rule.actions.length?active_rule.actions.map((item) => {

				var x_component = component_options_json?component_options_json.filter(x =>item.destination_filter && x.id===item.destination_filter.component):[]
				var x_sub_component = x_component && x_component.length && x_component[0].sub_component.length? x_component[0].sub_component.filter(x => x.id===item.destination_filter.sub_component):[]
				var x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length? x_sub_component[0].properties.filter(x =>x.id===item.destination_filter.property):[]

				let show_conditional_elements = false;
				if (!rule_input_value_map[item.rule_input_value_id]) {
					rule_input_value_map[item.rule_input_value_id] = true;
					show_conditional_elements = true;
				}

				return{
					sku_id:item.sku[0],
					show_conditional_elements,
					value:Number(item.value),
					relativecomponent:x_component?x_component:[],
					relativesubcomponent:x_sub_component?x_sub_component:[],
					relativeproperty: item.destination_filter && item.destination_filter.property && item.destination_filter.property == 'sku' ? [{ id: 'sku', name: 'SKU' }] : x_selected_property ? x_selected_property : [],
					index_value:item?'per_set':'',
					// condition_type:item?'relative':'',
					condition_type:item && item.type?item.type: 'absolute',
					rule_input_value_id:item.rule_input_value_id,
					additional_sku_metadata: item.additional_sku_metadata,
					default_action:item.default_action
				}
			}):[] 
			if (linked_sku_actions.length) {
				linked_sku_actions = linked_sku_actions.map((x,idx) => ({ ...x, show_addition_element: linked_sku_actions[idx + 1] && linked_sku_actions[idx + 1].rule_input_value_id == x.rule_input_value_id ? false : true, idx:idx }));
			}
			console.log('sku actions updated --> ', linked_sku_actions);
			x_source_list = active_rule.applicability&& active_rule.applicability.length?active_rule.applicability.map(x_item => {
				
				var x_component = component_tags_json?component_tags_json.filter(x =>x.id===x_item.source_filter.component):[]
				var x_sub_component = x_component && x_component.length && x_component[0].sub_component.length? x_component[0].sub_component.filter(x => x.id===x_item.source_filter.sub_component):[]
				var x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length? x_sub_component[0].properties.filter(x =>x.id===x_item.source_filter.property):[]
				
				if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='tag'){
                    var x_selected_value_list = tags && tags.length ?tags.filter(x => x_item.tag.includes(x.id)):[]
                    x_item.tag=x_selected_value_list
                }

				if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='sales_channel'){
                    var x_selected_value_list = sales_channels && sales_channels.length ?sales_channels.filter(x => x_item.sales_channel.includes(x.id)):[]
                    x_item.sales_channel=x_selected_value_list
                }

				return{
					...x_item, 
					component:x_component, 
					subcomponent:x_sub_component, 
					property:x_selected_property
				}
			}):[]
			x_dest_list = active_rule.conditions && active_rule.conditions.length?active_rule.conditions.map(x_item => {
				
				var x_component = component_options_json?component_options_json.filter(x =>x.id===x_item.source_filter.component):[]
				var x_sub_component = x_component && x_component.length && x_component[0].sub_component.length? x_component[0].sub_component.filter(x => x.id===x_item.source_filter.sub_component):[]
				var x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length? x_sub_component[0].properties.filter(x =>x.id===x_item.source_filter.property):[]
				var x_selected_value_list = [];
				if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='tag'){
                    x_selected_value_list = tags && tags.length ?tags.filter(x => x_item.tag.includes(x.id)):[]
                    x_item.tag=x_selected_value_list
                }
                if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='shelf_type'){
                    x_selected_value_list = shelf_type_values.filter(x => x_item.value.includes(x.id)) 
                    x_item.value=x_selected_value_list
                }
                if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='shutter_mechanism'){
                    x_selected_value_list = shutter_mechanism_values.filter(x => x_item.value == x.id)
                    x_item.value=x_selected_value_list
                }

				return{
					...x_item, 
					component:x_component, 
					subcomponent:x_sub_component, 
					property:x_selected_property
				}
			}):[]

			set_item_json_source(x_source_list)
			console.log('soruce list', x_source_list)
			set_item_json_dest(x_dest_list)
			
			if(!temp){				
				set_linked_item_json(linked_sku_actions)
			}

			set_rule_name(active_rule.name)
			set_rule_description(active_rule.description)
			set_rule_activation(active_rule.active ? true : false)
			set_condition_combination_logic(active_rule.condition_combination_logic)
			set_applicability_combination_logic(active_rule.applicability_combination_logic)
			set_active_rule_copy(JSON.parse(JSON.stringify(active_rule)))
			// set_initialized(1)
		}

	},[active_rule])

	// useEffect(() => {
	// 	if(open){
	// 		console.log(tags)
	// 	}
	// },[open, tags])

	const add_new_item_json = (x_item_json,type) => {
		if(is_valid_add_key(x_item_json,type)){
            let a = [...x_item_json];
			// a.push({condition_type:'absolute',component:[], subcomponent:[], property:[] ,relativecomponent:[], relativesubcomponent:[], relativeproperty:[], operator:'equal', division:[], category:[], sub_cat:[], group:[], sku:[], range_max:'', range_min:'', value:[], tag:[], value_combination_logic:'and'})
			if(type==='condition'){
				if(x_item_json && x_item_json.length>0 && x_item_json[0].subcomponent && x_item_json[0].subcomponent.length){
					a.push({condition_type:'absolute',component:item_json_dest_component?item_json_dest_component:[], subcomponent:item_json_dest_subcomponent?item_json_dest_subcomponent:[], property:[] ,relativecomponent:[], relativesubcomponent:[], relativeproperty:[], operator:'equal', division:[], category:[], sub_cat:[], group:[], sku:[], range_max:'', range_min:'', value:[], tag:[], value_combination_logic:'and', index_value:'reset'})
				}else{
					a.push({condition_type:'absolute',component:[], subcomponent:[], property:[] ,relativecomponent:[], relativesubcomponent:[], relativeproperty:[], operator:'equal', division:[], category:[], sub_cat:[], group:[], sku:[], range_max:'', range_min:'', value:[], tag:[], value_combination_logic:'and', index_value:'reset'})
				}
				set_item_json_dest(a)
			}else{
				a.push({condition_type:'absolute',component:[], subcomponent:[], property:[] ,relativecomponent:[], relativesubcomponent:[], relativeproperty:[], operator:'equal', division:[], category:[], sub_cat:[], group:[], sku:[], range_max:'', range_min:'', value:[], tag:[], value_combination_logic:'and'})
				set_item_json_source(a)
			}
        }else{
            alert.error("Empty or Duplicate key values not allowed.")
        }	
	}
	
    useEffect(() =>{
		if(item_json_source && !item_json_source.length){
			add_new_item_json(item_json_source, 'applicable')
		}
	},[item_json_source])

    useEffect(() =>{
		if(item_json_dest && !item_json_dest.length){
			add_new_item_json(item_json_dest, 'condition')
		}else{
			set_item_json_dest_component(item_json_dest[0].component?item_json_dest[0].component:[])
			set_item_json_dest_subcomponent(item_json_dest[0].subcomponent?item_json_dest[0].subcomponent:[])
		}
	},[item_json_dest])

	// useEffect(() =>{
	// 	if(item_json_dest_component && item_json_dest && item_json_dest.length){
	// 		item_json_dest.map((x_item, idx) => {
	// 			item_json_dest[idx].component = item_json_dest_component;
	// 		})
	// 	}
	// },[item_json_dest_component])

	// useEffect(() =>{
	// 	if(item_json_dest_subcomponent  && item_json_dest && item_json_dest.length){
	// 		item_json_dest.map((x_item, idx) => {
	// 			item_json_dest[idx].subcomponent = item_json_dest_subcomponent;
	// 		})
	// 	}
	// },[item_json_dest_subcomponent])
	

	// const add_new_item_json_source = () => {
	// 	if(is_valid_add_key(item_json_source,'source')){
    //         let a = [...item_json_source];
	// 		a.push({component:'', sub_component:'', property:'tag',tag_filtration_logic:'', value_combination_logic:'and', tags:'', min_value:null, max_value:null})
	// 		set_item_json_source(a);
    //     }else{
    //         alert.error("Empty or Duplicate key values not allowed.")
    //     }	
	// }

	// const add_new_item_json_dest = () => {
	// 	if(is_valid_add_key(item_json_dest, 'dest')){
    //         let b = [...item_json_dest];
	// 		b.push({component:'', sub_component:'', property:'', min_value:'', max_value:'', value_combination_logic:"and", tags:null})
	// 		set_item_json_dest(b);
    //     }else{
    //         alert.error("Empty or Duplicate key values not allowed.")
    //     }
	// }
	useEffect(() => {
		console.log("discard", discard_changes)
		if(discard_changes == true){
			onclick_handleclose()
			set_discard_changes(false)
		}
	},[discard_changes])

	const onclick_handleclose = () => {
		// fetch_hw_rules_details()
		handleClose()
		set_item_json_source([])
		set_item_json_dest([])
		set_rule_description('')
		set_rule_name()
		set_linked_item_json([])
		// set_all_eligible_cat_type([])
		set_all_eligible_designer_inputs([])
		set_selected_category_type([])
		set_valid_inputs([])
		set_rule_input_values([])
		set_rule_activation(false)
		set_initialized(0)
	}

	const show_escape_warning_dialog = () => {
		set_show_escape_warning(true)
	}

	const close_escape_warning_dialog = () => {
		set_show_escape_warning(false)
	}

	useEffect(() => {
		console.log('linkjed_item_json changed ====>>>>> ', linked_item_json);
	}, [linked_item_json]);

	useEffect(() => {
		var x_list = []		
		if(all_category_type && all_category_type.length && designer_inputs && designer_inputs.length){
			var input_list_cat_ids = designer_inputs.map( x => x.sku_category_type_id)
			x_list = all_category_type.filter(x => input_list_cat_ids.includes(x.id))
		}
		set_all_eligible_cat_type(x_list);
	},[all_category_type, designer_inputs])

	useEffect(() => {
		var x_list = []		
		if(selected_category_type && designer_inputs && designer_inputs.length){
			x_list = designer_inputs.filter(x => selected_category_type === x.sku_category_type_id)
		}
		set_all_eligible_designer_inputs(x_list)
	},[selected_category_type])

  	return (
		<Modal className="Margin_64px_top" size="xl" isOpen={open} toggle={() => {mode=='view'?onclick_handleclose():show_escape_warning_dialog()}}>
			<EscapeWarning open = {show_escape_warning} set_discard_changes={set_discard_changes} handleClose={close_escape_warning_dialog}/>
			<Card>
				<CardHeader className='global_modal_header'>
						<div style={{width:'100%', display:'flex'}}>
							<div>{(mode=='add') ? "Create a Rule" : "Hardware Rule -"+'\xa0'}</div>
							<div title={mode=='add' ? '' : active_rule ?active_rule.name:''} className='text_ellipsis' style={{width:'calc(100% - 200px)'}}>{mode=='add' ? '' : active_rule ?active_rule.name:''}</div>
						</div>
						<i onClick={() => {mode=='view'?onclick_handleclose():show_escape_warning_dialog()}} style={{cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body' style={{height:'600px'}}>
					<Row style={{marginBottom:'20px', ...(mode=='view' ? { pointerEvents: 'none' } : {}) }}>
						<Col xs={1}></Col>
							<Col xs={10}>
								<Row style={{marginBottom:'20px'}}>
									<Col xs={5}>
										<div>
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Name *</div>
											<Input id='hw_rule_name' style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder="Rule Name" type="text" value={rule_name} onChange={e => set_rule_name(e.target.value)}/>
										</div>
									</Col>	
									<Col xs={5}>
										<div>
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Description*</div>
											<Input id='hw_rule_description' style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder="Rule Description" type="text" value={rule_description} onChange={e => set_rule_description(e.target.value)}/>
										</div>
									</Col>	
									<Col xs={2}>
										<div>
											<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Rule Activation*</div>
											<Input style={{height:'100%',overflowY:'auto',fontSize:'12px',}} type="select"
												value={rule_activation}
												onChange={e => set_rule_activation(e.target.value == 'true')}
											>
												<option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} value='true'>True</option>
												<option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} value='false'>False</option>
											</Input>
										</div>
									</Col>	
								</Row>
								
								<Row>
									<Col>
										<div style={{fontSize:'18px', color:'#212529', marginBottom:'10px'}}>Applicable to</div>
									</Col>	
								</Row>
								{/* <Row style={{margin:0,marginTop:'5px', fontSize:'14px'}}>
									<Col xs={2} style={{display:'flex', alignItems:'center'}}>
										Component
									</Col>
									<Col xs={2} style={{display:'flex', alignItems:"center"}}>
										Sub Component
									</Col>
									<Col xs={2} style={{display:'flex', alignItems:"center"}}>
										Property
									</Col>
									<Col xs={2} style={{display:'flex', alignItems:"center"}}>
										Operator
									</Col>
									<Col xs={3} style={{display:'flex', alignItems:"center"}}>
										Value
									</Col>
									<Col xs={1} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>	
									</Col>
								</Row> */}
								{
									item_json_source ?item_json_source.map((single_item,idx) =>
										<HWKeyValuePair_range 
											open={open} 
											key={idx} 
											item_json={item_json_source} 
											idx={idx} 
											single_item_json={single_item} 
											set_item_json={set_item_json_source} 
											component_options_json={component_tags_json} 
											combination_logic = {applicability_combination_logic}
											set_combination_logic = {set_applicability_combination_logic}
											mode={mode} 
											tags={tags} 
											position_type='applicable' 
											shelf_type_values={shelf_type_values} 
											shutter_mechanism_values={shutter_mechanism_values}
											sales_channels={sales_channels}
										/>
									):''
								}
								<Row style={{margin:0}}>
								{
									mode=='view'?'':
									<Col style={{ paddingLeft:0}}>
										<span onClick={() => add_new_item_json(item_json_source, 'applicable')}  style={{fontSize:'13px', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add Applicability +</span>
									</Col>
								}
								</Row>
								<Row style={{margin:'20px 0px 5px 0px'}}>
									<Col xs={4} style={{padding:0}}>
										<div style={{fontSize:'18px', color:'#212529'}}>If the following criteria is satisfied</div>
									</Col>
								</Row>
								{
									item_json_dest ?item_json_dest.map((single_item,idx) => 
										<HWKeyValuePair_range open={open} key={single_item.id?single_item.id:idx} item_json={item_json_dest} idx={idx} single_item_json={single_item} set_item_json={set_item_json_dest} mode={mode} tags={tags} component_options_json={component_options_json} combination_logic={condition_combination_logic} set_combination_logic={set_condition_combination_logic} position_type='condition'shelf_type_values={shelf_type_values} shutter_mechanism_values={shutter_mechanism_values} item_json_dest_component={item_json_dest_component} item_json_dest_subcomponent={item_json_dest_subcomponent} applicable_array={item_json_source}/>
									):''
								}
								<Row style={{margin:0}}>
								{
									mode=='view'?'':
									<Col style={{ paddingLeft:0}}>
										<span id='add_condition_in_source' onClick={() => add_new_item_json(item_json_dest, 'condition')}  style={{fontSize:'13px', color:'#007BFF', cursor:'pointer', fontWeight:600}}>Add Condition +</span>
									</Col>
								}
								</Row>

								<Row style={{margin:'20px 0px 0px'}}>
									<Col xs={4} style={{padding:0}}>
										<div style={{fontSize:'18px', color:'#212529'}}>Designer Inputs (Optional)</div>
									</Col>
								</Row>

								<Row style={{margin:'10px 0px 0px', backgroundColor: 'white',padding: '20px'}}>
									<Col style={{paddingLeft:0}}>
										<div>
											<div style={{fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '22px'}}>Category Type</div>
											<Input id='hwrules_designer_input_category_type' style={{height:'100%',overflowY:'auto',fontSize:'12px',}} type="select"
												value={selected_category_type}
												onChange={e => {set_selected_category_type(e.target.value); set_valid_inputs([])}}
											>
												<option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} value={null}>Select Category Type</option>
												{
													all_eligible_cat_type && all_eligible_cat_type.length?all_eligible_cat_type.map((x, idx) => 
														<option key={idx} style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} value={x.id}>{x.display_name}</option>
													)
													:''
												}
											</Input>
										</div>
									</Col>
									<Col>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select Designer Input</div>
										<Multiselect
											style={{backgroundColor:'white', width:'100%'}}
											options={all_eligible_designer_inputs && all_eligible_designer_inputs.length? JSON.parse(JSON.stringify(all_eligible_designer_inputs)):[]} 
											selectedValues={valid_inputs}
											onSelect={set_valid_inputs}
											onRemove={set_valid_inputs}
											displayValue='name'// Property name 
											id='select_valid_inputs'
											placeholder='Select Valid Inputs'
										/>	
									</Col>	
								</Row>

								<Row>
									<Col>
										<div style={{fontSize:'18px', marginTop:'20px', marginBottom:'10px', color:'#212529'}}>Hardware to be added</div>
									</Col>	
								</Row>	
									
								<HWLinked_sku_structure 
									add_new_input_value={add_new_input_value} 
									update_input_value={update_input_value} 
									rule_input_values={rule_input_values} 
									valid_inputs={valid_inputs} 
									designer_inputs={designer_inputs} 
									all_brands={all_brands} 
									item_json={linked_item_json} 
									set_item_json={set_linked_item_json} 
									mode={mode} 
									all_division_tree_data={all_division_tree_data} 
									set_item_json_condition={set_item_json_dest} 
									item_json_condition={item_json_dest} 
									component_options_json={component_options_json}
									open={open}
									set_page_loader={set_page_loader}
								/>
							</Col>
						<Col xs={1}></Col>
					</Row>
				</CardBody>

				<CardFooter className='global_modal_footer'>
				{
					mode=='view'?
					<Button className='white_button' style={{fontSize:'14px', marginRight:'5px'}} type="button" onClick={onclick_handleclose}> Close </Button>
					:
					<Button id='add_edit_hw_rule_button' className='blue_button' style={{fontSize:'14px'}} type="button" onClick={mode==='add'?onclick_add_new_rule:mode==='edit'?onclick_edit_rule:onclick_handleclose}> Done </Button>
				}
				</CardFooter>
			</Card>  
		</Modal>
  	);
}

export default HWRulesDialogBox ; 

//rule_name && item_json_source && item_json_dest && component