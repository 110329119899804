import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Button } from 'reactstrap';

const UploadImage = ({selectedImage, setSelectedImage, sizeLimit, pixelLimtX, pixelLimtY, showPreview, inputId='upload_image', isVisible = true, isCustom = true, customName, customClass}) => {
//   const [selectedImage, setSelectedImage] = useState(null);
    const [input_key, set_input_key] = useState('upload_image');
    const alert = useAlert()
    
    
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        // Check image file size
        let limit = 5;
        if(sizeLimit){
            limit = sizeLimit
        }
        if (file.size > limit * 1024 * 1024) {
            alert.error(`Image size exceeds ${limit}MB. Please choose a smaller image.`);
            set_input_key(Date.now())
            return;
        }

        // Create an image object to get the dimensions
        const image = new Image();
        image.onload = () => {
            // Check image dimensions
            if (image.width > 2000 || image.height > 2000) {
            alert.error('Image dimensions exceed 2000x2000. Please choose a smaller image.');
            set_input_key(Date.now())
            return;
            }

            // Set the selected image in state
            setSelectedImage(file);
        };

        // Read the selected image file
        const reader = new FileReader();
        reader.onload = () => {
            image.src = reader.result;
        };
        reader.readAsDataURL(file);
    };

    const onclick_upload_button = () => {
		document.getElementById(inputId).click();
		// document.getElementById(id).value = null
		// console.log("filessss",document.getElementById(id).files)
	}

    return (
        <div style={{display: isVisible ? 'block' : 'none'}}>
            <div style={{display: !isCustom ? 'flex' : 'none'}}>
                <input key={input_key} id={inputId ? inputId : ''} type="file" accept=".jpeg, .jpg,.png" onChange={handleImageUpload} />
                {selectedImage && showPreview && (
                <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected Image"
                    style={{ width: '30px', marginTop: '10px' }}
                />
                )}
            </div>
            {
                isCustom ?
                    <Button className={ customClass ? customClass : "blue_button" } onClick = {onclick_upload_button}>{customName?customName:'Select file'}</Button>
                :''
            }
        </div>
    );
};

export default UploadImage;
