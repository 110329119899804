import React, { useState , useEffect, cloneElement, useContext } from 'react';
import {Dropdown, Label, Modal, FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";

import CheckBox_Inventory_Card from '../../../components/CheckBox_Inventory_Card';
import build_path from '../../../utils/path';
import general_fetch from '../../../utils/fetch';

import custom_sort from '../../../utils/custom_sort';
import Inventory_Layout from '../../../components/Inventory_Layout';
import './sku_groups.css';
import {primary_menu_options} from '../../../components/Sidebar/menu_options.js'
import { useAlert } from 'react-alert'
import LazyLoad from 'react-lazyload';
import name_sort from '../../../utils/name_sort';
import desc_date_sort from '../../../utils/desc_date_sort';
import { OrgBuContext } from '../../../components/Contexts/OrgBuContext';
import { UserContext } from '../../../components/Contexts/UserContext';
import Multiselect from '../../../components/Multiselect';
//import primary_menu_options from '../../../components/Sidebar/menu_options.js'

const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	categories_div: {
		height:'40px', 
		paddingLeft:'20px', 
		display:'flex', 
		alignItems:'center',
		color:'#435A6F',
		cursor: 'pointer'
	},
	text_elipsis: {
	  whiteSpace: 'nowrap',
	  overflowX: 'auto',
	},
	category_dropdown_style: {
		height: '18px',
	    fontSize: '14px',
	    backgroundColor: 'unset',
	    border: 'none',
	    padding: 0,
	    display: 'flex',
	    alignItems: 'center',
	    cursor:'pointer',
	}
};

const Filler = ({ initialized }) => (
	<Table className="align-items-center table-flush text-center" responsive>
		<thead className="thead-light text-center">
			<tr>
				{ initialized ? (
					<th scope="col">
						<span style={styles.table_header}><b>No Inventory to list  </b></span><br/> Try adjusting filters or consider adding from warehouse<br /> &nbsp;
					</th>
				):(
					<th scope="col">
						<Row>
							<Col xs={12} className="text-center">
								<img src="/load2.gif" />
							</Col>
							<Col xs={12} className="text-center">
								<span style={styles.table_header}><b>Fetching your inventory  </b></span><br/> <br /> Please wait...<br /> <br />
							</Col>
						</Row>
					</th>
				)}
			</tr>
		</thead>
	</Table>	
);

const FilterModal = ({open, handleclose, all_brands, filtered_brands, set_filtered_brands}) => {

	const [selected_brands, set_selected_brands] = useState([]);


	const handle_apply = () => {
		set_filtered_brands(selected_brands)
		onclick_handle_close()
	}

	const onclick_handle_close = () => {
		set_selected_brands([])
		handleclose()
	}

	useEffect(() => {
		if(filtered_brands && filtered_brands.length){
			set_selected_brands(filtered_brands)
		}
	}, [filtered_brands]);

	return(
		<div className='afw_filter_modal' style={{ display:open ? 'block' : 'none'}}>
			<CardHeader className='global_modal_header'>
				<div>Filters</div>
				<i className='fa fa-times cp' onClick={onclick_handle_close} />
			</CardHeader>
			<CardBody style={{overflow:'visible'}}>
				<div className='mb-2'>Brand</div>
				<Multiselect 
					options = {all_brands && all_brands.length ? all_brands : []}
					selectedValues= {selected_brands}
					onSelect={(selectedList) => set_selected_brands(selectedList)}
					onRemove={(selectedList) => set_selected_brands(selectedList)}
					displayValue={'name'}
					placeholder={'Select brands'}
				/>
			</CardBody>
			<CardFooter className='global_modal_footer flex_center'>
				<Button className='white_button' onClick={() => set_selected_brands([])}>Reset</Button>
				<Button className='blue_button' onClick={handle_apply}>Apply</Button>
			</CardFooter>
		</div>
	)
}

const Group_collapse  = ({ selected_skus, selected_group_map, update_selected_group_map, selected_sku_map, set_selected_skus, tag, unchecked_group_array, set_unchecked_group_array, checked_group_array, set_checked_group_array, sku_group, user, checked_sku_array, set_checked_sku_array, unchecked_sku_array, set_unchecked_sku_array}) => {	

	const [show_collapse, set_show_collapse] = useState(false)
	// const [active_group_id, set_active_group_id] = useState('')
	// const [active_group_name, set_active_group_name] = useState('')
	const [	checked_map_sku, set_checked_map_sku] =	useState(false);
	const [ group_checked, set_group_checked ] = useState('');
	const [c_unc_indicator_group, set_c_unc_indicator_group] =	useState('');
	const [stop_initial_group, set_stop_initial_group] = useState(false);
	const [display, set_display] = useState('');
	const [ group_selection_map, set_group_selection_map ] = useState({});
	const [ is_group_selected, set_is_group_selected ] = useState(false);


	const open_close_collapse = () => {
		if(sku_group){
			// set_active_group_id(sku_group.id)
			// set_active_group_name(sku_group.name)
			set_show_collapse(!show_collapse)
		}
	}

	const toggle_group_selection = () => {
		let sku_map = sku_group.sku.reduce((final, elem) => ({ ...final, [elem.id]: true }), {});
		set_selected_skus(is_group_selected ? selected_skus.filter(x => !sku_map[x]) : [ ...new Set([ ...selected_skus, ...Object.keys(sku_map) ]) ]);
	}

	const on_click_group_checked = (e) => {
		e.stopPropagation()
		set_group_checked(!group_checked)
		set_stop_initial_group(true)
	}

	const check_group_ticked = () => {
		if(sku_group && sku_group.sku && sku_group.sku.length && checked_sku_array && checked_sku_array.length){
			var check_ticked = sku_group.sku.map((x) => checked_sku_array.includes(x.id))
			var check_ticked_1 = check_ticked && check_ticked.filter((x) => x==false)
			if(check_ticked_1 && check_ticked_1.length==0){
				set_group_checked(true)
			}else{
				set_group_checked(false)
			}
		}
	}

	useEffect(() => {
		console.log('checked_group_array =========>',checked_group_array)
		console.log('unchecked_group_array =========>', unchecked_group_array)
	}, [display])	

	useEffect(() => {
		if(sku_group && stop_initial_group ){
			if(!group_checked){
				var check = checked_group_array.filter((x) => x==sku_group.id);
				set_c_unc_indicator_group(check && check.length==1?'delete':'unchecked')
				set_stop_initial_group(false)
			}
			if(group_checked){
				var check = unchecked_group_array.filter((x) => x==sku_group.id);
				set_c_unc_indicator_group(check && check.length==1?'delete':'checked')
				set_stop_initial_group(false)
			}
		}
	},[group_checked])


	useEffect(() => {
		if (Object.keys(selected_sku_map).length) {
			// console.log('group selection ops ---<>>>> ', selected_sku_map, sku_group.sku, (sku_group.sku.filter(x => !selected_sku_map[x])).length);
			let val = sku_group.sku.filter(x => !selected_sku_map[x.id]).length ? false : true
			// if((is_group_selected && !val) || (!is_group_selected && val)){
			// 	update_selected_group_map(sku_group.id, val)
			// }
			set_is_group_selected(val);
		} else {
			set_is_group_selected(false);
		}
	}, [ selected_sku_map, sku_group ])

	useEffect(() => {
		set_show_collapse(false);
	}, [ sku_group ])


	useEffect(() => {	
		if(!group_checked && c_unc_indicator_group=='unchecked'){
			var x = unchecked_group_array;
			x.push(sku_group.id)
			set_unchecked_group_array(x)
			set_display(!display)
		}
		if(!group_checked && c_unc_indicator_group=='delete'){
			var x = checked_group_array.filter((x1) => x1!=sku_group.id)
			set_checked_group_array(x)
			set_display(!display)
		}
		if(group_checked && c_unc_indicator_group=='checked'){
			var x = checked_group_array;
			x.push(sku_group.id)
			set_checked_group_array(x)
			set_display(!display)
		}
		if(group_checked && c_unc_indicator_group=='delete'){
			var x = unchecked_group_array.filter((x1) => x1!=sku_group.id)
			set_unchecked_group_array(x)
			set_display(!display)
		}
	},[c_unc_indicator_group])

	useEffect(() => {
		if(sku_group && sku_group.Tags && sku_group.Tags.length && tag){
			var check = sku_group.Tags.filter(x => x==tag.id)
			if(check && check.length==1){
				set_group_checked(true)
			}
		}else{
			set_group_checked(false)
		}
	}, [sku_group])
	

	return(
		<div>
			<div onClick={open_close_collapse} style={{height:'50px', padding:'0px 20px', display:'flex', alignItems:'center', backgroundColor: '#FAFAFA', boxShadow: 'inset 0 -1px 0 0 #E4E7EB', cursor: 'pointer'}}>
				<FormGroup style={{padding:0}} check >
					<Label className='checkbox_style' check style={{display:'flex', alignItems:'center'}}>
						<Input style={{margin:0}} type="checkbox" checked={is_group_selected}  onClick={e => {toggle_group_selection(); e.stopPropagation()}} />
					</Label> 
				</FormGroup>
				<span style={{textTransform:'capitalize',paddingLeft:'24px'}}>{sku_group.name}</span>
			</div>
			<div >
				<Row style={{display:show_collapse?'flex':'none', alignItems:'center', margin:0, width:'100%'}}>
				{
					sku_group && sku_group.sku && sku_group.sku.length && sku_group.sku.map((sku_item) => (
						<Single_sku selected_skus={selected_skus} selected_sku_map={selected_sku_map} set_selected_skus={set_selected_skus} tag={tag} stop_initial_group={stop_initial_group} c_unc_indicator_group={c_unc_indicator_group} checked_group_array={checked_group_array} group_checked={group_checked} check_group_ticked={check_group_ticked} user={user} sku_item={sku_item} set_checked_sku_array={set_checked_sku_array} checked_sku_array={checked_sku_array} unchecked_sku_array={unchecked_sku_array} set_unchecked_sku_array={set_unchecked_sku_array}/>
					))
				}
				</Row>
			</div>
		</div>
	)
}

const Single_sku  = ({ selected_skus, selected_sku_map, set_selected_skus, check_group_ticked, tag, stop_initial_group, c_unc_indicator_group, checked_group_array, group_checked, user, sku_item , checked_sku_array, set_checked_sku_array, unchecked_sku_array, set_unchecked_sku_array}) => {	

	const [ is_sku_selected, set_is_sku_selected ] = useState(false);

	const [checked_map_sku, set_checked_map_sku] =	useState('');
	const [c_unc_indicator_sku, set_c_unc_indicator_sku] =	useState('');
	const [stop_initial, set_stop_initial] = useState(false);

	const [display, set_display] = useState('');

	const toggle_sku_selection = () => {
		console.log('In --->>>>> ', selected_sku_map, sku_item, selected_skus.filter(x => x != sku_item.id));
		set_selected_skus(selected_sku_map[sku_item.id] ? selected_skus.filter(x => x != sku_item.id) : [ ...selected_skus, sku_item.id ]);
	}

	const onclick_sku_checkbox = () => {
		set_checked_map_sku(!checked_map_sku)	
		set_stop_initial(true)
		check_group_ticked()
	}
	
	useEffect(() => {
		if(stop_initial_group){
			if(group_checked){
				set_checked_map_sku(true)
			}else{
				set_checked_map_sku(false)
			}
		}
	}, [group_checked])

	useEffect(() => {
			console.log('checked_sku_array ====>',checked_sku_array)
			console.log('unchecked_sku_array ====>', unchecked_sku_array)
	}, [display])


	useEffect(() => {
		if(sku_item && sku_item.id && stop_initial ){
			if(!checked_map_sku){

				var check = checked_sku_array.filter((x) => x==sku_item.id);
				set_c_unc_indicator_sku(check && check.length==1?'delete':'unchecked')
				set_stop_initial(false)
			}
			if(checked_map_sku){

				var check = unchecked_sku_array.filter((x) => x==sku_item.id);
				set_c_unc_indicator_sku(check && check.length==1?'delete':'checked')
				set_stop_initial(false)
			}
		}
	},[checked_map_sku])

	useEffect(() => {
		if(!checked_map_sku && c_unc_indicator_sku=='unchecked'){
			var x = unchecked_sku_array;
			x.push(sku_item.id)
			set_unchecked_sku_array(x)
			set_display(!display)
		}
		if(!checked_map_sku && c_unc_indicator_sku=='delete'){
			var x = checked_sku_array.filter((x1) => x1!=sku_item.id)
			set_checked_sku_array(x)
			set_display(!display)
		}
		if(checked_map_sku && c_unc_indicator_sku=='checked'){
			var x = checked_sku_array;
			x.push(sku_item.id)
			set_checked_sku_array(x)
			set_display(!display)
		}
		if(checked_map_sku && c_unc_indicator_sku=='delete'){
			var x = unchecked_sku_array.filter((x1) => x1!=sku_item.id)
			set_unchecked_sku_array(x)
			set_display(!display)
		}
	},[c_unc_indicator_sku])

	useEffect(() => {
		if(sku_item && sku_item.Tags && sku_item.Tags.length && tag){
			var check = sku_item.Tags.filter(x => x.id==tag.id)
			if(check && check.length==1){
				set_checked_map_sku(true)
			}
		}else{
			set_checked_map_sku(false)
		}
	},[sku_item])


	useEffect(() => {
		if (selected_sku_map[sku_item.id]) {
			set_is_sku_selected(true);
		} else {
			set_is_sku_selected(false);
		}
	}, [ selected_sku_map, sku_item ])


	return(
		<Col xs={12} sm={6} md={4} lg={3} xl={2} style={{padding:'10px', height:'100%'}}>
			<div style={{width:'100%', height:'130px', border: '1px solid #D5DAE0', borderRadius: '4px'}}>
				<div style={{padding:'10px', height:'28px', fontSize:'12px', backgroundColor: '#F8F9FA', borderRadius: '4px 4px 0 0', display:'flex', alignItems:'center' }}>
					<FormGroup style={{padding:0}} check >
						<Label className='checkbox_style' check style={{display:'flex', alignItems:'center', cursor: 'pointer'}}>
							<Input checked={is_sku_selected} onClick={toggle_sku_selection} style={{margin:0}} type="checkbox" />
						</Label>
					</FormGroup>
					<span style={{textTransform:'capitalize',paddingLeft:'24px', ...styles.text_elipsis}}>{sku_item.name ? sku_item.name : ''}</span>
				</div>
				<div style={{width:'100%',border: '1px solid #F2F4F6'}}></div>
				<div style={{height:'calc(100% - 30px)', backgroundColor: 'rgba(255,255,255,0.10)',display:'flex', alignItems:'center', justifyContent:'center'}}><img style={{maxHeight:'100%', maxWidth:'100%', borderRadius: '0 0 4px 4px'}} src={sku_item.display_pic && sku_item.display_pic.image ? build_path(global.config.static_path, sku_item.display_pic.image.replace('.png', '_thumbnail.png').replace('.jpg', '_thumbnail.jpg').replace('.jpeg', '_thumbnail.jpeg')) : global.config.image_not_found}/></div>
			</div>
		</Col>
	)
}

const AFW_modal = ({ sku_sub_category_name, sku_category_id, open, handleclose, sku_sub_category_id, sku_category_name, sku_division_id,set_page_loader, fetch_sku_groups_details, store_details}) => {
	
	//State variables

	const [ sku_categories, set_sku_categories ] = useState([]);
	const [ active_sku_category_id, set_sku_category_id ] = useState(null);
	const [ active_sku_category_name, set_sku_category_name ] = useState(null);

	const [ sku_group_map, set_sku_group_map ] = useState([]);

	const alert = useAlert()

	const [ selected_skus, set_selected_skus ] = useState([]);
	const [ selected_sku_map, set_selected_sku_map ] = useState({});
	const [selected_group_map, set_selected_group_map] = useState({});
	const [selected_subcat_map, set_selected_subcat_map] = useState({});

	const [ all_afw_inventory, set_all_afw_inventory ] = useState([]);
	const [filtered_all_afw_inventory, set_filtered_all_afw_inventory] = useState([]);
	const [ afw_categories, set_afw_categories ] = useState('');
	const [ afw_sub_categories, set_afw_sub_categories ] = useState('');
	const [ afw_groups, set_afw_groups ] = useState('');
	const [ afw_skus, set_afw_skus ] = useState('');

	const [ afw_active_division_id, set_afw_active_division_id ] = useState('');
	const [ afw_active_category_id, set_afw_active_category_id ] = useState('');
	const [ afw_active_sub_category_id, set_afw_active_sub_category_id ] = useState('');
	const [ afw_active_group_id, set_afw_active_group_id ] = useState('');
	
	const [ afw_active_category_name, set_afw_active_category_name ] = useState('');
	const [ afw_active_sub_category_name, set_afw_active_sub_category_name ] = useState('');
	const [ afw_active_group_name, set_afw_active_group_name ] = useState('');

	const [category_dropdown_open, set_category_dropdown_open] = useState(false)

	const [	checked_sku_array, set_checked_sku_array] =	useState([]);
	const [	unchecked_sku_array, set_unchecked_sku_array] =	useState([]);
	const [	checked_group_array, set_checked_group_array] =	useState([]);
	const [	unchecked_group_array, set_unchecked_group_array] =	useState([]);

	const [division_json, set_division_json] = useState([])
	const [owned_sales_channels, set_owned_sales_channels] = useState([]);
	const [subscribed_sales_channels, set_subscribed_sales_channels] = useState([]);
	const [selected_public_sales_channel_id, set_selected_public_sales_channel_id] = useState();
	const [filter_active, set_filter_active] = useState(false);
	const [open_filter_modal, set_open_filter_modal] = useState(false);
	const [all_brands, set_all_brands] = useState([]);
	const [filtered_brands, set_filtered_brands] = useState([]);

	const org_bu_view = useContext(OrgBuContext)
	const user = useContext(UserContext)
	// const [ checked_list, set_checked_list ] = useState([]);

	// const[open_alert_box , set_open_alert_box] = useState(false);
	// const[current_state_alert , set_current_state_alert] = useState(null);
	

	//Member functions 

	// const fetch_sku_category_tree = async () => {
	// 	if(afw_active_division_id){
	// 		try {
	// 			console.log('Fetching categories tree!!')
	// 			var attributes = JSON.stringify({});
	// 			var identifiers = JSON.stringify({ store_id: user.store_id , sku_division_id: afw_active_division_id });
	// 			var categories_response = await general_fetch({ url: 'sales_channel/get_eligible_division_tree' , body: { identifiers , attributes } });
	// 			console.log('cat resp ====>>>>> ', categories_response);
	// 			set_afw_categories(categories_response);
	// 		} catch(err) {
	// 			console.log(err);
	// 		}
	// 	} else {
	// 		console.log('could not fetch categories +++++>>>> no sku_division selected');
	// 	}
	// }

	// const fetch_afw_sub_category_tree = async() => {
	// 	if(afw_active_category_id){
	// 		try {
	// 			console.log('Fetching sub categories tree!!')
	// 			set_afw_sub_categories('');
	// 			var attributes = JSON.stringify({});
	// 			var identifiers = JSON.stringify({ store_id: user.store_id, sku_category_id: afw_active_category_id });
	// 			var sub_categories_response = await general_fetch({ url: 'sales_channel/get_eligible_sub_category_tree' , body: { identifiers , attributes } });
	// 			set_afw_sub_categories(sub_categories_response);
	// 			set_sku_group_map(sub_categories_response.reduce((final, sub_cat) => ({ ...final, ...sub_cat.sku_group.reduce((f, sku_grp) => ({ ...f, [ sku_grp.id ]: sku_grp.sku }), {}) }), {}));
	// 			console.log('sub cat resp ---> ', sub_categories_response);
	// 		}catch(err) {
	// 			console.log(err);
	// 		}
	// 	}
	// }

	const update_selected_group_map = (id, val) => {
		console.log('toggle_subcat_selecion111')
		let a = JSON.parse(JSON.stringify(selected_group_map))
		a[id] = val
		set_selected_group_map(a)
	}

	const update_selected_subcat_map = (id, val) => {
		let a = JSON.parse(JSON.stringify(selected_subcat_map))
		a[id] = val
		set_selected_subcat_map(a)
	}

	const fetch_all_afw_inventory = async (type) => {
		try {
			if(type==='fetch'){
				set_page_loader({
					show:true,
					text:'Fetching inventory might take few seconds...'
				})
			}else{
				set_page_loader({
					show:true,
					text:'Updating inventory might take few seconds...'
				})
			}
			set_afw_categories('')		
			set_afw_sub_categories('')
			set_afw_groups('')
			set_afw_skus('')
			set_afw_active_division_id('')
			set_afw_active_category_id('')
			set_afw_active_sub_category_id('')
			set_afw_active_group_id('')
			let body = {business_unit_id: user.current_business_unit_id}
			if(selected_public_sales_channel_id){
				body['sales_channel_ids'] = [selected_public_sales_channel_id]
			}
			body['include_brand_info'] = true
			var resp = await general_fetch({ url: 'inventory/get_all_from_warehouse', body });
			set_page_loader({
				show:false,
				text:''
			})
			set_all_afw_inventory(resp?.inventory);
			let temp_brands = []
			let t = resp && resp.brands && Object.keys(resp.brands) && Object.keys(resp.brands).length ? Object.keys(resp.brands).map(x => temp_brands.push(resp.brands[x])):""
			set_all_brands(temp_brands)
			console.log('resp', resp)
		} catch(err) {
			set_page_loader({
				show:true,
				text:'Please contact admin. Failed to fetch'
			})
			console.log(err);
		}
	}

	// const fetch_subscribed_sales_channels = async() => {
	// 	try{

	// 		let resp = await general_fetch({url:'sales_channel/subscription_request/get', body: {filter_by_org: true}})
	// 		let subscription_requests_data =  resp.sales_channel_subscription_requests
	// 		let t = Object.keys(subscription_requests_data) && Object.keys(subscription_requests_data).length ? Object.keys(subscription_requests_data).map((x) => {
	// 			subscription_requests_data[x].sort((a,b) => desc_date_sort(b,a,'created_at'))
	// 		}):''
	// 		// set_subscription_requests_data(resp)

	// 		resp = await general_fetch({url:'sales_channel/get_public', body: {include_org_info: true, include_subscription_requests_info: false}})
	// 		let public_sales_channel_details = []
	// 		t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {
	// 				if(store_details && resp.sales_channels[x].store_id != store_details.id && (resp.sales_channels[x].name === 'Infurnia Supply Network' || (subscription_requests_data[x] && subscription_requests_data[x].length && subscription_requests_data[x][0].status === 'approved')) ) {
	// 					if(resp.sales_channels[x].name === 'Infurnia Supply Network') set_selected_public_sales_channel_id(x)
	// 					resp.sales_channels[x].store_details = resp.orgs[resp.sales_channels[x].store_id];
	// 					public_sales_channel_details.push(resp.sales_channels[x])
	// 				}}) : []

	// 		set_subscribed_sales_channels(public_sales_channel_details)
	// 		console.log('public_sales_channel_details', public_sales_channel_details)
	// 	}catch(err){	
	// 		console.log(err);
	// 	}
	// }

	const fetch_sales_channel_details = async () => {
		try {
			// var brand_timer = setInterval(() => {
			// 	var x = Number(fetch_progress);
			// 	console.log('xxx ---> ', x,x + Math.floor((100 - x)/2))
			// 	set_fetch_progress(String(x + Math.floor((100 - x)/2)))  
			// }, 200);

			let resp, sales_channel_details = [], subscribed_sales_channel_details = []
			let body = {
				"include_sales_channel_profile_info": true, 
				"include_bu_mapping": true, 
				"include_bu_info": false, 
				"include_price_type_mapping": true, 
				"include_price_type_info": true, 
				"include_user_mapping": true, 
				"include_user_info": false
			}

			if(org_bu_view === 'bu'){
				if(user && user.current_business_unit_id){
					body['business_unit_ids'] = [user.current_business_unit_id]
					resp = await general_fetch({ url: 'sales_channel/get_by_business_unit' , body})
					let owned_sc = resp && resp.sales_channels_by_business_unit && resp.sales_channels_by_business_unit[user.current_business_unit_id] ? resp.sales_channels_by_business_unit[user.current_business_unit_id].owned : []
					let t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(owned_sc.includes(x)) sales_channel_details.push(resp.sales_channels[x])}) : []
					set_owned_sales_channels(sales_channel_details)

					let subscribed_sc = resp && resp.sales_channels_by_business_unit && resp.sales_channels_by_business_unit[user.current_business_unit_id] ? resp.sales_channels_by_business_unit[user.current_business_unit_id].subscribed : []
					t = resp && resp.sales_channels && Object.keys(resp.sales_channels) && Object.keys(resp.sales_channels).length ? Object.keys(resp.sales_channels).map(x => {if(subscribed_sc.includes(x)) subscribed_sales_channel_details.push(resp.sales_channels[x])}) : []
					set_subscribed_sales_channels(subscribed_sales_channel_details)
					console.log('Infurnia Supply Network', sales_channel_details, subscribed_sales_channel_details)
					if(subscribed_sales_channel_details && subscribed_sales_channel_details.length){
						let isn = subscribed_sales_channel_details.find(o => o.id === '11513556338547665234')
						console.log('Infurnia Supply Network', isn)
						if(isn){
							set_selected_public_sales_channel_id(isn.id)
						}else{
							set_selected_public_sales_channel_id(subscribed_sales_channel_details && subscribed_sales_channel_details.length ? subscribed_sales_channel_details[0].id : '')
						}
					}
				}
			}else{
				resp = await general_fetch({ url: 'sales_channel/get_of_store' , body})
				sales_channel_details = resp && resp.sales_channels && resp.sales_channels.owned ? resp.sales_channels.owned.filter(x => !x.business_unit_id) : []
			}
		} catch(err) {
			console.log(err);
		}
	}

	const toggle_subcat_selecion = (subcat_id) => {
		let temp_sku_array = afw_groups.reduce((final, elem) => ([ ...final, ...elem.sku ]), []);
		let temp_sku_map = temp_sku_array.reduce((final, elem) => ({ ...final, [elem.id]: true}), {}) 
		let value = selected_subcat_map[subcat_id]
		set_selected_skus(value ? selected_skus.filter(x => !temp_sku_map[x]) : [ ...new Set([ ...selected_skus, ...Object.keys(temp_sku_map) ]) ])
		
	}
	

	//Effects

	useEffect(() => {
		if(open){
			fetch_sales_channel_details()
			// fetch_all_afw_inventory('fetch')
		}
	},[open])

	useEffect(() => {
		if(filtered_all_afw_inventory) {
			console.log('set_division_json', filtered_all_afw_inventory)
			set_division_json(filtered_all_afw_inventory)
		}else{
			if(open){
				
			}
		}
	},[ filtered_all_afw_inventory ])

	useEffect(() => {
		if(division_json && division_json.length) {
			if(filtered_brands && filtered_brands.length){
				var active_division_id_details = division_json[0]
				console.log('set_afw_active_sub_category_id66', active_division_id_details);
			} else{
				var active_division_id_details = division_json.find( x => x.id ===sku_division_id)
			}
			set_afw_active_division_id(active_division_id_details?active_division_id_details.id:'')
		}
	},[ division_json ])

	
	useEffect(() => {
		if(division_json && division_json.length && afw_active_division_id){
			let active_awf_categories = division_json.find( x => x.id ===afw_active_division_id) 
			active_awf_categories = active_awf_categories && active_awf_categories.sku_category && active_awf_categories.sku_category.length ? active_awf_categories.sku_category.sort(name_sort):[]
			active_awf_categories = active_awf_categories && active_awf_categories.length ? active_awf_categories.filter(x => x.deprecated != 1):[]
			set_afw_categories(active_awf_categories)
			console.log('set_afw_active_sub_category_id55', active_awf_categories);
		}
	},[afw_active_division_id])
	
	useEffect(() => {
		if(afw_categories && afw_categories.length){	
			if(sku_division_id===afw_active_division_id && afw_categories.find(x=> x.id===sku_category_id) && !(filtered_brands && filtered_brands.length)){
				set_afw_active_category_id(sku_category_id ? sku_category_id :"")
				console.log('set_afw_active_sub_category_id44', sku_category_id);
			}
			else{
				console.log('set_afw_active_sub_category_id33', sku_category_id);
				set_afw_active_category_id(afw_categories[0].id)
			}
		}
	},[afw_categories])

	useEffect(() => {
		if(afw_categories && afw_categories.length && afw_active_category_id){
			let afw_active_category_details = afw_categories.find(x=> x.id===afw_active_category_id)
			set_afw_active_category_name(afw_active_category_details?afw_active_category_details.name:'')
			let afw_active_sub_categories = afw_active_category_details && afw_active_category_details.sku_sub_category ? afw_active_category_details.sku_sub_category:[]
			afw_active_sub_categories = afw_active_sub_categories && afw_active_sub_categories.length ? afw_active_sub_categories.filter(x => x.deprecated != 1):[]
			set_afw_sub_categories(afw_active_sub_categories)
			console.log('set_afw_active_sub_category_id22', afw_active_category_details);
		}
	},[afw_active_category_id])
	
	useEffect(() => {
		if(afw_sub_categories && afw_sub_categories.length){
			if(afw_sub_categories.find(x=> x.id===sku_sub_category_id) && !(filtered_brands && filtered_brands.length)){
				console.log('set_afw_active_sub_category_id', sku_group_map, filtered_brands);
				set_afw_active_sub_category_id(sku_sub_category_id ? sku_sub_category_id :"")
			}else{
				console.log('set_afw_active_sub_category_id11', sku_group_map);
				set_afw_active_sub_category_id(afw_sub_categories[0].id)
			}
			set_sku_group_map(afw_sub_categories.reduce((final, sub_cat) => ({ ...final, ...sub_cat.sku_group.reduce((f, sku_grp) => ({ ...f, [ sku_grp.id ]: sku_grp.sku }), {}) }), {}));
		}
	},[afw_sub_categories])	

	useEffect(() => {
		if(sku_group_map){
			console.log('sub cat resp ---> ', sku_group_map);
		}
	},[sku_group_map])

	useEffect(() => {
		if(afw_active_sub_category_id && afw_sub_categories && afw_sub_categories.length){	
			let afw_active_sub_category_details = afw_sub_categories.find(x=> x.id===afw_active_sub_category_id)
			set_afw_active_sub_category_name(afw_active_sub_category_details?afw_active_sub_category_details.name:'')
			let afw_active_groups = afw_active_sub_category_details && afw_active_sub_category_details.sku_group ? afw_active_sub_category_details.sku_group:[]
			afw_active_groups = afw_active_groups && afw_active_groups.length ? afw_active_groups.filter(x => x.depricated != 1):[]
			set_afw_groups(afw_active_groups)
		}else{
			set_afw_groups('');
		}
	},[afw_active_sub_category_id])

	useEffect(() => {
		if(afw_groups && afw_groups.length){
			set_afw_active_group_id(afw_groups[0].id)
		}
	},[afw_groups])

	useEffect(() => {
		if(afw_active_group_id && afw_groups && afw_groups.length){
			if(filtered_brands && filtered_brands.length){
				var afw_active_group_details = afw_groups[0]
			}else{
				var afw_active_group_details = afw_groups.find(x=> x.id===afw_active_group_id)
			}	
			set_afw_skus(afw_active_group_details && afw_active_group_details.sku ? afw_active_group_details.sku[0]:[])
		}
	},[afw_active_group_id])

	useEffect(() => {
		if(selected_sku_map && Object.keys(selected_sku_map).length){
			let temp_sku_array = afw_groups.reduce((final, elem) => ([ ...final, ...elem.sku ]), []);
			let val = temp_sku_array.filter(x => !selected_sku_map[x.id]).length ? false : true
			if((selected_subcat_map[afw_active_sub_category_id] && !val) || (!selected_subcat_map[afw_active_sub_category_id] && val)){
				update_selected_subcat_map(afw_active_sub_category_id, val)
			}
		}else{
			update_selected_subcat_map(afw_active_sub_category_id, false)
		}
	}, [selected_sku_map]);

	useEffect(() => {
		if(selected_public_sales_channel_id) fetch_all_afw_inventory('fetch')
	}, [selected_public_sales_channel_id]);

	useEffect(() => {
		if(filtered_brands && filtered_brands.length){
			set_afw_categories('')		
			set_afw_sub_categories('')
			set_afw_groups('')
			set_afw_skus('')
			set_afw_active_division_id('')
			set_afw_active_category_id('')
			set_afw_active_sub_category_id('')
			set_afw_active_group_id('')

			set_filter_active(true)

			set_page_loader({show:true, text: 'Filtering Inventory...'})

			let temp_inventory = JSON.parse(JSON.stringify(all_afw_inventory))
			let final_inventory = []

			if(temp_inventory && temp_inventory.length){

				for(let i = 0; i < temp_inventory.length; i++){

					let categories = temp_inventory[i].sku_category
					let final_categories = []
					if(categories && categories.length){
						for(let j = 0; j < categories.length; j++){
							let sub_categories = categories[j].sku_sub_category
							let final_subcats = []
							if(sub_categories && sub_categories.length){
								for(let k = 0; k < sub_categories.length; k++){
									let groups = sub_categories[k].sku_group
									let final_groups = []
									if(groups && groups.length){
										for(let l = 0; l < groups.length; l++){
											let skus = groups[l].sku
											let final_skus = []
											if(skus && skus.length){
												for(let m = 0; m < skus.length; m++){
													if(skus[m].brand_id && filtered_brands.find(o => o.id === skus[m].brand_id)){
														final_skus.push(skus[m])
													}
												}
											}
											groups[l].sku = final_skus
											if(final_skus.length){
												final_groups.push(groups[l])
											}
										}
									}
									sub_categories[k].sku_group = final_groups
									if(final_groups.length){
										final_subcats.push(sub_categories[k])
									}
									console.log('final_groups', final_groups)
								}
							}
							categories[j].sku_sub_category = final_subcats
							if(final_subcats.length){
								final_categories.push(categories[j])
							}
						}
						console.log('final_categories', final_categories)
					}
					temp_inventory[i].sku_category = final_categories
					if(final_categories.length){
						final_inventory.push(temp_inventory[i])
					}
				}
			}
			console.log('final_inventory', final_inventory)
			set_filtered_all_afw_inventory(final_inventory)
			set_page_loader({show:false})

		}else{
			console.log('set_division_json', all_afw_inventory)
			set_filtered_all_afw_inventory(all_afw_inventory)
			set_filter_active(false)
		}

		set_selected_skus([])
	}, [filtered_brands,all_afw_inventory]);

	// useEffect(() => {
	// 	console.log('selected_group_map', selected_group_map)
	// 	if(selected_group_map && Object.keys(selected_group_map).length){
	// 		let val = afw_groups.filter(o => !selected_group_map[o.id]).length ? false : true
			// if((selected_subcat_map[afw_active_sub_category_id] && !val) || (!selected_subcat_map[afw_active_sub_category_id] && val)){
			// 	console.log('selected_group_map', selected_group_map, afw_groups.filter(o => !selected_group_map[o.id]))
			// 	update_selected_subcat_map(afw_active_sub_category_id, val)
			// }
	// 	}
	// }, [selected_group_map]);

	// useEffect(() => {
	// 	if(afw_active_group_id && afw_active_group_details && afw_groups && afw_groups.length){
	// 		var response3= afw_groups && afw_groups.length && afw_groups.filter((x) => x.id==afw_active_group_id)
	// 		var skus_response= response3&&response3.length && response3[0].sku
	// 		set_afw_skus(skus_response)
	// 	}
	// },[afw_active_group_details, afw_active_group_id])
	
	const onclick_apply_changes = async () =>{
		try{
			if(selected_skus && selected_skus.length){
				let body = {
					sku_ids : selected_skus,
				}
				
				set_page_loader({show:true, text: 'Please wait ...'})
				if(org_bu_view === 'bu'){
					body['business_unit_id'] = user.current_business_unit_id
					if(selected_public_sales_channel_id) body['from_sales_channel_id'] = selected_public_sales_channel_id
					if(owned_sales_channels && owned_sales_channels.length){
						let default_sc = owned_sales_channels.find(o => o.type === 'default')
						console.log('default_sc', default_sc)
						if(default_sc){
							body['add_to_sales_channel_id'] = default_sc.id
						}
					}
					var response = await general_fetch({ url: 'sku/add_to_business_unit' , body });
				}else if(org_bu_view === 'org'){
					var response = await general_fetch({ url: 'sku/add_to_store' , body });
				}
				set_page_loader({show:false})
				console.log('response_AFW ==========>', response);
				alert.success('All items are successfully added to inventory.')
				on_click_handleclose()
				// fetch_all_afw_inventory('add')
				fetch_sku_groups_details()
				set_selected_skus([]);
			} else {
				console.log('No inventory added');
				alert.error('Error! Items not added to inventory.')
				on_click_handleclose()
			}
		}catch(err){
			set_page_loader({show:false})
			alert.error('Error! Items not added to inventory.')
			console.log(err)
		}
	}

	const on_click_handleclose = () => {
		handleclose()
		set_afw_categories('')		
		set_afw_sub_categories('')
		set_afw_groups('')
		set_afw_skus('')
		set_afw_active_division_id('')
		set_afw_active_category_id('')
		set_afw_active_sub_category_id('')
		set_afw_active_group_id('')
		set_afw_active_category_name('')
		set_afw_active_sub_category_name('')
		set_afw_active_group_name('')
		set_selected_public_sales_channel_id('')
		set_checked_sku_array([])
		set_checked_group_array([])
		set_unchecked_sku_array([])
		set_unchecked_group_array([])
		set_filtered_brands([])
		set_filter_active(false)
	}

	const onclick_afw_sub_category = (sub_category) => {
		set_afw_active_sub_category_name(sub_category.name ? sub_category.name:'')
		set_afw_active_sub_category_id(sub_category.id ? sub_category.id :'')
	}

	const onclick_afw_division = (division) => {
		set_afw_active_division_id(division.id);
	}

	const onclick_afw_category = (category) => {
		set_afw_active_category_id(category.id);
		set_afw_active_category_name(category.name);	
	}  

	useEffect(() => {
		// console.log(selected_skus)
		set_selected_sku_map(selected_skus.reduce((final, elem) => ({ ...final, [elem]: true }), {}));
	}, [ selected_skus ])

	return(
		<div>
			<Modal className="Margin_64px_top" size="xl" isOpen={open} toggle={on_click_handleclose}>
			<div style={{width:'100%', height:'600px'}}>	
				<div className="modal-header" style={{paddingLeft:'20px', paddingRight:'20px', height:'56px', width:'100%', boxShadow: 'inset 0 -1px 0 0 #E4E7EB'}}>
					<div style={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
						<span style={{fontSize:'18px', color:'#234361', fontFamily: 'Source_Sans_Pro-SemiBold'}}>Add from Warehouse</span>
						<i onClick={on_click_handleclose} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
					</div>		
				</div>

				<div className="modal-body" style={{height:'calc(100% - 56px)', width:'100%', padding:0}}>
					
					<div style={{paddingTop:'10px', width:'240px', height:'calc(100% - 74px)', backgroundColor: 'rgba(255,255,255,0.00)', boxShadow: 'inset -1px 0 0 0 #F2F4F6'}}>
						<div className='ml-3 mr-3 mt-2 mb-2' style={{fontSize:'14px'}}>Select the Organization:</div>
						<Input className='ml-3 mr-3 mb-2 standard_input' style={{width:'200px'}} type='select' value={selected_public_sales_channel_id} onChange={(e) => set_selected_public_sales_channel_id(e.target.value)}>
						{
							subscribed_sales_channels && subscribed_sales_channels.length ? subscribed_sales_channels.map((single_channel) => (
								<option value={single_channel.id}>{single_channel.name == 'Infurnia Supply Network' ? 'Infurnia (Default)' : single_channel.name}</option>
							)):''
						}
						</Input>
						{
							division_json && division_json.length ? division_json.map((division, idx) => (
								<div key={idx} onClick={() => onclick_afw_division(division)} className={division.id==afw_active_division_id ? 'division_active' : 'division_hover'} style={{...styles.categories_div,textTransform:'capitalize'}}>{division.id}</div>
							)):''
						}
					</div>
					<div style={{position:'absolute', bottom:'0px', left:'0px' ,width:'240px', height:'74px'}} />		
					<div style={{position:'absolute', top:'0px', left:'240px' ,width:'calc(100% - 240px)', height:'100%'}}>		
					{
						filtered_all_afw_inventory && filtered_all_afw_inventory.length ?
						<>
							<div style={{height:'40px', padding:'0px 20px', justifyContent:'space-between', position:'relative'}} className='flex_property'>
								<div className='flex_center'>
									<span style={{fontSize:'14px',fontFamily: 'Source_Sans_Pro-SemiBold', color: '#234361', letterSpacing: '-0.05px', lineHeight: '18px'}}>Sub-categories for</span>&nbsp;
									<Dropdown className='x1' isOpen={category_dropdown_open} toggle={() => set_category_dropdown_open(!category_dropdown_open)}>
										<DropdownToggle style={styles.category_dropdown_style} >
											<span style={{fontFamily: 'Source_Sans_Pro-SemiBold', color: '#1070CA' , display:'flex', alignItems:'center'}}>
												<span style={{paddingRight:'5px'}}>{afw_active_category_name ? afw_active_category_name :''}</span>
												<i className='fa fa-caret-down'/>
											</span>
										</DropdownToggle>
										<DropdownMenu style={{maxHeight:'350px', overflow:'auto'}} className='sku_dropdown_list'>
										{
											afw_categories && afw_categories.length ? afw_categories.map((category) => (
												<DropdownItem className={category.id==afw_active_category_id ? 'sub_category_active sku_dropdown_list_item':'sku_dropdown_list_item'} onClick={() => onclick_afw_category(category)}><span style={{textTransform:'capitalize'}}>{category.name}</span></DropdownItem>
											)):''
										}
										</DropdownMenu>
									</Dropdown>
								</div>
								<div className='flex_center pr-2 pl-2 pt-1 pb-1 cp' style={{borderRadius:'4px', border:'1px solid gray', color:filter_active ? '#0078ff' : '', borderColor: filter_active ? '#0078ff' : 'gray'}} onClick={() => set_open_filter_modal(true)}>
									<div className='mr-3' style={{fontSize:'14px'}}>Filter</div>
									{/* <img src='/assets/icons/filter_icon.svg' style={{color:'blue'}} height='16px' width='16px' className='cp' /> */}
									<i className='fa fa-solid fa-filter' style={{}} />
								</div>
								<FilterModal open={open_filter_modal} handleclose={() => set_open_filter_modal(false)} all_brands={all_brands} filtered_brands={filtered_brands} set_filtered_brands={set_filtered_brands}/>
							</div>
							<div style={{height:'50px', padding:'0px 12px', display:'flex', alignItems:'center', width:'100%', overflow:'auto'}}>
								{
									afw_sub_categories && afw_sub_categories.length ? afw_sub_categories.map(sub_category => (
										<div onClick={() => onclick_afw_sub_category(sub_category)} className={sub_category.id==afw_active_sub_category_id ? 'sub_category_active flex_property' : 'flex_property'} style={{height:'28px', fontSize:'12px', padding:'0px 12px', borderRadius:'5px', display:'flex', cursor: 'pointer', alignItems:'center', color:'#435A6F'}}>
											{sub_category.id==afw_active_sub_category_id ? <input type='checkbox' className='mr-2' checked = {selected_subcat_map[sub_category.id]} onClick={() => toggle_subcat_selecion(sub_category.id)} /> : ''}
											{sub_category.name}
										</div>
									)):''
								}
							</div>
							<div style={{height:'40px', padding:'0px 20px', display:'flex', alignItems:'center'}}>
								<span style={{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold', color:'#234361'}}>Groups for <span style={{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold', textTransform: 'capitalize', color:'#234361'}}>{afw_active_sub_category_name?afw_active_sub_category_name:''}</span></span>
							</div>
							<div style={{height:'calc(100% - 180px)', overflow:'auto'}}>
								{
									(afw_groups && afw_groups.length) ? afw_groups.map((sku_group) => (
										<LazyLoad overflow={true}><Group_collapse selected_sku_map={selected_sku_map} selected_group_map={selected_group_map} update_selected_group_map = {update_selected_group_map} selected_skus={selected_skus} set_selected_skus={set_selected_skus} sku_group_map={sku_group_map} unchecked_group_array={unchecked_group_array} set_unchecked_group_array={set_unchecked_group_array} checked_group_array={checked_group_array} set_checked_group_array={set_checked_group_array} sku_group={sku_group} user={user} checked_sku_array={checked_sku_array} set_checked_sku_array={set_checked_sku_array} unchecked_sku_array={unchecked_sku_array} set_unchecked_sku_array={set_unchecked_sku_array}/></LazyLoad>
									)) : (
										''
										// <div>No Groups</div>
										// <Filler initialized={afw_sub_categories && afw_sub_categories.length ? true : false} />
									) 

								}
							</div>
							<div style={{height:'50px', padding:'0px 20px', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
								<Button disabled={!selected_skus.length} onClick={onclick_apply_changes} style={{height:'30px'}} className='blue_button' >Apply</Button>
								{/* onClick={onclick_apply_changes} */}
							</div>
						</>:<div className='flex_center' style={{width:'100%', height:'100%'}}>No Inventory found</div>
					}
					</div>
					
				</div>
			</div>
			</Modal>
		</div>					
	)
}

export default AFW_modal;