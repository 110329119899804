import React, { useState, useEffect, useContext } from 'react';
import { Button, CardFooter, CardHeader, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { UserContext } from '../../components/Contexts/UserContext';
import { OrgBuContext } from '../../components/Contexts/OrgBuContext';
import { useAlert } from 'react-alert'


import general_fetch from '../../utils/fetch';

const SkuSelector = ({ possible_category_types, all_division_tree_data, is_open, toggle, set_page_loader, set_value, set_display_name }) => {
    const org_bu_view = useContext(OrgBuContext)
    const user = useContext(UserContext)
    const [categories, set_categories] = useState([]);
    const [sub_categories, set_sub_categories] = useState([]);
    const [groups, set_groups] = useState([]);
    const [skus, set_skus] = useState([]);
    const [selected_category, set_selected_category] = useState();
    const [selected_sub_category, set_selected_sub_category] = useState();
    const [selected_group, set_selected_group] = useState();
    const [selected_sku, set_selected_sku] = useState();
    const [selected_division, set_selected_division] = useState();
    const alert = useAlert()

    const submit = () => {
        if (selected_sku) {
            set_value(selected_sku)
            let t = skus.find(x => x.id == selected_sku)
            set_display_name(t ? t.name : '')
            toggle()
        } else {
            alert.error("select a valid sku")
            console.error("select a valid sku")
        }
    }

    function getLastPartOfString(str) {
        const parts = str.split('_');
        return parts.pop();
      }
      

    const set_division_categories_fn = (category_id) => {
        let division_id = getLastPartOfString(category_id)
        let division = all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.find(o => o.id == division_id) : ''
        let category = division && division.sku_category && division.sku_category.length ? division.sku_category.find(o => o.sku_category_type_id == category_id) : ''
        set_sub_categories(category ? category.sku_sub_category : [])
        set_groups([])
        set_skus([])

    }

    const set_categories_fn = (division_id) => {
        let division = all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.find(o => o.id == division_id) : ''
        set_categories(division ? division.sku_category : [])
        set_sub_categories([])
        set_groups([])
        set_skus([])
    }

    const set_sub_categories_fn = (category_id) => {
        // if(possible_category_types){
        //     // set_division_categories_fn(category_id)
        // }
        // else{
            let category = categories && categories.length ? categories.find(o => o.id == category_id) : ''
            set_sub_categories(category ? category.sku_sub_category : [])
            set_groups([])
            set_skus([])
        // }
    }

    const get_groups = async (subcat_id) => {
        if (subcat_id) {
            try {
                set_page_loader({
                    show: true,
                    text: 'fetching groups...'
                })
                let body = { sku_sub_category_id: subcat_id }
                if (org_bu_view === 'bu') body['business_unit_id'] = user.current_business_unit_id
                let resp = await general_fetch({ url: 'inventory/get_groups', body })
                set_groups(resp)
                set_page_loader({
                    show: false
                })
            } catch (err) {
                set_page_loader({
                    show: false
                })
                console.log(err)
            }
        } else {
            set_groups([])
            set_skus([])
        }
    }

    const set_skus_fn = (group_id) => {
        let group = groups && groups.length ? groups.find(o => o.id === group_id) : ''
        console.log('skus set', group)
        set_skus(group && group.sku ? group.sku : [])
    }

    const convert_to_readable = (str) => {
        //snake case
        if (!str) return '';
        if (str.includes('_')) {
            return str.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
        }
        //camel case
        let words = str.match(/[A-Za-z][a-z]*/g) || [];
        return words.map(word => (word.charAt(0).toUpperCase() + word.substring(1))).join(" ");
    }

    const handle_close = () => {
        toggle();
        set_categories([])
        set_selected_category('')
        set_sub_categories([])
        set_selected_sub_category('')
        set_groups([])
        set_selected_group('')
        set_skus([])
        set_selected_sku('')
    }


    useEffect(() => {
        // console.log(possible_category_types, "possible category types")
        if (possible_category_types && is_open) {
            let categories_list  = []
            possible_category_types.map((category_type) => {
                let division_id = getLastPartOfString(category_type)
                let division = all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.find(o => o.id == division_id) : ''
                let category_list = division && division.sku_category && division.sku_category.length ? division.sku_category.filter(o => o.sku_category_type_id == category_type) : []
                categories_list = [...categories_list, ...category_list]
            })
            set_categories(categories_list)
        }
    }, [possible_category_types, all_division_tree_data, is_open]);

    return (
        <Modal isOpen={is_open} toggle={handle_close} className='modal-dialog-centered' size='lg'>
            {/* <ModalHeader toggle={handle_close}>Select Sku</ModalHeader> */}
            <CardHeader className='global_modal_header'>
				Select Sku
				<i style={{cursor:'pointer'}} className='fa fa-times' onClick={handle_close} />
			</CardHeader>
            <ModalBody>
                <div style={{ fontSize: '14px' }}>
                    <div className='flex_property' style={{}}>
                        {possible_category_types ? '' : <div style={{ flex: '3' }}>Division</div>}
                        <div style={{ flex: '3' }}>Category</div>
                        <div style={{ flex: '3' }}>Sub Category</div>
                        <div style={{ flex: '3' }}>Group</div>
                        <div style={{ flex: '3' }}>Sku</div>
                        {/* <div style={{flex:'2'}}></div> */}
                    </div>
                    <div className='flex_property' style={{}}>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '12px', width: '100%' }}>
                            {
                                !possible_category_types ?
                                    <div style={{ flex: '3' }}>
                                        <Input type='select' value={selected_division} onChange={(e) => { set_selected_division(e.target.value); set_categories_fn(e.target.value); set_selected_category(''); set_selected_sub_category(''); set_selected_group(''); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                            <option value=''>Select Division</option>
                                            {
                                                all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.map((division, idx) => (
                                                    <option value={division.id}>{convert_to_readable(division.id)}</option>
                                                )) : ''
                                            }
                                        </Input>
                                    </div>
                                    : ''
                            }
                            <div style={{ flex: '3' }}>
                                <Input type='select' value={selected_category} onChange={(e) => { set_selected_category(e.target.value); set_sub_categories_fn(e.target.value); set_selected_sub_category(''); set_selected_group(''); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                    <option value=''>Select Category</option>
                                    {/* {
                                        !possible_category_types ? categories && categories.length ? categories.map((single_category, idx) => (
                                            <option value={single_category.id}>{single_category.name}</option>
                                        )) : ""
                                        :
                                        possible_category_types && possible_category_types.length ? possible_category_types.map((single_category, idx) => (
                                            <option value={single_category.id}>{single_category.name}</option>
                                        )) : ""
                                    } */}
                                    {
                                        categories && categories.length ? categories.map((single_category, idx) => (
                                            <option value={single_category.id}>{single_category.name}</option>
                                        ))
                                        :''
                                    }
                                </Input>
                            </div>
                            <div style={{ flex: '3' }}>
                                <Input type='select' value={selected_sub_category} onChange={(e) => { set_selected_sub_category(e.target.value); get_groups(e.target.value); set_selected_group(''); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                    <option value=''>Select Sub Category</option>
                                    {
                                        sub_categories && sub_categories.length ? sub_categories.map((single_sub_category, idx) => (
                                            <option value={single_sub_category.id}>{single_sub_category.name}</option>
                                        )) : ""
                                    }
                                </Input>
                            </div>
                            <div style={{ flex: '3' }}>
                                <Input type='select' value={selected_group} onChange={(e) => { set_selected_group(e.target.value); set_skus_fn(e.target.value); set_selected_sku('') }} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                    <option value=''>Select Group</option>
                                    {
                                        groups && groups.length ? groups.map((single_group, idx) => (
                                            <option value={single_group.id}>{single_group.name}</option>
                                        )) : ""
                                    }
                                </Input>
                            </div>
                            <div style={{ flex: '3' }}>
                                <Input type='select' value={selected_sku} onChange={(e) => set_selected_sku(e.target.value)} style={{ height: '32px', fontSize: '12px', width: '90%' }}>
                                    <option value=''>Select Sku</option>
                                    {
                                        skus && skus.length ? skus.map((single_sku, idx) => (
                                            <option value={single_sku.id}>{single_sku.name}</option>
                                        )) : ""
                                    }
                                </Input>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
            {/* <ModalFooter>
                <Button onClick={submit}>Submit</Button>
            </ModalFooter> */}
            <CardFooter className='global_modal_footer'>
				<Button className='blue_button' onClick={submit} >Submit</Button>
			</CardFooter>
        </Modal>
    )
}

export default SkuSelector